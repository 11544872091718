import React, { Component } from 'react';
import { FlatList } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Card from '../Card';

export default class ScrollList extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onPress: PropTypes.func,
  };

  render() {
    const {
      items,
      containerStyle,
      cardStyle,
      cardImageStyle,
      onPress,
    } = this.props;
    return (
      <FlatList
        data={items}
        keyExtractor={(item, index) => index.toString()}
        initialNumToRender={4}
        windowSize={5}
        removeClippedSubviews={false}
        horizontal
        nestedScrollEnabled
        style={[styles.container, containerStyle]}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <Card
            key={item.id}
            item={item}
            onPress={onPress}
            style={[styles.cardStyle, cardStyle]}
            imageStyle={[styles.cardImageStyle, cardImageStyle]}
          />
        )}
      />
    );
  }
}
