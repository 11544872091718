import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { I18n } from '../../locales';
import { colors, images } from '../../common';
import Accordion from 'react-native-collapsible/Accordion';
import { ChevronAnimation, Text, SearchBar, Image } from '../../components';
import Fuse from 'fuse.js';
import styles from './styles';

const Areas = ({ handleAreaChange, onClose }) => {
  const areas = useSelector((state) => state.address.areas);
  const [list, setList] = useState([]);
  const [activeSections, setActiveSections] = useState([]);
  const [selectedArea, setSelectedArea] = useState({ zoneID: '' });
  const [key, setKey] = useState('');

  useEffect(() => {
    if (Array.isArray(areas)) {
      setList(groupData(areas));
    }
  }, []);

  const groupData = (data) => {
    return data?.reduce((prev, next) => {
      let ele = prev.length && prev.find((r) => r && r.city === next.city);
      if (ele) {
        ele.data.push({
          area_name: next.area_name,
          id: next.id,
          is_enabled: next.is_enabled,
        });
      } else {
        prev.push({
          city: next.city,
          data: [
            {
              area_name: next.area_name,
              id: next.id,
              is_enabled: next.is_enabled,
            },
          ],
        });
      }
      return prev;
    }, []);
  };

  const onValueChange = (item, index) => {
    handleAreaChange(item, index);
    onClose();
  };

  const renderHeader = (section, index, isActive) => {
    return (
      <View key={index.toString()} style={styles.headerView}>
        <Text style={styles.headerText}>{section.city}</Text>
        <ChevronAnimation open={isActive} />
      </View>
    );
  };

  const renderContent = (section) => {
    return (
      <View style={styles.content}>
        {section.data.map((zone, index) => {
          const { is_enabled, area_name, id } = zone;
          const isSelected = selectedArea.zoneID === id;
          return (
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={() => onValueChange(zone)}
              key={index.toString()}
              style={styles.itemView}
              disabled={!is_enabled}
            >
              <Text
                style={[
                  styles.addText,
                  {
                    color: !is_enabled ? colors.warmGrey : colors.greyishBrown,
                  },
                ]}
              >
                {area_name}
              </Text>
              {!is_enabled && (
                <Text style={[styles.addText, { color: colors.warmGrey }]}>
                  Not Available
                </Text>
              )}
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
  };

  const onSearch = (key) => {
    if (!key.length) {
      setList(groupData(areas));
      setActiveSections(areas);
      setKey('');
      return;
    }
    let options = {
      shouldSort: true,
      findAllMatches: true,
      threshold: 0.0,
      location: 0,
      distance: 2,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['area_name'],
    };

    let fuse = new Fuse(areas, options);
    const result = fuse.search(key);

    setList(groupData(result));
    setKey(key);
    updateSections(Array.from(Array(result.length).keys()));
  };

  return (
    // <Screen>
    <ScrollView
      style={styles.scrollContainer}
      // keyboardShouldPersistTaps={'always'}
    >
      <TouchableOpacity onPress={onClose} style={styles.closeButton}>
        <Image
          source={images.close}
          style={styles.closeIcon}
          resizeMode="contain"
        />
      </TouchableOpacity>
      {/* <SearchBar
        onChangeText={onSearch}
        value={key}
        placeholder={I18n.t('placeholders.searchAreas')}
        onCancel={onClose}
      /> */}
      {list && (
        <Accordion
          touchableProps={{
            activeOpacity: 1,
            underlayColor: 'transparent',
          }}
          sections={list}
          activeSections={activeSections}
          renderHeader={renderHeader}
          renderContent={renderContent}
          onChange={updateSections}
        />
      )}
    </ScrollView>
    //  </Screen>
  );
};
export default Areas;
