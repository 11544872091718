import React from 'react';
import { View, StyleSheet } from 'react-native';
import { I18n } from '../../locales';
import Text from '../Text';
import { colors } from '../../common';
import { isMobile } from '../../services';
const OrLine = ({ title, style }) => (
  <View style={[styles.container, style]}>
    <View style={styles.line} />
    <Text size={isMobile ? 'small' : 'normal'} style={styles.orText}>
      {title || I18n.t('profile.or')}
    </Text>
    <View style={styles.line} />
  </View>
);

const styles = StyleSheet.create({
  container: {
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orText: {
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.grey700,
    marginHorizontal: 16,
  },
  line: {
    height: 1,
    alignSelf: 'center',
    backgroundColor: colors.borderColor,
    flex: 1,
    borderRadius: 6,
  },
});

export default OrLine;
