import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  itemContiner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 299,
    height: 52,
    alignSelf: 'stretch',
    paddingStart: 20,
    paddingEnd: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.borderColor,
    backgroundColor: colors.white,
  },
  textView: {
    alignItems: 'flex-start',
  },
});
