import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: '100%',
    zIndex: 1000,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 83,
    marginHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.grey100,
  },
  searchContainer: {
    height: 48,
  },
  inputContainer: {
    borderRadius: 24,
    backgroundColor: '#F7F7F7',
  },
  languageIcon: {
    width: 24,
    height: 24,
    marginHorizontal: 5,
  },
});
