import types from './types';

const initialState = {
  list: {}, // format: { [parentId]: [...], } where parentId : parentCategoryId || 'home',
  parent_ids: [], // format: [ { id: '', name: '', image: '' }, ...]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WIDGETS.SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.parentId]: action.payload.data,
        },
      };
    }
    case types.GET_WIDGETS_PARENT_IDS.SUCCESS: {
      return {
        ...state,
        parent_ids: action.payload.data,
      };
    }
    default:
      return state;
  }
};
