export default {
  // all screens (in flatten state) and their data
  intro0: {
    title: 'يرجي اختيار اللغة المفضله لك',
  },
  intro1: {
    title: 'تغليف الهدايا',
    description:
      'استمتع بخدمة تغليف الهدايا وشكل هديتك بالطريقة التي تعجبك من خلال إضافة كرت تهنئة والعديد من الإضافات المميزة.',
  },
  intro2: {
    title: 'أكبر تشكيلة',
    description:
      'لدينا مجموعات رائعة من الألعاب التي تم انتقائها لتناسب جميع الأعمار والمناسبات.',
  },
  intro3: {
    title: 'سرعة التوصيل',
    description:
      'استمتع بخدمة التوصيل السريع خلال ٤ ساعات فقط لجميع الطلبات داخل الكويت.',
  },
  selectCountry: {
    title: 'من فضلك اختار دولتك',
  },
  tabBarLabels: {
    home: 'الرئيسية',
    categories: 'الاقسام',
    account: 'حسابي',
    cart: 'السله',
    offers: 'العروض',
  },
  gender: {
    boy: 'أولاد',
    girl: 'بنات',
    boyToys: 'العاب الأولاد',
    girlToys: 'العاب البنات',
    switchBoy: '👦🏻 أولاد',
    switchGirl: '👧🏼 بنات',
  },

  home: {
    title: 'الرئيسية',
    feature: 'الألعاب المميزة',
    appUpdate: 'جاري التحديث',
    updateApp: 'تحديث التطبيق',
    outOfDate:
      'نحن نعمل دائمًا على تحسين تجربتك معنا من خلال تطوير وإثراء تطبيق تيلا بميزات وتحسينات جديدة.',
    callToAction: 'اتصل',
    seeAll: 'المزيد',
    stopOrdersModal: {
      description:
        'شكرا على ثقتكم الكبيرة تم الاكتفاء من استلام الطلبات لهذا اليوم يمكنكم معاودة الطلب غدا.',
    },
  },
  search: {
    errorTitle: 'لم يتم العثور على نتائج',
    errorSubTitle: 'حاول البحث عن شئ آخر.',
  },
  categories: {
    title: 'الأقسام',
  },
  profile: {
    title: 'الملف الشخصي',
    settings: 'الإعدادات',
    hi: 'مرحبا',
    or: 'أو',
    logout: 'تسجيل الخروج',
    login: 'تسجيل الدخول',
    signup: 'إنشاء حساب',
    contactUs: 'إتصل بنا',
    appLanguage: 'إختار اللغة',
    faq: 'الاسئلة الأكثر شيوعاً',
    signChoose: 'أنت لست عضوًا، سجل لدخول أو الاشتراك',
    haveAccount: 'هل لديك حساب؟',
    donthaveAccount: 'ليس لديك حساب؟',
    alreadyHaveAcc: 'هل لديك حساب؟',
    createAccount: 'انشئ حساب',
    wishlist: 'مفضلتي',
    FavouriteProduct: 'منتجاتي المفضلة',
    orderHistory: 'الطلبات السابقة',
    manageAddresses: 'إدارة العناوين',
    logoutModal: {
      description: 'هل ترغب بتسجبل الخروج من حسابك؟',
      title: 'تسجيل الخروج',
    },
    supportModal: {
      title: 'خدمة العملاء',
      description:
        ' لأي استفسار او سؤال يرجى الاتصال علي خدمة العملاء أو التواصل عن طريق البريد الالكتروني support@teeela.com',
      callSupportBtn: 'اتصل بخدمة العملاء',
    },
    appLangModal: {
      title: 'تغيير اللغة',
      description: 'لغة التطبيق التي تريد اختيارها',
    },
    intercom: 'تحدث معنا',
    continueWithEmail: 'الأستمرار بالبريد الألكتروني',
  },
  cart: {
    title: 'سلة التسوق',
    noItems: 'لا يوجد منتجات في سلة التسوق',
    total: 'الإجمالي',
    emptyCart: 'سلة فارغة',
    outOfStockMsg:
      'بعض المنتجات في سلة التسوق قد نفذت من المخزون، يرجى مراجعة السله قبل إتمام الشراء',
    expectedDelivery: 'التوصيل المتوقع',
    sameDayDelivery: 'اليوم خلال 4 ساعات',
    nextDayDelivery: 'التوصيل في اليوم التالي',
    titleDelivery: 'التوصيل',
    warningDelivery: 'بعض المنتجات في سلة المشتريات سيتم توصيلها خلال يومين',
    twoDayDelivery: 'التوصيل خلال يومين عمل',
    internationalDelivery: 'التوصيل خلال 3 ألي 4 أيام عمل',
    couponCodeSuccess: 'تم تطبيق رمز القسيمة بنجاح.',
    deleteCouponCodeSuccess: 'تم حذف رمز القسيمة بنجاح.',
    deliveryDay: {
      same: 'Same',
      next: 'Next',
      two: 'Two',
    },
    giftWrap: {
      title: 'خدمة تغليف الهدايا',
      startGift: '🎁 ابدأ تغليف الهدايا',
      title: 'اجعلها هدية رائعة!',
      description:
        'استمتع بخدمة تغليف الهدايا وشكل هديتك بالطريقة التي تعجبك من خلال إضافة كرت تهنئة.',
      modal: {
        description: 'استمتع بخدمة تغليف الهدايا وأكتب كرت المعايدة',
      },
      placeholders: {
        sender: 'إسم المرسل',
        recipient: 'إسم المستقبل',
        message: 'إكتب رسالتك هنا (العدد المسموح به ٦٠ حرف)',
      },
      addWrapping: 'اضف تغليف الهدايا',
    },
    startShopping: 'ابدأ التسوق',
    startShoppingItems: 'ابدأ التسوق لاضافة المنتجات إلي السلة',
    delete: 'حذف المنتج ؟',
    deleteConfirm: 'هل أنت متأكد من حذف المنتج ؟',
    dismiss: 'إلغاء',
    paymentSummary: 'ملخص الدفع',
    deleteAllProducts: 'هل أنت متأكد من حذف جميع المنتجات والتغليف؟',
    clearCart: 'مسح السلة',
  },
  celebrityPicks: {
    title: 'خيارات المشاهير',
    words: {
      picks: 'الخيارات',
    },
  },
  filters: {
    title: 'التصنيف',
    priceTitle: 'السعر',
    genderTitle: 'النوع',
    ageTitle: 'العمر',
    relevance: 'ذات صلة',
    lowToHigh: 'السعر: الأقل الى الأعلى',
    highToLow: 'السعر: الأعلى الى الأقل',
    words: {
      from: 'من',
      to: 'الى',
    },
    priceErrorMessage: 'يرجي إدخال نطاق سعر صحيح',
  },
  products: {
    title: 'المنتجات',
    outOfStock: 'نفذ من المخزن',
    warpProduct: 'تغليف الهدايا',
    errorTitle: 'لم يتم العثور على منتجات',
    errorSubTitle: 'حاول إعادة تعيين الفلاتر أو أختر قسم آخر.',
    allToys: 'جميع الألعاب',
  },
  productDetails: {
    title: 'تفاصيل المنتج',
    itemSuccess: 'تمت الإضافة',
    descriptionTitle: 'وصف المنتج',
    inStock: 'متوفر',
    outOfStock: 'غير متوفر',
    productInfo: {
      title: 'معلومات المنتج',
      sku: 'رمز المنتج',
      model: 'موديل المنتج',
      weight: 'وزن المنتج',
      age: 'يناسب عمر',
      gender: 'النوع',
      Numbers: 'الارقام',
      brand: 'العلامة التجارية',
      dimensions: 'الأبعاد',
    },
    relatedItemsTitle: 'المنتجات المشابهه',
    inCart: 'في السلة',
    select: 'أختار',
    remove: 'مسح',
    selectYour: 'من فضلك اختار',
    Size: 'الحجم',
    Color: 'اللون',
    Numbers: 'أرقامك',
    size: 'الحجم',
    color: 'اللون',
    numbers: 'أرقامك',
    brandDetails: 'جميع منتجات العلامة التجارية',
    selectOptions: 'حدد من الخيارات',
  },
  wishlist: {
    title: 'مفضلتي',
    items: 'الاصناف',
    noFavorites: 'لا يوجد مفضلات',
    addFavorite: 'حاول إضافة بعض المنتجات للمفضلة.',
  },
  orderHistory: {
    title: 'عرض الطلب',
    noOrder: 'لم يتم العثور على أي طلب من خلال رقم الطلب المستخدم',
    orderNo: 'رقم الطلب',
    orderDate: 'تاريخ الطلب',
    status: 'الحالة',
    paymentMethod: 'طريقة الدفع',
    total: 'الإجمالي',
    email: 'البريد الإلكتروني',
    view: 'تفاصيل',
    payment: 'الدفع',
    emptyOrders: 'لا توجد طلبات حديثة لعرضها حتى الآن. ابدأ بوضع طلب',
    promoCode: 'خصم',
  },
  login: {
    title: 'تسجيل الدخول',
    forgetPass: 'نسيت كلمة الدخول؟',
    donotHaveAnAccount: 'لا تملك حساب تسجيل؟',
    haveAnAccount: 'هل تملك حساب بالفعل ؟',
    signup: 'إنشاء حساب',
    errorMsg: 'لا يوجد حساب لعنوان البريد الإلكتروني المطلوب.',
  },
  signup: {
    title: 'إنشاء حساب',
    description: 'يوجد حساب بهذا البريد الإلكتروني بالفعل',
    resetPassword: 'تغيير كلمة المرور',
  },
  forgetPassword: {
    title: 'نسيت كلمة المرور؟',
    subtitle: 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك.',
    successMsg: 'لقد أرسلنا رابط إعادة تعيين كلمة المرور بنجاح',
  },
  faq: {
    title: 'الاسئلة الأكثر شيوعاً',
  },
  delivery: {
    title: 'التوصيل',
    shippingAddressTitle: 'عنوان الشحن',
    note: 'ملاحظات',
    emailNotice: 'سيتم إستخدام البريد الإلكتروني للإشعار وفاتورة الطلب',
    createAccount: 'إنشاء حساب',
  },
  payment: {
    title: 'الدفع',
    deliveryAddressTitle: 'ملخص التوصيل',
    orderSummaryTitle: 'ملخص المنتجات',
    totalTitle: 'الإجمالي',
    paymentMethodTitle: 'طريقة الدفع',
    knetOrVisa: 'KNET',
    cod: 'كاش',
    card: 'Credit Card',
    mada: 'MADA',
    applePay: 'Apple Pay',
    kuwait: 'الكويت',
    preferredDelivery: 'التسليم المفضل',
    expectedDelivery: 'التوصيل المتوقع',
    yourComments: 'تعليقاتك',
    optional: 'اختياري',
    preferredDate: 'وقت التوصيل',
    infoMessage:
      'سلامتك هي أولويتنا، تم تعطيل الدفع النقدي مؤقتًا ويمكنك استخدام الدفع الإلكتروني',
  },
  order: {
    title: 'الطلب',
  },
  newAddress: {
    title: 'عنوان جديد',
    missingNumber: 'تعديل الحساب',
    missingNumberSubTitle:
      'يفتقد حسابك إلى رقم هاتفك المحمول ، يرجى تحديثه من أجل المتابعة',
    addressTitle: 'أكد معلومات التوصيل',
    confirmBtn: 'تأكيد تحديد الموقع',
    selectAddressOptionTitle: 'معلومات الشحن',
    selectAddressOptionSubTitle:
      'الدقة في العنوان ستساعدنا في الوصول إليك أسرع',
    selectAddressOptionMap: 'حدد موقعي الحالي',
    selectAddressOptionManual: 'أضف العنوان يدوياً',
    iWantToDeliver: 'أريد توصيل الطلب الي عنوان',
  },
  editAddress: {
    title: 'تحرير العنوان',
  },
  manageAddresses: {
    title: 'إدارة العناوين',
    altTitle: 'عناوين التوصيل',
    allAddressesTitle: 'جميع العناوين',
    defaultShippingMsg: 'يرجى إختيار العنوان الإفتراضي',
    newAddressMsg: 'يرجى إضافة عنوان الشحن الجديد',
    noAddressMsg: 'ليس لديك أي عنوان للشحن حتى الآن.',
    sessionExpiredMsg: 'انتهت المدة. يرجى تسجيل الدخول مرة أخرى.',
    signInAgainMsg: 'يجب تسجيل الدخول لإدارة العناوين',
    selectAddress: 'اختر العنوان',
  },

  successOrder: {
    title: 'تم الطلب',
    header: 'تهانينا',
    orderNumberTitle: 'رقم الطلب',
    successMsg: 'طلبك تم بنجاح، بإمكانك الدخول على قائمة طلباتك لمتابعة طلبك',
    goToHomepage: 'الذهاب إلى الصفحة الرئيسية',
    paymentId: 'رقم الدفع',
    transactionId: 'رقم العملية',
  },

  failedOrder: {
    title: 'فشلت عملية الدفع',
    header: 'فشلت عملية الدفع',
    body: 'بيانات الكرت خاطئة, برجاء إعادة المحاولة مرة أخرى.',
    tableTitle: 'بيانات الطلب',
    orderNumberTitle: 'رقم الطلب',
    dateTitle: 'التاريخ',
    errorCodeTitle: 'كود الخطأ',
    btnTitle: 'حاول مرة أخرى',
    paymentId: 'رقم الدفع',
    transactionId: 'رقم العملية',
    goToHomepage: 'الذهاب إلى الصفحة الرئيسية',
  },
  processingPayment: {
    title: 'معالجة الدفع',
    header: 'معالجة الدفع',
    body: 'يستغرق عملية الدفع أكثر من المعتاد ،\n يرجى التحقق من حالة الطلب خلال 15 دقيقة.',
    btnTitle: 'فحص حالة الطلب',
    goToHomepage: 'الذهاب إلى الصفحة الرئيسية',
  },

  noNetworkConnection: 'لا يوجد اتصال بالشبكة',

  // btns
  btns: {
    back: 'العوده',
    confirm: 'تأكيد',
    login: 'تسجيل الدخول',
    register: 'إنشاء حساب',
    signup: 'إنشاء حساب',
    signupContinue: 'إنشاء حساب & أستمرار',
    findOrder: 'ابحث عن الطلب',
    resetPassword: 'إعادة ضبط كلمة المرور',
    delete: 'مسح',
    deleteTShirt: 'مسح القميص',
    cancel: 'إلغاء',
    done: 'تفعيل',
    edit: 'تحرير',
    editTShirt: 'تعديل القميص',
    addNew: 'عنوان جديد',
    next: 'التالي',
    nextStep: 'الخطة التالية',
    apply: 'تطبيق',
    discard: 'تجاهل',
    skip: 'تخطي',
    yes: 'نعم',
    no: 'لا',
    create: 'انشاء',
    update: 'تحديث',
    addToCart: ' أضف إلى السلة',
    addAllToCart: 'إضافة الجميع إلي السلة',
    clearAll: 'مسح الجميع',
    viewList: 'عرض المنتجات',
    gettingStarted: 'إبدء',
    allowNotifications: 'الموافقه على الإشعارات ',
    DO_NOT_ALLOW: 'عدم السماح',
    proceed: 'الإستمرار',
    proceedToCheckout: 'تابع الدفع',
    chooseProductOptions: 'اختر خيارات المنتج',
    chooseOptions: 'اختر الخيارات',
    guestCheckout: 'الدفع كزائر',
    loginCheckout: 'تسجيل الدخول والدفع',
    forgetPassword: 'نسيت كلمة المرور',
    newAddress: 'أضف عنوان',
    editAddress: 'تحرير العنوان',
    backToCategories: 'العوده الى الأقسام',
    checkout: 'تابع عملية الشراء',
    placeOrder: 'إتمام الطلب',
    continue: 'إستمرار',
    backToCart: 'الرجوع إلى السلة',
    updateApp: 'حدث الأن',
    viewMyOrders: 'طلباتي',
    goToSettings: 'الذهاب الي الاعدادات',
    LOOKS_GOOD: 'تبدو جيدا',
    saveLocation: 'حفظ الموقع',
    change: 'تغيير',
    checkoutAsNewUser: 'الشراء كمستخدم جديد',
    changePassword: 'تغيير كلمة المرور',
    currentPassword: 'كلمة المرور الحالية',
    newPassword: 'كلمة المرور الجديدة',
    editProfile: 'تحديث الحساب',
    editSuccessfully: 'تم تحديث البيانات بنجاح.',
    tabToEdit: 'اضغط للتحديث',
    confirmGiftCard: 'تأكيد كرت الهدية',
    editGiftCard: 'تعديل كرت الهدية',
    confirmImages: 'تأكيد خيارات الصور',
    activated: 'تم التفعيل',
    remove: 'حذف',
    select: 'تحديد',
    addPhotos: 'أضف صورك',
    customize: 'تخصيص القميص',
  },

  // errors
  errors: {
    email: 'يرجى تقديم بريد إلكتروني صحيح',
    fullname: 'الإسم الكامل مطلوب',
    firstname: 'الأسم الاول مطلوب',
    lastname: 'إسم العائلة مطلوب',
    orderId: 'رقم الطلب مطلوب',
    passwordLength: 'يرجى اختيار ٨ حروف على الأقل لكلمة السر.',
    password: 'كلمة المرور مطلوبة',
    passwordBlank: 'كلمة المرور لا يمكن أن تكون فارغة',
    passwordRequired: 'كلمة المرور مطلوبة',
    passwordAgain: 'تكرار كلمة المرور مطلوب',
    passwordAgainSame: 'يجب أن تكون كلمة المرور المتكررة هي نفسها',
    noAccountExist: 'لا يوجد حساب للبريد الإلكتروني المطلوب',
    tooManyPassReset:
      'عدد كبير جدًا من طلبات إعادة تعيين كلمة المرور. يرجى المحاولة بعد مرور بعض الوقت',
    address: 'يرجى تقديم عنوان صحيح',
    area: 'يرجى إختيار المنطقة',
    street: 'الشارع مطلوب',
    block: 'القطعة مطلوبة',
    house: 'رقم المنزل مطلوب',
    houseNo: 'رقم المنزل مطلوب',
    tel: 'يرجى تقديم رقم موبايل صحيح',
    customerFirstname: 'اسم العميل الأول المطلوب',
    customerLastname: 'اسم العائلة للعميل مطلوبة',
    coupon: 'رقم الكوبون غير صالح',
    errorMessage: 'لقد حدث خطأ، من فضلك حاول مجدداً في وقت لاحق',
    notEnoughQty: 'بعض منتجاتك غير متوفرة بالكمية المطلوبة',
    productNotExist: 'نأسف ، هذا المنتج غير متاح الآن',
    UNAVAILABLE_LOCATION: 'عذرًا ، الوصول إلى ميزة الموقع غير متاح في جهازك',
    UNAVAILABLE_GALLERY: 'عذرًا ، الوصول إلى معرض الصور غير متاح في جهازك',
    OPEN_SETTINGS_ERROR: 'آسف ، لا يمكننا فتح الإعدادات',
    ERROR_MESSAGE: 'حدث خطأ!',
    from: 'من مطلوب',
    to: 'الي مطلوب',
    message: 'نص الرسالة مطلوب',
    areaRequired: 'المنطقة مطلوبة',
    city: 'يرجى إختيار المدينة',
    invalidImage: 'الصورة غير صالحة يمكنك اختيار صورة أخرى',
    messageLength: 'العدد المسموح به ١٨٠ حرف',
  },

  // input placeholders.
  placeholders: {
    email: 'البريد الإلكتروني',
    password: 'كلمة السر',
    fullname: 'الإسم الكامل',
    name: 'الإسم',
    country: 'الدولة',
    firstname: 'الإسم الأول',
    lastname: 'إسم العائلة',
    couponCode: 'كوبون الخصم',
    passwordAgain: 'أدخل كلمة السر مرة أخرى',
    city: 'المدينة',
    area: 'المنطقة',
    region: 'المحافظه',
    street: 'الشارع',
    avenue: 'الجادة ( اختياري )',
    block: 'القطعة',
    house: 'المنزل / المبنى',
    tel: 'رقم الموبايل',
    deliveryTime: 'اختر وقت التسليم',
    search: 'بحث',
    selectArea: 'اختر المنطقة',
    searchToys: 'ابحث عن العاب',
    searchAreas: 'ابحث عن المنظقة',
    floor: 'الطابق',
    addressName: 'اسم العنوان',
    address: 'العنوان',
    extraGuidance: 'إرشادات أضافية للشحن',
    guidance: 'ارشادات اضافية',
    giftForSomeone: 'هل هي هدية لشخص ما؟',
    defaultAddress: 'تعيين العنوان الافتراضي',
    receiverName: 'اسم المستلم',
    receiverNo: 'هاتف المستلم',
    customizedWriting: 'اسم الطفل/الطفلة',
    from: 'من',
    to: 'الي',
    message: 'الرسالة',
    searchGiphy: 'ابدأ البحث',
    noStickersAvailable: 'لا توجد ملصقات',
  },

  labels: {
    area: 'المنطقة',
    block: 'المبنى',
    street: 'الشارع',
    house: 'المنزل',
    floor: 'الطابق',
    avenue: 'الجادة',
    guidance: 'ارشادات اضافية',
    home: 'المنزل',
    work: 'العمل',
    friend: 'صديق',
  },

  alerts: {
    whoops: 'عفوًا',
  },

  // components.
  cartIndicator: {
    cart: 'السلة',
    delivery: 'التوصيل',
    payment: 'الدفع',
    order: 'الطلب',
    thankYou: 'شكراً لك',
  },

  cartTotal: {
    subtotal: 'إجمالي المنتجات',
    shippingFee: 'رسوم التوصيل',
    discount: 'خصم الكوبون',
    giftWrapping: 'تغليف الهدايا',
    orderTotal: 'إجمالي الطلب',
    productTotal: 'إجمال المنتجات',
    tax: 'ضريبة القيمة المضافة',
    fee: 'رسوم الدفع عند الإستلام',
  },

  timezone: {
    yesterday: 'في الامس',
    today: 'اليوم',
    tomorrow: 'غدا',
  },
  giftWrapping: {
    wrapOptions: 'خيارات التغليف',
    wrapPreview: 'عرض التغليف',
    dVision: 'رؤية ثلاثية الابعاد',
    title: 'تغليف الهدايا',
    addOnsTitle: 'إضافات',
    addOnsSubTitle: 'اختياري',
    kwd: 'دينار',
    wrappingPaperTitle: 'ورق تغليف',
    messageCardsTitle: 'كرت تهنئة',
    addMessageCardsTitle: 'كرت تهنئة',
    addMessageCardsSubTitle: 'إضافة كرت تهنئة',
    addCard: 'أضف كرت',
    editCard: 'تعديل كرت',
    addMessageCardsButton: 'إضافة كرت تهنئة جديد',
    addNewMessageCardsButton: 'إضافة كرت تهنئة إضافي',
    add: 'أضف',
    addMessage: 'أضف رسالة',
    addOnProductOptions: 'خيارات المنتج',
    PaperOptions: 'إختار منتج واحد على الأقل',
    kwdPerPiece: 'دينار للقطعة',
    from: 'من',
    to: 'إلى',
    typeMessage: 'اكتب الرسالة هنا',
    startGiftWrapping: 'بدء تغليف الهدايا',
    startGiftWrappingSub: 'اجعلها هدية رائعة!',
    startingFrom: 'من',
    paper: 'إضافة تغليف',
    edit: 'تعديل التغليف',
    delete: 'حذف تغليف المنتج',
    noItemsTitle: 'لا يوجد منتجات للتغليف',
    editAlert: 'تجاهل التغييرات التي لم يتم حفظها؟',
    selectWrapping: 'إختار منتجات',
    selectAddons: 'إختار إضافات',
    continueGiftwrapping: 'هل تريد الاستمرار في تغليف هديتك؟ ',
    alertGiftwrapping:
      'انت لم تنتهي من عملية التغليف.  هل تريد الرجوع الى سلة التسوق وإلغاء التغييرات المعمولة.',
    product: 'منتج',
    chooseProducts: 'إختيار منتجات الهدية',
    confirmProducts: 'تأكيد منتجات الهدية',
    simple: 'بسيط',
    diy: 'صممها بنفسك',
    diyContent: 'ابني تصميمك الخاص 🔥',
    custom: 'مميز',
    faces: 'أوجه',
    placeholder: 'placeholder',
    pattern: 'pattern',
    stickersOptions: 'خيارات الملصقات',
    color: 'لون',
    stickers: 'ملصقات',
    photos: 'صور',
    chooseProductsToWrap: 'قم بأختيار المنتجات التي ستغلف معاً',
    hint: ' ضع الصور في الأماكن المخصصة. \n حرك لتغيير الحجم.',
    notAllowedProducts: 'منتجات لن يتم تغليفها لكبر حجمها',
    notAllowedProduct: 'لا يمكن تغليفها',
    wrappingProducts: 'منتجات قابلة للتغليف',
    busy: 'مشغول',
    addSticker: 'اضف ملصق',
    text: 'نص',
    done: 'تم',
    writeSomthing: 'أكتب شيئا…',
    startDesign: 'ابدأ التصميم',
    addYourFace: 'أضف صور ابنائك 🧒 واختر من ملايين الستكرات',
  },
  notificationsAccessModal: {
    title: 'تفعيل الاشعارات',
    description:
      'استمتع بعروضنا والقدرة على تتبع طلباتك عندما تقوم بالسماح للإشعارات بالوصول.',
  },
  settings: {
    accountSettings: 'إعدادات الحساب',
    appLanguage: 'اللغة',
    lang: 'English',
    country: 'الدولة',
    manageShippingAddresses: 'إدارة عنواين الشحن',
    Save: 'حفظ',
    kuwait: 'الكويت',
    needHelp: 'تريد مساعدة؟ تواصل معنا',
  },
  MAP: {
    TITLE: 'هل هذا الدبوس في المكان المناسب؟',
    SUB_TITLE: 'ساعد سائقك في العثور عليك عن طريق إضافة موقع دقيق',
    OPEN_SETTINGS_ALERT_MESSAGE:
      'لاستخدام موقعك الحالي ، يجب تمكين مشاركة الموقع في الإعدادات، الذهاب الي الإعدادات؟',
    ALLOW_LOCATION: 'السماح بالوصول إلى الموقع',
    ALLOW_LOCATION_MESSAGE: 'نحن بحاجة إلى إذنك للوصول إلى موقعك.',
  },
  GALLERY: {
    OPEN_SETTINGS_ALERT_MESSAGE:
      'لاستخدام الصور ، يجب تمكين مشاركة معرض الصور في الإعدادات، الذهاب الي الإعدادات؟',
  },
  STORY: {
    VIEW_PRODUCT: 'تفاصيل المنتج',
  },

  GWProductCard: {
    message: 'أكبر من حجم التغليف المختار',
  },
  GiftCard: {
    giftCard: 'كرت الهدية',
    cardAddedSuccessfully: 'تم اضافة كرت تهنئة بنجاح',
    cardUpdatedSuccessfully: 'تم تعديل كرت تهنئة بنجاح',
  },
  GiftWrapItems: {
    gifts: 'الهدايا',
    unWrappedProducts: 'منتجات لم يتم تغليفها',
    confirmWrapping: 'تأكيد التغليف',
    giftWrapping: 'تغليف المنتجات',
    wrapAsGift: 'غلف كهدية',
    addAddOns: 'اضف ملحقات',
    wrappedProducts: 'المنتجات المغلفة',
  },
  GWAddons: {
    confirmAddons: 'تأكيد الملحقات',
    addons: 'الملحقات',
  },
  GWDecision: {
    continueWithoutGW: 'إستكمال  بدون التغليف  🙈',
    giftWrapping: 'تغليف الهدايا بشكل مميز  🎁 ',
    distinctivePW: 'هل تريد تغليف المنتجات بشكل مميز؟',
  },
  GWCutout: {
    customGiftWrap: 'التغليف المتخصص',
    diy: 'أصنع صورتك بنفسك',
    emptyStateStickers: '١,٠٠٠,٠٠٠ صورة متاحة',
    moreThanBillionImages: 'أكثر من مليار صورة',
  },
  DeleteWrapperModal: {
    deleteWrapper: 'حذف التغليف',
    back: 'رجوع',
    delete: 'حذف',
    deleteProductWrapping: 'حذف تغليف المنتج',
    deletingPackagingPermanently:
      'سيؤدي حذف تغليف المنتج إلى فقد جميع تغييرات التغليف بشكل دائم.',
  },

  otp: {
    verificationCode: 'كود التفعيل',
    pleaseTypeOtp: 'يرجي كتابة كود التفعيل المرسل الي هاتفك',
    resendCode: 'اعادة ارسال الكود',
    invalidOtp: 'كود تفعيل خاطئ',
    validOtp: 'كود تفعيل صحيح',
  },
  addons: {
    itemSuccess: 'تمت الإضافة',
    AddAddonsToOrder: 'اضف ملحقات حفلات إلى طلبك',
    decorateYourOrder: 'زين طلبك بشرائك ملحقات',
    addonsTypes: '(بلالين, شموع,زينة وقبعات أعياد ميلاد)',
    addAddons: 'اضف ملحقات',
    AddEditAddons: 'اضافة / تعديل الملحقات',
    addons: 'الملحقات',
    totalAddons: 'اجمالي الملحقات',
    buyAddons: 'أضف ملحقات',
  },
  navBar: {
    allCategories: 'جميع الأقسام',
    products: 'منتج',
    from: 'من',
  },
  paymentSummary: {
    subtotal: 'إجمالي المنتجات',
    discount: 'الخصم',
    creditPay: 'دفع الائتمان',
    delivery: 'التوصيل',
    total: 'الإجمالي',
    checkout: 'الدفع',
  },
  wrapAsGift: {
    wrapAsGift: 'غلف كهدية؟',
    subTitle: 'اختر من بين مجموعتنا المتنوعة من التغليف.\nأضف وجه طفلك!',
  },
  navBar: {
    allCategories: 'جميع الأقسام',
    products: 'منتج',
    from: 'من',
  },
  footer: {
    rightsReserved: '© ٢٠٢١ تيله. جميع الحقوق محفوظة',
  },
  countries: {
    KW: 'الكويت',
    SA: 'المملكة العربية السعودية',
    AE: 'الإمارات العربية المتحدة',
    QA: 'قطر',
    OM: 'عُمان',
    BH: 'البحرين',
  },
  DeleteModal: {
    deleteWrapper: 'حذف التغليف',
    back: 'رجوع',
    delete: 'حذف',
  },
  pagination: {
    previous: '→  السابق',
    next: 'التالي  ←',
  },
};
