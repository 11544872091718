import React, { useEffect, useLayoutEffect } from 'react';
import { View, ScrollView, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/wishlist';
import {
  Text,
  WishlistItem,
  Screen,
  EmptyScreen,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import styles from './styles';
import { loadingSelector } from '../../common/utils';
import { navigateToProduct } from '../../services';
const Wishlist = ({ navigation }) => {
  const dispatch = useDispatch();
  const { wishlist, loading } = useSelector((state) => {
    return {
      wishlist: state.wishlist,
      loading: loadingSelector(state, [
        types.GET_WISHLIST_ITEMS,
        types.REMOVE_FROM_WISHLIST,
      ]),
    };
  });
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  useEffect(() => {
    _initialFetch();
  }, []);

  const _initialFetch = () => {
    dispatch(actions.getWishlistItems());
  };

  const onDeleteItem = (id) => {
    dispatch(actions.removeFromWishlist(id));
  };

  const noData = wishlist.list.length == 0;

  return (
    <Screen isLoading={loading}>
      {noData && !loading ? (
        <EmptyScreen
          errorMessage={{
            title: I18n.t('wishlist.noFavorites'),
            subTitle: I18n.t('wishlist.addFavorite'),
          }}
        />
      ) : (
        <ScrollView contentContainerStyle={styles.container}>
          <View style={styles.cartHeader}>
            <Text size="small" style={{ fontWeight: 'bold' }}>
              {wishlist.totalCount} {I18n.t('wishlist.items')}
            </Text>
          </View>

          <FlatList
            scrollEnabled={false}
            style={styles.favoriteList}
            data={wishlist.list}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, item: { sku, id } }) => {
              return (
                <WishlistItem
                  onPress={() => navigateToProduct(sku, item?.name)}
                  item={item}
                  onDelete={() => onDeleteItem(id)}
                />
              );
            }}
          />
        </ScrollView>
      )}
    </Screen>
  );
};

export default Wishlist;
