export default {
  // brand color
  brand: '#e93766',
  buttons: '#aa0056',
  // buttons2: '#1399AB',
  brandGrey: 'rgb(236, 238, 243)',
  brandGreyLight: 'rgb(250,250,250)',
  brandGreen: 'rgb(40, 187, 78)',
  brandGreen2: '#50E3C2',
  brandGreenLight: '#9CCC65',
  brandBlue: 'rgb(88, 201, 233)',
  brandBlue2: 'rgb(33,150,243)',
  brandOrange: 'rgb(255, 134, 84)',
  brandPink: '#FF3B64',
  brandYellow: '#FFB300',
  brandWarning: '#FFFDEE',
  brandDanger: '#FFE8E8',
  paleGrey: '#eceef3',
  // Main (Bottom) TabBar
  tabBar: 'rgba(255, 255, 255, 1)',
  tabBarTint: '#1CB5B4',
  tabBarColor: '#aaa',
  bg: '#fbfbfc',

  // navigation bar
  headerTintColor: '#1CB5B4',

  white: 'rgb(255,255,255)',
  black: '#000000',
  black100: '#030303',

  // selected material colors
  grey100: '#F5F5F5',
  grey200: '#EEEEEE',
  grey300: '#E0E0E0',
  grey400: '#BDBDBD',
  grey500: '#9E9E9E',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#212121',
  grey110: '#A7A7AA',
  grey120: '#bbbbbb',
  grey130: '#f5f6f9',
  grey140: '#f7f7f7',
  greyBackground: '#fafafa',

  blueGrey100: '#CFD8DC',
  blueGrey200: '#B0BEC5',
  blueGrey300: '#90A4AE',
  blueGrey400: '#78909C',
  blueGrey500: '#607D8B',
  blueGrey600: '#455A64',
  blueGrey700: '#455A64',
  blueGrey800: '#37474F',
  blueGrey900: '#263238',
  blueGrey110: '#007aff',
  blueGrey120: '#1f314a',
  blueGrey140: '#bec0d0',

  deepOrangeA400: '#FF3D00',
  deepOrangeA700: '#DD2C00',

  green000: '#ecf7ed',
  green500: '#4CAF50',
  green700: '#388E3C',
  greenA400: '#00E676',
  greenA700: '#00C853',
  greenA800: '#06b473',

  red400: '#EF5350',
  red500: '#F44336',
  red600: '#E53935',

  blueA400: '#2979FF',
  blueA700: '#2962FF',
  blueLight: 'rgba(72, 111, 247,.1)',
  blueCouponBtn: '#486ff7',

  indigo600: '#3949AB',
  indigoA400: '#3D5AFE',
  indigoA700: '#304FFE',

  amber50: '#FFF8E1',
  amber100: '#FFECB3',
  amber200: '#FFE082',
  amber500: '#FFC107',
  amber600: '#FFB300',
  amber700: '#FFA000',

  pinkA400: '#F50057',

  brightBlue: '#007aff',
  greyishBrown: '#404040',
  greyishBrownTwo: '#4a4a4a',
  uglyBlue: 'rgb(42, 132, 127)',
  turquoiseBlue: '#00bcd4',
  azure: '#2196f3',
  bubblegumPink: '#f99bc4',
  cerise: '#ec1381',
  cloudyBlue30: 'rgba(190, 192, 208, 0.3)',
  // NewUI
  blueyGrey: '#8e8e93', // productCard
  blueGrey130: '#f3f4f9',
  LightPink: 'rgba(255, 232, 224, 0.2)',
  overlayColor: 'rgba(255, 255, 255, 0.50)',
  greenBlue: 'rgb(2,184,117)',
  greenBtn: '#018353',
  warmGrey: 'rgba(155, 155, 155,1)',
  textGrey: '#555555',
  shadowBtn: 'rgba(173, 170, 190, 0.19)',
  borderColor: '#d8dde6',
  borderColor10: '#ebebeb',
  greenBlue: 'rgba(2, 184, 117,1)',
  bluishGreen: 'rgba(15, 169, 112,1)',
  // bluishGreen: '#1399AB',
  greenShadow: '#018353',
  headerBackground: 'rgba(248, 248, 248, 0.82)',
  brandShadow: 'rgba(224, 0, 114, 0.05)',
  cartBackground: '#fbfbfb',
  redLight: 'rgba(255, 9, 9,.1)',
  // homepage
  darkBannerBG: '#235772',
  bannerBtnBG: 'rgba(0,0,0,.1)',
  darkBannerBtnBG: 'rgba(255,255,255,.2)',

  //shimmer
  brandShimm1: '#E9EBEE',
  brandShimm2: '#eff1f4',
  brandShimm3: '#e1e3e8',
  warmGrey: '#9b9b9b',
  shadowColor: 'rgba(0, 0, 0, 0.05)',
  shadowDarkColor: 'rgba(0, 0, 0, 0.1)',
  shadowColor100: 'rgba(38, 27, 77, 0.18)',
  shadowColor200: 'rgba(38, 27, 77, 0.08)',
  shadowColor300: 'rgba(0, 0, 0, 0.12)',
  shadowColor400: 'rgba(0, 0, 0, 0.04)',
  shadowColor500: 'rgba(118, 118, 128, 0.12)',
  shadowColor600: 'rgba(127, 116, 116, 0.1)',
  shadowColor700: 'rgba(0, 0, 0, 0.25)',
  shadowColor800: 'rgba(0, 0, 0, 0.5)',
  shadowColor960: 'rgba(0,0,0,0.96)',
  transparentBrand: 'rgba(233, 53, 101, 0.2)',
  // transparentBrand: 'rgba(19, 153, 171, 0.1)',
  transparentBrand500: 'rgba(233, 53, 101, 0.5)',

  shadowWhiteColor250: 'rgba(256, 256, 256, 0.25)',
  //backgroundBotton
  button1: '#fafafa',
  tag: '#00796b',
  purple: '#5e35b1',
  green: '#0d875a',
  green100: '#02b875',
  green200: '#0fa970',
  robinEggBlue: '#90e4ee',
  robinEggBlueLite: 'rgba(144,228,238, 0.1)',
  cerulean: '#0089be',
  greenLight: 'rgba(12, 173, 114,.1)',
  greenCouponBtn: '#0cad72',
  //account screen
  overlay: 'rgba(255, 255, 255, .85)',
  //progress bar
  emptyProgress: 'rgba(233,55,102, 0.25)',

  toastError: '#C23934',
  toastSuccess: '#29844A',

  subCategoriesBackground: '#E0E4F6',
  transparent: 'rgba(255, 255, 255,0)',
  selectedTabGW: '#aebceb',

  danger: '#eb2121',

  redMain: '#e7235f',
  redSecondary: '#fad3df',
  redHover: '#ff1e63',

  greenMain: '#1aa1a4',
  greenSecondary: '#d1eced',
  greenHover: '#0fd0d4',

  purpleMain: '#573baa',
  purpleSecondary: '#eeebf6',

  textMain: '#000000',
  textSecondary: '#888888',

  unselected: '#bec0d0',

  headerColor: '#555555',
  cartIconColor: '#e93766',

  lipstick: '#d82054',
  lipstick50: 'rgba(216, 32, 84, 0.5)',
  paleGray: '#f7f6fb',
  darkIndigo: 'rgb(23, 10, 60)',
  blueGreen: '#00807c',
  darkAquamarine50: 'rgba(0, 115, 112, 0.5)',
  black15: 'rgba(0, 0, 0, 0.15)',
  selection: 'rgb(21, 195, 189)',
  mainYellow: '#ebbe39',
  amethyst: 'rgb(170, 74, 208)',
};
