import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import debounce from 'debounce';
import PropTypes from 'prop-types';
import {
  CategoriesCardView,
  ShimmerList,
  HeaderButton,
  CartIcon,
  Spinner,
  Screen,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import styles from './styles';
import { useScrollToTop } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { useCategories } from '../../redux/useCategories';
import { colors } from '../../common';
import { navigateToProducts } from '../../services';

const CategoriesScreen = ({ navigation }) => {
  const [screenLoading, setScreenLoading] = useState(true);
  const ref = React.useRef(null);
  useScrollToTop(ref);
  const { getSubcategories } = useCategories();
  useEffect(() => {
    _initalFetch();
  }, []);

  const _initalFetch = async () => {
    await getSubcategories();
    setScreenLoading(false);
  };
  const { loading, categories } = useSelector((state) => {
    return {
      categories: state.categories,
    };
  });
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerTitle: I18n.t('tabBarLabels.categories'),
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <HeaderButton elements={[{ element: <CartIcon /> }]} />
      ),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  const onPressItem = (item) => {
    // navigate to screen props.
    const categoryId = item && item.id;
    const queryData = {
      id: item.id,
      title: item.name,
    };
    navigateToProducts({ category_id: categoryId }, queryData);
  };
  if (screenLoading) {
    return <Spinner visible={screenLoading} />;
  }

  return (
    <Screen>
      <FlatList
        ref={ref}
        data={categories.list}
        numColumns={3}
        keyExtractor={(item) => item.id}
        contentContainerStyle={styles.listContainer}
        columnWrapperStyle={styles.wrapperStyle}
        showsVerticalScrollIndicator={false}
        renderItem={({ item, index }) => (
          <CategoriesCardView
            navigation={navigation}
            index={index}
            keyExtractor={(item) => item.id.toString()}
            item={item}
            onPressItem={debounce(() => onPressItem(item), 200)}
          />
        )}
      />
    </Screen>
  );
};
CategoriesScreen.propTypes = {
  navigation: PropTypes.object,
  loading: PropTypes.bool,
  categories: PropTypes.object,
};
export default CategoriesScreen;
