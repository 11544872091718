import React from 'react';
import { View, Switch } from 'react-native';
import { colors } from '../../common';

class SwitchButton extends React.Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      iosBackgroundColor: '',
      value: value,
      tintColor: value ? colors.brand : '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  toggleValue = (checked) => {
    this.setState((state) => ({
      value: !state.value,
      iosBackgroundColor: colors.brand,
      tintColor: colors.brand,
    }));
    this.props.toggleValue(checked);
  };

  render() {
    const { value, thumbColor, tintColor } = this.state;
    return (
      <View>
        <Switch
          onTintColor={tintColor}
          value={value}
          onValueChange={this.toggleValue}
          // trackColor={colors.white}
          // thumbColor={value ? colors.white : colors.brand}
          // ios_backgroundColor={colors.white}
        />
      </View>
    );
  }
}

export default SwitchButton;
