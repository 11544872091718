import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { Text, Spinner } from '../index';
import { colors, config } from '../../common';
import Icon from 'react-native-vector-icons/Ionicons';
import PropTypes from 'prop-types';
import styles from './webStyle';
import { convertRGBToRGBA, getPriceFormat } from '../../common/utils';
import { isAvailableProduct } from '../../services';

export const CartItemWeb = (props) => {
  const { item, onPress, onIncrement, onDecrement, onDelete, loading } = props;
  const { product, quantity } = item;
  const { name, small_image, brand_name, price_range } = product;
  const price = price_range?.maximum_price?.regular_price?.value;
  const final_price = price_range?.maximum_price?.final_price?.value;
  const discount_percentage = price_range?.maximum_price?.discount?.percent_off;
  const special_price = discount_percentage !== 0 ? final_price : null;
  const dimmingItem = !isAvailableProduct(item);
  // const disableDecrement = quantity == 1;
  return (
    <TouchableOpacity
      activeOpacity={0.85}
      onPress={onPress}
      style={styles.container}
      disabled={loading}
    >
      {loading && <Spinner style={styles.spinner} />}
      {/* delete button */}
      <TouchableOpacity
        onPress={onDelete}
        activeOpacity={0.9}
        style={styles.deleteButton}
      >
        <Icon
          name="md-close"
          color={convertRGBToRGBA(colors.darkIndigo, 0.3)}
          size={20}
        />
      </TouchableOpacity>
      <Image
        source={{ uri: small_image?.url }}
        placeholder={config.placeholderImage}
        resizeMode="contain"
        style={styles.image}
      />
      <View style={styles.dataContainer}>
        <Text style={styles.brandName} numberOfLines={1}>
          {brand_name}
        </Text>
        <Text style={styles.productName} numberOfLines={1}>
          {name}
        </Text>
        <View style={styles.viewRow}>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={onDecrement}
            disabled={dimmingItem}
          >
            <View style={styles.qytButton}>
              <Icon
                name="md-remove"
                color={
                  dimmingItem
                    ? convertRGBToRGBA(colors.darkIndigo, 0.05)
                    : colors.black100
                }
                size={24}
              />
            </View>
          </TouchableOpacity>

          <View style={styles.qtyContainer}>
            <Text numberOfLines={1} size="large" style={styles.qtyText}>
              {quantity}
            </Text>
          </View>
          <TouchableOpacity activeOpacity={0.9} onPress={onIncrement}>
            <View style={styles.qytButton}>
              <Icon name="md-add" color={colors.black100} size={24} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.priceContainer}>
        {special_price && (
          <Text style={styles.oldPriceStyle} numberOfLines={1}>
            {getPriceFormat(price)}
          </Text>
        )}
        <Text
          style={[styles.priceStyle, special_price && styles.specialPriceStyle]}
          numberOfLines={1}
        >
          {getPriceFormat(special_price ? special_price : price)}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

CartItemWeb.propTypes = {
  item: PropTypes.object.isRequired,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func,
  onDelete: PropTypes.func,
  onPress: PropTypes.func,
};

CartItemWeb.defaultProps = {
  onIncrement: () => {},
  onDecrement: () => {},
  onDelete: () => {},
  onPress: () => {},
};
