import { useCallback } from 'react';
import { client, gateway_client } from '../services';

export const useAwaitQuery = (query) => {
  return useCallback(
    (options) => {
      return client().query({
        ...options,
        query,
        fetchPolicy: 'no-cache',
      });
    },
    [query],
  );
};

export const useAwaitQueryGateway = (query) => {
  return useCallback(
    (options) => {
      return gateway_client().query({
        ...options,
        query,
        fetchPolicy: 'no-cache',
      });
    },
    [query],
  );
};

export const useMutation = (mutation) => {
  return [
    useCallback(
      (options) => {
        return client().mutate({
          ...options,
          mutation,
          fetchPolicy: 'no-cache',
        });
      },
      [mutation],
    ),
  ];
};
