import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View, FlatList } from 'react-native';
import {
  Screen,
  Text,
  OrderProductItem,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import styles from './styles';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/order';
import { loadingSelector } from '../../common/utils';

const OrderDetails = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { id, increment_id } = JSON.parse(route?.params?.params);
  const { loading, config } = useSelector((state) => {
    return {
      loading: loadingSelector(state, [types.GET_ORDER_DETAILS]),
      config: state.config,
    };
  });
  const [details, setDetails] = useState({});
  const currencyCode = config?.country?.currencyCode;
  const { date, status, totals } = details;
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: `${I18n.t('order.title')} #${increment_id}`,
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const orderData = await dispatch(actions.getOrderDetails(id));
      setDetails(orderData.payload);
    } catch (error) {
      console.warn('error', error);
    }
  };

  const renderDescText = (title, desc) => {
    return (
      <View style={styles.descView}>
        <Text size="small" style={styles.descText}>
          {title}
        </Text>
        <Text size="small" style={styles.descText}>
          {desc}
        </Text>
      </View>
    );
  };

  const renderTinyText = (title, desc) => {
    return (
      <View style={styles.tinyView}>
        <Text size="smaller" style={styles.tinyText}>
          {title}
        </Text>
        <Text size="smaller" style={styles.tinyText}>
          {desc}
        </Text>
      </View>
    );
  };

  const listFooterComponent = () => {
    return (
      <View style={styles.listFooter}>
        {renderDescText(I18n.t('orderHistory.orderDate'), date.split(' ')[0])}
        {renderDescText(I18n.t('orderHistory.status'), status)}
        {renderDescText(
          I18n.t('orderHistory.payment'),
          `${totals.total} ${currencyCode}`,
        )}
        {renderTinyText(
          I18n.t('cartTotal.subtotal'),
          `${totals.sub_total} ${currencyCode}`,
        )}
        {totals.discount > 0 &&
          renderTinyText(
            I18n.t('orderHistory.promoCode'),
            `- ${totals.discount} ${currencyCode}`,
          )}
        {renderTinyText(
          I18n.t('cartTotal.shippingFee'),
          `${totals.shipping_fee} ${currencyCode}`,
        )}
      </View>
    );
  };

  const isLoading = _.isEmpty(details) || loading;
  return (
    <Screen isLoading={isLoading}>
      <FlatList
        style={styles.list}
        renderItem={({ item }) => (
          <OrderProductItem currency={currencyCode} item={item} />
        )}
        data={details.items}
        keyExtractor={(item) => item.entity_id.toString()}
        ListFooterComponent={() => !isLoading && listFooterComponent()}
      />
    </Screen>
  );
};

export default OrderDetails;
