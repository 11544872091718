import { gql } from '@apollo/client';
import { CheckoutFragment } from './CheckoutFragment';

export const SET_PAYMENT_METHOD = gql`
  mutation setPaymentMethodOnCart($cartId: String!, $paymentMethod: String!) {
    setPaymentMethodOnCart(
      input: { cart_id: $cartId, payment_method: { code: $paymentMethod } }
    ) {
      cart {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
