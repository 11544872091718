import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { colors } from '../../common';
import Icon from 'react-native-vector-icons/MaterialIcons';
import Collapsible from 'react-native-collapsible';
import PropTypes from 'prop-types';
import { capitalize } from '../../common/utils';
import { Text } from '../index';

class FilterCollapse extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    selectedValue: PropTypes.string,
    children: PropTypes.any,
    collapsed: PropTypes.bool,
  };
  static defaultProps = {
    collapsed: false,
  };
  state = {
    isCollapsed: this.props.collapsed,
  };
  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    const { title, selectedValue, children } = this.props;
    return (
      <View>
        <TouchableOpacity
          activeOpacity={0.9}
          style={styles.header}
          onPress={this.toggleCollapse}
        >
          <Text style={{ fontWeight: '600' }}>{title}</Text>
          <View style={styles.row}>
            <Text
              size="small"
              style={{ fontWeight: '600', color: colors.grey600 }}
            >
              {selectedValue && capitalize(selectedValue)}&nbsp;&nbsp;
            </Text>
            <Icon
              name={
                this.state.isCollapsed
                  ? 'keyboard-arrow-left'
                  : 'keyboard-arrow-down'
              }
              size={20}
              color={colors.grey700}
              style={{ marginTop: 1.5 }}
            />
          </View>
        </TouchableOpacity>
        <View style={{ padding: 15 }}>
          <Collapsible collapsed={this.state.isCollapsed}>
            {children}
          </Collapsible>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 15,
    paddingVertical: 10,
    borderBottomColor: colors.grey300,
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: 'row',
  },
});

export default FilterCollapse;
