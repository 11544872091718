import React, { Fragment } from 'react';
import { ShimmerPlaceHolder } from './index';
import PropTypes from 'prop-types';
import styles from './styles';
import { colors } from '../../common';

export default class ShimmerHOC extends React.PureComponent {
  static propTypes = {
    style: PropTypes.object,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
    loading: false,
  };

  render() {
    const { children, style, loading } = this.props;
    return (
      <Fragment>
        <ShimmerPlaceHolder
          colorShimmer={colors.brandShimm3}
          autoRun
          style={[styles.slider, style]}
          visible={!loading}
        >
          {children}
        </ShimmerPlaceHolder>
      </Fragment>
    );
  }
}
