import React, { Component, Fragment } from 'react';
import { View } from 'react-native';

import { ShimmerScrollList, ShimmerHOC } from './index';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styles from './styles';

export default class ShimmerHome extends Component {
  static propTypes = {
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: true,
  };

  render() {
    const { loading } = this.props;
    return (
      <View style={[styles.container, styles.homeContainer]}>
        <ShimmerHOC loading={loading} />
        <ShimmerScrollList loading={loading} />
        <ShimmerScrollList loading={loading} />
        <ShimmerScrollList loading={loading} />
      </View>
    );
  }
  keyExtractor = index => {
    return `${index}`;
  };
}
