import React from 'react';
import { StyleSheet } from 'react-native';
import { HorizontalList, HomeMiniItem } from '..';
import { scale, vScale } from '../../services';

export const MiniItemList = ({ data, onPress, hideHeader, showTitle }) => {
  const { contentContainerStyle } = styles;
  const cardDimensions = {
    width: scale(80),
    height: showTitle ? vScale(120) : scale(80),
  };
  const hasData = data?.items?.length;
  return hasData ? (
    <HorizontalList
      hideSeeAll
      {...{
        data,
        cardDimensions,
        contentContainerStyle,
        hideHeader,
      }}
      rowRenderer={(type, i) => {
        const { image, image_url, title, name } = i;
        return (
          <HomeMiniItem
            key={i.id}
            image={image || image_url}
            showTitle={showTitle}
            onPress={() => onPress(i)}
            title={showTitle && (title || name)}
          />
        );
      }}
    />
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  contentContainerStyle: {
    paddingHorizontal: scale(10),
    paddingTop: vScale(5),
  },
});
