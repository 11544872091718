import React from 'react';
import { View, StyleSheet, Modal, TouchableOpacity } from 'react-native';
import { Image, Text } from '..';
import { colors, images } from '../../common';
import { SecondaryButton } from '../Button';

export const MainAlert = (props) => {
  const { visible, title, source, emoji, description, buttons, onClose } =
    props;
  return (
    <Modal transparent animationType={'slide'} visible={visible} {...props}>
      <View style={styles.innerContainer}>
        <TouchableOpacity onPress={onClose} style={styles.closeButton}>
          <Image
            source={images.close}
            style={styles.closeIcon}
            resizeMode="contain"
          />
        </TouchableOpacity>
        {source && <Image source={source} style={styles.image} />}
        {emoji && <Text style={styles.emoji}>{emoji}</Text>}
        <View style={styles.textsContainer}>
          {title && <Text style={styles.title}>{title}</Text>}
          {description && (
            <Text size="small" style={styles.subTitle}>
              {description}
            </Text>
          )}
        </View>

        {/* Buttons Section */}
        <View style={styles.buttonsContainer}>
          {/* eslint-disable-next-line react/display-name */}
          {buttons?.map((item, index) => {
            const { title, onPress } = item;
            const isCancel = index == 1;
            return (
              <SecondaryButton
                key={index}
                text={title}
                onPress={onPress}
                containerStyle={[isCancel && styles.cancelButton]}
                textStyle={isCancel && styles.cancelButtonText}
              />
            );
          })}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  innerContainer: {
    width: 260,
    borderRadius: 8,
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingHorizontal: 26,
    overflow: 'hidden',
    paddingTop: 16,
    paddingBottom: 8,
    alignSelf: 'center',
    marginTop: 100,
  },
  image: {
    width: 225,
    height: 150,
  },

  emoji: {
    fontSize: 80,
    marginBottom: 16,
  },

  textsContainer: {
    marginTop: 4,
  },
  title: {
    opacity: 0.9,
    fontWeight: '800',
    textAlign: 'center',
    color: colors.blueGrey120,
  },
  subTitle: {
    lineHeight: 20,
    textAlign: 'center',
    color: colors.grey600,
    marginTop: 10,
  },
  buttonsContainer: {
    width: 230,
    marginTop: 24,
  },

  cancelButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: colors.buttons,
  },
  cancelButtonText: {
    color: colors.buttons,
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  closeIcon: {
    width: 15,
    height: 15,
  },
});
