import * as _ from 'lodash';
import { parseArabicNum } from '../common/utils';

//customes the comming as we can send it to query params
export const customFilters = (data) => {
  const {
    ages,
    brand,
    gender,
    price,
    price_sort,
    category_id,
    currentPage,
    pageSize,
  } = data;
  const sortTypes = { Descending: 'DESC', Ascending: 'ASC' };
  const hasPrice = Boolean(price?.from);
  const variables = {
    category_id: category_id?.toString() || '',
    age: ages || [],
    gender: gender?.value || '',
    priceFrom: hasPrice ? `${parseArabicNum(price.from)}` : '',
    priceTo: hasPrice ? `${parseArabicNum(price.to)}` : '',
    brand: brand?.toString() || '',
    sort: { position: 'ASC' },
    currentPage,
    pageSize,
  };
  if (sortTypes && sortTypes[price_sort?.direction]) {
    variables.sort = { price: sortTypes[price_sort?.direction] };
  }

  return variables;
};
