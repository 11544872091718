import React, { useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { Image, Text } from '..';
import * as _ from 'lodash';
import { colors, images } from '../../common';
import { Ionicons as Icon } from '@expo/vector-icons';
import { Hoverable } from 'react-native-web-hover';
import {
  appStoreUrl,
  googlePlayUrl,
  instagramUrl,
  youtubeUrl,
} from '../../common/constants';
import { I18n } from '../../locales';
import { useCategories } from '../../redux/useCategories';
import { useDevices } from '../../services/devices';

export const Footer = ({ onCategoryPress, onBrandPress }) => {
  const { getMainCategories } = useCategories();
  useEffect(() => {
    getMainCategories();
  }, []);
  const categories = useSelector((state) => state.categories?.main);
  const homeWidgets = useSelector((state) => state.widgets.list.home);
  const brand = _.find(homeWidgets, (e) => e?.component_type == 'brand-slider');

  const { isTabletOrMobile } = useDevices();

  return (
    <View style={styles.container}>
      <View style={!isTabletOrMobile && styles.rowContainer}>
        {categories?.map((item) => {
          const { name, children } = item;
          return (
            <View>
              <Text style={styles.catTitle} size="large">
                {name}
              </Text>
              <View style={styles.separator} />
              <View style={styles.subsContainer}>
                {children?.slice(0, 12).map((i) => {
                  const { id, name } = i;
                  return (
                    <Hoverable>
                      {({ hovered }) => (
                        <TouchableOpacity
                          onPress={() => onCategoryPress(id, null, name, '')}
                          style={styles.subTitleContainer}
                        >
                          <Text
                            numberOfLines={1}
                            style={[
                              styles.subTitle,
                              {
                                color: hovered
                                  ? colors.textSecondary
                                  : colors.darkIndigo,
                              },
                            ]}
                          >
                            {name}
                          </Text>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  );
                })}
              </View>
            </View>
          );
        })}

        {brand?.items?.length && (
          <View>
            <Text style={styles.catTitle} size="large">
              {brand?.title}
            </Text>
            <View style={styles.separator} />
            <View style={styles.subsContainer}>
              {brand?.items?.slice(0, 12).map((i) => {
                return (
                  <Hoverable>
                    {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => onBrandPress(i, i.component_type)}
                        style={styles.subTitleContainer}
                      >
                        <Text
                          numberOfLines={1}
                          style={[
                            styles.subTitle,
                            {
                              color: hovered
                                ? colors.textSecondary
                                : colors.darkIndigo,
                            },
                          ]}
                        >
                          {i.title}
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                );
              })}
            </View>
          </View>
        )}
      </View>
      <View style={!isTabletOrMobile && styles.rowContainer}>
        <View style={styles.row}>
          <TouchableOpacity
            onPress={() => window.open(appStoreUrl, '_blank')}
            style={[styles.storeImageContainer, styles.appStoreContainer]}
            activeOpacity={0.9}
          >
            <Image
              source={images.appstore}
              style={styles.store}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => window.open(googlePlayUrl, '_blank')}
            style={styles.storeImageContainer}
            activeOpacity={0.9}
          >
            <Image
              source={images.googleplay}
              style={styles.store}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <TouchableOpacity
            onPress={() => window.open(instagramUrl, '_blank')}
            style={[styles.socialBtn, styles.youtubeBtn]}
            activeOpacity={0.9}
          >
            <Icon name="logo-instagram" size={20} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => window.open(youtubeUrl, '_blank')}
            style={styles.socialBtn}
            activeOpacity={0.9}
          >
            <Icon name="logo-youtube" size={20} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.largeSeparator} />
      <View style={!isTabletOrMobile && styles.rowContainer}>
        <Text style={styles.copyright}>{I18n.t('footer.rightsReserved')}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(23, 10, 60, 0.04)',
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 100,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  catTitle: {
    fontWeight: 'bold',
    color: colors.darkIndigo,
  },
  subsContainer: {
    flexWrap: 'wrap',
    height: 200,
  },
  subTitleContainer: {
    maxWidth: 110,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: '600',
    marginBottom: 12,
    maxWidth: 110,
  },
  separator: {
    width: 359,
    height: 1,
    borderRadius: 10,
    backgroundColor: colors.darkIndigo,
    opacity: 0.05,
    marginTop: 13,
    marginBottom: 10,
  },
  storeImageContainer: {
    width: 150,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
  appStoreContainer: {
    marginEnd: 12,
  },
  store: {
    width: 150,
    height: 44,
  },
  largeSeparator: {
    height: 1,
    borderRadius: 10,
    backgroundColor: colors.darkIndigo,
    opacity: 0.05,
    marginTop: 20,
    marginBottom: 20,
  },
  youtubeBtn: {
    marginHorizontal: 24,
  },
  socialBtn: {
    width: 48,
    height: 48,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: 'rgba(23, 10, 60,0.05)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  copyright: {
    fontSize: 14,
    color: colors.darkIndigo,
  },
});
