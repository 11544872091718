import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import ScrollList from '../ScrollList';
import { Image } from '../index';

export default class CoverScrollList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onPress: PropTypes.func,
  };

  render() {
    const { data, onPress } = this.props;
    return (
      <View style={styles.container}>
        <Image
          style={styles.coverImage}
          resizeMode={'cover'}
          source={{ uri: data.section_cover }}
        />
        <ScrollList
          items={data.items}
          showsHorizontalScrollIndicator={false}
          onPress={onPress}
          nestedScrollEnabled={true}
          cardStyle={styles.cardStyle}
          containerStyle={styles.scrollView}
        />
      </View>
    );
  }
}
