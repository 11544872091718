import React from 'react';
import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { scale, vScale } from '../../services';
import { ImageButton } from '..';

export const HomeMiniItem = ({ onPress, image, title }) => {
  const { imageContainer, imageStyle, textStyle } = styles;
  return (
    <ImageButton
      container={{
        height: title ? vScale(110) : scale(80),
        justifyContent: 'space-between',
      }}
      {...{ imageContainer, imageStyle, textStyle, onPress, image, title }}
    />
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: scale(80),
    height: scale(80),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: vScale(10),
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: vScale(2),
    },
    shadowRadius: vScale(4),
    shadowOpacity: 0.15,
    elevation: 2,
  },
  imageStyle: {
    width: scale(75),
    height: scale(75),
    borderRadius: vScale(10),
  },
  textStyle: {
    width: scale(80),
    textAlign: 'center',
  },
});
