import { StyleSheet } from 'react-native';
// import colors from '../../common/colors';

export default StyleSheet.create({
  container: {
    marginVertical: 8,
    marginHorizontal: 4,
  },
  imageContainer: {
    marginHorizontal: 4,
  },
  image: {
    borderRadius: 4,
    width: 88,
    height: 88,
  },
});
