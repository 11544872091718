import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { scale, vScale } from '../../services';

export default StyleSheet.create({
  container: {
    justifyContent: 'space-between',
  },
  cartHeader: {
    padding: scale(15),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: colors.grey200,
  },
  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: vScale(30),
  },
  favoriteList: {
    marginBottom: vScale(100),
  },
});
