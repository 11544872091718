import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.brandGreyLight,
  },
  shimmer: {
    height: 180,
  },
  listContainer: {
    paddingVertical: 8,
  },
  wrapperStyle: {
    justifyContent: 'space-between',
    paddingHorizontal: 13,
    paddingVertical: 7,
  },
});
