import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '..';
import { scale, vScale } from '../../services';
import { colors } from '../../common';

export const TableCard = props => {
  const { title, text } = props;
  if (!Boolean(text)) {
    return null;
  }
  return (
    <View style={styles.rowContainer}>
      <Text size="small" style={styles.titleText}>
        {title}
      </Text>
      <Text size="small" style={styles.contentText}>
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    borderRadius: vScale(4),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.borderColor10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: scale(16),
    paddingVertical: vScale(8),
  },
  titleText: {
    lineHeight: 22,
    letterSpacing: -0.29,
    textAlign: 'center',
    color: colors.warmGrey,
  },
  contentText: {
    fontWeight: '500',
    lineHeight: 22,
    letterSpacing: -0.29,
    color: colors.greyishBrown,
  },
});
