import { colors } from '../../common';
import { StyleSheet } from 'react-native';
import { isRTL } from '../../services';

const styles = StyleSheet.create({
  searchContainer: {
    height: 48,
  },
  inputContainer: {
    borderRadius: 24,
  },
  dropDownContainer: {
    backgroundColor: colors.white,
    paddingTop: 15,
    paddingStart: 27,
    shadowColor: colors.shadowDarkColor,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 3,
    elevation: 5,
  },
  dropDownRow: {
    flexDirection: 'row',
  },
  subCategoryItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subCategoryImageContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: 'rgba(23, 10, 60, 0.05)',
  },
  subCategoryImage: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  subCategoryTextContainer: {
    // height: 30,
    paddingHorizontal: 16,
    width: 300,
    // justifyContent: 'space-around',
  },
  subCategoryName: {
    fontSize: 13,
    fontWeight: '600',
    color: '#170a3c',
    textAlign: isRTL ? 'left' : 'right',
  },
  subCategorySubText: {
    fontSize: 12,
    color: '#170a3c',
    opacity: 0.4,
  },
  hoverItem: {
    height: 71,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
