export default [
  {
    id: 0,
    code: 302,
    enMessage: 'Your Order is Cancelled',
    arMessage: 'لقد تم الغاء طلبك',
  },
  {
    id: 2,
    code: 405,
    enMessage: 'Please enter a valid card number',
    arMessage: 'من فضلك ادخل رقم كرت صحيح',
  },
  {
    id: 3,
    code: 406,
    enMessage: 'Please enter the valid expiry date',
    arMessage: 'يرجي ادخال تاريخ انتهاء صالح',
  },
  {
    id: 4,
    code: 407,
    enMessage: 'Your card is expired, please use a valid one',
    arMessage: 'انتهت صلاحية بطاقتك، يرجى استخدام بطاقة صالحة',
  },
  // {
  //   id: 5,
  //   code: 408,
  //   enMessage: 'General',
  //   arMessage: 'General',
  // },
  {
    id: 6,
    code: 502,
    enMessage: 'incorrect CVV, please enter the correct CVV',
    arMessage: 'رمز الامان غير صحيح، يرجي ادخال الرمز الصحيح',
  },
  {
    id: 7,
    code: 503,
    enMessage: 'Incorrect 3D Security code',
    arMessage: 'رمز التحقق غير صحيح',
  },
  {
    id: 8,
    code: 504,
    enMessage: 'Card not Enrolled',
    arMessage: 'البطاقة غير مسجلة',
  },
  {
    id: 9,
    code: 505,
    enMessage: 'Declined, Insufficient balance in your card',
    arMessage: 'لا يوجد رصيد كافي لاتمام المعاملة',
  },
  {
    id: 10,
    code: 801,
    enMessage: 'Your connection timed out',
    arMessage: 'انتهت مهلة الاتصال',
  },
  {
    id: 11,
    code: 702,
    enMessage: 'Retry Limit Exceeded',
    arMessage: 'تم تجاوز حد إعادة المحاولة',
  },
  {
    id: 12,
    code: 701,
    enMessage: 'Recursion limit reached',
    arMessage: 'تم بلوغ الحد الاقصي من محاولات الاتصال',
  },
  {
    id: 13,
    code: 704,
    enMessage: 'Recursion limit reached',
    arMessage: 'تم بلوغ الحد الاقصي من محاولات الاتصال',
  },
  {
    id: 14,
    code: 703,
    enMessage: 'Recursion limit reached',
    arMessage: 'تم بلوغ الحد الاقصي من محاولات الاتصال',
  },
];
