import { Animated, Platform, StyleSheet, View } from 'react-native';
// import liraries
import React, { Component } from 'react';

import PropTypes from 'prop-types';

// create a component
class ShimmerPlaceHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      beginShimmerPosition: new Animated.Value(-1),
    };
  }
  componentDidMount() {
    const { autoRun } = this.props;
    if (autoRun) {
      this.loopAnimated();
    }
  }
  componentWillUnmount() {
    const shimmerAnimated = this.getAnimated();
    shimmerAnimated.stop();
  }
  loopAnimated() {
    const shimmerAnimated = this.getAnimated();
    const { visible } = this.props;
    shimmerAnimated.start(({ finished }) => {
      if (!visible && finished) {
        this.loopAnimated();
      }
    });
  }
  getAnimated = () => {
    this.state.beginShimmerPosition.setValue(-1);
    return Animated.timing(this.state.beginShimmerPosition, {
      toValue: 1,
      delay: this.props.delay,
      duration: this.props.duration,
      useNativeDriver: true,
      isInteraction: this.props.isInteraction,
    });
  };
  render() {
    const {
      width,
      reverse,
      height,
      colorShimmer,
      style,
      children,
      visible,
    } = this.props;
    let outputRange = [1, 0, 1];
    if (reverse) {
      outputRange = outputRange.reverse();
    }
    const opacity = this.state.beginShimmerPosition.interpolate({
      inputRange: [-1, 0, 1],
      outputRange: [0.6, 0.1, 0.6],
    });

    return (
      <View
        style={!visible ? [{ height, width }, styles.container, style] : []}
      >
        {!visible ? (
          <View style={{ flex: 1 }}>
            {/* USING TRANSFORM */}
            <Animated.View
              style={{
                flex: 1,
                backgroundColor: colorShimmer,
                opacity,
              }}
            />
            {/* Force run children */}
            <View style={{ width: 0, height: 0 }}>{this.props.children}</View>
          </View>
        ) : (
          children
        )}
      </View>
    );
  }
}

ShimmerPlaceHolder.defaultProps = {
  width: 200,
  height: 15,
  duration: 1000,
  delay: 0,
  colorShimmer: '#E9EBEE',
  reverse: false,
  autoRun: false,
  visible: false,
  isInteraction: true,
};
// define your styles
const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
});
ShimmerPlaceHolder.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  duration: PropTypes.number,
  delay: PropTypes.number,
  colorShimmer: PropTypes.string,
  reverse: PropTypes.bool,
  autoRun: PropTypes.bool,
  visible: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.any,
  isInteraction: PropTypes.bool,
};
// make this component available to the app
export default ShimmerPlaceHolder;
