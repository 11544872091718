import React, { useLayoutEffect, useState } from 'react';
import { ScrollView, Linking, I18nManager } from 'react-native';
import { I18n } from '../../locales';
import styles from './styles';
import {
  Logo,
  SettingRow,
  Screen,
  MainAlert,
  DrawerWithBackIcons,
} from '../../components';
import { images, config } from '../../common';
import { useCart } from '../../redux/useCart';
import { useDispatch, useSelector } from 'react-redux';
import { actions as configActions } from '../../redux/config';
import { actions } from '../../redux/user';
import { navigateTo, navigateToManageAddresses } from '../../services';

const Settings = ({ navigation }) => {
  const dispatch = useDispatch();
  const { clearCart_GQL } = useCart();
  const { config: storeConfig, user } = useSelector((state) => {
    return {
      config: state.config,
      user: state.user,
    };
  });
  const [logoutBox, setLogoutBox] = useState(false);
  const [selectedLang, setSelectedLang] = useState(storeConfig.lang);
  // const [devMode, setDevMode] = useState(false);
  // const [attribution, setAttribution] = useState({});
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  const navigateToFAQ = () => {
    navigation.navigate('FAQ');
  };

  const navigateToAddresses = () => {
    navigateToManageAddresses({ fromSettings: true });
  };

  const navigateToCountry = () => {
    navigateTo('country');
  };

  const onCallSupport = () => {
    const isKSA = storeConfig.country.currencyCode == 'SAR';
    const tel = isKSA ? config.ksaTelSupport : config.telSupport;
    const url = `tel:${tel}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          console.log("Can't handle url: " + url);
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) => console.warn('An unexpected error happened', err));
  };

  const changeLang = async () => {
    const language = selectedLang == 'en' ? 'ar' : 'en';
    setSelectedLang(language);
    I18nManager.forceRTL(language === 'ar');
    dispatch(configActions.setLanguage(language));
    if (!user.loggedIn) {
      clearCart_GQL();
    }
    setTimeout(() => {
      location.reload();
    }, 200);
  };

  const toggleLogoutBox = () => {
    setLogoutBox(!logoutBox);
  };

  const onLogout = async () => {
    await dispatch(actions.userLogout());
    // trackUserLogout(user);
    setLogoutBox(false);
    setTimeout(() => {
      navigateTo('home');
    }, 200);
  };

  let flag, enName, arName;
  if (storeConfig.country) {
    const country = storeConfig.country;
    flag = country.flag;
    enName = country.enName;
    arName = country.arName;
  }

  return (
    <Screen>
      <ScrollView style={styles.scrollStyle}>
        {user.loggedIn && (
          <SettingRow
            imageUrl={images.account}
            title={I18n.t('settings.accountSettings')}
            onPress={() => navigateTo('accountSettings')}
          />
        )}
        <SettingRow
          imageUrl={images.language}
          title={I18n.t('settings.appLanguage')}
          subTitle={I18n.t('settings.lang')}
          onPress={() => changeLang()}
        />
        <SettingRow
          IconText={flag}
          title={I18n.t('settings.country')}
          subTitle={I18nManager.isRTL ? arName : enName}
          onPress={() => navigateToCountry()}
        />
        {user.loggedIn && (
          <SettingRow
            imageUrl={images.delivery}
            title={I18n.t('settings.manageShippingAddresses')}
            onPress={() => navigateToAddresses()}
          />
        )}
        {/* <SettingRow
          imageUrl={images.faq}
          title={I18n.t('profile.faq')}
          onPress={() => navigateToFAQ()}
        /> */}
        <SettingRow
          imageUrl={images.contactUs}
          title={I18n.t('profile.contactUs')}
          onPress={() => onCallSupport()}
        />
        {user.loggedIn && (
          <SettingRow
            imageUrl={images.exit}
            title={I18n.t('profile.logout')}
            onPress={() => toggleLogoutBox()}
          />
        )}

        {/*---------'version code '---------*/}
        {/* <View
          onStartShouldSetResponder={(e) =>
            e.nativeEvent.touches.length == 5 && setDevMode(true)
          }
        > */}
        <Logo logoStyle={styles.logoStyle} />
        {/* {devMode && (
            <View>
              <Text style={styles.devModeText}>
                {JSON.stringify(attribution)}
              </Text>
            </View>
          )} */}
        {/* </View> */}
        {/** Logout modal box **/}
        <MainAlert
          visible={logoutBox}
          onClose={() => toggleLogoutBox()}
          title={I18n.t('profile.logoutModal.title')}
          description={I18n.t('profile.logoutModal.description')}
          buttons={[
            { title: I18n.t('profile.logout'), onPress: onLogout },
            { title: I18n.t('btns.cancel'), onPress: toggleLogoutBox },
          ]}
        />
      </ScrollView>
    </Screen>
  );
};

export default Settings;
