import { gql } from '@apollo/client';

export const GET_SUB_CATEGORIES = gql`
  query($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      children {
        id
        name
        image
        product_count
        children {
          id
          name
          image
          product_count
        }
      }
    }
  }
`;
