import { store } from '../common';
import { convertQueryParams, createAction, createTypes } from '../common/utils';
import MagentoAPI from '../services/MagentoAPI';
// import { captureSentryError } from '../services/sentry';
import { types as CartTypes } from './useCart';
// import { types as giftWrapTypes } from './useGiftWrap';
// import {
// trackOrderStatusRequested,
// trackOrderStatusSuccess,
// trackOrderStatusFailed,
// } from '../services/analytics/order';
// import NavigationService from '../services/NavigationService';
import {
  // trackPaymentCanceledFromWebView,
  // trackPaymentSuccessFromWebView,
  // trackPaymentFailedFromWebView,
  navigateToFailedOrder,
  navigateToProcessing,
  navigateToSuccessOrder,
  navigateToPayment,
} from '../services';
import { trackEcommercePurchase } from '../services/analytics';

export const types = {
  CREATE_ORDER: createTypes('CREATE_ORDER'),
  GET_ORDERS: createTypes('GET_ORDERS'),
  VERIFY_PAYMENT: createTypes('VERIFY_PAYMENT'),
  ADD_COMMENT_TO_ORDER: createTypes('ADD_COMMENT_TO_ORDER'),
  GET_TAP_URL: createTypes('GET_TAP_URL'),
  GET_CREDIT_URL: createTypes('GET_CREDIT_URL'),
  START_ONLINE_ORDER: 'START_ONLINE_ORDER',
  FINISH_ONLINE_ORDER: 'FINISH_ONLINE_ORDER',
  CLEAR_ORDER_ID: 'CLEAR_ORDER_ID',
  CLEAR_ORDER: 'CLEAR_ORDER',
  SET_INVOICE_QUERY: 'SET_INVOICE_QUERY',
  RESTORE_ORDER_ID: createTypes('RESTORE_ORDER_ID'),
  APPLE_PAY_CREATE: createTypes('APPLE_PAY_CREATE'),
  GET_ORDER_INCREMENT_ID: createTypes('GET_ORDER_INCREMENT_ID'),
  GET_MORE_ORDERS: createTypes('GET_MORE_ORDERS'),
  GET_ORDER_DETAILS: createTypes('GET_ORDER_DETAILS'),
  SET_IS_PENDING: 'SET_IS_PENDING',
};

export const actions = {
  // NOTE: Though slotData is optional field in api, it is necessary for api.
  createOrder:
    (paymentMethod, email, slotData) => async (dispatch, getState) => {
      const type = types.CREATE_ORDER;
      try {
        const { cartState: cart, user, config } = getState();
        if (cart.id) {
          dispatch(createAction(type.REQUEST));
          const data = {
            email: email,
            paymentMethod: {
              method: paymentMethod,
              // extension_attributes: {
              //   // slot_data: slotData // fields must be: `{slot_time, date, slot_id, slot_group(optional)}`
              // }
            },
          };
          const { currencyCode } = config.country;
          const resp = user.loggedIn
            ? await MagentoAPI.post(
                `/carts/mine/payment-information`,
                type,
                JSON.stringify(data),
                {
                  country: currencyCode,
                },
              )
            : await MagentoAPI.post(
                `/guest-carts/${cart.id}/payment-information`,
                type,
                JSON.stringify(data),
              );

          if (resp.ok)
            return dispatch(
              createAction(type.SUCCESS, resp.json, {
                email: email,
              }),
            );
          else return dispatch(createAction(type.ERROR, resp, data));
        } else return Promise.resolve();
      } catch (e) {
        // captureSentryError(e);
        dispatch(createAction(type.ERROR));
      }
    },

  getIncrementIdOrderNumber: () => async (dispatch, getState) => {
    const type = types.GET_ORDER_INCREMENT_ID;
    try {
      const { order } = getState();
      if (order.orderId) {
        dispatch(createAction(type.REQUEST));
        const resp = await MagentoAPI.get(
          `/mobile/orders/${order.orderId}?fields=increment_id`,
          type,
        );
        if (resp.ok) {
          return dispatch(createAction(type.SUCCESS, resp.json));
        } else return dispatch(createAction(type.ERROR, resp));
      } else return Promise.resolve();
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  getOrders: () => (dispatch, getState) => {
    const type = types.GET_ORDERS;
    try {
      // get order and user from store
      const { order, user } = getState();
      if (!user.token) return;
      // create query params to send to endpoint
      const queryParams = {
        limit: order.pageSize,
        offset: 1,
      };

      //make query params to string
      const queryParamsString = convertQueryParams(queryParams);

      // fire requst action
      dispatch(createAction(type.REQUEST));

      return MagentoAPI.get(`/mobile/orders?${queryParamsString}`, type).then(
        (resp) => {
          if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
          else return dispatch(createAction(type.ERROR, resp));
        },
      );
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  getMoreOrders: () => (dispatch, getState) => {
    const type = types.GET_MORE_ORDERS;
    try {
      const { order, user } = getState();
      if (!order.history) return Promise.resolve();
      if (order.fetchMore) {
        dispatch(createAction(type.REQUEST));
        const queryParams = {
          limit: order.pageSize,
          offset: order.page + 1,
        };
        //make query params to string
        const queryParamsString = convertQueryParams(queryParams);
        return MagentoAPI.get(`/mobile/orders?${queryParamsString}`, type).then(
          (resp) => {
            if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
            else return dispatch(createAction(type.ERROR, resp));
          },
        );
      }
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  getOrderDetails: (orderId) => (dispatch, getState) => {
    const type = types.GET_ORDER_DETAILS;
    try {
      orderId = orderId || getState().order.orderId;
      const { user } = getState();

      dispatch(createAction(type.REQUEST));

      return MagentoAPI.get(`/mobile/orders/${orderId}`, type).then((resp) => {
        if (resp.ok) {
          return dispatch(createAction(type.SUCCESS, resp.json[0]));
        } else {
          return dispatch(createAction(type.ERROR, resp));
        }
      });
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  getKnetURL: () => (dispatch, getState) => {
    const type = types.GET_TAP_URL;
    try {
      const orderId = getState().order.orderId;
      if (orderId) {
        dispatch(createAction(type.REQUEST));
        return MagentoAPI.post(`/knet/pay/${orderId}`, type).then((resp) => {
          if (resp.ok)
            return dispatch(createAction(type.SUCCESS, JSON.parse(resp.json)));
          else return dispatch(createAction(type.ERROR, resp));
        });
      } else throw Error('[getKnetURL]: Please create order first');
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  /**
   * take the order id and the payment method
   * return the initial WebView url
   */
  getWebViewURL: (paymentMethod) => (dispatch, getState) => {
    const type = types.GET_CREDIT_URL;
    try {
      const orderId = getState().order.orderId;
      if (orderId) {
        dispatch(createAction(type.REQUEST));
        const data = { orderId, paymentMethod };
        return MagentoAPI.post(`/tap/all`, type, JSON.stringify(data)).then(
          (resp) => {
            if (resp.ok)
              return dispatch(
                createAction(type.SUCCESS, JSON.parse(resp.json)),
              );
            else return dispatch(createAction(type.ERROR, resp, data));
          },
        );
      } else throw Error('[getWebViewURL]: Please create order first');
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  /**
   * take the order id and the payment method
   * return the initial WebView url
   */
  getOtherWebViewURL: () => (dispatch, getState) => {
    const type = types.GET_TAP_URL;
    try {
      const orderId = getState().order.orderId;
      if (orderId) {
        dispatch(createAction(type.REQUEST));
        return MagentoAPI.post(`/other/pay/${orderId}`, type).then((resp) => {
          if (resp.ok)
            return dispatch(createAction(type.SUCCESS, JSON.parse(resp.json)));
          else return dispatch(createAction(type.ERROR, resp));
        });
      } else throw Error('[getOtherWebViewURL]: Please create order first');
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  addCommentToOrder: (comment) => (dispatch, getState) => {
    const type = types.ADD_COMMENT_TO_ORDER;
    try {
      const orderId = getState().order.orderId;
      if (orderId) {
        dispatch(createAction(type.REQUEST));
        const data = {
          statusHistory: {
            comment: comment || 'empty',
          },
        };
        return MagentoAPI.post(
          `/mobile/orders/${orderId}/comments`,
          type,
          JSON.stringify(data),
        ).then((resp) => {
          if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
          else return dispatch(createAction(type.ERROR, resp, data));
        });
      } else throw Error('[AddCommentToOrder]: Please create order first');
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },

  startOnlineOrder: () => createAction(types.START_ONLINE_ORDER),

  finishOnlineOrder: () => createAction(types.FINISH_ONLINE_ORDER),

  clearOrderId: () => createAction(types.CLEAR_ORDER_ID),
  clearOrder: () => createAction(types.CLEAR_ORDER),

  restoreOrder: () => (dispatch, getState) => {
    const type = types.RESTORE_ORDER_ID;
    try {
      const orderId = getState().order.orderId;
      // console.log('restoreOrder ', orderId)
      if (orderId) {
        dispatch(createAction(type.REQUEST));
        return MagentoAPI.post(`/restore/quote/${orderId}`, type).then(
          (resp) => {
            if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
            else return dispatch(createAction(type.ERROR, resp));
          },
        );
      }
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },
  getOrderStatus: () => (dispatch, getState) => {
    try {
      const orderId = getState().order.orderId;
      return MagentoAPI.get(`/pay/order/status/${orderId}`).then((resp) => {
        if (resp.ok) return resp.json[0];
      });
    } catch (e) {
      // captureSentryError(e);
    }
  },
  // combining the functions we call
  clearAll: () => (dispatch) => {
    const { finishOnlineOrder } = actions;
    store.dispatch({ type: CartTypes.CLEAR_CART });
    // store.dispatch({ type: giftWrapTypes.CLEAR_GW });
    dispatch(finishOnlineOrder());
  },
  checkOrderStatus: () => async (dispatch, getState) => {
    const { restoreOrder, getOrderStatus, clearAll, setIsPending } = actions;
    const { order } = getState();
    const { incrementId, isPendingOrder } = order;
    const isCart = location.href.includes('cart');
    // trackOrderStatusRequested(order);
    //if user kill the app in the middle of the transaction
    if (isPendingOrder) {
      try {
        // checking the order status of the order
        const resp = await dispatch(getOrderStatus());
        // trackOrderStatusSuccess({ ...order, orderStatus: resp.status });
        // handling what to do based on the order status
        await dispatch(setIsPending(false));
        switch (resp.status) {
          case 'pending':
            dispatch(clearAll());
            navigateToProcessing();
            break;
          case 'canceled':
            dispatch(restoreOrder()).then(() => {
              if (!isCart) {
                navigateToFailedOrder({
                  OrderNumber: incrementId,
                  transactionData: resp,
                });
              }
            });

            break;
          case 'processing':
            dispatch(clearAll());
            navigateToSuccessOrder(resp);
            break;
          default:
            await dispatch(restoreOrder());
            break;
        }
      } catch (error) {
        // trackOrderStatusFailed({ ...order, error });
        console.warn('checkOrderStatus error ', error);
        dispatch(clearAll());
        navigateToProcessing();
      }
    }
  },

  setIsPending: (status) => createAction(types.SET_IS_PENDING, status),

  applePayCreateCharge: (tokendata) => (dispatch, getState) => {
    const type = types.APPLE_PAY_CREATE;
    try {
      const orderId = getState().order.orderId;
      const data = { orderId, tokendata };
      if (orderId) {
        dispatch(createAction(type.REQUEST));
        return MagentoAPI.post(
          `/applepay/create`,
          type,
          JSON.stringify(data),
        ).then((resp) => {
          // console.log('resp', resp);
          if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
          else return dispatch(createAction(type.ERROR, resp));
        });
      }
    } catch (e) {
      // captureSentryError(e);
      dispatch(createAction(type.ERROR));
    }
  },

  handleOrderResult:
    ({ transactionData, paymentMethod }) =>
    async (dispatch, getState) => {
      const {
        order,
        cartState: cart,
        config: { country },
        checkout,
      } = getState();
      const { restoreOrder, clearAll } = actions;
      const { incrementId } = order;
      const { shipping_addresses } = checkout;
      const shipping_amount =
        shipping_addresses[0]?.selected_shipping_method?.amount?.value;
      const applePayMethod = paymentMethod.additioncode == 'Apple_Pay';
      const { result } = transactionData;
      switch (result) {
        // CAPTURED means the transaction succeeded so we clear the local order and navigate to succeeded order screen
        case 'CAPTURED':
          if (!applePayMethod) {
            // trackPaymentSuccessFromWebView();
          }
          //should await Ackoo request before redirect to success page to let the request complete(not canceled)
          await trackEcommercePurchase(
            paymentMethod,
            { ...cart, currency: country.currencyCode },
            order,
            shipping_amount,
          );
          await dispatch(clearAll());
          navigateToSuccessOrder({
            transactionData,
          });
          break;
        // CANCELED means the transaction canceled from the web view so we restore the order and navigate back
        case 'CANCELED':
          // if (!applePayMethod) {
          //   trackPaymentCanceledFromWebView();
          // }
          await dispatch(restoreOrder());
          navigateToPayment();
          break;
        default:
          // in any other case we restore the order and navigate to failed order screen
          // if (!applePayMethod) {
          //   trackPaymentFailedFromWebView();
          // }
          await dispatch(restoreOrder());

          navigateToFailedOrder({
            OrderNumber: incrementId,
            transactionData,
          });
          break;
      }
    },
};

const initialState = {
  orderId: '',
  email: '',
  tapURL: '',
  paymentVerified: {},
  history: [],
  incrementId: '',
  pageSize: 10,
  page: 1,
  fetchMore: true,
  isPendingOrder: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ORDER.SUCCESS:
      return {
        ...state,
        orderId: action.payload,
        email: action.meta.email,
      };
    case types.GET_TAP_URL.SUCCESS:
      const tapURL = action.payload.url;
      return {
        ...state,
        tapURL,
      };
    case types.GET_CREDIT_URL.SUCCESS:
      const url = action.payload.redirect.url;
      return {
        ...state,
        tapURL: url,
      };
    case types.VERIFY_PAYMENT.SUCCESS:
      return {
        ...state,
        paymentVerified: JSON.parse(action.payload),
      };
    case types.GET_ORDERS.SUCCESS:
      return {
        ...state,
        history: action.payload,
        prevHistory: action.payload,
        fetchMore: true,
        page: 1,
      };
    case types.GET_MORE_ORDERS.SUCCESS:
      const isSame =
        JSON.stringify(action.payload) == JSON.stringify(state.prevHistory);
      return {
        ...state,
        history: isSame ? state.history : state.history.concat(action.payload),
        prevHistory: action.payload, // set previous history to compare between new and old for pagentation
        fetchMore: !isSame, //here if last array the same of previouse stope immediately
        page: state.page + 1,
      };
    case types.CLEAR_ORDER_ID:
      return { ...state, orderId: '' };
    case types.START_ONLINE_ORDER:
      return { ...state, isPendingOrder: true };
    case types.FINISH_ONLINE_ORDER:
      return { ...state, isPendingOrder: false };
    case types.CLEAR_ORDER:
      return initialState;
    case types.RESTORE_ORDER_ID.SUCCESS:
      return { ...initialState, history: state.history };
    case types.GET_ORDER_INCREMENT_ID.SUCCESS:
      return {
        ...state,
        incrementId: action.payload.increment_id,
      };
    case types.SET_IS_PENDING:
      return {
        ...state,
        isPendingOrder: action.payload,
      };
    default:
      return state;
  }
};
