import { StyleSheet } from 'react-native';
import { scale, vScale } from '../../services';

export default StyleSheet.create({
  container: {
    marginBottom: 8,
    height: 349,
  },
  hideAddToCartContainer: {
    height: 293,
  },
  card: {
    width: 160,
  },
  image: {
    width: 120,
  },
});
