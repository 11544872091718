import React from 'react';
import { View, StyleSheet, Button } from 'react-native';
import { ApplePay } from 'react-native-apay';
// import { ApplePayButton as Button } from 'react-native-rn-apple-pay-button';
import { vScale, scale } from '../../services';
import { constants } from '../../common';

export const ApplePayButton = props => {
  const { onPress, country, total } = props;
  const applePay = () => {
    const requestData = {
      merchantIdentifier: constants.MERCHANT_IDENTIFIER,
      supportedNetworks: ['mastercard', 'visa'],
      countryCode: country.key,
      currencyCode: country.currencyCode,
      paymentSummaryItems: [
        {
          label: 'Teeela Order',
          amount: total,
        },
      ],
    };

    if (ApplePay.canMakePayments) {
      ApplePay.requestPayment(requestData).then(paymentData => {
        const token_data = JSON.parse(paymentData);
        ApplePay.complete(ApplePay.SUCCESS).then(() => {
          onPress(token_data);
        });
      });
    }
  };
  return (
    <View style={styles.container}>
      <Button
        buttonStyle="black"
        type="buy"
        width={scale(345)}
        onPress={applePay}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    paddingVertical: vScale(10),
  },
});
