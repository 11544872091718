import { useAwaitQueryGateway } from '../queries/customHooks';
import { GET_SUB_CATEGORIES, GET_MAIN_CATEGORIES } from '../queries/categories';
import { useDispatch } from 'react-redux';
import { createAction, createTypes } from '../common/utils';

export const types = {
  GET_MAIN_CATEGORIES: createTypes('GET_MAIN_CATEGORIES'),
  GET_SUB_CATEGORIES: createTypes('GET_GQL_CATEGORIES'),
};

export const useCategories = () => {
  const fetchCategories = useAwaitQueryGateway(GET_MAIN_CATEGORIES);
  const fetchSubcategories = useAwaitQueryGateway(GET_SUB_CATEGORIES);
  const dispatch = useDispatch();

  //get the Main app categories [Toys, Babies]
  const getMainCategories = async () => {
    const type = types.GET_MAIN_CATEGORIES;
    try {
      dispatch(createAction(type.REQUEST));
      const { data, loading } = await fetchCategories();
      const { categoryList } = data;
      if (!loading && categoryList[0]?.children) {
        return dispatch(createAction(type.SUCCESS, categoryList[0]?.children));
      }
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  };

  const getSubcategories = async (catId) => {
    const id = catId?.toString() || '490';
    const type = types.GET_SUB_CATEGORIES;
    try {
      dispatch(createAction(type.REQUEST));
      const { data, loading } = await fetchSubcategories({
        variables: { id },
      });
      if (!loading && data.categoryList[0]?.children) {
        if (!catId) {
          return dispatch(
            createAction(type.SUCCESS, data.categoryList[0]?.children),
          );
        } else {
          return data.categoryList[0].children;
        }
      }
      return [];
    } catch (error) {
      dispatch(createAction(type.ERROR));
      console.log('error ', error);
    }
  };

  return {
    getMainCategories,
    getSubcategories,
  };
};

const initialState = {
  list: [],
  main: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUB_CATEGORIES.SUCCESS:
      return {
        ...state,
        list: [...action.payload],
      };
    case types.GET_MAIN_CATEGORIES.SUCCESS:
      return {
        ...state,
        main: [...action.payload],
      };
    default:
      return state;
  }
};
