import { gql } from '@apollo/client';
import { CheckoutFragment } from './CheckoutFragment';

export const GET_PAYMENT_INFORMATION = gql`
  query getPaymentInformation($cartId: String!) {
    cart(cart_id: $cartId) {
      ...CheckoutFragment
    }
  }
  ${CheckoutFragment}
`;
