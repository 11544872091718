import React from "react";
import { StyleSheet } from "react-native";
import { Button } from "..";

export const RoundedButton = ({ large, ...props }) => (
  <Button
    {...props}
    containerStyle={[
      large ? styles.largeContainer : styles.container,
      props.containerStyle,
    ]}
    textStyle={[
      large ? styles.largeTextStyle : styles.textStyle,
      props.textStyle,
    ]}
  />
);

const styles = StyleSheet.create({
  container: {
    height: 28,
    borderRadius: 14,
  },
  largeContainer: {
    height: 47,
    borderRadius: 47 / 2,
  },
  textStyle: {
    paddingHorizontal: 16,
  },
  largeTextStyle: {
    fontSize: 18,
    paddingHorizontal: 16,
    fontWeight: "bold",
    lineHeight: 22,
  },
});
