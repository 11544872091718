import React from 'react';
import { StyleSheet } from 'react-native';
import { HeaderSeeAll } from '..';
import { scale, vScale } from '../../services';
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from 'recyclerlistview';
import PropTypes from 'prop-types';
import { colors } from '../../common';
// eslint-disable-next-line react/display-name
export const HorizontalList = ({
  data,
  cardDimensions,
  hideSeeAll,
  onPressSeeAll,
  hideHeader,
  ...props
}) => {
  let dataProvider = new DataProvider((r1, r2) => {
    return r1 !== r2;
  }).cloneWithRows(data?.items);

  const _layoutProvider = new LayoutProvider(
    (index) => {
      return index;
    },
    (type, dimension) => {
      dimension.width = cardDimensions.width + scale(10);
      dimension.height = cardDimensions.height + vScale(10);
    },
  );

  return (
    <>
      {!hideHeader && (
        <HeaderSeeAll
          {...{ hideSeeAll }}
          title={data?.title}
          style={styles.header}
          onPress={() => onPressSeeAll(data)}
        />
      )}
      <RecyclerListView
        isHorizontal
        dataProvider={dataProvider}
        layoutProvider={_layoutProvider}
        contentContainerStyle={styles.listContainer}
        style={[
          styles.list,
          {
            height: cardDimensions.height + vScale(20),
            marginTop: vScale(hideHeader ? 20 : 0),
          },
        ]}
        scrollViewProps={{
          showsHorizontalScrollIndicator: false,
        }}
        {...props}
      />
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.transparent,
  },
  list: {
    minHeight: 1,
    minWidth: 1,
  },
  listContainer: {
    paddingHorizontal: scale(20),
  },
});

HorizontalList.propTypes = {
  data: PropTypes.object.isRequired,
  cardDimensions: PropTypes.object,
  rowRenderer: PropTypes.func,
};
