import React, { Fragment } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { ShimmerPlaceHolder } from './index';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styles from './styles';
import { colors } from '../../common';
import { sWidth } from '../../services';

const ShimmerSubCategories = (props) => {
  const { loading, num } = props;

  const renderItem = () => {
    return (
      <Fragment>
        <ShimmerPlaceHolder
          autoRun
          style={shimerStyles.shimmerCard}
          visible={!loading}
          colorShimmer={colors.brandShimm3}
        />
      </Fragment>
    );
  };

  const keyExtractor = (index) => {
    return `${index}`;
  };

  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      horizontal
      data={_.range(num)}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      contentContainerStyle={[
        styles.contentContainerScroll,
        shimerStyles.contentContainer,
      ]}
    />
  );
};

ShimmerSubCategories.propTypes = {
  loading: PropTypes.bool,
  num: PropTypes.number,
};
export default ShimmerSubCategories;
const shimerStyles = StyleSheet.create({
  shimmerCard: {
    width: sWidth,
    height: 110,
    borderRadius: 11,
    borderWidth: 2,
    borderColor: 'transparent',
    marginHorizontal: 4,
  },
  contentContainer: {
    marginTop: 8,
    height: 110,
  },
});
