import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    // borderRadius: 4,
    backgroundColor: colors.white,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    justifyContent: 'center',
    marginBottom: 8,
    // flex: 1,
    position: 'relative',
  },

  button: {
    backgroundColor: colors.bannerBtnBG,
    borderRadius: 4,
    height: 48,
    width: 166,
    borderWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontWeight: 'bold',
    marginTop: 2,
    letterSpacing: -0.38,
    color: colors.brand,
  },

  // banner-cover
  bannerCoverContainer: {
    height: 194,
  },
  bannerCoverDesc: {
    width: '55%',
    zIndex: 999,
    height: '100%',
    padding: 16,
    justifyContent: 'center',
    position: 'absolute',
  },
  bannerCoverDescText: {
    marginBottom: 30,
  },
  bannerCoverBtn: {
    bottom: 16,
    left: 16,
    position: 'absolute',
  },
  bannerCoverImage: {
    width: '100%',
    height: '100%',
  },

  // banner-card
  bannerCardImage: {
    width: '100%',
    height: 180,
  },
  bannerCardDesc: {
    padding: 16,
  },
  bannerCardBtn: {
    marginTop: 16,
  },
  titleText: {
    color: colors.greyishBrown,
    fontWeight: 'bold',
  },
  decText: {
    color: colors.greyishBrownTwo,
  },
});
