import React from 'react';
import { StyleSheet, TouchableOpacity, View, Platform } from 'react-native';
import { colors, config } from '../../common';
import ImageLoader from '../ImageLoader';
import { getCurrentStore } from '../../services/getCurrentStore';
import { Text } from '..';
const currentStore = getCurrentStore();

const CategoriesCardView = (props) => {
  const { item, onPressItem, style } = props;
  const { image, name } = item;

  return (
    <TouchableOpacity activeOpacity={0.9} onPress={onPressItem}>
      <View style={[styles.container, style]}>
        <View style={styles.textContainer}>
          <Text numberOfLines={3}  style={styles.textStyle}>
            {name}
          </Text>
        </View>
        <ImageLoader
          {...props}
          source={{ uri: image?.replace(currentStore, '') || '' }}
          style={styles.image}
          placeholder={config.placeholderImage}
        />
      </View>
    </TouchableOpacity>
  );
};
export default CategoriesCardView;
const styles = StyleSheet.create({
  container: {
    width: 250,
    height: 300,
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    borderRadius: 9,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    ...Platform.select({
      android: {
        elevation: 2,
        shadowColor: colors.black,
      },
    }),
  },
  image: {
    width: 200,
    height: 200,
    borderBottomStartRadius: 8,
    borderBottomEndRadius: 8,
    alignSelf:'center'
  },
  textContainer: {
    height: 50,
    paddingHorizontal: 6,
    justifyContent: 'center',
  },
  textStyle: {
    color: colors.black,
    textAlign: 'center',
  },
});
