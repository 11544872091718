import React, { useRef, useState } from 'react';
import { View, TouchableOpacity, Image, FlatList } from 'react-native';
import Swiper from 'react-native-web-swiper';
import { colors, images } from '../../common';
import { styles } from './webStyles';

export const PreviewSlider = (props) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { items, onPressSliderItem, containerStyle } = props;

  const _renderItem = ({ item, index }) => {
    const { url, video } = item;
    const isVideo = Boolean(video);
    return (
      <TouchableOpacity
        activeOpacity={0.9}
        onPress={() => onPressSliderItem(index)}
        key={index}
        style={[styles.container, containerStyle]}
      >
        <Image
          source={{ uri: url }}
          resizeMode="cover"
          style={styles.imageView}
        />
        {isVideo && (
          <View pointerEvents="none" style={styles.bigIconContainer}>
            <Image
              source={images.bluePlay}
              resizeMode="contain"
              style={styles.bigIconStyle}
            />
          </View>
        )}
      </TouchableOpacity>
    );
  };
  const isSingleItem = items.length == 1;
  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ width: 88, height: 473 }}>
        <FlatList
          data={items}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.listContentContainer}
          extraData={carouselRef.current}
          renderItem={({ item, index }) => {
            const { url, video } = item;
            const isVideo = Boolean(video);
            const isFocused = currentIndex == index;

            return (
              <View
                style={[
                  styles.smallItemContainer,
                  isFocused && styles.activeItemContainer,
                ]}
              >
                <TouchableOpacity
                  activeOpacity={0.1}
                  onPress={() => {
                    carouselRef.current.goTo(index);
                    setCurrentIndex(index);
                  }}
                >
                  <Image
                    source={{ uri: url }}
                    resizeMode="cover"
                    style={styles.smallItem}
                  />
                </TouchableOpacity>
                {isVideo && (
                  <View pointerEvents="none" style={styles.iconContainer}>
                    <Image
                      source={images.bluePlay}
                      resizeMode="contain"
                      style={styles.iconStyle}
                    />
                  </View>
                )}
              </View>
            );
          }}
        />
      </View>
      <View style={styles.carouselContainer}>
        {isSingleItem ? (
          items.map((item) => _renderItem?.({ item, index: 0 }))
        ) : (
          <Swiper
            ref={carouselRef}
            from={0}
            vertical
            // timeout={5}
            minDistanceForAction={0.1}
            onIndexChanged={(index) => setCurrentIndex(index)}
            controlsProps={{
              dotsTouchable: true,
              dotsPos: false,
              dotActiveStyle: { backgroundColor: colors.brand },
              prevPos: false,
              nextPos: false,
            }}
          >
            {items.map((item, index) => _renderItem?.({ item, index }))}
          </Swiper>
        )}
      </View>
    </View>
  );
};
