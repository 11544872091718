import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    minHeight: 320,
    backgroundColor: 'transparent',
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    marginBottom: 8,
  },
  coverImage: {
    height: 180,
  },

  scrollView: {
    backgroundColor: 'transparent',
    // borderWidth: 1,
    // borderColor: 'red',
    marginTop: -30,
  },
});
