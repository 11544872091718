import React from 'react';
import { View, TouchableOpacity, StyleSheet, TextInput } from 'react-native';
import { colors } from '../../common';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Text } from '..';
Icon.loadFont();

class SelectArea extends React.Component {
  render() {
    const { onPress, errorMessage, placeholder, selectedValue, style } =
      this.props;

    return (
      <View style={styles.container}>
        <TouchableOpacity
          activeOpacity={0.85}
          onPress={onPress}
          style={[styles.select, style]}
        >
          <Text style={styles.label}>{placeholder}</Text>
          <TextInput
            containerStyle={styles.input}
            editable={false}
            value={selectedValue}
          />
          {errorMessage && (
            <Text size="small" style={styles.errorMsg}>
              {errorMessage}
            </Text>
          )}
          <View style={styles.iconView}>
            <Icon name="arrow-drop-down" size={25} color={colors.brand} />
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
  },
  select: {
    backgroundColor: colors.grey140,
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  iconView: {
    position: 'absolute',
    right: 16,
    bottom: 12,
  },
  input: {
    flex: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    minHeight: 0,
  },
  label: {
    color: colors.grey600,
    marginVertical: 8,
    fontWeight: '600',
  },
  inputWeb: {
    width: 400,
  },
  errorMsg: {
    marginVertical: 5,
    color: colors.buttons,
  },
});

export default SelectArea;
