import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  content: {
    paddingTop: 25,
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  topView: {
    alignSelf: 'stretch',
  },
  inputContainerStyle: {
    marginVertical: 25,
    paddingHorizontal: 16,
  },
  subTitle: {
    paddingHorizontal: 30,
    textAlign: 'center',
  },
  btn: {
    marginHorizontal: 20,
  },
  input: {
    paddingHorizontal: 10,
    outline: 'none',
    backgroundColor: colors.white,
    height: 40,
    borderRadius: 8,
    alignSelf: 'center',
    marginVertical: 10,
  },
  inputWeb: {
    width: 400,
  },
  webButton: {
    width: 400,
  },
  buttonText: {
    paddingHorizontal: 25,
    fontWeight: '600',
    color: colors.white,
    textAlign: 'center',
  },
  errorMsg: {
    marginVertical: 5,
    color: colors.buttons,
  },
});
