import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import MainStack from './MainStack';
import { Text } from '../components';
const linking = {
  prefixes: ['exp://192.168.1.108:19000'],
  config: {
    screens: {
      Home: '',
      Cart: {
        path: '/cart/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      Categories: '/categories',
      Product: '/product/:sku',
      UserProfile: '/userProfile',
      Products: {
        path: '/products/:filters/:category',
        parse: {
          filters: (filters) => decodeURIComponent(filters),
          category: (category) => decodeURIComponent(category),
        },
      },
      Search: '/search',
      Login: {
        path: '/login/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      Signup: {
        path: '/signup/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      ForgetPassword: '/forgetPassword',
      ManageAddresses: {
        path: '/manageAddresses/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      Address: {
        path: '/address/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      OrderHistory: '/orderHistory',
      OrderDetails: {
        path: '/orderDetails/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      Payment: '/payment',
      PaymentHandler: '/paymentHandler',
      SuccessOrder: {
        path: '/successOrder/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      Wishlist: '/wishlist',
      AccountScreen: '/account',
      AccountSettings: '/accountSettings',
      ChangePassword: '/changePassword',
      Settings: '/settings',
      Country: '/country',
      FailedOrder: {
        path: '/failedOrder/:params?',
        parse: {
          params: (params) => decodeURIComponent(params),
        },
      },
      ProcessingPayment: '/processingPayment',
    },
  },
};

const Root = () => {
  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...!</Text>}>
      <MainStack />
    </NavigationContainer>
  );
};

export default Root;
