import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.bg,
  },
  childrenContainer: {
    marginHorizontal: 100,
  },
});
