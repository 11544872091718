import { createAction, createTypes } from '../common/utils';
import MagentoAPI from '../services/MagentoAPI';

// only for registered user.
export const types = {
  GET_WISHLIST_ITEMS: createTypes('GET_WISHLIST_ITEMS'),
  ADD_TO_WISHLIST: createTypes('ADD_TO_WISHLIST'),
  REMOVE_FROM_WISHLIST: createTypes('REMOVE_FROM_WISHLIST'),
  CLEAR_WISHLIST: 'CLEAR_WISHLIST',
};

export const actions = {
  getWishlistItems: () => (dispatch, getState) => {
    const { user } = getState();
    if (!user.token) return;
    const type = types.GET_WISHLIST_ITEMS;
    dispatch(createAction(type.REQUEST));
    return MagentoAPI.get(`mobile/favorites`, type).then((resp) => {
      if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
      else return dispatch(createAction(type.ERROR, resp));
    });
  },

  addToWishlist: (id) => (dispatch, getState) => {
    const { user } = getState();
    const type = types.ADD_TO_WISHLIST;
    dispatch(createAction(type.REQUEST));
    return MagentoAPI.post(`mobile/favorites/${id}`, type).then((resp) => {
      if (resp.ok)
        return dispatch(createAction(type.SUCCESS, resp.json, { sku: id }));
      else return dispatch(createAction(type.ERROR, resp));
    });
  },

  removeFromWishlist: (id) => (dispatch, getState) => {
    const { user } = getState();
    const type = types.REMOVE_FROM_WISHLIST;
    dispatch(createAction(type.REQUEST));
    return MagentoAPI.delete(`mobile/favorites/${id}`, type).then((resp) => {
      if (resp.ok)
        return dispatch(createAction(type.SUCCESS, resp.json, { itemId: id }));
      else return dispatch(createAction(type.ERROR, resp));
    });
  },
  clearWishList: () => createAction(types.CLEAR_WISHLIST),
};

const initialState = {
  list: [],
  totalCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WISHLIST_ITEMS.SUCCESS:
      return {
        ...state,
        list: action.payload,
        totalCount: action.payload.length,
      };
    case types.ADD_TO_WISHLIST.SUCCESS:
      return {
        ...state,
        list: action.payload,
        totalCount: action.payload.length,
      };
    case types.REMOVE_FROM_WISHLIST.SUCCESS:
      if (action.payload) {
        return {
          ...state,
          list: action.payload,
          totalCount: action.payload.length,
        };
      } else return state;
    case types.CLEAR_WISHLIST:
      return initialState;
    default:
      return state;
  }
};
