import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import {
  ProductCard,
  SearchBar,
  EmptyScreen,
  ShimmerList,
  DrawerWithBackIcons,
} from '../../components';
import { useSelector } from 'react-redux';
import Header from '../../components/Header';
import { useProducts, types } from '../../redux/UseProducts';
import { colors } from '../../common';
import Highlighter from 'react-native-highlight-words';
import { I18n } from '../../locales';
import { loadingSelector, showErrorMsg } from '../../common/utils';
import { navigateToProduct } from '../../services';
import Loading from '../../components/Loading';

const MobileSearch = ({ navigation }) => {
  const START_SEARCH_LENGTH = 1;
  const { searchProducts } = useProducts();
  const [text, setText] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [endReached, setEndReached] = useState(false);
  const timeoutRef = useRef(null);
  const isReadyToSearch = text?.length >= START_SEARCH_LENGTH;
  const loading = useSelector((state) =>
    loadingSelector(state, [types.SEARCH_PRODUCTS_GQL]),
  );
  const { config } = useSelector((state) => {
    return {
      config: state?.config,
    };
  });

  useEffect(() => {
    I18n.locale = config.lang;
  }, []);

  useLayoutEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    if (isReadyToSearch) {
      if (text?.length == START_SEARCH_LENGTH) {
        getProducts();
      } else {
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null;
          getProducts();
        }, 400);
      }
    }
  }, [text, currentPage]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      // eslint-disable-next-line react/display-name
      headerTitle: () => I18n.t('placeholders.search'),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  const getProducts = async () => {
    try {
      const resp = await searchProducts(text, currentPage);
      if (resp?.page_info?.total_pages == currentPage) {
        setEndReached(true);
      }
      setData(currentPage == 1 ? resp.items : [...data, ...resp.items]);
    } catch (error) {
      showErrorMsg();
      console.log('error', error);
    }
  };

  const renderItem = ({ item }) => {
    const {
      regular_price = {},
      discount = {},
      final_price = {},
    } = item?.price_range?.maximum_price;
    const { value: price = 0 } = regular_price;
    const discount_percentage = discount?.percent_off;
    const { value: product_final_price = 0 } = final_price;
    const special_price =
      discount_percentage !== 0 ? product_final_price : null;
    const newItem = {
      id: item.id,
      image_url: item?.small_image?.url,
      name: item?.name,
      price,
      special_price,
      brand: { name: item?.brand_object?.value, id: item?.brand_object?.id },
      discount_percentage,
      stock_status: item?.stock_status,
      sku: item.sku,
      type: item?.__typename,
    };
    return (
      <ProductCard
        item={newItem}
        onPressItem={() => onPressItem(item)}
        nameElement={
          <Highlighter
            style={styles.nameStyle}
            highlightStyle={styles.highlightStyle}
            searchWords={[text]}
            textToHighlight={newItem.name}
            autoEscape
            numberOfLines={2}
          />
        }
      />
    );
  };
  const onPressItem = (item) => {
    navigateToProduct(item?.sku, item?.name);
  };

  const handlePagination = async () => {
    setCurrentPage(currentPage + 1);
  };
  const listLoading = (loading && currentPage == 1) || !isReadyToSearch;
  const noData = data?.length == 0;

  return (
    <>
      <Header>
        <SearchBar
          onChangeText={(text) => {
            setCurrentPage(1);
            setEndReached(false);
            setText(text);
          }}
          value={text}
          autoFocus
          onCancel={() => navigation.goBack()}
          onClear={() => {
            setCurrentPage(1);
            setEndReached(false);
          }}
        />
      </Header>
      <>
        {listLoading ? (
          <ShimmerList loading={listLoading} num={6} style={{ width: 176 }} />
        ) : (
          <>
            {noData ? (
              <EmptyScreen
                errorMessage={{
                  title: I18n.t('search.errorTitle'),
                  subTitle: I18n.t('search.errorSubTitle'),
                }}
              />
            ) : (
              <FlatList
                numColumns={2}
                data={data}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                ListFooterComponent={() => (
                  <View style={styles.loadingStyle}>
                    {loading && <Loading />}
                  </View>
                )}
                contentContainerStyle={{
                  alignItems: 'center',
                }}
                onEndReachedThreshold={0.5}
                onEndReached={!endReached && handlePagination}
              />
            )}
          </>
        )}
      </>
    </>
  );
};
const styles = StyleSheet.create({
  nameStyle: {
    textAlign: 'left',
  },
  highlightStyle: {
    color: colors.brand,
  },
  loadingStyle: {
    height: 40,
  },
});
export default MobileSearch;
