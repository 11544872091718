import '../../bootstrap.css';
import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import styles from './styles.js';
import { countries } from '../../common';
import i18n from '../../locales/i18n';
import COUNTRIES from '../../common/countries';

export const CountriesList = ({ onItemPress }) => {
  const { config: storeConfig } = useSelector((state) => {
    return {
      config: state.config,
    };
  });
  const currentCountry = Boolean(storeConfig?.country?.key)
    ? storeConfig?.country
    : COUNTRIES[0];
  return (
    <>
      <nav
        id="countries-container"
        class="navbar navbar-expand-lg navbar-dark bg-primary"
      >
        <div class="container-fluid">
          <div class="collapse navbar-collapse" id="main_nav">
            <ul class="navbar-nav">
              <li id="list-item" class="nav-item dropdown">
                <View style={styles.mainButton}>
                  <p id="list-text" data-bs-toggle="dropdown">
                    {currentCountry?.flag}
                  </p>
                  <View style={{ width: 10 }} />
                  <p id="list-text" data-bs-toggle="dropdown">
                    {i18n.t(`countries.${currentCountry?.key}`)}
                  </p>
                </View>

                <ul class="dropdown-menu" id="dropdown-menu-custom">
                  <View style={styles.dropDownContainer}>
                    <View style={styles.dropDownRow}>
                      <View>
                        {countries?.map((item) => (
                          <li>
                            <li class="dropdown-item" id="dropdown-item-custom">
                              <TouchableOpacity
                                onPress={() => onItemPress(item)}
                              >
                                <View style={{ flexDirection: 'row' }}>
                                  <p class="dropdown-item-text-custom">
                                    {item.flag}
                                  </p>
                                  <View style={{ width: 10 }} />
                                  <p class="dropdown-item-text-custom">
                                    {i18n.t(`countries.${item.key}`)}
                                  </p>
                                </View>
                              </TouchableOpacity>
                            </li>
                          </li>
                        ))}
                      </View>
                    </View>
                  </View>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
