import React, { useEffect, useState, useLayoutEffect } from 'react';
import { DrawerWithBackIcons, OrdersList, Screen } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/order';
import { loadingSelector } from '../../common/utils';
import { navigateToOrderDetails } from '../../services';

const OrderHistory = ({ navigation }) => {
  const dispatch = useDispatch();
  const [onEndReachedCalled, setOnEndReachedCalled] = useState();
  const { order, loading, moreLoading } = useSelector((state) => {
    return {
      order: state.order,
      // online: state.network.isConnected,
      loading: loadingSelector(state, [types.GET_ORDERS]),
      moreLoading: loadingSelector(state, [types.GET_MORE_ORDERS]),
    };
  });

  const _initialFetch = () => {
    dispatch(actions.getOrders());
  };
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  useEffect(() => {
    // if (online) {
    _initialFetch();
    // }
  }, []);

  return (
    <Screen isLoading={loading}>
      <OrdersList
        loading={moreLoading}
        orders={order.history}
        onItemPress={({ increment_id, id }) =>
          navigateToOrderDetails({ id, increment_id })
        }
        onMomentumScrollBegin={() => {
          setOnEndReachedCalled(false);
        }}
        onEndReached={() => {
          if (!onEndReachedCalled && !moreLoading) {
            dispatch(actions.getMoreOrders());
            setOnEndReachedCalled(true);
          }
        }}
      />
    </Screen>
  );
};

export default OrderHistory;
