import React from 'react';
import { View, TouchableOpacity, FlatList, Image } from 'react-native';
import { Text, RoundedButton, ToggleButton } from '../index';
import { config, colors } from '../../common';
import styles from './styles';
import * as _ from 'lodash';

export default class ProductVariations extends React.Component {
  render() {
    const { onItemSelect, options, selectedOption } = this.props;
    if (options && options.length > 0) {
      const variation = options[0]?.attributes[0]?.code;
      const product = _.find(
        options,
        (e) => e?.product?.sku == selectedOption?.sku,
      );
      const selectedValue = product?.attributes[0]?.label || null;
      return (
        <View>
          <Text size="small" style={styles.header}>
            {variation} :
          </Text>

          <FlatList
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            data={options}
            extraData={this.props}
            renderItem={({ item, key }) => {
              const { product, attributes } = item;
              const { only_x_left_in_stock: qty = 0, small_image } = product;
              const isSelected = selectedValue == attributes[0]?.label;
              return (
                <TouchableOpacity
                  disabled={!Boolean(qty)}
                  key={key}
                  onPress={() => onItemSelect(item)}
                  activeOpacity={0.5}
                  style={styles.btnContainer}
                >
                  <View
                    style={[
                      styles.imageView,
                      {
                        borderColor: isSelected ? colors.brand : colors.grey300,
                      },
                    ]}
                  >
                    <Image
                      source={{ uri: small_image?.url }}
                      placeholder={config.placeholderImage}
                      style={[styles.image]}
                      resizeMode="contain"
                    />
                    {/* {qty ? null : <OutOfStock />} */}
                  </View>
                  <ToggleButton
                    disabled={!Boolean(qty)}
                    text={attributes[0]?.label}
                    containerStyle={styles.btn}
                    onPress={() => onItemSelect(item)}
                    isSelected={isSelected}
                  />
                </TouchableOpacity>
              );
            }}
          />
        </View>
      );
    } else return null;
  }
}
