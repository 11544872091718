import React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from '..';
import { colors } from '../../common';

export const SecondaryButton = props => (
  <Button
    {...props}
    containerStyle={[styles.container, props.containerStyle]}
  />
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.bluishGreen,
  },
});
