import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Modal, RefreshControl, ScrollView, View } from 'react-native';
import { images, colors } from '../../common';
import {
  ProductsList,
  CartIcon,
  HeaderButton,
  Screen,
  SubcategoriesList,
  DrawerWithBackIcons,
} from '../../components';
import styles from './styles';
import { useSelector } from 'react-redux';
import { I18n } from '../../locales';
import {
  customFilters,
  sHeight,
  sWidth,
  navigateToProduct,
} from '../../services';
import Animated from 'react-native-reanimated';
import { useMemoOne } from 'use-memo-one';
import { useCategories } from '../../redux/useCategories';
import { defaultNavigationOptions } from '../../navigation/navigationOptions';
import { useProducts } from '../../redux/useProducts';
import * as _ from 'lodash';
import Filters from '../Filters';

const MobileProductsScreen = (props) => {
  const { navigation, route } = props;
  const filtersParam = route.params?.filters;
  const stringfiedCategory = route.params?.category;
  const mainCategory = JSON.parse(stringfiedCategory);
  const [filters, setFilters] = useState(JSON.parse(filtersParam));
  const [refreshing, setRefreshing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [endReached, setEndReached] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(mainCategory);
  const [selectedSubcategory, setSelectedSubcategory] = useState({});
  const { getSubcategories } = useCategories();
  const { getProducts: _getProducts } = useProducts();

  const filterCount =
    Object.values(filters).filter((e) => e != undefined).length - 1;
  const hasCategories = Boolean(categories?.length);

  const attributes = useSelector((state) => state.attributes);

  const { Value } = Animated;
  const { scrollY } = useMemoOne(() => ({ scrollY: new Value(0) }), []);

  useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerTitle: mainCategory?.name || mainCategory?.title,
      //always keep title in center event in Android
      headerTitleAlign: 'center',
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <HeaderButton
          elements={[
            {
              icon: images.filter,
              onPress: () => setModalVisible(true),
              value: filterCount,
            },
            { element: <CartIcon navigation={navigation} /> },
          ]}
        />
      ),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,

      headerStyle: hasCategories
        ? {
            shadowOpacity: 0,
          }
        : defaultNavigationOptions.headerStyle,
    });
  }, [navigation, filters, loading]);

  useEffect(() => {
    onSelectFilters();
  }, [route]);

  useEffect(() => {
    getCategories();
  }, [filters.category_id]);

  const getProducts = async (pageIndex = page, newFilters = filters) => {
    let resp = {};
    try {
      const queryParamsString = customFilters({
        ...newFilters,
        currentPage: pageIndex,
        pageSize: 15,
      });
      resp = await _getProducts(queryParamsString);
      if (resp?.json) {
        setProducts(pageIndex == 1 ? resp.json : [...products, ...resp.json]);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.warn('error', error);
      setEndReached(true);
    }
    setProductsLoading(false);
    setMoreLoading(false);
    setRefreshing(false);
  };

  const getCategories = async () => {
    const categoryId =
      mainCategory?.parentId || mainCategory?.id || mainCategory?.categoryId;
    try {
      if (categoryId) {
        // const data = await categoriesActions.getSubcategories(categoryId);
        const data = await getSubcategories(categoryId);
        setCategories(data);
      }
    } catch (error) {
      console.log('error ', error);
    }
    setLoading(false);
  };

  // //open item details
  const onPressItem = (item) => {
    const { parent_sku, sku: isSku, name } = item;
    const sku = parent_sku ? parent_sku : isSku;
    const childSku = parent_sku ? isSku : null;
    navigateToProduct(sku, name);
  };

  const onRefresh = () => {
    setRefreshing(true);
    setPage(1);
    getProducts(1);
    setEndReached(false);
  };

  const onEndReached = () => {
    if (!endReached && !moreLoading) {
      setPage(page + 1);
      setMoreLoading(true);
      getProducts(page + 1);
    }
  };

  const handleFiltersChange = (newFilters) => {
    const currentFilters =
      typeof newFilters == 'string' ? JSON.parse(newFilters) : newFilters;
    setModalVisible(false);
    setFilters(currentFilters);
    getProducts(1, currentFilters);
    setPage(1);
    setEndReached(false);
    setProductsLoading(true);
  };

  const onSelectFilters = () => {
    if (filters != filtersParam) {
      handleFiltersChange(filtersParam);
      const hasAges = filtersParam?.ages?.length > 0;
      let ageValue = [];
      if (hasAges) {
        attributes.age.forEach((e) => {
          if (filtersParam.ages.includes(e.value)) {
            ageValue.push(e.label);
          }
        });
      }
    }
  };

  const onSelectCategory = (item) => {
    const mainCategoryId = mainCategory.id || mainCategory.categoryId;
    const newFilters = { ...filters, category_id: item?.id || mainCategoryId };
    handleFiltersChange(newFilters);
    setSelectedCategory({ ...item, parentId: mainCategory.parentId });
    setSelectedSubcategory({});
  };

  const onSelectSubcategory = (item) => {
    const newFilters = {
      ...filters,
      category_id: item?.id || selectedCategory?.id,
    };
    handleFiltersChange(newFilters);
    setSelectedSubcategory(item);
  };

  return (
    <Screen>
      <View style={{ flexDirection: 'row', height: sHeight }}>
        <Modal visible={modalVisible}>
          <Filters
            {...props}
            filters={filters}
            onFilterPress={(filters) => handleFiltersChange(filters)}
            onCancelPress={() => setModalVisible(false)}
          />
        </Modal>

        {/*---------'Render Products List Components'---------*/}
        <ProductsList
          renderHeader={() => {
            return (
              <View style={styles.categoriesContainer}>
                {(hasCategories || loading) && (
                  <SubcategoriesList
                    data={categories}
                    loading={loading}
                    productsLoading={productsLoading}
                    onSelectCategory={(item) => onSelectCategory(item)}
                    onSelectSubCategory={(item) => onSelectSubcategory(item)}
                    selectedCategory={selectedCategory}
                    selectedSubcategory={selectedSubcategory}
                    {...{ scrollY }}
                  />
                )}
              </View>
            );
          }}
          navigation={navigation}
          data={products}
          numColumns={2}
          loading={productsLoading}
          moreLoading={moreLoading}
          onPressItem={(item) => onPressItem(item)}
          onEndReached={onEndReached}
          errorMessage={{
            title: I18n.t('products.errorTitle'),
            subTitle: I18n.t('products.errorSubTitle'),
          }}
          refreshControl={
            <RefreshControl
              tintColor={colors.brand}
              colors={[colors.brand]}
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
          scrollEventThrottle={1}
          bounces={products?.length > 4}
          overScrollMode={'never'}
        />
      </View>
    </Screen>
  );
};

export default MobileProductsScreen;
