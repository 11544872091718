import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useCart, types as CartTypes } from '../../redux/useCart';
import { actions as errorsActions } from '../../redux/errors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import * as _ from 'lodash';
import {
  Button,
  Text,
  Image,
  Spinner,
  CartItemWeb,
  PaymentSummary,
  WrapAsGift,
  Screen,
  DeleteModal,
} from '../../components';

import styles from './webStyle';
import { I18n } from '../../locales';
import { colors, images } from '../../common';
import {
  isAvailable,
  navigateToProduct,
  navigateToManageAddresses,
  navigateToAddress,
  navigateToLogin,
} from '../../services';
import {
  errorMsgSelector,
  loadingSelector,
  showErrorMsg,
  showSuccessMsg,
} from '../../common/utils';

const Cart = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { cart, addCartError, user, loading, updateCartItemLoading, address } =
    useSelector((state) => {
      return {
        cart: state.cartState,
        user: state.user,
        addCartError: errorMsgSelector(state, [CartTypes.UPDATE_CART_ITEMS]),
        loading: loadingSelector(state, [CartTypes.GET_CART_INFO]),
        updateCartItemLoading: loadingSelector(state, [
          CartTypes.UPDATE_CART_ITEMS,
          CartTypes.REMOVE_CART_ITEM,
          CartTypes.DELETE_CART_GQL,
        ]),
        address: state.address,
      };
    });
  const {
    removeItemFromCart_GQL,
    updateCartItems_gql,
    addCouponCode_gql,
    removeCoupon,
    getCartInfo_GQL,
    clearCart_GQL,
    deleteCart_GQL,
  } = useCart();
  const [clearCartVisible, setClearCartVisible] = useState(false);
  const [showStockWarning, setShowStockWarning] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const hasDiscount = cart?.applied_coupons?.length;
  const discount = cart?.price?.discounts?.amount?.value;
  const subTotal = cart?.prices?.grand_total?.value;
  const addonsItems = cart?.addonsItems;
  const items = cart.items;
  const total = cart?.prices?.subtotal_excluding_tax?.value;
  const { isFromLogin = false } = JSON.parse(route?.params?.params);
  // call onProceedToCheckout after login
  useEffect(() => {
    if (isFromLogin) {
      onProceedToCheckout();
    }
  }, [isFromLogin]);

  useEffect(() => {
    _initialFetch();
    checkProductsStatus();
  }, []);

  useEffect(() => {
    checkProductsStatus();
  }, [cart]);

  const _initialFetch = async () => {
    getCartInfo_GQL();
    if (hasDiscount) {
      setCouponCode(cart.applied_coupons[0].code);
    }
  };

  const checkProductsStatus = () => {
    const isAllInStock = cart.items?.every((it) => isAvailable(it));
    //if any item is out of stock show warning box
    if (!isAllInStock) {
      setShowStockWarning(true);
      return false;
    } else {
      setShowStockWarning(false);
      return true;
    }
  };

  // show toast on add cart error.
  useEffect(() => {
    if (addCartError && navigation.isFocused()) {
      showErrorMsg(addCartError);
      setTimeout(() => dispatch(errorsActions.resetError()), 3000);
    }
  }, [addCartError]);

  const onProceedToCheckout = async () => {
    const allProductsInStock = checkProductsStatus(true);
    if (allProductsInStock) {
      // if (hasWrapRequest || disabledWrap) {
      if (user.loggedIn) {
        const userHasNoAddress = address?.addresses?.length == 0;
        if (userHasNoAddress) {
          navigateToAddress({ isCheckout: true });
        } else {
          navigateToManageAddresses({ isCheckout: true });
        }
      } else {
        // guest user.
        navigateToLogin({ fromCart: true });
      }
    }
  };

  const onAddToCart = async (item) => {
    setSelectedItemId(item.id);
    const { quantity, originalQuantity } = item;
    await updateCartItems_gql({
      cart_item_id: item.id,
      quantity: (originalQuantity || quantity) + 1,
    });
    setSelectedItemId(null);
  };

  const onSubtractFromCart = async (item) => {
    setSelectedItemId(item.id);
    const { quantity, originalQuantity, product } = item;
    const updateCount = isAvailable(item)
      ? (originalQuantity || quantity) - 1
      : product?.only_x_left_in_stock;
    await updateCartItems_gql({
      cart_item_id: item.id,
      quantity: updateCount,
    });
    setSelectedItemId(null);
  };

  const onDeleteItem = async (item) => {
    try {
      const resp = await removeItemFromCart_GQL(item.id);
      if (!resp?.payload?.id) {
        showErrorMsg(resp?.payload);
      } else {
      }
    } catch (error) {
      showErrorMsg();
      console.log('error', error);
    }
  };

  const onStockMsgDismiss = () => {
    setShowStockWarning(false);
  };

  const handleCouponAction = (code) => {
    hasDiscount ? onRemoveCoupon() : onAddCouponCode(code);
  };

  const onAddCouponCode = (code) => {
    setCouponLoading(true);
    addCouponCode_gql(code).then((res) => {
      if (res && !res.error) {
        // show success message
        showSuccessMsg(I18n.t('cart.couponCodeSuccess'));
        setCouponLoading(false);
      } else {
        setCouponLoading(false);
      }
    });
  };

  const onRemoveCoupon = () => {
    setCouponLoading(true);
    removeCoupon().then((res) => {
      if (res && !res.error) {
        showSuccessMsg(I18n.t('cart.deleteCouponCodeSuccess'));
        setCouponLoading(false);
        setCouponCode('');
      } else {
        showErrorMsg(I18n.t('errors.errorMessage'));
        setCouponLoading(false);
      }
    });
  };

  const cartItemPress = (item) => {
    const sku = item?.product?.sku;
    const name = item?.product?.name;
    navigateToProduct(sku, name);
  };

  const addWrapping = () => {};
  const paymentInfo = [
    { title: I18n.t('paymentSummary.subtotal'), value: subTotal },
    // { title: I18n.t('paymentSummary.discount'), value: discount },
    // { title: I18n.t('paymentSummary.creditPay'), value: 20 },
    // { title: I18n.t('paymentSummary.delivery'), value: 20 },
  ];

  const onClearCart = async () => {
    onCloseClearCart();
    if (user.loggedIn) {
      await deleteCart_GQL();
    }
    clearCart_GQL();
  };

  const onCloseClearCart = () => {
    setClearCartVisible(false);
  };

  if (loading) return <Spinner />;
  return (
    <Screen>
      {cart?.items?.length || addonsItems?.length ? (
        <View style={styles.mainContainer}>
          {showStockWarning && (
            <View style={styles.cartDangerMsg}>
              <View style={styles.warnContainer}>
                <Icon
                  name="info"
                  color={colors.brand}
                  style={styles.infoIcon}
                  size={24}
                />
                <Text size="small" style={styles.outOfStockText}>
                  {I18n.t('cart.outOfStockMsg')}
                </Text>
              </View>
              <Button
                onPress={onStockMsgDismiss}
                text={I18n.t('cart.dismiss')}
              />
            </View>
          )}
          <View style={styles.container}>
            <View>
              <Text style={styles.titleStyle}>{I18n.t('cart.title')}</Text>
              {items?.length > 0 &&
                items.map((item, index) => {
                  const isSelectedItem = selectedItemId == item.id;
                  return (
                    <CartItemWeb
                      {...{ item }}
                      key={index}
                      onPress={() => cartItemPress(item)}
                      onIncrement={() => onAddToCart(item)}
                      onDecrement={() => onSubtractFromCart(item)}
                      onDelete={() => onDeleteItem(item)}
                      loading={isSelectedItem && updateCartItemLoading}
                    />
                  );
                })}
              <TouchableOpacity
                onPress={() => setClearCartVisible(true)}
                style={styles.clearCart}
              >
                <Text style={styles.clearCartText}>
                  {I18n.t('cart.clearCart')}
                </Text>
              </TouchableOpacity>
              {clearCartVisible && (
                <DeleteModal
                  isVisible={clearCartVisible}
                  onClose={onCloseClearCart}
                  onPress={onClearCart}
                  title={I18n.t('cart.clearCart')}
                  subTitle={I18n.t('cart.deleteAllProducts')}
                />
              )}

              <WrapAsGift onPress={addWrapping} />
            </View>
            <View>
              <Text style={styles.titleStyle}>
                {I18n.t('cart.paymentSummary')}
              </Text>
              <PaymentSummary
                {...{
                  paymentInfo,
                  couponLoading,
                  couponCode,
                  setCouponCode,
                  total,
                  onProceedToCheckout,
                }}
                onApply={handleCouponAction}
                isCouponValid={hasDiscount}
              />
            </View>
          </View>
        </View>
      ) : (
        <>
          {/* Empty Cart Screen */}
          <View style={styles.middleView}>
            <Image source={images.emptyCart} style={styles.emptyCart} />
            <View>
              <Text style={{ textAlign: 'center' }}>
                {I18n.t('cart.emptyCart')}
              </Text>
              <Text style={styles.descText}>
                {I18n.t('cart.startShoppingItems')}
              </Text>
            </View>
            {/* <Button
              text={I18n.t('cart.startShopping')}
              onPress={() => navigateTo('categories')}
            /> */}
          </View>
        </>
      )}
    </Screen>
  );
};

export default Cart;
