import { colors } from '../../common';
import { StyleSheet, Platform } from 'react-native';
import { isMobile, sWidth } from '../../services';

const styles = StyleSheet.create({
  scrollContainer: {
    padding: 16,
    backgroundColor: colors.white,
    width: isMobile ? sWidth : sWidth - 200,
    margin: 'auto',
  },
  addText: {
    letterSpacing: -0.38,
    color: colors.greyishBrown,
  },
  headerText: {
    fontWeight: 'bold',
    letterSpacing: -0.38,
    color: colors.greyishBrown,
  },
  headerView: {
    marginTop: 20,
    paddingVertical: 6,
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: colors.borderColor,
  },
  content: {
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  itemView: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 13,
    paddingHorizontal: 16,
    // marginHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#f4f4f6',
  },
  searchContainer: {
    marginRight: 15,
    width: 343,
    borderRadius: 10,
    borderColor: 'transparent',
    backgroundColor: colors.brandGreyLight,
    ...Platform.select({
      android: {
        marginTop: 7,
        marginRight: 25,
      },
    }),
  },
  leftIcon: {
    ...Platform.select({
      ios: {
        marginRight: 5,
        width: 38,
      },
      android: {
        marginLeft: 1,
        width: 40,
      },
    }),
  },
  closeButton: {
    paddingHorizontal: 10,
    alignSelf: 'flex-end',
  },
  closeIcon: {
    width: 15,
    height: 15,
  },
});

export default styles;
