import { colors } from '../../common';
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
  countryContainer: {
    alignItems: 'center',
    // backgroundColor: colors.amber600
  },

  slider: {
    overflow: 'visible',
    flexGrow: 0,
  },

  flagContainer: {
    width: 100,
    height: 100,
    marginBottom: 5,
    marginHorizontal: 5,
    backgroundColor: colors.white,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },

  flag: {
    fontSize: 50,
  },

  countryNameContainer: {
    marginTop: 16,
  },
  countryName: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.black,
  },
});
