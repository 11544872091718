import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import styles from './styles';
import Text from '../Text';
import { colors } from '../../common';

export default class ProductTag extends React.PureComponent {
  static propTypes = {
    style: PropTypes.object,
    textStyle: PropTypes.object,
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
  };

  static defaultProps = {
    style: {},
    textStyle: {},
    title: 'Best seller',
    backgroundColor: colors.brand,
  };

  renderTitle = backgroundColor => {
    const { style, title, textStyle } = this.props;
    return (
      <View style={[styles.container, { backgroundColor }, style]}>
        <Text
          size="smaller"
          style={[styles.badge, textStyle]}
          numberOfLines={1}
        >
          {title}
        </Text>
      </View>
    );
  };

  render() {
    const { title } = this.props;
    switch (title) {
      case 'Best seller':
        return this.renderTitle(colors.tag);
      case 'New':
        return this.renderTitle(colors.purple);
      default:
        return this.renderTitle(colors.brand);
    }
  }
}
