import React, { useEffect, useState } from 'react';
import { Animated, Image } from 'react-native';

const AnimatedFastImage = Animated.createAnimatedComponent(Image);

const ImageLoader = ({ style, navigation, ...props }) => {
  // const [opacity, setOpacity] = useState(new Animated.Value(0));

  // useEffect(() => {
  //   onLoad();
  // }, []);

  // const onLoad = () => {
  //   Animated.spring(opacity, {
  //     toValue: 1,
  //     duration: 400,
  //     mass: 0.5,
  //     damping: 9,
  //     // delay: props.index < 6 ? (props.index + 1) * 100 : 0,
  //     useNativeDriver: true,
  //   }).start();
  // };

  return (
    <Image
      {...props}
      style={[
        {
          // opacity,
          // transform: [
          //   {
          //     scale: opacity.interpolate({
          //       inputRange: [0, 1],
          //       outputRange: [0, 1],
          //     }),
          //   },
          // ],
        },
        style,
      ]}
    />
  );
};

export default ImageLoader;
