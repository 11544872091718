import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import { colors } from '../../common';
import { Text, Badge, Image } from '../index';

export default class OrderProductItem extends PureComponent {
  render() {
    const { item, onPress, currency } = this.props;
    const { thumbnail_url, price, name, qty } = item;

    return (
      <View style={styles.container}>
        <View style={styles.topView}>
          <Image style={styles.image} source={{ uri: thumbnail_url }} />
          <View style={styles.textView}>
            <Text style={styles.price} numberOfLines={1} size="small">
              {price}{' '}
              <Text size="smaller" style={styles.currency}>
                {currency}
              </Text>
            </Text>
            <Text
              size="smaller"
              style={{ color: colors.greyishBrown }}
              numberOfLines={1}
            >
              {name}
            </Text>
          </View>
        </View>
        <Badge
          value={qty}
          containerStyle={styles.badge}
          textStyle={styles.badgeText}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 24,
    marginHorizontal: 24,
    borderBottomWidth: 1,
    borderBottomColor: colors.borderColor,
  },
  topView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
  },
  image: {
    width: 44,
    height: 44,
    borderRadius: 4.4,
    backgroundColor: colors.grey200,
  },
  price: {
    fontWeight: '500',
    color: colors.greyishBrown,
  },
  textView: {
    marginHorizontal: 16,
    flex: 1,
  },
  badge: {
    alignSelf: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.borderColor,
    width: 35,
    height: 44,
    backgroundColor: colors.white,
  },
  badgeText: {
    color: colors.greyishBrown,
    fontWeight: 'bold',
    padding: 0,
  },
  currency: {
    color: colors.greyishBrown,
    fontWeight: '500',
  },
});
