import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';

import { colors } from '../../common';

export const Button = (props) => {
  const {
    containerStyle,
    disabled,
    textStyle,
    text,
    isAnimated = false,
  } = props;

  return (
    <TouchableOpacity
      activeOpacity={disabled ? 1 : 0.85}
      children={
        <Text size="small" style={[styles.text, textStyle]}>
          {text}
        </Text>
      }
      {...props}
      style={[styles.container, containerStyle, disabled && styles.disabled]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    height: 48,
    alignSelf: 'stretch',
    borderRadius: 4,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
    backgroundColor: colors.buttons,
  },
  text: {
    paddingHorizontal: 25,
    fontWeight: '600',
    color: colors.white,
    textAlign: 'center',
  },
  disabled: {
    backgroundColor: colors.grey500,
    borderColor: colors.grey500,
  },
});
