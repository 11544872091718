import { StyleSheet } from 'react-native';
import colors from '../../common/colors';

export default StyleSheet.create({
  container: {
    marginBottom: 8,
    backgroundColor: colors.white,
  },
  cardStyle: {
    marginHorizontal: 4,
  },
  cardImageStyle: {
    width: 135,
    height: 128,
  },
});
