import React from 'react';
import { View, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { colors, config } from '../../common';
import { DiscountBadge, Image, Text } from '..';
import { getPriceFormat } from '../../common/utils';

export const RelatedProducts = (props) => {
  const { title, items, navigation } = props;
  const onPressItem = (item) => {
    navigation.push('ProductDetails', { sku: item.sku });
  };

  return (
    <View>
      <View style={styles.headContainer}>
        <Text style={{ fontWeight: 'bold' }}>{title}</Text>
      </View>

      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {items.map((item) => {
          const { id, name, small_image, price_range } = item;
          const {
            regular_price = {},
            discount = {},
            final_price = {},
          } = price_range?.maximum_price;
          const { value: price = 0 } = regular_price;
          const discount_percentage = discount?.percent_off;
          const { value: product_final_price = 0 } = final_price;
          const special_price =
            discount_percentage !== 0 ? product_final_price : null;
          return (
            <TouchableOpacity
              key={id}
              activeOpacity={0.9}
              onPress={() => onPressItem(item)}
            >
              <View style={styles.card}>
                <Image
                  source={{ uri: small_image.url }}
                  placeholder={config.placeholderImage}
                  style={styles.cardImage}
                  resizeMode="contain"
                />
                <View style={styles.cardBody}>
                  {special_price ? (
                    <>
                      <Text size="small" style={styles.price}>
                        {getPriceFormat(price)}
                      </Text>
                      <View style={styles.discountView}>
                        <Text size="small" style={styles.discountText}>
                          {getPriceFormat(special_price)}
                        </Text>

                        {discount_percentage ? (
                          <DiscountBadge
                            discountPercentage={discount_percentage}
                          />
                        ) : null}
                      </View>
                    </>
                  ) : (
                    <Text size="small" style={styles.mainPrice}>
                      {getPriceFormat(price)}
                    </Text>
                  )}
                  <Text size="small" numberOfLines={2} style={styles.cardTitle}>
                    {name}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  headContainer: {
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card: {
    width: 170,
    paddingLeft: 15,
    paddingRight: 15,
  },
  cardImage: {
    maxWidth: '100%',
    height: 120,
  },
  cardBody: {
    padding: 5,
  },
  cardTitle: {
    marginTop: 4,
  },
  price: {
    textDecorationLine: 'line-through',
    lineHeight: 16,
    letterSpacing: -0.47,
    fontWeight: '500',
    color: colors.warmGrey,
  },
  mainPrice: {
    marginBottom: 8,
    lineHeight: 20,
    letterSpacing: -0.47,
    color: colors.black,
    fontWeight: 'bold',
  },
  discountView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  discountText: {
    letterSpacing: -0.47,
    fontWeight: 'bold',
    color: colors.brand,
  },
});
