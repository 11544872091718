import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as orderActions, types } from '../../redux/order';
import { actions as userActions } from '../../redux/user';
import {
  View,
  TouchableOpacity,
  ScrollView,
  RefreshControl,
  Linking,
} from 'react-native';
import * as _ from 'lodash';
import {
  Text,
  HeaderSeeAll,
  OrdersList,
  Image,
  Screen,
  SettingRow,
} from '../../components';
import { colors, config, images } from '../../common';
import { I18n } from '../../locales';
import styles from './styles';
import { loadingSelector } from '../../common/utils';
import {
  isMobile,
  navigateTo,
  navigateToManageAddresses,
  navigateToOrderDetails,
} from '../../services';
import i18n from '../../locales/i18n';

const UserProfile = () => {
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);
  const { order, user, loading } = useSelector((state) => {
    return {
      order: state.order,
      user: state.user,
      // intercomUnreadConversation: state.home.count,
      loading: loadingSelector(state, [types.GET_ORDERS]),
    };
  });
  const sendWhatsApp = (msg = '') => {
    const mobile = config.whatsapp;
    if (mobile) {
      const url = `https://wa.me/${mobile}/?text=${msg}`;
      // const url = 'whatsapp://send?text=' + msg + '&phone=' + mobile;
      Linking.openURL(url)
        .then((data) => {
          console.log('WhatsApp Opened');
          console.log('data', data);
        })
        .catch(() => {
          alert('Make sure WhatsApp installed on your device');
        });
    } else {
      alert('Please insert mobile no');
    }
  };

  useEffect(() => {
    dispatch(orderActions.getOrders());
  }, []);

  const navigateToWishlist = () => {
    navigateTo('/wishlist');
  };

  const navigateToOrderHistory = () => {
    navigateTo('/orderHistory');
  };
  const onSupportPress = () => {
    sendWhatsApp();
  };

  const onRefresh = () => {
    getOrders().then(() => {
      setRefreshing(false);
    });
  };
  const logout = () => {
    dispatch(userActions.userLogout());
  };

  return (
    <Screen isLoading={loading}>
      <ScrollView
        refreshControl={
          <RefreshControl
            tintColor={colors.brand}
            colors={[colors.brand]}
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={styles.loginSignupContainer}>
            <Image
              source={images.userImage}
              resizeMode="contain"
              style={styles.imageProfile}
            />
            <View>
              <Text
                size="small"
                style={{ fontWeight: '500', color: colors.textGrey }}
              >
                {user.info.firstname} {user.info.lastname}
              </Text>
              <Text size="small" style={{ color: colors.textGrey }}>
                {user.info.email}
              </Text>
            </View>
          </View>
          <TouchableOpacity style={styles.logoutButton} onPress={logout}>
            <Text style={styles.logoutText}>{i18n.t('profile.logout')}</Text>
            <Image
              source={images.exit}
              style={styles.logoutImage}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        {!isMobile && (
          <>
            <SettingRow
              imageUrl={images.account}
              title={I18n.t('settings.accountSettings')}
              onPress={() => navigateTo('accountSettings')}
              containerStyle={{ borderBottomWidth: 0 }}
              hideArrow
            />
            <SettingRow
              imageUrl={images.delivery}
              title={I18n.t('settings.manageShippingAddresses')}
              onPress={() => navigateToManageAddresses()}
              containerStyle={{ borderBottomWidth: 0 }}
              hideArrow
            />
          </>
        )}
        <View style={styles.btnGroup}>
          <TouchableOpacity
            activeOpacity={0.85}
            onPress={navigateToWishlist}
            style={styles.cardBtn}
          >
            <Image
              source={images.heart}
              resizeMode="contain"
              style={styles.intercomImage}
            />
            <View style={styles.descView}>
              <Text size="small" style={styles.titleText}>
                {I18n.t('profile.wishlist')}
              </Text>
              <Text size="smaller" style={styles.descText}>
                {I18n.t('profile.FavouriteProduct')}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.85}
            style={styles.cardBtn}
            onPress={onSupportPress}
          >
            <View>
              <Image
                source={images.iconSupport}
                resizeMode="cover"
                style={styles.supportImage}
              />
              {/* {intercomUnreadConversation ? (
                <View style={styles.quantityOuter}>
                  <Text style={styles.quantityNumber}>
                    {intercomUnreadConversation}
                  </Text>
                </View>
              ) : null} */}
            </View>
            <View style={styles.descView}>
              <Text size="small" style={styles.titleText}>
                {I18n.t('profile.intercom')}
              </Text>
              <Text size="smaller" style={styles.descText}>
                {I18n.t('profile.supportModal.callSupportBtn')}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View>
          {order?.history ? (
            <HeaderSeeAll
              style={styles.seeAllStyle}
              title={I18n.t('profile.orderHistory')}
              onPress={navigateToOrderHistory}
              hideSeeAll={loading || !order.history.length}
            />
          ) : null}

          <OrdersList
            orders={order?.history?.slice(0, 3)}
            onItemPress={({ increment_id, id }) =>
              navigateToOrderDetails({ id, increment_id })
            }
          />
        </View>
      </ScrollView>
    </Screen>
  );
};

export default UserProfile;
