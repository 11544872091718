import { colors } from '../../common';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  dropDownContainer: {
    backgroundColor: colors.white,
    paddingTop: 15,
    paddingStart: 27,
    shadowColor: colors.shadowDarkColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 25,
    shadowOpacity: 1,
  },
  dropDownRow: {
    flexDirection: 'row',
  },
  subCategoryItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subCategoryImageContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: 'rgba(23, 10, 60, 0.05)',
  },
  subCategoryImage: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  subCategoryTextContainer: {
    height: 60,
    paddingHorizontal: 16,
    justifyContent: 'space-around',
  },
  subCategoryName: {
    fontSize: 16,
    fontWeight: '600',
    color: '#170a3c',
  },
  subCategorySubText: {
    fontSize: 12,
    color: '#170a3c',
    opacity: 0.4,
  },
  hoverItem: {
    height: 71,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
