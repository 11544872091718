import { ApolloClient, InMemoryCache } from '@apollo/client';
import { SERVER_URL, GATEWAY_URL } from '../common/constants';
import { store } from '../common/store';
import { getCurrentStore } from './getCurrentStore';

const MagentoGraphQL = `${SERVER_URL}/graphql`;

export const client = () => {
  const {
    user: { token },
  } = store.getState((state) => state);
  const Store = getCurrentStore();
  let headers = { Store };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return new ApolloClient({
    uri: MagentoGraphQL,
    cache: new InMemoryCache(),
    headers,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

export const gateway_client = () => {
  const Store = getCurrentStore();
  return new ApolloClient({
    uri: GATEWAY_URL,
    cache: new InMemoryCache(),
    headers: { Store },
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
