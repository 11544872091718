import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 15,
  },
  thumbnail: {
    width: 30,
    height: 30,
  },
  productBrand: {
    color: colors.brand,
    // lineHeight: 20,
    letterSpacing: -0.47,
  },
  title: {
    color: colors.darkIndigo,
    fontWeight: 'bold',
    marginVertical: 10,
  },

  headerRightView: {
    flexDirection: 'row',
  },
  bannerImage: {
    maxWidth: '100%',
    minHeight: 360,
  },

  shareIconView: {
    marginRight: 40,
    padding: 10,
    position: 'absolute',
    zIndex: 101,
    right: 0,
  },

  closeIcon: {
    width: 60,
    marginRight: 20,
    paddingVertical: 10,
    paddingLeft: 10,
    height: 60,
  },
  imageVariation: {
    maxWidth: '100%',
    height: 360,
  },
  currency: {
    color: colors.lipstick,
    fontSize: 32,
    fontWeight: 'bold',
  },
  banner: {
    // height: 360,
    backgroundColor: colors.white,
    margin: 0,
    padding: 0,
  },
  discount: {
    textDecorationLine: 'line-through',
    color: colors.warmGrey,
    fontWeight: 'bold',
    marginTop: 10,
    fontSize: 20,
  },
  productInfoRow: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingVertical: 10,
    paddingHorizontal: 8,
    marginStart: -8,
  },
  infoTitle: {
    width: 234,
    color: 'rgba(23, 10, 60, 0.3)',
    fontWeight: 'bold',
  },
  infoValue: {
    fontWeight: '500',
    color: '#170a3c',
  },
  productInfoRowGrey: {
    backgroundColor: colors.brandGreyLight,
  },
  bannerVideo: {
    // don't set backgroundColor on Video on android > 24. It's not allowed on TextureView.
    ...Platform.select({
      ios: {
        backgroundColor: colors.brand,
      },
    }),
  },
  deliveryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    shadowColor: colors.paleGrey,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowRadius: 0,
    shadowOpacity: 1,
    // marginVertical: 10
  },

  borderRow: {
    borderBottomWidth: 1,
    borderColor: colors.white,
  },
  innerDelivery: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  deliveryIcon: {
    width: 24,
    height: 24,
  },
  ctaContainer: {
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderTopWidth: 2,
    borderColor: colors.brandGrey,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toast: {
    backgroundColor: colors.grey600,
  },
  doneBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  toastView: {
    backgroundColor: colors.brandGreen,
    padding: 20,
    alignItems: 'center',
  },
  containerView: {
    width: 816,
    marginStart: 104,
    marginVertical: 71,
  },

  customizedWritingInput: {
    flex: 1,
    backgroundColor: colors.button1,
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 0,
    shadowOpacity: 1,
  },
  addBtn: {
    flex: 1,
    height: 52,
    borderRadius: 10,
    backgroundColor: colors.selection,
    shadowColor: colors.darkAquamarine50,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
  },
  deliveryData: {
    flex: 1,
  },
  deliveryTitle: {
    marginLeft: 15,
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  sideCard: {
    width: 340,
    height: 472,
    borderRadius: 10,
    backgroundColor: colors.white,
    paddingTop: 28,
    paddingBottom: 46,
    paddingStart: 31,
    paddingEnd: 10,
  },
  favoriteButton: {
    width: 52,
    height: 52,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.borderColor,
    alignItems: 'center',
    justifyContent: 'center',
    marginStart: 9,
    alignSelf: 'center',
  },
  favoriteIcon: {
    width: 20,
    height: 18,
  },
  addBtnTitle: {
    paddingHorizontal: 25,
    fontWeight: '600',
    color: colors.white,
    textAlign: 'center',
  },
});

export default styles;
