import React from 'react';
import { StyleSheet } from 'react-native';
import { Modal } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { colors } from '../../common';
import { sHeight, sWidth } from '../../services';
// The Main Modal Container of the App
export const ModalContainer = ({
  style,
  children,
  setModalVisible,
  ...props
}) => {
  return (
    <Modal {...props} transparent style={[styles.container, style]}>
      <TouchableOpacity
        style={styles.closeButton}
        activeOpacity={1}
        onPress={() => setModalVisible(false)}
      />
      {children}
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    width: sWidth,
    height: sHeight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    width: sWidth,
    height: sHeight,
    position: 'absolute',
    zIndex: -1,
    backgroundColor: colors.shadowColor700,
  },
});
