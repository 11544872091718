import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  scrollStyle: {
    marginVertical: 24,
    paddingHorizontal: 16,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
  btn: {
    marginVertical: 10,
    marginHorizontal: 16,
  },
  input: {
    paddingHorizontal: 10,
    outline: 'none',
    backgroundColor: colors.white,
    height: 40,
    borderRadius: 8,
  },
  inputWeb: {
    width: 400,
  },
  inputsView: {
    marginHorizontal: 10,
    padding: 16,
    backgroundColor: colors.grey300,
    borderRadius: 8,
  },
  inputsViewWeb: {
    alignSelf: 'center',
  },
  errorMsg: {
    marginVertical: 5,
    color: colors.buttons,
  },
  label: {
    color: colors.grey600,
    marginVertical: 8,
    fontWeight: '600',
  },
});
