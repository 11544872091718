import React from 'react';
import { connect, useSelector } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import Badge from '../Badge';
import styles from './styles';
import { colors, images } from '../../common';
import { Image } from '..';
import { types as cartTypes } from '../../redux/useCart';
import { loadingSelector } from '../../common/utils';
import { navigateToCart } from '../../services';

const FavoriteButton = connect((state) => ({ cart: state.cartState }))(
  ({ cart, hitSlop = { top: 10, bottom: 10, left: 10, right: 10 } }) => {
    const { total_quantity } = cart;
    const loading = useSelector((state) =>
      loadingSelector(state, [cartTypes.GET_CART_INFO]),
    );
    return (
      <TouchableOpacity
        hitSlop={hitSlop}
        onPress={() => navigateToCart()}
        style={styles.container}
        disabled={loading}
      >
        <Image
          source={images.favoriteIcon}
          style={styles.imageStyle}
          resizeMode="contain"
          tintColor={loading ? colors.blueGrey100 : colors.cartIconColor}
        />
        {total_quantity > 0 && (
          <Badge
            value={total_quantity}
            textStyle={styles.badgeTextStyle}
            rounded
            containerStyle={[
              styles.badgeContainer,
              loading && { backgroundColor: colors.blueGrey100 },
            ]}
            size={11}
          />
        )}
      </TouchableOpacity>
    );
  },
);

export default FavoriteButton;
