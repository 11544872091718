import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { isMobile } from '../../services';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.white,
  },
  inputsRow: {
    flexDirection: isMobile ? 'row' : 'column',
    justifyContent: 'space-between',
  },
  priceContainer: {
    marginHorizontal: 15,
  },
  priceInputContainer: {
    borderRadius: 40,
    flex: 1,
    borderWidth: 1,
    borderColor: colors.grey300,
    paddingVertical: 6,
    marginHorizontal: 5,
    marginVertical: 7,
  },
  genderContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  headerRight: {
    justifyContent: 'center',
    alignItems: 'center',
    width: wp('20%'),
    height: hp('10%'),
    flexDirection: 'row',
    paddingLeft: 20,
  },
  sortItemStyle: {
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
  },
  inputLableContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  inputContainer: {
    borderColor: 'transparent',
    height: 33,
  },
  input: {
    overflow: 'hidden',
  },
  sortContainer: {
    marginHorizontal: 15,
    marginVertical: 20,
  },
  selectionButtonStyle: {
    marginBottom: 8,
    marginEnd: 10,
  },
  attributesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
