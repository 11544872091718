import React from 'react';
import { View, StyleSheet } from 'react-native';
import { colors } from '../../common';
import { scale, vScale } from '../../services';
import { Text, Image } from '..';
import { getPriceFormat } from '../../common/utils';

export const PaymentProductItem = (props) => {
  const { item } = props;
  const { product, prices, quantity } = item;
  return (
    <View style={styles.itemContainer}>
      <Image
        source={{
          uri: product?.small_image?.url,
        }}
        style={styles.itemImage}
        resizeMode="contain"
      />
      <View style={styles.descView}>
        <View style={styles.priceView}>
          <Text style={styles.blackText} size="small">
            {getPriceFormat(prices?.price?.value)}
          </Text>
          <Text style={styles.blackText} size="small">
            x{quantity}
          </Text>
        </View>
        <View>
          <Text size="smaller" numberOfLines={1} style={styles.desText}>
            {product?.name}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: scale(16),
  },
  itemImage: {
    width: scale(64),
    height: scale(64),
    borderRadius: vScale(8),
  },
  descView: {
    justifyContent: 'space-around',
    flex: 1,
    marginLeft: scale(8),
    alignSelf: 'stretch',
  },
  priceView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blackText: {
    color: colors.black,
    letterSpacing: -0.38,
    lineHeight: 24,
    fontWeight: 'bold',
  },
  desText: {
    lineHeight: 16,
  },
});
