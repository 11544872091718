import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const MERGE_CARTS = gql`
  mutation($source_cart_id: String!, $destination_cart_id: String!) {
    mergeCarts(
      source_cart_id: $source_cart_id
      destination_cart_id: $destination_cart_id
    ) {
      ...CartFragment
    }
  }
  ${CartFragment}
`;
