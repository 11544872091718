import '../../bootstrap.css';
import './styles.css';
import { Hoverable } from 'react-native-web-hover';
import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Image, Text } from '../../components';
import { useSelector } from 'react-redux';
import { isMobile } from '../../services';
import styles from './styles.js';
import { I18n } from '../../locales';

export const NavBar = ({ navigateToCategoryDetails }) => {
  const { categories } = useSelector((state) => {
    return {
      categories: state.categories?.main,
    };
  });
  const [selectedCat, setSelectedCat] = useState({});
  return (
    <>
      {!isMobile && (
        <nav
          id="container"
          class="navbar navbar-expand-lg navbar-dark bg-primary"
        >
          <div class="container-fluid">
            <div class="collapse navbar-collapse" id="main_nav">
              <ul class="navbar-nav">
                {categories?.map((item) => {
                  const { name, children } = item;
                  const isDropdown = children?.length > 0;
                  const styleClass = isDropdown
                    ? 'nav-item dropdown'
                    : 'nav-item';
                  return (
                    <li id="list-item" class={styleClass}>
                      <Hoverable style={styles.hoverItem}>
                        {({ hovered }) => {
                          if (hovered && selectedCat.id) {
                            setSelectedCat({});
                          }
                          return (
                            <TouchableOpacity
                              onPress={() =>
                                navigateToCategoryDetails(
                                  item.id,
                                  null,
                                  name,
                                  '',
                                )
                              }
                            >
                              <p id="list-text" data-bs-toggle="dropdown">
                                {name}
                              </p>
                            </TouchableOpacity>
                          );
                        }}
                      </Hoverable>

                      {isDropdown && (
                        <ul class="dropdown-menu" id="dropdown-menu-custom">
                          <View style={styles.dropDownContainer}>
                            <View style={styles.dropDownRow}>
                              <View
                                style={{ marginEnd: 27, paddingBottom: 20 }}
                              >
                                {children?.map((item) => (
                                  <li>
                                    <li
                                      class="dropdown-item"
                                      id="dropdown-item-custom"
                                    >
                                      <Hoverable>
                                        {({ hovered }) => {
                                          if (hovered) {
                                            setSelectedCat(item);
                                          }
                                          return (
                                            <TouchableOpacity
                                              onPress={() =>
                                                navigateToCategoryDetails(
                                                  item.id,
                                                  null,
                                                  item.name,
                                                  item.component_type,
                                                )
                                              }
                                            >
                                              <p class="dropdown-item-text-custom">
                                                {item.name}
                                              </p>
                                            </TouchableOpacity>
                                          );
                                        }}
                                      </Hoverable>
                                    </li>
                                  </li>
                                ))}
                              </View>
                              <View
                                style={
                                  {
                                    // backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  }
                                }
                              >
                                {selectedCat?.children?.map((i) => (
                                  <li
                                    class="dropdown-item"
                                    id="sub-category-item"
                                  >
                                    <TouchableOpacity
                                      onPress={() =>
                                        navigateToCategoryDetails(
                                          i.id,
                                          null,
                                          i.name,
                                          i.component_type,
                                          selectedCat.id,
                                        )
                                      }
                                      style={styles.subCategoryItem}
                                    >
                                      <View
                                        style={styles.subCategoryImageContainer}
                                      >
                                        <Image
                                          style={styles.subCategoryImage}
                                          source={{ uri: i.image }}
                                        />
                                      </View>
                                      <View
                                        style={styles.subCategoryTextContainer}
                                      >
                                        <Text
                                          numberOfLines={1}
                                          style={styles.subCategoryName}
                                        >
                                          {i.name}
                                        </Text>
                                        <Text style={styles.subCategorySubText}>
                                          {i.product_count}{' '}
                                          {I18n.t('navBar.products')}
                                        </Text>
                                        <Text style={styles.subCategorySubText}>
                                          {I18n.t('navBar.from')}{' '}
                                          {i.product_count}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                  </li>
                                ))}
                              </View>
                            </View>
                          </View>
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};
