import 'react-native-gesture-handler';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './src/common';
import { persistor } from './src/common/store';
import { client } from './src/services/apollo';
import Navigation from './src/navigation';
import { Toaster } from 'react-hot-toast';
export default function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <PersistGate persistor={persistor} loading={null}>
          <Navigation />
          <Toaster />
        </PersistGate>
      </ApolloProvider>
    </Provider>
  );
}
