import React, { useEffect, useLayoutEffect } from 'react';
import { View, ScrollView, I18nManager } from 'react-native';
import { Text, Image, Screen, Button, TableCard } from '../../components';
import { images } from '../../common';
import { I18n } from '../../locales';
import styles from './styles';
import { getPaymentTimeFormatFromTimeStamp } from '../../services/date';
// import { vibrateSuccess } from '../../services/nativeFeatures';
import TAP_ERRORS from '../../common/tapErrors';
import * as _ from 'lodash';
import { navigateTo, navigateToPayment } from '../../services';

const FailedOrder = ({ navigation, route }) => {
  const { OrderNumber = '', transactionData = {} } = route.params;

  const {
    transaction,
    failedResponseCode,
    paymentid,
    tranid,
  } = transactionData;

  // useLayoutEffect(() => {
  //   navigation.setOptions({
  //     headerTitle: I18n.t('failedOrder.title'),
  //     headerLeft: () => null,
  //     gesturesEnabled: false,
  //   });
  // }, [navigation]);

  // useEffect(() => {
  //   vibrateSuccess();
  // }, []);

  const getTheErrorMessage = () => {
    //check if the error code included in the error list
    const errorMessageObject =
      failedResponseCode &&
      _.find(TAP_ERRORS, ['code', parseInt(failedResponseCode)]);

    // console.log('errorMessageObject  ', errorMessageObject);
    // if included return the ar or en message else include the default message in translations file
    if (errorMessageObject) {
      const { enMessage, arMessage } = errorMessageObject;
      const displayMessage = I18nManager.isRTL ? arMessage : enMessage;
      return displayMessage;
    } else return I18n.t('failedOrder.body');
  };

  const navigateToHomePayment = () => {
    navigateToPayment();
  };

  return (
    <Screen>
      <View style={styles.content}>
        <ScrollView>
          <View style={styles.headerContent}>
            <Image
              source={images.FAILED_PAYMENT}
              style={styles.image}
              resizeMode="contain"
            />

            <View style={styles.headerTextContent}>
              <Text size="larger" style={styles.headerText}>
                {I18n.t('failedOrder.header')}
              </Text>

              <View style={styles.bodyTextContainer}>
                <Text style={styles.bodyText}>{getTheErrorMessage()}</Text>
              </View>
            </View>
          </View>

          <View style={styles.orderInfoTableContainer}>
            <Text>{I18n.t('failedOrder.tableTitle')}</Text>
            <View style={styles.orderInfoTable}>
              {Boolean(OrderNumber) && (
                <TableCard
                  title={I18n.t('failedOrder.orderNumberTitle')}
                  text={`#${OrderNumber}`}
                />
              )}
              {Boolean(transaction?.created) && (
                <TableCard
                  title={I18n.t('failedOrder.dateTitle')}
                  text={getPaymentTimeFormatFromTimeStamp(
                    parseInt(transaction.created),
                  )}
                />
              )}
              <TableCard
                title={I18n.t('failedOrder.errorCodeTitle')}
                text={failedResponseCode}
              />
              <TableCard
                title={I18n.t('failedOrder.paymentId')}
                text={paymentid}
              />
              <TableCard
                title={I18n.t('failedOrder.transactionId')}
                text={tranid}
              />
            </View>
          </View>
          <Button
            text={I18n.t('failedOrder.btnTitle')}
            containerStyle={styles.button}
            onPress={navigateToHomePayment}
          />
          <Button
            text={I18n.t('failedOrder.goToHomepage')}
            containerStyle={styles.button}
            onPress={() => navigateTo()}
          />
        </ScrollView>
      </View>
    </Screen>
  );
};

export default FailedOrder;
