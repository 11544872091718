import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import {
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import * as _ from 'lodash';
import HTML from 'react-native-render-html';
import { colors, config, images as IMAGES } from '../../common';
import {
  Text,
  PreviewSlider,
  Badge,
  RelatedProducts,
  DiscountBadge,
  SelectProductOptions,
  ProductVariations,
  ProductVariationSummary,
  ShimmerProducts,
  CustomInput,
  EmptyScreen,
  Image,
  Screen,
  SelectionModal,
  Button,
  ZoomImage,
  HeaderButton,
  CartIcon,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import styles from './mobileStyles';
import { useCart, types as cartTypes } from '../../redux/useCart';
import { useProducts } from '../../redux/useProducts';
import { useDispatch, useSelector } from 'react-redux';
import { actions as errorsActions } from '../../redux/errors';
import {
  getPriceFormat,
  errorMsgSelector,
  loadingSelector,
  showErrorMsg,
  showSuccessMsg,
} from '../../common/utils';
import { actions as wishlistActions } from '../../redux/wishlist';
import { isRTL, navigateToProducts } from '../../services';
import Icon from 'react-native-vector-icons/Ionicons';

const ProductDetails = ({ navigation, route }) => {
  let _isMounted = false;
  const { sku: skuParam, id: idParam, category } = route?.params || {};
  const dispatch = useDispatch();
  const { addToCartLoading, cartError, cart, attributes, user } = useSelector(
    (state) => {
      return {
        user: state.user,
        cart: state.cartState,
        attributes: state.attributes,
        addToCartLoading: loadingSelector(state, [
          cartTypes.ADD_CART_ITEM,
          cartTypes.CREATE_EMPTY_CART,
        ]),
        cartError: errorMsgSelector(state, [cartTypes.ADD_CART_ITEM]),
      };
    },
  );
  const { newAddToCart_gql: newAddToCart, createEmptyCart_GQL } = useCart();

  const [screenLoading, setScreenLoading] = useState(true);
  const [productError, setProductError] = useState(false);
  const [showProductOptions, setShowProductOptions] = useState(false);
  const [customizedWriting, setCustomizedWriting] = useState('');
  const [isChildProduct, setIsChildProduct] = useState(false);
  const [product, setProduct] = useState({});
  const [mainProduct, setMainProduct] = useState({});
  const [showZoomModal, setShowZoomModal] = useState(false);
  const [imageZoomIndex, setImageZoomIndex] = useState(0);
  const [isFavorited, setIsFavorited] = useState();
  const { addToWishlist, removeFromWishlist } = wishlistActions;
  const { getProductBySku } = useProducts();
  const isCustomProduct =
    mainProduct.attribute_set_name == 't-shirt' &&
    mainProduct.__typename === 'ConfigurableProduct' &&
    Boolean(product?.optionId);

  const sku = skuParam || idParam;
  // const childSku = navigation.getParam('childSku');
  // let component_type = navigation.getParam('component_type');

  useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerTitle: () => (
        <Image
          source={{ uri: product?.small_image?.url }}
          resizeMode="cover"
          style={styles.thumbnail}
        />
      ),
      //always keep title in center event in Android
      headerTitleAlign: 'center',
      headerRight: () => (
        <HeaderButton
          elements={[
            {
              element: isFavorited !== undefined && (
                <TouchableOpacity
                  onPress={() => onFavorite()}
                  style={{ paddingHorizontal: 5 }}
                >
                  <Icon
                    name={isFavorited ? 'heart' : 'heart-outline'}
                    size={24}
                    color={colors.buttons}
                  />
                </TouchableOpacity>
              ),
            },
            { element: <CartIcon navigation={navigation} /> },
          ]}
        />
      ),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation, product, isFavorited]);

  useEffect(() => {
    _initialFetch();
    favouriteChecker();
    return () => (_isMounted = false);
  }, [route]);

  const onFavorite = () => {
    // NOTE: No need to check if item is already in wishlist as adding multiple time does the same.
    if (user.loggedIn) {
      if (!isFavorited) {
        setIsFavorited(true);
        dispatch(addToWishlist(mainProduct.id || id));
      } else {
        setIsFavorited(false);
        dispatch(removeFromWishlist(mainProduct.id || id));
      }
    }
  };

  const favouriteChecker = async () => {
    if (!user.loggedIn) return;
    const wishlist = await dispatch(wishlistActions.getWishlistItems());
    _isMounted = true;
    const favourite = wishlist?.payload?.find(
      (ele) => ele.sku == sku || ele.id == id,
    );
    if (favourite) {
      if (_isMounted) {
        setIsFavorited(true);
      }
    } else {
      setIsFavorited(false);
    }
  };

  const checkConfigurableProduct = (mainProduct) => {
    let selectedProduct;
    /*responseArray array need to be fixed response should be in an single object*/
    if (mainProduct) {
      const { __typename, variants } = mainProduct;
      const isConfigurable = __typename === 'ConfigurableProduct';
      if (isConfigurable && variants && variants?.length) {
        //if child Sku product passed from cart select it else select the lower price child
        selectedProduct = variants[0]?.product;
      }
      const product = selectedProduct ? selectedProduct : mainProduct;
      const isChildProduct = selectedProduct ? true : false;
      setMainProduct(mainProduct);
      const tShirtOptionId = _.find(
        product.options,
        (e) => e.title == 'tShirtId',
      )?.option_id;

      setProduct({
        ...product,
        optionId: tShirtOptionId || null,
      });
      setIsChildProduct(isChildProduct);
      const thumbnail = product?.small_image?.url;
      //track view Item from category
      if (category) category = { category: category?.name };
    }
  };
  const resetState = () => {
    setMainProduct({});
    setProduct({});
    setIsChildProduct(false);
    setProductError(false);
    setScreenLoading(true);
    setShowZoomModal(false);
    setImageZoomIndex(0);
    setCustomizedWriting('');
  };

  const _initialFetch = async () => {
    resetState();
    if (Boolean(!cart.id)) {
      createEmptyCart_GQL();
    }
    try {
      const resp = await getProductBySku(sku);
      if (resp) {
        checkConfigurableProduct(resp);
      } else {
        setProductError(true);
      }
    } catch (e) {
      setProductError(true);
    }
    setScreenLoading(false);
  };

  useEffect(() => {
    if (cartError && navigation.isFocused()) {
      showErrorMsg(cartError, () => {
        dispatch(errorsActions.resetError());
      });
    }
  }, [cartError]);

  const addToCart = async () => {
    const { sku, id } = product;
    try {
      const resp = await newAddToCart({ entityId: id, sku });
      if (resp.type === cartTypes.ADD_CART_ITEM.SUCCESS) {
        showSuccessMsg(I18n.t('productDetails.itemSuccess'));
      }
    } catch (error) {
      console.warn('error', error);
    }
  };

  const back = () => {
    navigation.pop();
  };

  const toggleProductOptions = () => {
    setShowProductOptions(!showProductOptions);
  };

  const onProductOptionSelect = (product) => {
    const tShirtOptionId = _.find(
      product.options,
      (e) => e.title == 'tShirtId',
    )?.option_id;
    setProduct({
      ...product,
      optionId: tShirtOptionId || null,
    });
  };

  const getAddCartBtnText = () => {
    const { sku, only_x_left_in_stock: qty } = product;
    const isInStock = qty > 0;
    const cartItem = cart?.items?.find((it) => it.product.sku === sku);
    let returnedText;
    if (!isInStock) returnedText = I18n.t('products.outOfStock');
    else if (isCustomProduct) returnedText = I18n.t('btns.customize');
    else if (cartItem)
      returnedText = cartItem.quantity + ' ' + I18n.t('productDetails.inCart');
    else returnedText = I18n.t('btns.addToCart');
    return returnedText;
  };

  const showZoomingModal = (imageZoomIndex = 0) => {
    setShowZoomModal(true);
    setImageZoomIndex(imageZoomIndex);
  };

  // console.log('##props ', props);
  // console.log('##state ', state);
  // console.log('#product ', product);

  const getGender = () => {
    const obj = _.find(attributes.gender, (e) => e.value == gender);
    if (obj?.label) return obj.label;
    return false;
  };

  if (screenLoading) return <ShimmerProducts loading />;
  if (productError)
    return (
      <EmptyScreen
        errorMessage={{
          title: I18n.t('errors.productNotExist'),
        }}
      />
    );

  const {
    id,
    name,
    age,
    weight,
    video,
    media_gallery = [],
    delivery_message,
    ts_dimensions_width: width,
    ts_dimensions_height: height,
    ts_dimensions_length: length,
    gender,
    brand,
    brand_name,
    price_range,
    small_image,
    only_x_left_in_stock: qty,
    // isSupportCustomizedWriting - shipping_message - model ---?
    isSupportCustomizedWriting,
    shipping_message,
    model,
    related_products,
  } = product;

  const calcAges = () => {
    const agesArr = age?.split(',');
    const agesLabels = agesArr?.map(
      (age) => _.find(attributes.age, (e) => e.value == age)?.label,
    );
    return _.compact(agesLabels).join(', ');
  };
  const agesText = calcAges();
  const { variants: option_products } = mainProduct;

  const description = product?.description?.html;
  const isInStock = qty > 0;
  const price = price_range?.maximum_price?.regular_price?.value;
  const final_price = price_range?.maximum_price?.final_price?.value;
  const discount_percentage = price_range?.maximum_price?.discount?.percent_off;
  const special_price = discount_percentage !== 0 ? final_price : null;
  const thumbnail = small_image?.url;
  const hasBrand = typeof brand_name == 'string' || !parseInt(brand);
  const hasSKU = typeof product?.sku == 'string' && product?.sku != '';
  const hasModel = typeof model == 'string' && model != '';
  const hasWeight = typeof weight == 'number' && weight != '';
  const hasAge = typeof age == 'string' && agesText != '';
  const hasGender = typeof gender == 'string' && gender != '';
  const hasDescription = typeof description == 'string' && description != '';
  const hasShipping_message =
    typeof shipping_message == 'string' && shipping_message != '';

  const size =
    width && height && length
      ? `${length} cm L x ${width} cm W x ${height} cm H`
      : null;

  const sliderData = Boolean(video)
    ? [{ url: thumbnail + '?', video }, ...media_gallery]
    : media_gallery;

  return (
    <Screen
      containerStyle={{ backgroundColor: colors.white }}
      pointerEvents={addToCartLoading ? 'none' : 'auto'}
    >
      {/* <Spinner visible={addToCartLoading} />; */}
      <ScrollView>
        {isChildProduct ? (
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => showZoomingModal(0)}
          >
            <Image
              resizeMode="contain"
              source={{ uri: thumbnail }}
              style={styles.imageVariation}
              placeholder={config.placeholderImage}
            />
          </TouchableOpacity>
        ) : (
          <PreviewSlider
            containerStyle={styles.banner}
            items={sliderData}
            onPressSliderItem={(index) => showZoomingModal(index)}
          />
        )}

        {/*---------'title and brand view'---------*/}
        <View style={styles.containerView}>
          {hasBrand && (
            <TouchableOpacity
              onPress={() => {
                const queryData = {
                  // id: item.id,
                  // title: item.title,
                  component_type: 'brand-slider',
                  type: 'brand',
                };
                navigateToProducts({ brand }, queryData);
              }}
            >
              <Text size="small" style={styles.productBrand}>
                {brand_name}
              </Text>
            </TouchableOpacity>
          )}
          <Text size="large" style={styles.title} numberOfLines={3}>
            {name}
          </Text>
        </View>

        {/*---------'Price View'---------*/}
        <View
          style={[styles.containerView, styles.deliveryRow, styles.borderRow]}
        >
          {!special_price ? (
            <View style={styles.headerRightView}>
              <Text size="large" style={styles.title}>
                {getPriceFormat(price)}
              </Text>
            </View>
          ) : (
            <View>
              <Text size="small" style={styles.discount}>
                {getPriceFormat(price)}
                &nbsp;
              </Text>
              <View style={styles.headerRightView}>
                <Text size="large" style={styles.currency}>
                  {getPriceFormat(special_price)}
                </Text>
                <DiscountBadge discountPercentage={discount_percentage} />
              </View>
            </View>
          )}
        </View>

        {/*---------'customized Writing Input View'---------*/}
        {isSupportCustomizedWriting && (
          <View
            style={[styles.containerView, styles.deliveryRow, styles.borderRow]}
          >
            <CustomInput
              containerWrapperStyle={styles.customizedWritingInput}
              activeContainerStyle={{
                borderColor: 'transparent',
              }}
              containerStyle={{ borderBottomWidth: 0.3 }}
              placeholder={I18n.t('placeholders.customizedWriting')}
              value={customizedWriting}
              onChangeText={(text) => setCustomizedWriting(text)}
              maxLength={16}
            />
          </View>
        )}

        {hasShipping_message && (
          <View
            style={[styles.containerView, styles.deliveryRow, styles.borderRow]}
          >
            <View style={styles.innerDelivery}>
              <Text size="small" style={{ textAlign: 'center' }}>
                {shipping_message}
              </Text>
            </View>
          </View>
        )}
        {/*---------'Delivery View'---------*/}
        <View
          style={[
            styles.containerView,
            styles.deliveryRow,
            styles.borderRow,
            styles.deliveryView,
          ]}
        >
          <View style={[styles.innerDelivery, styles.deliveryData]}>
            <Image
              source={IMAGES.deliveryIconCopy}
              resizeMode="contain"
              style={styles.deliveryIcon}
            />
            <View style={styles.deliveryTitle}>
              <Text size="small" style={{ fontWeight: 'bold' }}>
                {I18n.t('cart.titleDelivery')}
              </Text>
              <Text numberOfLines={1} size="small">
                {delivery_message}
              </Text>
            </View>
          </View>
          <Badge
            value={
              isInStock
                ? I18n.t('productDetails.inStock')
                : I18n.t('productDetails.outOfStock')
            }
            textStyle={{ fontWeight: 'bold' }}
            textSize="smaller"
            rounded
            containerStyle={{
              backgroundColor: isInStock ? colors.brandGreen : colors.red600,
              marginTop: 8,
            }}
          />
        </View>

        <View style={styles.containerView}>
          {isChildProduct && (
            <SelectProductOptions
              options={option_products}
              selectedOption={product}
              onPress={toggleProductOptions}
            />
          )}

          {/*---------'product details description'---------*/}
          {hasDescription && (
            <View styles={{ flex: 1 }}>
              <Text style={{ fontWeight: 'bold' }}>
                {I18n.t('productDetails.descriptionTitle')}
              </Text>

              <HTML
                source={{ html: description || '<p></p>' }}
                baseFontStyle={{
                  fontSize: 16,
                  fontFamily: isRTL() ? 'Cairo-Regular' : 'Gotham Rounded',
                  lineHeight: 22,
                }}
                allowedStyles={[]}
              />
            </View>
          )}
        </View>

        <Text style={{ marginHorizontal: 15, fontWeight: 'bold' }}>
          {I18n.t('productDetails.productInfo.title')}
        </Text>

        {hasSKU && (
          <View style={styles.productInfoRow}>
            <Text size="small">{I18n.t('productDetails.productInfo.sku')}</Text>
            <Text size="small">{product?.sku}</Text>
          </View>
        )}

        {hasModel && (
          <View style={styles.productInfoRow}>
            <Text size="small">
              {I18n.t('productDetails.productInfo.model')}
            </Text>
            <Text size="small">{model}</Text>
          </View>
        )}

        {hasWeight && (
          <View style={[styles.productInfoRow, styles.productInfoRowGrey]}>
            <Text size="small">
              {I18n.t('productDetails.productInfo.weight')}
            </Text>
            <Text size="small">{weight}</Text>
          </View>
        )}
        {hasAge && (
          <View style={[styles.productInfoRow]}>
            <Text size="small">{I18n.t('productDetails.productInfo.age')}</Text>
            <Text size="small">{agesText}</Text>
          </View>
        )}
        {hasGender && (
          <View style={[styles.productInfoRow, styles.productInfoRowGrey]}>
            <Text size="small">
              {I18n.t('productDetails.productInfo.gender')}
            </Text>
            <Text size="small">{getGender()}</Text>
          </View>
        )}

        {size && (
          <View style={styles.productInfoRow}>
            <Text size="small">
              {I18n.t('productDetails.productInfo.dimensions')}
            </Text>
            <Text size="small">{size}</Text>
          </View>
        )}
        {related_products && related_products.length ? (
          <RelatedProducts
            title={I18n.t('productDetails.relatedItemsTitle')}
            items={related_products}
            navigation={navigation}
          />
        ) : null}
      </ScrollView>
      {isChildProduct && (
        <SelectionModal
          visible={showProductOptions}
          onBack={toggleProductOptions}
          setModalVisible={setShowProductOptions}
        >
          <TouchableOpacity
            activeOpacity={0.85}
            onPress={toggleProductOptions}
            style={styles.doneBtn}
          >
            <Text size="small" style={{ fontWeight: '600' }}>
              {I18n.t('btns.confirm')}
            </Text>
          </TouchableOpacity>
          <Fragment>
            <ProductVariationSummary
              selectedOption={product}
              option_products={option_products}
            />
            <ProductVariations
              options={option_products}
              selectedOption={product}
              onItemSelect={(value) => onProductOptionSelect(value?.product)}
            />
          </Fragment>
        </SelectionModal>
      )}
      <View style={styles.ctaContainer}>
        <Button
          containerStyle={styles.addBtn}
          disabled={!isInStock || addToCartLoading}
          onPress={
            isCustomProduct
              ? () =>
                  navigation.navigate('CustomProduct', {
                    product,
                    parentSku: mainProduct.sku,
                  })
              : addToCart
          }
        >
          {addToCartLoading ? (
            <ActivityIndicator size="small" color={colors.white} />
          ) : (
            <Text size="small" style={styles.addBtnTitle}>
              {getAddCartBtnText()}
            </Text>
          )}
        </Button>
        {isChildProduct && (
          <Button
            containerStyle={[
              styles.addBtn,
              { flex: 1, backgroundColor: colors.blueA700 },
            ]}
            disabled={!isInStock}
            text={
              I18n.t('productDetails.select') +
              ' ' +
              I18n.t(
                `productDetails.${option_products[0]?.attributes[0]?.code}`,
              )
            }
            onPress={toggleProductOptions}
          />
        )}
      </View>

      {/*render modal of zooming image */}
      {showZoomModal && (
        <ZoomImage
          visible={showZoomModal}
          data={isChildProduct ? [{ url: thumbnail }] : sliderData}
          imageIndex={imageZoomIndex}
          setModalVisible={setShowZoomModal}
        />
      )}
    </Screen>
  );
};

export default ProductDetails;
