import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  headerContent: {
    alignItems: 'center',
  },
  headerTextContent: {
    marginTop: 60,
    alignItems: 'center',
  },
  button: {
    marginHorizontal: 15,
    marginBottom: 10,
  },
  image: {
    width: 242,
    height: 170,
    marginTop: 74,
  },
  headerText: {
    fontWeight: 'bold',
    lineHeight: 26,
    letterSpacing: -1.14,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
  bodyTextContainer: {
    marginTop: 24,
    width: 287,
  },
  bodyText: {
    fontWeight: '500',
    lineHeight: 22,
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
  orderInfoTableContainer: {
    marginVertical: 24,
    marginHorizontal: 22,
  },
  orderInfoTable: {
    marginTop: 8,
  },
});
