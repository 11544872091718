import {
  GET_PAYMENT_INFORMATION,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_ADDRESS,
  SET_BILLING_ADDRESS,
  SET_SHIPPING_METHOD,
} from '../queries/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { useAwaitQuery, useMutation } from '../queries/customHooks';
import { createTypes, showErrorMsg, createAction } from '../common/utils';
// import { GraphQErrorHandling } from '../services/ErrorHandling';

export const types = {
  GET_PAYMENT_DETAILS: createTypes('GET_PAYMENT_DETAILS'),
  SET_PAYMENT_METHOD: createTypes('SET_PAYMENT_METHOD'),
  SET_SHIPPING_ADDRESS: createTypes('SET_SHIPPING_ADDRESS'),
  SET_BILLING_ADDRESS: createTypes('SET_BILLING_ADDRESS'),
  SET_SHIPPING_METHOD: createTypes('SET_SHIPPING_METHOD'),
};

export const useCheckout = () => {
  const { cartState } = useSelector((state) => {
    return {
      cartState: state.cartState,
    };
  });
  const dispatch = useDispatch();
  const cartId = cartState.id;

  const getPaymentInformation = useAwaitQuery(GET_PAYMENT_INFORMATION);
  const [_setPaymentMethod] = useMutation(SET_PAYMENT_METHOD);
  const [_setShippingAddress] = useMutation(SET_SHIPPING_ADDRESS);
  const [_setBillingAddress] = useMutation(SET_BILLING_ADDRESS);
  const [_setShippingMethod] = useMutation(SET_SHIPPING_METHOD);

  const setShippingAddress = async (address) => {
    const type = types.SET_SHIPPING_ADDRESS;
    try {
      dispatch(createAction(type.REQUEST));
      const result = await _setShippingAddress({
        variables: { cartId, address },
      });
      const { data } = result;
      return dispatch(
        createAction(type.SUCCESS, data?.setShippingAddressesOnCart?.cart),
      );
    } catch (e) {
      // GraphQErrorHandling(e, type.ERROR);
      showErrorMsg();
      dispatch(createAction(type.ERROR, e));
    }
  };

  const setBillingAddress = async (address) => {
    const type = types.SET_BILLING_ADDRESS;
    try {
      dispatch(createAction(type.REQUEST));
      const result = await _setBillingAddress({
        variables: { cartId, address, same_as_shipping: true },
      });
      const { errors, data } = result;
      if (!errors) {
        return dispatch(
          createAction(type.SUCCESS, data?.setBillingAddressOnCart?.cart),
        );
      }
    } catch (e) {
      dispatch(createAction(type.ERROR, e));
      // GraphQErrorHandling(e, type.ERROR);
    }
  };

  const getPaymentDetails = async () => {
    const type = types.GET_PAYMENT_DETAILS;
    try {
      dispatch(createAction(type.REQUEST));
      const result = await getPaymentInformation({ variables: { cartId } });
      const { data } = result;
      return dispatch(createAction(type.SUCCESS, data?.cart));
    } catch (e) {
      // GraphQErrorHandling(e, type.ERROR);
      showErrorMsg();
      dispatch(createAction(type.ERROR, e));
    }
  };

  const setPaymentMethod = async (paymentMethod) => {
    const type = types.SET_PAYMENT_METHOD;
    try {
      dispatch(createAction(type.REQUEST));
      const result = await _setPaymentMethod({
        variables: { cartId, paymentMethod },
      });
      const { data } = result;
      return dispatch(
        createAction(type.SUCCESS, data?.setPaymentMethodOnCart?.cart),
      );
    } catch (e) {
      // GraphQErrorHandling(e, type.ERROR);
      showErrorMsg();
      dispatch(createAction(type.ERROR, e));
    }
  };

  const setShippingMethod = async (shipping_methods) => {
    const type = types.SET_SHIPPING_METHOD;
    try {
      dispatch(createAction(type.REQUEST));
      const result = await _setShippingMethod({
        variables: { cartId, shipping_methods },
      });
      const { data } = result;
      return dispatch(
        createAction(type.SUCCESS, data?.setShippingMethodsOnCart?.cart),
      );
    } catch (e) {
      // GraphQErrorHandling(e, type.ERROR);
      showErrorMsg();
      dispatch(createAction(type.ERROR, e));
    }
  };

  return {
    getPaymentDetails,
    setPaymentMethod,
    setShippingAddress,
    setBillingAddress,
    setShippingMethod,
  };
};

const initialState = { available_payment_methods: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SHIPPING_ADDRESS.SUCCESS:
    case types.GET_PAYMENT_DETAILS.SUCCESS:
    case types.SET_PAYMENT_METHOD.SUCCESS:
    case types.SET_SHIPPING_METHOD.SUCCESS:
    case types.SET_BILLING_ADDRESS.SUCCESS:
      return {
        ...initialState,
        ...action.payload,
      };

    default:
      return state;
  }
};
