import { store, config as configs } from '../common';

export const getCurrentStore = () => {
  const {
    config: {
      lang,
      country: { key },
    },
  } = store.getState((state) => state.country.key);
  let Store = '';
  //Kuwait
  if (lang == 'en' && key == 'KW') Store = configs.stores.KW_en;
  if (lang == 'ar' && key == 'KW') Store = configs.stores.KW_ar;
  //Saudi Arabia
  if (lang == 'en' && key == 'SA') Store = configs.stores.SA_en;
  if (lang == 'ar' && key == 'SA') Store = configs.stores.SA_ar;
  //United Arab Emirates
  if (lang == 'en' && key == 'AE') Store = configs.stores.AE_en;
  if (lang == 'ar' && key == 'AE') Store = configs.stores.AE_ar;
  //Qatar
  if (lang == 'en' && key == 'QA') Store = configs.stores.QA_en;
  if (lang == 'ar' && key == 'QA') Store = configs.stores.QA_ar;
  //Oman
  if (lang == 'en' && key == 'OM') Store = configs.stores.OM_en;
  if (lang == 'ar' && key == 'OM') Store = configs.stores.OM_ar;
  //Bahrain
  if (lang == 'en' && key == 'BH') Store = configs.stores.BH_en;
  if (lang == 'ar' && key == 'BH') Store = configs.stores.BH_ar;

  return Store;
};
