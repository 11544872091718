import React, { useLayoutEffect, useState } from 'react';
import { View, Keyboard, TextInput, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/user';
import { Button, Text, Screen, DrawerWithBackIcons } from '../../components';
import { I18n } from '../../locales';
import styles from './styles';
import {
  loadingSelector,
  showErrorMsg,
  showSuccessMsg,
  validateEmail,
} from '../../common/utils';
import { isMobile } from '../../services';
import { colors } from '../../common';
const ForgetPassword = ({ navigation }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    loadingSelector(state, [types.RESET_PASSWORD]),
  );
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: null });

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerTitle: I18n.t('forgetPassword.title'),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  const validate = (obj) => {
    const errors = {};
    if (!validateEmail(obj.email)) errors.email = I18n.t('errors.email');
    return errors;
  };

  const onChangeInput = (text) => {
    setEmail(text);
  };

  const onResetPassword = () => {
    const errors = validate({ email });
    if (Object.keys(errors).length) {
      setErrors(errors);
      setTimeout(() => setErrors({}), 5000);
    } else {
      Keyboard.dismiss();
      dispatch(actions.resetPassword(email)).then((resp) => {
        if (resp.type === types.RESET_PASSWORD.SUCCESS) {
          showSuccessMsg(I18n.t('forgetPassword.successMsg'), () =>
            navigation.goBack(),
          );
          setEmail('');
        } else {
          const message = resp.payload.startsWith('No such entity')
            ? I18n.t('errors.noAccountExist')
            : I18n.t('errors.tooManyPassReset');
          showErrorMsg(message);
        }
      });
    }
  };

  return (
    <Screen>
      {/* {loading && <Spinner />} */}
      <View style={styles.content}>
        <View style={styles.topView}>
          <Text size="small" style={styles.subTitle}>
            {I18n.t('forgetPassword.subtitle')}
          </Text>
          <TextInput
            containerStyle={styles.inputContainerStyle}
            value={email}
            textContentType="email"
            autoCompleteType="email"
            onChangeText={(text) => onChangeInput(text.trim())}
            keyboardType="email-address"
            returnKeyType="done"
            style={[styles.input, !isMobile && styles.inputWeb]}
            placeholder="example@domain.com"
          />
        </View>
        {errors.email && (
          <Text size="small" style={styles.errorMsg}>
            {errors.email}
          </Text>
        )}
        <Button
          disabled={loading}
          containerStyle={[
            { alignSelf: 'center' },
            !isMobile && styles.webButton,
          ]}
          onPress={() => onResetPassword()}
        >
          {loading ? (
            <ActivityIndicator
              size={isMobile ? 'small' : 'large'}
              color={colors.white}
            />
          ) : (
            <Text size="small" style={styles.buttonText}>
              {I18n.t('btns.resetPassword')}
            </Text>
          )}
        </Button>
      </View>
    </Screen>
  );
};

export default ForgetPassword;
