import React, { useRef, useState } from 'react';
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  FlatList,
  StyleSheet,
} from 'react-native';
import Swiper from 'react-native-web-swiper';
import { colors, images } from '../../common';
import { Video } from 'expo-av';
import { isMobile, isRTL, sWidth } from '../../services';
import { styles } from './mobileStyles';

export const PreviewSlider = (props) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { items, onPressSliderItem, containerStyle } = props;

  const _renderItem = ({ item, index }) => {
    const { url, video } = item;
    const isVideo = Boolean(video);

    return (
      <TouchableOpacity
        activeOpacity={0.9}
        onPress={() => onPressSliderItem(index)}
        key={index}
        style={[styles.container, containerStyle]}
      >
        {isLoading && isVideo && (
          <ActivityIndicator
            color={colors.brand}
            size={'large'}
            style={isMobile && { ...StyleSheet.absoluteFillObject }}
          />
        )}
        {isVideo ? (
          <Video
            shouldPlay
            source={{ uri: video }}
            style={[styles.videoStyle, isLoading && !isMobile && { height: 0 }]}
            isMuted
            isLooping
            onLoad={() => setIsLoading(false)}
            resizeMode="contain"
          />
        ) : (
          <Image
            source={{ uri: url }}
            resizeMode="contain"
            style={styles.imageView}
          />
        )}
      </TouchableOpacity>
    );
  };
  const isSingleItem = items.length == 1;
  const newItems = isRTL() ? items.slice().reverse() : items;
  return (
    <View>
      <View style={styles.carouselContainer}>
        {isSingleItem ? (
          items.map((item) => _renderItem?.({ item, index: 0 }))
        ) : (
          <Swiper
            ref={carouselRef}
            from={0}
            timeout={5}
            positionFixed
            minDistanceForAction={0.1}
            swipeAreaStyle={{
              left:
                isRTL() &&
                -(isMobile ? sWidth : sWidth - 200) * (items.length - 1),
            }}
            onIndexChanged={(index) => setCurrentIndex(index)}
            controlsProps={{
              dotsTouchable: true,
              dotsPos: false,
              dotActiveStyle: { backgroundColor: colors.brand },
              prevPos: false,
              nextPos: false,
            }}
          >
            {newItems.map((item, index) => _renderItem?.({ item, index }))}
          </Swiper>
        )}
      </View>
      <FlatList
        data={items}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.listContentContainer}
        extraData={carouselRef.current}
        renderItem={({ item, index }) => {
          const { url, video } = item;
          const isVideo = Boolean(video);
          const isFocused = currentIndex == index;

          return (
            <View
              style={[
                styles.smallItemContainer,
                isFocused && styles.activeItemContainer,
              ]}
            >
              <TouchableOpacity
                activeOpacity={0.1}
                onPress={() => {
                  carouselRef.current.goTo(index);
                  setCurrentIndex(index);
                }}
              >
                <Image
                  source={{ uri: url }}
                  resizeMode="cover"
                  style={styles.smallItem}
                />
              </TouchableOpacity>
              {isVideo && (
                <View pointerEvents="none" style={styles.iconContainer}>
                  <Image
                    source={images.bluePlay}
                    resizeMode="contain"
                    style={styles.iconStyle}
                  />
                </View>
              )}
            </View>
          );
        }}
      />
    </View>
  );
};
