import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { Text } from '../index';
import { colors, config, images } from '../../common';
import Icon from 'react-native-vector-icons/Ionicons';
import PropTypes from 'prop-types';
import styles from './mobileStyle';
import { getPriceFormat } from '../../common/utils';
import { isAvailableProduct } from '../../services';

export const CartItemMobile = (props) => {
  const { item, onPress, onIncrement, onDecrement, onDelete } = props;
  const { prices, product, quantity } = item;
  const { name, small_image, delivery_message } = product;
  const { value } = prices.price;
  const dimmingItem = !isAvailableProduct(item);
  return (
    <TouchableOpacity
      activeOpacity={0.85}
      onPress={onPress}
      style={styles.container}
    >
      <Image
        source={{ uri: small_image?.url }}
        placeholder={config.placeholderImage}
        resizeMode="contain"
        style={styles.image}
      />
      <View style={styles.dataContainer}>
        <View style={styles.viewRow}>
          <Text size="small" style={[styles.productName]} numberOfLines={1}>
            {name}
          </Text>
          {/* delete button */}
          {dimmingItem && (
            <TouchableOpacity
              onPress={onDelete}
              activeOpacity={0.9}
              style={styles.deleteButton}
            >
              <Image source={images.delete} style={styles.deleteImage} />
            </TouchableOpacity>
          )}
        </View>

        <View style={styles.viewRow}>
          <View style={[styles.priceAndMessageContainer]}>
            <Text size="small" style={styles.price} numberOfLines={2}>
              {getPriceFormat(value)}
            </Text>

            <Text
              numberOfLines={1}
              size="smaller"
              style={styles.deliveryMessage}
            >
              {delivery_message}
            </Text>
          </View>
          <View>
            <View style={styles.viewRow}>
              {/* decrease button */}
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={onDecrement}
                style={styles.decrementContainer}
              >
                <View style={[styles.qytButton]}>
                  <Icon name="md-remove" color={colors.greenMain} size={20} />
                </View>
              </TouchableOpacity>

              <View style={styles.qtyContainer}>
                <Text numberOfLines={1} size="small" style={styles.qtyText}>
                  {quantity}
                </Text>
              </View>

              {/* increase button */}
              <TouchableOpacity
                style={styles.incrementContainer}
                activeOpacity={0.9}
                onPress={onIncrement}
              >
                <View style={[styles.qytButton, styles.disabledStyle]}>
                  <Icon name="md-add" color={colors.greenMain} size={20} />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

CartItemMobile.propTypes = {
  item: PropTypes.object.isRequired,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func,
  onDelete: PropTypes.func,
  onPress: PropTypes.func,
};

CartItemMobile.defaultProps = {
  onIncrement: () => {},
  onDecrement: () => {},
  onDelete: () => {},
  onPress: () => {},
};
