import types from './types';

//create initial state
const initialState = {
  addresses: [],
  areas: {},
  selectedAddress: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ADDRESSES.SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    case types.ADD_ADDRESS.SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    case types.UPDATE_ADDRESS.SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    case types.DELETE_ADDRESS.SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    case types.CLEAR_ADDRESSES:
      return initialState;
    case types.SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case types.GET_AREAS.SUCCESS:
      return {
        ...state,
        areas: action.payload,
      };
    default:
      return state;
  }
};
