const COUNTRIES = [
  {
    id: 0,
    flag: '🇰🇼',
    key: 'KW',
    currencyCode: 'KWD',
  },
  {
    id: 1,
    flag: '🇸🇦',
    key: 'SA',
    currencyCode: 'SAR',
  },
  {
    id: 2,
    flag: '🇦🇪',
    key: 'AE',
    currencyCode: 'AED',
  },
  {
    id: 3,
    flag: '🇶🇦',
    key: 'QA',
    currencyCode: 'QAR',
  },
  {
    id: 4,
    flag: '🇴🇲',
    key: 'OM',
    currencyCode: 'OMR',
  },
  {
    id: 5,
    flag: '🇧🇭',
    key: 'BH',
    currencyCode: 'BHD',
  },
];

export default COUNTRIES;
