import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    borderRadius: 4,
    flex: 0.5, // automatically adjust according numColumns in grid layout.
    marginHorizontal: 8,
  },
  image: {
    maxWidth: '100%',
    height: 120,
    borderRadius: 4,
    backgroundColor: colors.white,
  },

  textStyle: {
    paddingTop: 10,
    paddingBottom: 20,
    fontWeight: '500',
    color: colors.brand,
    textAlign: 'center',
  },
});
