import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { colors } from '../../common';

const TextElement = (props) => {
  const { style, size, ...rest } = props;

  const sizes = {
    larger: 24,
    large: 18,
    normal: 16,
    small: 12,
    smaller: 9,
  };
  return (
    <Text
      style={[styles.text, { fontSize: sizes[size] || 16 }, style && style]}
      {...rest}
    />
  );
};

export default TextElement;

const styles = StyleSheet.create({
  text: {
    color: colors.textMain,
    textAlign: 'left',
  },
});
