import React, { Fragment } from 'react';
import { View, I18nManager, StyleSheet, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
Icon.loadFont();
import { colors } from '../../common';
import { Text, Image } from '../index';

export default class SettingRow extends React.PureComponent {
  renderIcon = () => {
    const { imageUrl, IconText } = this.props;

    if (IconText) return <Text>{IconText}</Text>;
    else
      return (
        <Image
          resizeMode="contain"
          source={imageUrl}
          style={styles.image}
          tintColor={colors.brand}
        />
      );
  };

  render() {
    const { containerStyle, title, subTitle, onPress, hideArrow } = this.props;
    return (
      <Fragment>
        <TouchableOpacity
          activeOpacity={0.8}
          style={[styles.container, containerStyle]}
          onPress={onPress}
        >
          <View style={styles.titleView}>
            {this.renderIcon()}
            <Text style={styles.title}>{title}</Text>
            {subTitle && <Text style={styles.subTitle}>( {subTitle} )</Text>}
          </View>
          {!hideArrow && (
            <Icon
              name={I18nManager.isRTL ? 'ios-arrow-back' : 'ios-arrow-forward'}
              size={15}
              color={colors.brand}
            />
          )}
        </TouchableOpacity>
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colors.white,
    borderBottomWidth: StyleSheet.hairlineWidth,
    height: 50,
    borderBottomColor: colors.grey110,
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  titleView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginRight: I18nManager.isRTL ? 0 : 5,
    marginLeft: I18nManager.isRTL ? 15 : 15,
    letterSpacing: 0,
    color: colors.greyishBrown,
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: '500',
    letterSpacing: 0,
    color: colors.greyishBrown,
    marginHorizontal: 5,
  },
  image: {
    height: 25,
    width: 25,
  },
});
