import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from '../Text';
import { colors } from '../../common';
import * as _ from 'lodash';
import { I18n } from '../../locales';
import { getPriceFormat } from '../../common/utils';

const CartTotal = ({ totals, isCartPage, shipping_amount }) => {
  const { grand_total, subtotal_excluding_tax, discounts } = totals;
  const hasDiscounts = discounts && discounts[0] != undefined;

  return (
    <View style={styles.container}>
      {/* <View style={styles.row}>
        <Text size="smaller" stlye={styles.text}>
          {I18n.t('cartTotal.subtotal')}
        </Text>
        <Text size="smaller" stlye={styles.text}>
          {getPriceFormat(totals.subtotal)}
        </Text>
      </View> */}

      {shipping_amount > 0 && !isCartPage && (
        <View style={styles.row}>
          <Text size="smaller" stlye={styles.text}>
            {I18n.t('cartTotal.shippingFee')}
          </Text>
          <Text size="smaller" stlye={styles.text}>
            {getPriceFormat(shipping_amount)}
          </Text>
        </View>
      )}

      {hasDiscounts && (
        <View style={styles.row}>
          <Text size="smaller" stlye={styles.text}>
            {I18n.t('cartTotal.discount')}
            {/* &nbsp;({couponCode}) */}
          </Text>
          <Text size="smaller" stlye={styles.text}>
            {getPriceFormat(discounts[0]?.amount?.value)}
          </Text>
        </View>
      )}

      {/* {parseInt(giftAmount) > 0 && (
        <View style={[styles.row, styles.rowGrey]}>
      {totals.tax_amount != 0 && (
        <View style={styles.row}>
          <Text size="smaller" stlye={styles.text}>
            {I18n.t('cartTotal.tax')}
          </Text>
          <Text size="smaller" stlye={styles.text}>
            {getPriceFormat(totals.tax_amount)}
          </Text>
        </View>
      )}
      {cash_on_delivery_fee?.value != 0 && !isCartPage && (
        <View style={styles.row}>
          <Text size="smaller" stlye={styles.text}>
            {I18n.t('cartTotal.fee')}
          </Text>
          <Text size="smaller" stlye={styles.text}>
            {getPriceFormat(cash_on_delivery_fee?.value)}
          </Text>
        </View>
      )} */}

      <View style={[styles.row]}>
        <Text size="small" style={styles.boldText}>
          {isCartPage
            ? I18n.t('cartTotal.subtotal')
            : I18n.t('cartTotal.orderTotal')}
        </Text>
        <Text size="small" style={styles.boldText}>
          {getPriceFormat(
            isCartPage ? subtotal_excluding_tax?.value : grand_total?.value,
            // isCartPage ? totals.subtotal_incl_tax : grand_total?.value,
          )}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  row: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingVertical: 10,
  },
  rowGrey: {
    backgroundColor: colors.brandGreyLight,
  },
  boldText: {
    fontWeight: 'bold',
    letterSpacing: -0.47,
    color: colors.black,
  },
  text: {
    color: colors.greyishBrown,
  },
});

export default CartTotal;
