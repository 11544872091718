import { colors } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    paddingTop: 52,
  },
  topContent: {
    alignItems: 'center',
  },

  logo: {
    width: 143,
    height: 72,
  },
  selectCountryTextContainer: {
    marginTop: 32,
    marginBottom: 64,
  },
  selectCountryText: {
    lineHeight: 32,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.textGrey,
  },
  btn: {
    marginHorizontal: 20,
    marginBottom: 20,
    marginTop: 52,
  },
});
