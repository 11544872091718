import React, { useEffect, useLayoutEffect, useState } from 'react';
import { TextInput, View } from 'react-native';
import { useSelector } from 'react-redux';
import styles from './style';
import { Text, Button, Spinner } from '../../components';
import * as _ from 'lodash';
import { I18n } from './../../locales';
// import OTPInputView from '@twotalltotems/react-native-otp-input';
import MagentoAPI from '../../services/MagentoAPI';
import { IS_DEV_OTP } from '../../common/constants';
import { showErrorMsg, showSuccessMsg } from '../../common/utils';

const COUNTER = 59000;
const OTP = ({ onCOD, setModalVisible }) => {
  const { user, config } = useSelector((state) => {
    return {
      user: state.user,
      config: state.config,
    };
  });

  const mobile =
    user.info?.custom_attributes && user.info?.custom_attributes[0]?.value;
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [ms, setMs] = useState(COUNTER);
  const [text, setText] = useState('');
  const disableResend = ms == 0;

  // useLayoutEffect(() => {
  //   navigation.setOptions({ headerTitle: I18n.t('otp.verificationCode') });
  // }, [navigation]);

  useEffect(() => {
    !IS_DEV_OTP && sendOtp();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ms <= 0) {
        return;
      } else {
        setMs((prev) => prev - 1000);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [ms]);

  const sendOtp = async () => {
    setLoading(true);
    try {
      const resp = await MagentoAPI.post(
        `/teeela-twilio/sendotp`,
        null,
        JSON.stringify({ mobile }),
        { country: config.country.currencyCode },
      );
      if (resp.json[0]?.status !== 'pending') {
        showErrorMsg();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setModalVisible(false);
      showErrorMsg();
      console.log('error', error);
    }
  };

  const verifyOtp = async (otp) => {
    setLoading(true);
    try {
      const resp = await MagentoAPI.post(
        `/teeela-twilio/verifyotp`,
        null,
        JSON.stringify({ mobile, otp }),
        { country: config.country.currencyCode },
      );
      if (resp.json[0]?.status == 'approved') {
        setVerified(true);
        showSuccessMsg(I18n.t('otp.validOtp'));
      } else {
        showErrorMsg(I18n.t('otp.invalidOtp'));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorMsg();
      console.log('error', error);
    }
  };

  const resendCode = () => {
    setMs(COUNTER);
    sendOtp();
  };

  const formatCounter = () => {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    minutes = minutes < 1 ? '00' : minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 1 ? '00' : seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${seconds}`;
  };
  return (
    <View style={styles.container}>
      {/* <Spinner visible={loading} /> */}
      <View style={styles.content}>
        <View style={styles.textContainer}>
          <Text style={styles.verificationText}>
            {I18n.t('otp.verificationCode')}
          </Text>
          <Text size="small" style={styles.text}>
            {I18n.t('otp.pleaseTypeOtp')} {mobile}
          </Text>
        </View>
        <TextInput
          style={[
            styles.underlineStyleBase,
            verified && styles.notEditableText,
          ]}
          maxLength={4}
          onChangeText={(text) => {
            setText(text);
            if (text.length == 4) {
              verifyOtp(text);
            }
          }}
          disabled={verified}
          value={text}
          autoFocus
        />
        <Button
          containerStyle={styles.resendButton}
          text={`${I18n.t('otp.resendCode')} ${formatCounter()}`}
          onPress={() => resendCode()}
          disabled={!disableResend || verified}
        />
      </View>

      <Button
        disabled={!verified && !IS_DEV_OTP}
        containerStyle={styles.addBtn}
        text={I18n.t('btns.confirm')}
        onPress={() => onCOD()}
      />
    </View>
  );
};

export default OTP;
