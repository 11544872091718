import React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';
import { colors, images } from '../../common';
import { Image } from '..';
import { navigateTo } from '../../services';

const AccountButton = () => {
  return (
    <TouchableOpacity
      onPress={() => navigateTo('account')}
      style={styles.container}
    >
      <Image
        source={images.profileIcon}
        style={styles.imageStyle}
        resizeMode="contain"
        tintColor={colors.cartIconColor}
      />
    </TouchableOpacity>
  );
};
export default AccountButton;
