import { MAGENTO_API_URL } from '../common/constants';
import { actions } from '../redux/user';
import { getCurrentStore } from './getCurrentStore';
import { store as reduxStore } from '../common';

// TODO: Replace Token auth with OAuth for better security.
export default class MagentoAPI {
  constructor() {
    this._request = this._request.bind(this);
    this.currencyCode = null;
  }

  static setCurrencyCode(currencyCode) {
    this.currencyCode = currencyCode;
  }

  static async _request(method, url, type, data, headers) {
    let response = null;
    const user = reduxStore?.getState()?.user;
    const defaultHeaders = {
      'Content-Type': 'application/json',
      country: this.currencyCode,
    };

    if (user?.token) {
      defaultHeaders.Authorization = `Bearer ${user.token}`;
    }
    const store = getCurrentStore();
    let endpoint = url.startsWith('/') ? url : `/${url}`;

    try {
      response = await fetch(`${MAGENTO_API_URL}/${store}/V1${endpoint}`, {
        method: method,
        // credentials: 'include',
        body: data, // data can be formData or JSON.stringify()
        headers: Object.assign({}, defaultHeaders, headers),
      });
    } catch (e) {
      return {
        status: 503,
        ok: false,
        message: e.message,
      };
    }

    const json = await response.json();

    if (response.ok)
      return {
        status: response.status,
        ok: response.ok,
        json: json,
      };
    else {
      // captureSentryAPIError(response, type?.ERROR);
      //check if user is not authorized
      const isUserNotAuthorized =
        json.message == 'Consumer is not authorized to access %resources';
      if (response.status == 401 && isUserNotAuthorized) {
        reduxStore.dispatch(actions.userLogout());
      }
      return {
        status: response.status,
        ok: response.ok,
        message: json.message,
        fullMessage: json,
      };
    }
  }

  /** params: endpoint, headers (optional) */
  static async get(endpoint, type, headers) {
    return await this._request('GET', endpoint, type, null, headers);
  }
  /** params: endpoint, data, headers (optional) */
  static async put(endpoint, type, data, headers) {
    return await this._request('PUT', endpoint, type, data, headers);
  }
  /** params: endpoint, data, headers (optional) */
  static async post(endpoint, type, data, headers) {
    return await this._request('POST', endpoint, type, data, headers);
  }
  /** params: endpoint, headers (optional) */
  static async delete(endpoint, type, headers) {
    return await this._request('DELETE', endpoint, type, null, headers);
  }
  /** params: endpoint, headers (optional) */
  static async options(endpoint, type, headers) {
    return await this._request('OPTIONS', endpoint, type, null, headers);
  }
}
