import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { vScale, scale, fontScale, sWidth } from '../../services';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.white,
    width: sWidth - 100,
    marginVertical: 'auto',
    padding: 20,
    borderRadius: 10,
  },
  content: {
    flex: 1,
    alignItems: 'center',
  },
  textContainer: {
    marginBottom: vScale(32),
  },
  otpContainer: {
    backgroundColor: colors.white,
    height: vScale(200),
    padding: vScale(16),
    borderRadius: vScale(10),
    width: '80%',
  },
  underlineStyleBase: {
    width: scale(200),
    height: vScale(50),
    borderRadius: vScale(4),
    color: colors.black,
    fontSize: fontScale(22),
    borderWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  underlineStyleHighLighted: {
    borderColor: colors.brand,
  },
  notEditableText: {
    color: colors.grey500,
  },
  text: {
    marginTop: vScale(16),
    fontWeight: '500',
    textAlign: 'center',
  },
  verificationText: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  resendButton: {
    width: '80%',
    alignSelf: 'center',
  },
});
