import React from 'react';
import { View, StyleSheet, TouchableOpacity, I18nManager } from 'react-native';
import { colors } from '../../common';
import PropTypes from 'prop-types';
import { Text, Image } from '../index';
import Badge from '../Badge';
import { isRTL } from '../../services';

/* hint  
elements: [
  {
    icon: '', //the source of the image
    onPress: () => {}, // function fired when click on the element
    iconStyle: {}, // the style of icon
    text: '', // text render if found
  },
],
*/

const HeaderButton = ({ style, elements }) => {
  return (
    <View style={[styles.container, style]}>
      {elements.map((ele, index) => {
        return (
          <View
            key={index}
            style={[
              { flexDirection: 'row', alignItems: 'center' },
              //add horizontal margin between btns if not the first btn
              elements.length != 1 && index == 0 && { marginHorizontal: 16 },
            ]}
          >
            {ele.element && ele.element}
            {ele.icon && (
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={ele.onPress}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              >
                <Image
                  resizeMode="contain"
                  style={[styles.icon, ele.iconStyle]}
                  source={ele.icon}
                  tintColor={colors.headerColor}
                />
                {ele.value && ele.value != 0 ? (
                  <Badge
                    value={ele.value || ''}
                    textStyle={styles.badgeTextStyle}
                    rounded
                    containerStyle={styles.badgeContainer}
                    size={22}
                  />
                ) : null}
              </TouchableOpacity>
            )}
            {ele.text && (
              <Text
                onPress={ele.onPress}
                style={{
                  color: colors.headerColor,
                  weight: '500',
                }}
              >
                {ele.text}
              </Text>
            )}
          </View>
        );
      })}
    </View>
  );
};

HeaderButton.propTypes = {
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  elements: PropTypes.array,
};

HeaderButton.defaultProps = {
  style: {},
  elements: [],
};

export default HeaderButton;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 16,
    // backgroundColor: 'red',
  },
  icon: {
    width: 24,
    height: 24,
    tintColor: colors.headerColor,
  },
  badgeContainer: {
    bottom: 15,
    left: isRTL() ? -10 : 10,
    padding: 0,
    paddingVertical: 0,
    position: 'absolute',
  },
  badgeTextStyle: {
    fontWeight: 'bold',
  },
});
