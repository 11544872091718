import { store } from '../../common';

const delay = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

const retryPromise =
  (promiseFunc, { retries = 4, waitDuration = 400 } = {}) =>
  async (...props) => {
    try {
      const x = await promiseFunc(...props);
      return x;
    } catch (error) {
      if (retries > 0) {
        await delay(waitDuration);
        return retryPromise(promiseFunc, {
          retries: retries - 1,
          waitDuration,
        })(...props);
      }
      throw error;
    }
  };

export const trackEcommercePurchase = (paymentMethod, cart) => {
  if (paymentMethod && cart && cart.prices) {
    const { order } = store.getState((state) => state);
    const { prices, currency } = cart;
    const { grand_total } = prices;
    const { orderId = '' } = order;
    const ackooParams = {
      orderId,
      amount: grand_total?.value,
      currency: currency?.toLowerCase(),
    };
    console.log('ackooParams', ackooParams);
    //should return Ackoo response promise to let the request complete

    return retryPromise(trackAckooCheckout)(ackooParams)
      .then((value) => console.log('success', value))
      .catch((e) => console.log({ errMsg: e.message }));
  }
};

const trackAckooCheckout = async (ackooParams) => {
  console.log('trying to call ackoo');
  if (window.ackoo) {
    await window.ackoo.trackCheckout(ackooParams);
    return;
  }
  throw new Error('Ackoo Not Initialized Yet');
};
