import React, { Component, Fragment } from 'react';
import { View, FlatList, Animated, Image, Easing } from 'react-native';

import { ShimmerPlaceHolder } from './index';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styles from './styles';
import { colors } from '../../common';

export default class ShimmerScrollList extends Component {
  static propTypes = {
    style: PropTypes.object,
    loading: PropTypes.bool,
    num: PropTypes.number,
  };

  static defaultProps = {
    style: {},
    loading: true,
    num: 10,
  };

  renderItem = () => {
    const { loading } = this.props;
    return (
      <Fragment>
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardScroll}
          visible={!loading}
          colorShimmer={colors.brandShimm3}
        />
      </Fragment>
    );
  };
  render() {
    const { loading } = this.props;
    return (
      <View style={styles.scrollListContainer}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.headerSeeAllShimmer}
          visible={!loading}
          colorShimmer={colors.brandShimm3}
        />
        <FlatList
          showsHorizontalScrollIndicator={false}
          horizontal
          data={_.range(this.props.num)}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          contentContainerStyle={styles.contentContainerScroll}
        />
      </View>
    );
  }
  keyExtractor = index => {
    return `${index}`;
  };
}
