import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Image, Text } from '..';
import { colors, images } from '../../common';
import { scale, vScale } from '../../services';
import { I18n } from '../../locales';

export const BrandButton = ({ brand_name, brand_image, onPress }) => {
  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.8}
      onPress={onPress}
    >
      <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          resizeMode="contain"
          source={
            brand_image
              ? {
                  uri: brand_image,
                }
              : images.placeholder
          }
        />
      </View>
      <View style={styles.infoContainer}>
        <Text style={styles.text} type="regularThick" numberOfLines={1}>
          {I18n.t('productDetails.brandDetails')}
        </Text>
        <Text style={styles.brand} type="buttonTextDark" numberOfLines={1}>
          {brand_name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    width: 290,
    height: 60,
    flexDirection: 'row',
    marginVertical: 24,
  },
  text: {
    opacity: 0.3,
    marginBottom: vScale(4),
  },
  imageContainer: {
    width: 60,
    height: 60,
    backgroundColor: colors.white,
    borderRadius: scale(10),
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.darkIndigo,
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 20,
    shadowOpacity: 0.1,
    elevation: 2,
  },
  image: {
    width: 45,
    height: 45,
    borderRadius: scale(10),
  },
  infoContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: scale(20),
  },
  brand: {
    fontWeight: 'bold',
  },
});
