import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import i18n from '../../locales/i18n';
import { isRTL, navigateTo, replace, sHeight } from '../../services';
import { actions as configActions } from '../../redux/config';
import { actions as userActions } from '../../redux/user';
import { images, countries, colors } from '../../common';
import AppLogo from '../AppLogo';
import { baseUrl } from '../../common/constants';
import { Image } from '..';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { convertRGBToRGBA } from '../../common/utils';
import COUNTRIES from '../../common/countries';

export const CustomDrawer = (props) => {
  const {
    categories,
    clearCart_GQL,
    config,
    dispatch,
    selectedCategory,
    setSelectedCategory,
    subCategories,
    setSubcategories,
    isLoggedIn,
    deleteCart_GQL,
  } = props;
  const navigateToCategoryDetails = (item) => {
    window.open(
      `${baseUrl}/products/${JSON.stringify({
        category_id: item.id,
      })}/${JSON.stringify({
        categoryId: item.id,
        title: item.name,
        parentId: item.id,
      })}`,
      '_self',
    );
  };

  const changeLang = async () => {
    const language = config.lang == 'en' ? 'ar' : 'en';
    await dispatch(configActions.setLanguage(language));
    if (!isLoggedIn) {
      await clearCart_GQL();
    }
    setTimeout(() => {
      location.reload();
    }, 200);
  };

  const onSetCountry = async (country) => {
    try {
      await dispatch(configActions.setCountry(country));
      if (isLoggedIn) {
        await deleteCart_GQL();
      }
      await clearCart_GQL();
      setTimeout(() => {
        location.reload();
        replace();
      }, 200);
    } catch (e) {
      console.log('setCountry ', e);
    }
  };
  const currentCountry = Boolean(config?.country?.key)
    ? config?.country
    : COUNTRIES[0];

  const logout = async () => {
    await dispatch(userActions.userLogout());
    setTimeout(() => {
      navigateTo();
    }, 200);
  };

  return (
    <View style={styles.container}>
      <AppLogo
        style={styles.appLogo}
        onPress={() => {
          window.open(baseUrl, '_self');
        }}
      />
      <TouchableOpacity
        style={styles.logoutButton}
        onPress={() => navigateTo('account')}
      >
        <Image
          source={images.account}
          style={styles.logoutImage}
          resizeMode="contain"
        />
        <Text style={styles.logoutText}>{i18n.t('profile.title')}</Text>
      </TouchableOpacity>
      {isLoggedIn && (
        <TouchableOpacity style={styles.logoutButton} onPress={logout}>
          <Image
            source={images.exit}
            style={styles.logoutImage}
            resizeMode="contain"
          />
          <Text style={styles.logoutText}>{i18n.t('profile.logout')}</Text>
        </TouchableOpacity>
      )}
      <View style={styles.mainCategoryContainer}>
        {categories?.map((item, index) => {
          const isSelected = selectedCategory == index;
          return (
            <TouchableOpacity
              onPress={() => {
                setSelectedCategory(index);
                setSubcategories(item.children);
              }}
              style={[
                styles.mainCategory,
                isSelected && styles.selectedMainCat,
              ]}
            >
              <Text
                numberOfLines={1}
                style={[
                  styles.categoryText,
                  isSelected && styles.selectedCategoryText,
                ]}
              >
                {item.name}
              </Text>
              <Image
                source={{ uri: item.image }}
                style={styles.categoryImage}
                resizeMode="contain"
              />
            </TouchableOpacity>
          );
        })}
      </View>

      <FlatList
        data={subCategories || []}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.itemContainer}
            onPress={() => navigateToCategoryDetails(item)}
          >
            <Text numberOfLines={1} style={styles.subcategoryText}>
              {item.name}
            </Text>
            <Icon
              name={isRTL() ? 'keyboard-arrow-left' : 'keyboard-arrow-right'}
              size={20}
              color={convertRGBToRGBA(colors.darkIndigo, 0.3)}
            />
          </TouchableOpacity>
        )}
      />
      <TouchableOpacity
        style={styles.langContainer}
        onPress={() => changeLang()}
      >
        <Image style={styles.languageIcon} source={images.language} />
        <Text>{i18n.t('settings.lang')}</Text>
      </TouchableOpacity>
      <View style={styles.countriesContainer}>
        {countries.map((item) => {
          const isSelected = currentCountry.key == item.key;
          return (
            <TouchableOpacity
              style={[styles.countryItem, isSelected && styles.selectedCountry]}
              onPress={() => onSetCountry(item)}
            >
              <Text style={styles.flag}>{item.flag}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: sHeight,
    paddingTop: 10,
  },
  appLogo: {
    alignItems: 'center',
  },
  mainCategoryContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 5,
    marginBottom: 5,
  },
  mainCategory: {
    paddingHorizontal: 5,
    width: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: convertRGBToRGBA(colors.darkIndigo, 0.05),
    paddingBottom: 5,
    marginBottom: 5,
  },
  selectedMainCat: {
    borderBottomColor: colors.selection,
  },
  categoryText: {
    fontWeight: 'bold',
    color: convertRGBToRGBA(colors.darkIndigo, 0.4),
    marginEnd: 5,
  },
  selectedCategoryText: {
    color: colors.selection,
  },
  categoryImage: {
    width: 40,
    height: 40,
  },
  subcategoryText: {
    flex: 1,
    fontWeight: '500',
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 7,
    width: '100%',
    paddingHorizontal: 11.5,
  },
  langContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 10,
  },
  languageIcon: {
    width: 24,
    height: 24,
    marginHorizontal: 5,
  },
  countriesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: 20,
  },
  countryItem: {
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedCountry: {
    backgroundColor: colors.selection,
  },
  flag: {
    fontSize: 30,
  },
  logoutButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
    paddingHorizontal: 12,
  },
  logoutImage: {
    width: 20,
    height: 20,
    tintColor: colors.grey600,
    marginEnd: 6,
  },
  logoutText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.darkIndigo,
  },
});
