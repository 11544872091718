import { gql } from '@apollo/client';

export const SEARCH_PRODUCTS_GATEWAY = gql`
  query ($search: String!, $currentPage: Int!) {
    search(search: $search, currentPage: $currentPage, pageSize: 50) {
      page_info {
        total_pages
      }
      items {
        id
        name
        sku
        brand_object {
          id
          value
        }
        price_range {
          maximum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        stock_status
        special_price
        small_image {
          url
        }
      }
    }
  }
`;
