import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  forgetStyle: {
    marginVertical: 20,
    fontWeight: '500',
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.buttons,
  },
  orStyle: {
    marginHorizontal: 16,
    marginTop: 20,
  },
  orStyleWeb: {
    width: 400,
    alignSelf: 'center',
  },
  inputsView: {
    marginHorizontal: 10,
    padding: 16,
    backgroundColor: colors.grey300,
    borderRadius: 8,
  },
  inputsViewWeb: {
    alignSelf: 'center',
  },
  bottomView: {
    alignItems: 'center',
    marginTop: 35,
    marginHorizontal: 16,
  },
  bottomViewWeb: {
    alignSelf: 'center',
  },
  cancelStyle: {
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: -0.38,
    textAlign: 'right',
    color: colors.brand,
    paddingHorizontal: 16,
  },
  topView: {
    margin: 16,
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
    borderRadius: 6,
    backgroundColor: colors.brandShadow,
  },
  label: {
    color: colors.grey600,
    marginVertical: 8,
    fontWeight: '600',
  },
  input: {
    paddingHorizontal: 10,
    outline: 'none',
    backgroundColor: colors.white,
    height: 40,
    borderRadius: 8,
  },
  inputWeb: {
    width: 400,
  },
  errorMsg: {
    marginVertical: 5,
    color: colors.buttons,
  },
  webButton: {
    width: 400,
  },
  buttonText: {
    paddingHorizontal: 25,
    fontWeight: '600',
    color: colors.white,
    textAlign: 'center',
  },
  donthaveAccText: {
    alignSelf: 'center',
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.textGrey,
    marginBottom: 8,
    fontWeight: '600',
  },
  helpView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10,
  },
  helpText: {
    fontWeight: '500',
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.brand,
  },
});
