import { StyleSheet, I18nManager } from 'react-native';
import { colors } from '../../common';
import { scale, vScale } from '../../services';

export default StyleSheet.create({
  headerContent: {
    alignItems: I18nManager.isRTL ? 'flex-start' : 'flex-end',
  },
  bodyContent: {
    marginVertical: vScale(26.7),
    alignItems: 'center',
  },
  button: {
    marginHorizontal: scale(15),
    marginBottom: vScale(10),
  },
  image: {
    width: scale(357),
    height: vScale(341),
    marginTop: vScale(34.4),
  },
  bodyHeaderText: {
    fontWeight: 'bold',
    lineHeight: 26,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
  bodyTextContainer: {
    marginTop: vScale(24),
    width: scale(357),
  },
  bodyText: {
    lineHeight: 22,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
});
