import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { isMobile, sWidth } from '../../services';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    height: isMobile ? 360 : 440,
    marginTop: 0,
    marginBottom: 16,
    justifyContent: 'center',
  },
  imageView: {
    maxWidth: sWidth,
    height: isMobile ? 360 : 440,
  },
  videoStyle: {
    height: isMobile ? 360 : 440,
    width: isMobile ? sWidth : 1232,
  },
  listContentContainer: {
    height: 100,
    alignItems: 'center',
    marginLeft: 8,
  },
  smallItemContainer: {
    width: 57,
    height: 57,
    marginHorizontal: 4,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.36,
    borderWidth: 1,
    borderColor: colors.paleGrey,
  },
  activeItemContainer: {
    opacity: 1,
    borderColor: colors.greenHover,
    shadowColor: colors.blueGrey100,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  smallItem: {
    width: 55,
    height: 55,
    borderRadius: 10,
  },
  iconContainer: {
    position: 'absolute',
    width: 44,
    height: 44,
    borderRadius: 22,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.shadowColor700,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  iconStyle: {
    width: 15,
    height: 15,
  },
  carouselContainer: {
    borderBottomWidth: 1,
    borderColor: colors.paleGrey,
    height: isMobile ? 360 : 440,
  },
});
