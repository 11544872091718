import React, { useState, useEffect, useLayoutEffect } from 'react';
import { View, ActivityIndicator, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import {
  useCheckout,
  types as useCheckoutTypes,
} from '../../redux/useCheckout';
import {
  actions as orderActions,
  types as orderTypes,
} from '../../redux/order';
import { colors, images } from '../../common';
import {
  Text,
  Image,
  CartTotal,
  AddressMap,
  AccordionInput,
  SecondaryButton,
  CartItemsLabel,
  PaymentProductItem,
  Screen,
  ModalContainer,
  DrawerWithBackIcons,
} from '../../components';
import styles from './style';
import { I18n } from '../../locales';
import {
  trackEcommercePurchase,
  // trackCheckoutCompleted,
} from '../../services/analytics';
// import NavigationService from '../../services/NavigationService';
import { IS_IOS } from '../../common/platform';
import { ApplePayButton } from '../../components/ApplePay';
import * as _ from 'lodash';
import { useCart } from '../../redux/useCart';
import { loadingSelector, showErrorMsg } from '../../common/utils';
import { IS_DEV_OTP } from '../../common/constants';
import OTP from '../OTP';
import {
  navigateToManageAddresses,
  navigateToSuccessOrder,
} from '../../services';
// import { navigateTo } from '../../services';

const Payment = ({ navigation }) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);
  const {
    cart,
    config: { country },
    order,
    user,
    loading,
    address,
    checkout,
    state,
    buttonLoading,
  } = useSelector((state) => {
    return {
      cart: state.cartState,
      config: state.config,
      order: state.order,
      user: state.user,
      loading: loadingSelector(state, [
        useCheckoutTypes.GET_PAYMENT_DETAILS,
        useCheckoutTypes.SET_PAYMENT_METHOD,
      ]),
      buttonLoading: loadingSelector(state, [
        orderTypes.CREATE_ORDER,
        orderTypes.GET_TAP_URL,
        orderTypes.GET_CREDIT_URL,
        orderTypes.GET_ORDER_INCREMENT_ID,
        orderTypes.APPLE_PAY_CREATE,
      ]),
      address: state.address,
      checkout: state.checkout,
      state,
    };
  });

  const mobile =
    user?.info?.custom_attributes && user?.info?.custom_attributes[0]?.value;
  const currency = country.currencyCode;
  const { prices, items = [], addonsItems = [] } = cart;
  const productsList = [...items, ...addonsItems];
  const {
    createOrder,
    startOnlineOrder,
    getIncrementIdOrderNumber,
    addCommentToOrder,
    getKnetURL,
    getWebViewURL,
    getOtherWebViewURL,
    applePayCreateCharge,
    restoreOrder,
    handleOrderResult,
  } = orderActions;

  const { available_payment_methods: originalPaymentMethods } = checkout || {};
  console.warn('state', state);
  const { getPaymentDetails, setPaymentMethod: setPaymentMethodOnCart } =
    useCheckout();
  const { clearCart_GQL } = useCart();
  const [orderComment, setOrderComment] = useState('');
  const [paymentMethod, setPaymentMethod] = useState({});
  const [shippingAmount, setShippingAmount] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    _getPaymentDetails();
  }, []);

  const _getPaymentDetails = async () => {
    try {
      const res = await getPaymentDetails();
      console.warn('resresres', res);
      setShippingAmount(
        res?.payload?.shipping_addresses[0]?.selected_shipping_method?.amount
          ?.value,
      );
    } catch (error) {
      console.log('_getPaymentDetails', error);
      showErrorMsg();
    }
  };

  const onSelectPayment = (option) => {
    setPaymentMethod(option);
    setPaymentMethodOnCart(option?.code);
  };

  const onCOD = (method = 'cashondelivery') => {
    const email = user?.info.email;
    setModalVisible(false);
    dispatch(createOrder(method, email)).then((resp) => {
      console.warn('resp', resp);
      if (resp.type === orderTypes.CREATE_ORDER.SUCCESS) {
        dispatch(getIncrementIdOrderNumber()).then(async () => {
          if (orderComment) {
            dispatch(addCommentToOrder(orderComment));
          }
          //should await Ackoo request before redirect to success page to let the request complete(not canceled)
          await trackEcommercePurchase(
            paymentMethod,
            {
              ...cart,
              currency,
            },
            order,
            shippingAmount,
          );

          navigateToSuccessOrder();

          clearCart_GQL();
        });
      } else {
        showErrorMsg(resp?.payload);
      }
    });
  };

  const handleApplePayResult = (transactionData) => {
    try {
      dispatch(
        handleOrderResult({ navigation, transactionData, paymentMethod }),
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  const processPayment = (tokenData) => {
    if (paymentMethod && paymentMethod.code) {
      const email = user?.info.email;
      const { code, additioncode } = paymentMethod;

      if (code === 'cashondelivery') {
        if (mobile) {
          IS_DEV_OTP ? onCOD() : setModalVisible(true);
        } else {
          showErrorMsg(I18n.t('newAddress.missingNumberSubTitle'));
        }
      } else if (code === 'knet') {
        dispatch(createOrder(code, email)).then((resp) => {
          if (resp.type === orderTypes.CREATE_ORDER.SUCCESS) {
            dispatch(startOnlineOrder());
            dispatch(getIncrementIdOrderNumber());
            if (orderComment) dispatch(addCommentToOrder(orderComment));
            dispatch(getKnetURL()).then((resp) => {
              location.assign(resp.payload?.url);
            });
          } else {
            showErrorMsg(resp?.payload);
          }
        });
      } else if (code === 'tap') {
        dispatch(createOrder(code, email)).then((resp) => {
          if (resp.type === orderTypes.CREATE_ORDER.SUCCESS) {
            dispatch(startOnlineOrder());
            dispatch(getIncrementIdOrderNumber());
            if (orderComment) dispatch(addCommentToOrder(orderComment));
            if (additioncode == 'Apple_Pay') {
              dispatch(applePayCreateCharge(tokenData)).then((res) => {
                console.log('res=>', res);
                const result = res?.payload[0];
                if (!_.isEmpty(result) && result.result) {
                  handleApplePayResult(result);
                } else {
                  dispatch(restoreOrder());
                }
              });
            } else {
              dispatch(getWebViewURL(additioncode)).then((resp) => {
                location.replace(
                  resp?.payload?.url || resp?.payload?.redirect?.url,
                );
              });
            }
          } else {
            showErrorMsg(resp?.payload);
          }
        });
      } else if (code === 'free') {
        onCOD('free');
      } else if (code) {
        dispatch(createOrder(code, email)).then((resp) => {
          if (resp.type === orderTypes.CREATE_ORDER.SUCCESS) {
            dispatch(startOnlineOrder());
            dispatch(getIncrementIdOrderNumber());
            if (orderComment) dispatch(addCommentToOrder(orderComment));
            dispatch(getOtherWebViewURL()).then((resp) => {
              location.replace(
                resp?.payload?.url || resp?.payload?.redirect?.url,
              );
            });
          } else {
            showErrorMsg(resp?.payload);
          }
        });
      }

      // trackCheckoutCompleted({
      //   checkout_id: cart.id,
      //   shipping_method: currency == 'KWD' ? 'local' : 'international',
      //   payment_method: paymentMethod.code,
      // });
    }
  };

  const onApplePayPress = async (tokenData) => {
    setPaymentMethod(applePayMethod);
    processPayment(tokenData);
  };

  const applePayMethod = _.find(originalPaymentMethods, {
    additioncode: 'Apple_Pay',
  });

  const isCODEnabled = _.find(originalPaymentMethods, {
    code: 'cashondelivery',
  });

  //if apple pay method exists remove it from the list because it have a different UI
  const paymentMethods = applePayMethod
    ? _.reject(originalPaymentMethods, { additioncode: 'Apple_Pay' })
    : originalPaymentMethods;

  console.warn('originalPaymentMethods', originalPaymentMethods);
  console.warn('checkout', checkout);
  console.warn('address', address);

  return (
    <Screen isLoading={loading}>
      {/* <ScrollView
        keyboardShouldPersistTaps={'handled'}
        showsVerticalScrollIndicator={false}
      > */}
      <ModalContainer
        animationType="slide"
        visible={modalVisible}
        setModalVisible={setModalVisible}
      >
        <OTP {...{ onCOD, setModalVisible }} />
      </ModalContainer>
      <CartItemsLabel
        label={I18n.t('products.title')}
        counter={productsList?.length}
      />
      {productsList?.map((item, index) => {
        return <PaymentProductItem key={index} item={item} />;
      })}

      {/*---------'Delivery Address'---------*/}
      {address && (
        <View style={styles.deliveryAddressContainer}>
          <View style={styles.deliveryAddress}>
            <Text size="large" style={styles.labelText}>
              {I18n.t('payment.deliveryAddressTitle')}
            </Text>
            <Text
              size="small"
              style={styles.changeText}
              onPress={() =>
                navigateToManageAddresses({
                  fromPayment: true,
                  isCheckout: true,
                  selectedAddress: address.selectedAddress,
                })
              }
            >
              {I18n.t('btns.change')}
            </Text>
          </View>

          <AddressMap
            isEditable={false}
            hideSelectButton
            data={address.selectedAddress}
          />
        </View>
      )}

      {/*---------'comment View'---------*/}
      <AccordionInput
        onCommentChange={(text) => setOrderComment(text)}
        comment={orderComment}
      />

      {/*---------'render payment method'---------*/}
      <View style={styles.paymentView}>
        <Text size="large" style={styles.labelText}>
          {I18n.t('payment.paymentMethodTitle')}
        </Text>
        {/* if COD is not enabled show the safety info View */}
        {!isCODEnabled && (
          <View style={styles.infoMessageContainer}>
            <Image
              source={images.safetyFireShield}
              style={styles.infoMessageIcon}
            />
            <View style={styles.infoMessageTextContainer}>
              <Text style={styles.infoMessageText}>
                {I18n.t('payment.infoMessage')}
              </Text>
            </View>
          </View>
        )}
        {paymentMethods?.length != 0 && (
          <View style={styles.paymentMethodsContainer}>
            {paymentMethods?.map((item, index) => {
              const { image, title } = item;
              const isSelected =
                paymentMethod?.code == item?.code &&
                paymentMethod?.additioncode == item?.additioncode;
              return (
                <TouchableOpacity
                  disabled={isSelected}
                  activeOpacity={0.9}
                  onPress={() => onSelectPayment(item)}
                  key={index}
                  style={[styles.pay, isSelected && styles.selectedPay]}
                >
                  {image ? (
                    <Image source={{ uri: image }} style={styles.payImage} />
                  ) : (
                    <Text size="small" style={styles.payTitle}>
                      {title}
                    </Text>
                  )}
                </TouchableOpacity>
              );
            })}
          </View>
        )}
      </View>
      {applePayMethod && IS_IOS && (
        <ApplePayButton
          onPress={(tokenData) => onApplePayPress(tokenData)}
          country={country}
          total={prices?.grand_total?.value.toString()}
        />
      )}

      <CartTotal
        currency={currency}
        totals={prices}
        shipping_amount={shippingAmount}
      />

      <View style={styles.endView}>
        <SecondaryButton
          text={
            buttonLoading || order.isPendingOrder ? (
              <ActivityIndicator color={colors.white} />
            ) : (
              I18n.t('btns.placeOrder')
            )
          }
          containerStyle={styles.ctaBtn}
          onPress={processPayment}
          disabled={
            buttonLoading ||
            order.isPendingOrder ||
            !(paymentMethod && paymentMethod.code)
          }
        />
      </View>
      {/* </ScrollView> */}
    </Screen>
  );
};

export default Payment;
