import { colors, utils } from '../../common';
import { vScale, scale } from '../../services';

export default {
  container: {
    // flex: 1,
    backgroundColor: colors.brand,
  },
  ctaBtn: {
    marginHorizontal: scale(16),
    flex: 1,
  },
  paymentMethodsContainer: {
    flexDirection: 'row',
  },
  pay: {
    flex: 1,
    paddingVertical: vScale(11),
    margin: scale(8),
    borderRadius: vScale(4),
    width: scale(101),
    minHeight: vScale(88),
    alignItems: 'center',
    borderWidth: scale(2),
    borderColor: colors.white,
    backgroundColor: colors.white,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: vScale(2),
    },
    shadowRadius: scale(4),
    shadowOpacity: 1,
  },
  selectedPay: {
    borderWidth: scale(2),
    borderColor: colors.greenA800,
  },
  payImage: {
    width: scale(40),
    height: scale(40),
    marginVertical: vScale(7),
  },
  payTitle: {
    fontWeight: 'bold',
    color: colors.grey700,
    textAlign: 'center',
  },
  labelText: {
    fontWeight: 'bold',
    color: colors.greyishBrown,
    padding: scale(16),
  },
  deliveryAddressContainer: {
    marginBottom: vScale(10),
  },
  deliveryAddress: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: vScale(16),
  },
  changeText: {
    color: colors.brand,
    letterSpacing: -0.38,
    padding: scale(16),
  },
  paymentView: {
    backgroundColor: 'white',
    shadowColor: colors.paleGrey,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 0,
    shadowOpacity: 1,
    marginVertical: 10,
  },
  endView: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignSelf: 'stretch',
    paddingVertical: vScale(16),
  },
  infoMessageContainer: {
    marginVertical: vScale(8),
    padding: scale(16),
    backgroundColor: colors.green000,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoMessageIcon: {
    width: scale(40),
    height: scale(40),
  },
  infoMessageTextContainer: {
    marginHorizontal: scale(8),
    flex: 1,
  },
  infoMessageText: {
    lineHeight: 22,
    letterSpacing: -0.38,
    fontWeight: '500',
    color: colors.green700,
  },
};
