import React from 'react';
import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
Icon.loadFont();
import Animated, { Easing } from 'react-native-reanimated';
import { mix, bin, useTransition } from 'react-native-redash';
const { not } = Animated;
import { colors } from '../../common';

const size = 30;
const styles = StyleSheet.create({
  container: {
    height: size,
    width: size,
    borderRadius: size / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ChevronAnimation = ({ open, iconColor }) => {
  const transition = useTransition(
    open,
    not(bin(open)),
    bin(open),
    400,
    Easing.inOut(Easing.ease),
  );
  const rotateZ = mix(transition, Math.PI, 0);
  return (
    <Animated.View style={[styles.container, { transform: [{ rotateZ }] }]}>
      <Icon
        name="chevron-down"
        color={iconColor || colors.warmGrey}
        size={24}
      />
    </Animated.View>
  );
};

export default ChevronAnimation;
