import React, { useState, useLayoutEffect } from 'react';
import { View } from 'react-native';
import { I18n } from '../../locales';
import styles from './styles';
import {
  Button,
  DrawerWithBackIcons,
  Input,
  Screen,
  Text,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/user';
import {
  loadingSelector,
  showErrorMsg,
  showSuccessMsg,
  validateUserPassword,
} from '../../common/utils';
import { isMobile, navigateTo } from '../../services';

const ChangePassword = ({ navigation }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    loadingSelector(state, [types.GET_USER_INFO, types.EDIT_USER_PROFILE]),
  );
  const inputs = {};
  const [state, setState] = useState({});
  const { currentPassword, newPassword } = state;
  const [errors, setErrors] = useState({});
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  // called when inputs changed
  const onChangeInput = (input, text) => setState({ ...state, [input]: text });

  //called when press on submit keyboard input to focus next one
  const focusNext = (id) => {
    if (inputs[id]) inputs[id].focus();
  };

  //save changes
  const save = () => {
    const errors = validateUserPassword(state);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    dispatch(
      actions.editUserProfile({
        new_password: newPassword,
        old_password: currentPassword,
      }),
    ).then((res) => {
      if (res.error) {
        showErrorMsg(res.payload);
      } else {
        showSuccessMsg(I18n.t('btns.editSuccessfully'));
        navigateTo('account');
      }
    });
  };

  return (
    <Screen isLoading={loading}>
      <View style={[styles.inputsView, !isMobile && styles.inputsViewWeb]}>
        <Text style={styles.label}>{I18n.t('btns.currentPassword')}</Text>
        <Input
          blurOnSubmit={false}
          ref={(i) => (inputs.c = i)}
          onSubmitEditing={() => focusNext('d')}
          onChangeText={(text) => onChangeInput('currentPassword', text)}
          value={currentPassword}
          returnKeyType="next"
          secureTextEntry
          style={[styles.input, !isMobile && styles.inputWeb]}
        />
        {errors.currentPassword && (
          <Text size="small" style={styles.errorMsg}>
            {errors.currentPassword}
          </Text>
        )}
        <Text style={styles.label}>{I18n.t('btns.newPassword')}</Text>
        <Input
          ref={(i) => (inputs.d = i)}
          textContentType="newPassword"
          autoCompleteType="password"
          onChangeText={(text) => onChangeInput('newPassword', text)}
          value={newPassword}
          returnKeyType="done"
          secureTextEntry
          style={[styles.input, !isMobile && styles.inputWeb]}
        />
        {errors.newPassword && (
          <Text size="small" style={styles.errorMsg}>
            {errors.newPassword}
          </Text>
        )}
      </View>
      <Button
        containerStyle={styles.btn}
        text={I18n.t('settings.Save')}
        onPress={() => save()}
      />
    </Screen>
  );
};

export default ChangePassword;
