import React from 'react';

import { View, TouchableOpacity } from 'react-native';
import styles from './styles';
import { colors } from '../../common';
import Text from '../Text';
import { Ionicons as Icon } from '@expo/vector-icons';

import * as _ from 'lodash';
import { I18n } from '../../locales';
import { isRTL } from '../../services';

export default class SelectProductOptions extends React.PureComponent {
  render() {
    const { options, selectedOption, onPress } = this.props;
    if (options && options.length > 0) {
      const variation = options[0]?.attributes[0]?.code;
      const product = _.find(
        options,
        (e) => e?.product?.sku == selectedOption?.sku,
      );
      const value = product?.attributes[0]?.label || null;
      return (
        <TouchableOpacity style={styles.itemContiner} onPress={onPress}>
          <View style={styles.textView}>
            <Text
              size="small"
              style={{ fontWeight: '500', color: colors.grey600 }}
            >
              {I18n.t('productDetails.select') +
                ' ' +
                I18n.t(`productDetails.${variation}`)}
            </Text>
            <Text
              size="small"
              style={{ fontWeight: '200', color: colors.grey600 }}
            >
              {value ||
                I18n.t('productDetails.selectYour') +
                  ' ' +
                  I18n.t(`productDetails.${variation}`)}
            </Text>
          </View>
          <Icon name="chevron-down" size={20} color={colors.darkIndigo} />
        </TouchableOpacity>
      );
    } else return null;
  }
}
