import { useRef } from 'react';
import { SEARCH_PRODUCTS_GATEWAY } from '../queries/products/searchProductsGateway';
import { useDispatch } from 'react-redux';
import { useAwaitQueryGateway } from '../queries/customHooks';
import { createAction, createTypes } from '../common/utils';
export const types = {
  SET_CHANGE_TEXT: 'SET_CHANGE_TEXT',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SEARCH_PRODUCTS: createTypes('SEARCH_PRODUCTS'),
  CLEAR_SEARCH: 'CLEAR_SEARCH',
};

export const useSearch = () => {
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const _searchProducts = useAwaitQueryGateway(SEARCH_PRODUCTS_GATEWAY);

  const searchProducts = async (search = '', currentPage = 1) => {
    const type = types.SEARCH_PRODUCTS;
    try {
      dispatch(createAction(type.REQUEST));
      const { data, loading } = await _searchProducts({
        variables: { search, currentPage },
      });
      if (!loading && data.search) {
        dispatch(
          createAction(type.SUCCESS, {
            items: data?.search?.items,
            total_pages: data?.search?.page_info?.total_pages,
          }),
        );
      } else {
        dispatch(createAction(type.ERROR));
      }
    } catch (e) {
      console.log('error ', e);
      dispatch(createAction(type.ERROR));
    }
  };

  const changeText = (text) => {
    dispatch({
      type: types.SET_CHANGE_TEXT,
      payload: text,
    });
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    if (Boolean(text.length)) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        searchProducts(text);
      }, 400);
    }
  };

  const changeCurrentPage = (text, page) => {
    dispatch({
      type: types.SET_CURRENT_PAGE,
      payload: page,
    });
    searchProducts(text, page);
  };

  const clearSearch = () => {
    dispatch({
      type: types.CLEAR_SEARCH,
    });
  };

  return {
    changeText,
    changeCurrentPage,
    searchProducts,
    clearSearch,
  };
};

const initialState = {
  data: [],
  text: '',
  currentPage: 1,
  total_pages: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CHANGE_TEXT:
      return {
        ...state,
        text: action.payload,
        currentPage: 1,
        total_pages: null,
      };
    case types.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case types.SEARCH_PRODUCTS.SUCCESS:
      return {
        ...state,
        data: action.payload.items,
        total_pages: action.payload.total_pages,
      };
    case types.CLEAR_SEARCH:
      return initialState;
    default:
      return state;
  }
};
