import React from 'react';
import { View, Platform, StyleSheet, StatusBar } from 'react-native';
import PropTypes from 'prop-types';
import { config } from '../../common';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { colors } from '../../common';

// See: https://github.com/react-navigation/react-navigation/blob/master/src/views/Header/Header.js
// Issue: https://github.com/react-navigation/react-navigation/issues/842
// Also beneficial to directly provide props.
// Only support portrait mode.
const APPBAR_HEIGHT = Platform.OS === 'ios' ? 44 : 56;
const STATUSBAR_HEIGHT = Platform.OS === 'ios' ? 20 : 0;

import Text from '../Text';
import { isMobile } from '../../services';

export default class NavHead extends React.Component {
  static propTypes = {
    title: PropTypes.node,
    headerBackground: PropTypes.node,
    headerLeft: PropTypes.element,
    headerRight: PropTypes.element,
    headerBottom: PropTypes.node,
    // headerStyle: ViewPropTypes.style,
    // containerStyle: ViewPropTypes.style,
    textColor: PropTypes.string,
    withoutShadow: PropTypes.bool,
  };

  static get HEIGHT() {
    return APPBAR_HEIGHT + STATUSBAR_HEIGHT;
  }

  renderTitle = () => {
    const { title, textColor } = this.props;
    if (typeof title === 'string')
      return (
        <Text
          style={{
            textAlign: 'center',
            fontWeight: '600',
            color: textColor || colors.black,
          }}
        >
          {title}
        </Text>
      );
    else return title;
  };

  renderHeaderRight = () => {
    const { headerRight } = this.props;
    return <View style={styles.headerRight}>{headerRight}</View>;
  };

  renderHeaderLeft = () => {
    const { headerLeft } = this.props;
    return <View style={styles.headerLeft}>{headerLeft}</View>;
  };

  render() {
    const {
      headerBackground,
      headerBottom,
      containerStyle,
      headerStyle,
      title,
      withoutShadow,
    } = this.props;
    return (
      <View
        style={[
          styles.container,
          { shadowOpacity: withoutShadow ? 0 : 1 },
          containerStyle,
        ]}
      >
        <View style={StyleSheet.absoluteFill}>{headerBackground}</View>
        <View style={[styles.headerContainer, headerStyle]}>
          {this.renderHeaderLeft()}
          {title ? (
            <View style={styles.title}>{this.renderTitle()}</View>
          ) : null}
          {this.renderHeaderRight()}
        </View>
        {headerBottom}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderBottomColor: colors.grey110,
    ...Platform.select({
      android: {
        marginTop: config.statusBar ? StatusBar.currentHeight : 0,
        paddingVertical: 10,
      },
      ios: {
        paddingTop: isMobile ? 38 : 20,
      },
    }),
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 5,
    shadowOpacity: 1,
    ...Platform.select({
      android: {
        elevation: 0.4,
        shadowColor: colors.black,
      },
    }),
  },
  title: {
    flex: 1,
    alignItems: 'center',
    width: wp('10%'),
  },
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerRight: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingHorizontal: 15,
    width: wp('25%'),
    height: hp('10%'),
  },
  headerLeft: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingHorizontal: 15,
    width: wp('25%'),
    height: hp('10%'),
  },
});
