import React, { useState } from 'react';
import { FlatList, View, TouchableOpacity, StyleSheet } from 'react-native';
import { Text } from '..';
import * as _ from 'lodash';
import { colors } from '../../common';
import { convertRGBToRGBA } from '../../common/utils';
import { I18n } from '../../locales';
export const Pagination = ({ total_pages, onPress }) => {
  const pages = Array.from({ length: total_pages }, (e, i) => i + 1);
  const [activePage, setActivePage] = useState(1);
  const hideFixedNumsAndDots = total_pages <= 4;
  const listPages = hideFixedNumsAndDots
    ? pages
    : pages?.slice(1, -1).slice(0, 3);
  const [list, setList] = useState(listPages || []);
  const firstItem = activePage == 1;
  const lastItem = total_pages == activePage;
  const PaginationButton = ({ item, onPress, isSelected }) => {
    return (
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={onPress}
        style={[styles.container, isSelected && styles.isSelectedContainer]}
      >
        <Text type={isSelected && 'regularAction'}>{item}</Text>
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item }) => {
    const isSelected = activePage == item;
    const hideFirstAndLastItems = item >= total_pages || item == pages[0];
    if (hideFirstAndLastItems && !hideFixedNumsAndDots) return null;
    return (
      <PaginationButton
        {...{ item, isSelected }}
        onPress={() => {
          setActivePage(item);
          onPress(item);
          generateList(item);
        }}
      />
    );
  };

  const generateList = (item) => {
    if (hideFixedNumsAndDots) return;
    else {
      if (item == total_pages) {
        setList([item - 2, item - 1, item]);
      } else if ((item || activePage) == 1) {
        setList(pages.slice(1, -1).slice(0, 3));
      } else {
        setList([item - 1, item, item + 1]);
      }
    }
  };

  const onNext = () => {
    if (!lastItem) {
      onPress(activePage + 1);
      setActivePage(activePage + 1);
      generateList(activePage + 1);
    }
  };

  const onPrevious = () => {
    if (!firstItem) {
      onPress(activePage - 1);
      setActivePage(activePage - 1);
      generateList(activePage - 1);
    }
  };

  return (
    <FlatList
      style={styles.list}
      data={list}
      renderItem={renderItem}
      keyExtractor={(item, index) => index.toString()}
      showsHorizontalScrollIndicator={false}
      horizontal
      ListHeaderComponent={() => {
        return (
          <View style={{ flexDirection: 'row' }}>
            <PaginationButton
              item={I18n.t('pagination.previous')}
              onPress={onPrevious}
            />
            {total_pages > 4 && (
              <>
                <PaginationButton
                  isSelected={firstItem}
                  item={1}
                  onPress={() => {
                    setActivePage(1);
                    onPress(1);
                    generateList(1);
                  }}
                />
                {!list?.includes(2) && (
                  <Text size="small" style={{ alignSelf: 'center' }}>
                    ...
                  </Text>
                )}
              </>
            )}
          </View>
        );
      }}
      ListFooterComponent={() => {
        return (
          <View style={{ flexDirection: 'row' }}>
            {total_pages > 4 && (
              <>
                {!list?.includes(total_pages) && (
                  <Text size="small" style={{ alignSelf: 'center' }}>
                    ...
                  </Text>
                )}
                <PaginationButton
                  isSelected={lastItem}
                  item={total_pages}
                  onPress={() => {
                    setActivePage(total_pages);
                    onPress(total_pages);
                    generateList(total_pages);
                  }}
                />
              </>
            )}
            <PaginationButton
              item={I18n.t('pagination.next')}
              onPress={onNext}
            />
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    alignSelf: 'center',
    paddingBottom: 20,
  },
  container: {
    minWidth: 44,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 22,
    borderWidth: 1,
    borderColor: convertRGBToRGBA(colors.darkIndigo, 0.05),
    paddingHorizontal: 10,
    marginHorizontal: 8,
  },
  isSelectedContainer: {
    backgroundColor: convertRGBToRGBA(colors.selection, 0.1),
    borderColor: colors.selection,
  },
  separator: {
    width: 16,
  },
});
