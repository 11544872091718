import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Text, Image } from '..';
import { colors, images } from '../../common';
import { convertRGBToRGBA } from '../../common/utils';
import { I18n } from '../../locales';

export const WrapAsGift = ({ onPress }) => {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.titleStyle}>{I18n.t('wrapAsGift.wrapAsGift')}</Text>
        <Text style={styles.subTitle}>{I18n.t('wrapAsGift.subTitle')}</Text>
        <Button
          onPress={onPress}
          containerStyle={styles.button}
          text={I18n.t('cart.giftWrap.addWrapping')}
        />
      </View>
      <Image
        source={images.wrapPlaceholder}
        style={styles.imageStyle}
        resizeMode="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 819,
    height: 219,
    flexDirection: 'row',
    borderRadius: 10,
    backgroundColor: colors.white,
    shadowColor: convertRGBToRGBA(colors.black, 0.02),
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 20,
    shadowOpacity: 1,
    paddingStart: 50,
    justifyContent: 'space-between',
    alignItems: 'center',
    marignTop: 10,
    marginBottom: 44,
  },
  titleStyle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.darkIndigo,
  },
  subTitle: {
    marginVertical: 9,
    fontSize: 14,
    fontWeight: '500',
    color: convertRGBToRGBA(colors.darkIndigo, 0.5),
  },
  button: {
    width: 160,
    height: 44,
    borderRadius: 10,
    backgroundColor: colors.greenMain,
    shadowColor: convertRGBToRGBA(colors.selection, 0.5),
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 20,
    shadowOpacity: 1,
  },
  imageStyle: {
    width: 440,
    height: 182,
  },
});
