import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { normalizeSize } from '../../common/utils';
import { scale, vScale } from '../../services';

export default StyleSheet.create({
  scrollStyle: {
    padding: scale(16),
  },
  innerInput: {
    fontWeight: '500',
    letterSpacing: 0,
    color: colors.greyishBrown,
    fontSize: normalizeSize(12.5),
  },
  input: {
    paddingHorizontal: 10,
    outline: 'none',
    backgroundColor: colors.white,
    height: 40,
    borderRadius: 8,
  },
  inputWeb: {
    width: 400,
  },
  inputsView: {
    marginHorizontal: 10,
    padding: 16,
    backgroundColor: colors.grey300,
    borderRadius: 8,
  },
  inputsViewWeb: {
    alignSelf: 'center',
  },
  errorMsg: {
    marginVertical: 5,
    color: colors.buttons,
  },
  label: {
    color: colors.grey600,
    marginVertical: 8,
    fontWeight: '600',
  },
  nameView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  changePassword: {
    paddingVertical: vScale(5),
    borderColor: colors.grey300,
    borderBottomWidth: 1,
  },
  rowView: {
    flexDirection: 'row',
    marginTop: vScale(5),
    paddingVertical: vScale(3),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
