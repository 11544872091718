import React, { Component, Fragment } from 'react';
import { View, FlatList } from 'react-native';
import { ShimmerPlaceHolder } from './index';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styles from './styles';
import { colors } from '../../common';
import { isMobile } from '../../services';

export default class Shimmer extends Component {
  static propTypes = {
    style: PropTypes.object,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
    loading: false,
  };

  renderItem = () => {
    const { style, loading } = this.props;
    return (
      <Fragment>
        <ShimmerPlaceHolder
          autoRun
          style={[styles.card, style]}
          visible={!loading}
          colorShimmer={colors.brandShimm3}
        />
      </Fragment>
    );
  };
  render() {
    return (
      <View style={styles.container}>
        <FlatList
          numColumns={isMobile ? 2 : 5}
          data={_.range(this.props.num)}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          contentContainerStyle={{
            alignItems: 'center',
          }}
        />
      </View>
    );
  }
  keyExtractor = (index) => {
    return `${index}`;
  };
}
