import React, { useEffect, useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styles from './style';
import { I18n } from '../../locales';
import { SearchBar as SearchInput } from 'react-native-elements';
import { IS_ANDROID } from '../../common/platform';
import { colors } from '../../common';

const SearchBar = ({
  onPress,
  containerStyle,
  disabled,
  onRef = () => {},
  ...props
}) => {
  const searchRef = useRef();
  useEffect(() => {
    onRef(searchRef);
  }, []);

  return (
    <TouchableOpacity
      {...{ onPress }}
      activeOpacity={1}
      style={styles.containerStyle}
    >
      <SearchInput
        ref={searchRef}
        pointerEvents={disabled && 'none'}
        onTouchStart={onPress}
        placeholder={I18n.t('placeholders.searchToys')}
        placeholderTextColor={colors.grey600}
        // containerStyle={[styles.containerStyle, containerStyle]}
        inputContainerStyle={[styles.inputContainerStyle]}
        autoCorrect={false}
        spellCheck={false}
        autoCapitalize={'none'}
        cancelButtonTitle={I18n.t('btns.cancel')}
        platform={IS_ANDROID ? 'android' : 'ios'}
        inputStyle={styles.inputStyle}
        lightTheme
        clearIcon={true}
        {...props}
      />
    </TouchableOpacity>
  );
};

SearchBar.propTypes = { onPress: PropTypes.func };

export default SearchBar;
