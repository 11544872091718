import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const REMOVE_ITEM_FROM_CART = gql`
  mutation($cartId: String!, $cart_item_id: Int!) {
    removeItemFromCart(
      input: { cart_id: $cartId, cart_item_id: $cart_item_id }
    ) {
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`;
