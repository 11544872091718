import { gql } from '@apollo/client';

export const PRODUCT_BY_SKU = gql`
  query($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        id
        name
        sku
        product_brand
        stock_status
        shipping_message
        weight
        age
        only_x_left_in_stock
        attribute_set_name
        brand
        brand_name
        video
        media_gallery {
          url
        }
        description {
          html
        }
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
          }
        }
        delivery_message
        ts_dimensions_height
        ts_dimensions_length
        ts_dimensions_width
        delivery_day
        gender
        price_range {
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        special_price
        small_image {
          url
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              ... on CustomizableProductInterface {
                options {
                  title
                  required
                  sort_order
                  option_id
                }
              }
              related_products {
                id
                name
                sku
                product_brand
                price_range {
                  maximum_price {
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                    }
                    discount {
                      amount_off
                      percent_off
                    }
                  }
                }
                special_price
                small_image {
                  url
                }
              }
              id
              name
              sku
              product_brand
              stock_status
              weight
              age
              shipping_message
              only_x_left_in_stock
              brand
              brand_name
              media_gallery {
                url
              }
              description {
                html
              }
              delivery_message
              delivery_day
              gender
              price_range {
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
              special_price
              small_image {
                url
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
        related_products {
          id
          name
          sku
          product_brand
          price_range {
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
              }
              discount {
                amount_off
                percent_off
              }
            }
          }
          special_price
          small_image {
            url
          }
        }
      }
    }
  }
`;
