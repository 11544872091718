import React, { useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { Footer } from '..';
import { isMobile, navigateToProducts } from '../../services';
import styles from './style';
import { FixedHeader } from '../FixedHeader';
import { useCategories } from '../../redux/useCategories';
import { I18n } from './../../locales';
import { useDispatch, useSelector } from 'react-redux';
import { useDevices } from '../../services/devices';
import { actions as configActions } from '../../redux/config';
import { countries } from '../../common';
import { ModalContainer } from '../ModalContainer';
import Spinner from '../Spinner';

const Screen = ({
  style,
  containerStyle,
  children,
  showSearch,
  isSearchPage,
  isLoading = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const { config } = useSelector((state) => {
    return {
      config: state?.config,
    };
  });
  const onBrandPress = (item, component_type) => {
    const queryData = {
      id: item.id,
      title: item.title,
      component_type: component_type,
      type: 'brand',
    };
    navigateToProducts({ brand: item?.target?.id }, queryData);
  };

  const { getSubcategories } = useCategories();
  useEffect(() => {
    if (Object.keys(config.country).length == 0) {
      dispatch(configActions.setCountry(countries[0]));
    }
    I18n.locale = config.lang;
    getSubcategories();
  }, []);
  const navigateToCategoryDetails = (
    categoryId,
    type,
    title,
    component_type,
    parentId,
  ) => {
    const queryData = {
      id: categoryId,
      title,
      component_type,
      type,
      parentId: parentId || categoryId,
    };
    navigateToProducts({ category_id: categoryId }, queryData);
  };
  const ComponentContainer = isMobile ? ScrollView : View;

  const { isTabletOrMobile } = useDevices();
  return (
    <ComponentContainer showsVerticalScrollIndicator={false}>
      <View style={[styles.container, style]} props>
        <ModalContainer visible={isLoading}>
          <Spinner visible />
        </ModalContainer>
        <FixedHeader
          {...{ showSearch, navigateToCategoryDetails, isSearchPage }}
        />
        <View
          style={[
            !isTabletOrMobile && styles.childrenContainer,
            containerStyle,
          ]}
        >
          {children}
        </View>
        {!isMobile && (
          <Footer
            onBrandPress={onBrandPress}
            onCategoryPress={navigateToCategoryDetails}
          />
        )}
      </View>
    </ComponentContainer>
  );
};

export default Screen;
