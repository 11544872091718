import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query (
    $category_id: String!
    $age: [String]!
    $priceFrom: String!
    $priceTo: String!
    $gender: String!
    $brand: String!
    $currentPage: Int!
    $sort: ProductAttributeSortInput!
    $pageSize: Int!
  ) {
    products(
      filter: {
        category_id: { eq: $category_id }
        age: { in: $age }
        gender: { eq: $gender }
        price: { from: $priceFrom, to: $priceTo }
        brand: { eq: $brand }
      }
      sort: $sort
      currentPage: $currentPage
      pageSize: $pageSize
    ) {
      page_info {
        total_pages
      }
      items {
        id
        name
        sku
        product_brand
        brand_name
        brand
        only_x_left_in_stock
        stock_status
        price_range {
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        special_price
        small_image {
          url
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              ... on CustomizableProductInterface {
                options {
                  title
                  required
                  sort_order
                  option_id
                }
              }
              related_products {
                id
                name
                sku
                product_brand
                price_range {
                  maximum_price {
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                    }
                    discount {
                      amount_off
                      percent_off
                    }
                  }
                }
                special_price
                small_image {
                  url
                }
              }
              id
              name
              sku
              product_brand
              stock_status
              weight
              age
              shipping_message
              only_x_left_in_stock
              brand
              brand_name
              media_gallery {
                url
              }
              description {
                html
              }
              delivery_message
              delivery_day
              gender
              price_range {
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
              special_price
              small_image {
                url
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;
