import React from 'react';
import { View, StyleSheet, Modal } from 'react-native';
import { Button, Image, Text } from '..';
import { colors, images } from '../../common';
import I18n from '../../locales/i18n';

export const DeleteModal = (props) => {
  const { title, subTitle, onClose, isVisible, onPress } = props;
  return (
    <Modal
      animationType={'slide'}
      transparent
      onRequestClose={onClose}
      visible={isVisible}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.deleteIconContainer}>
            <Image
              source={images.delete}
              resizeMode="contain"
              style={styles.imageStyle}
            />
          </View>
          <Text style={styles.headerText} numberOfLines={1}>
            {title}
          </Text>
        </View>
        <View style={styles.divider} />
        <Text size="small" style={styles.text}>
          {subTitle}
        </Text>
        <View style={styles.buttonsContainer}>
          <Button
            text={I18n.t('DeleteModal.delete')}
            containerStyle={styles.button}
            onPress={onPress}
          />
          <Button
            text={I18n.t('DeleteModal.back')}
            textStyle={styles.cancelText}
            containerStyle={[styles.button, styles.cancelButton]}
            onPress={onClose}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    width: 291,
    alignSelf: 'center',
    borderRadius: 16,
    backgroundColor: colors.white,
    shadowColor: colors.shadowColor600,
    paddingHorizontal: 16,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 24,
    shadowOpacity: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 21,
  },
  deleteIconContainer: {
    width: 29,
    height: 29,
    borderRadius: 14.5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.greenSecondary,
    marginEnd: 8,
  },
  imageStyle: {
    width: 16,
    height: 16,
  },
  headerText: {
    flex: 1,
    fontWeight: 'bold',
    color: colors.textGrey,
  },
  divider: {
    width: 291,
    height: 2,
    alignSelf: 'center',
    backgroundColor: colors.grey100,
    marginBottom: 16,
  },
  text: {
    fontWeight: 'normal',
    color: colors.textGrey,
  },
  buttonsContainer: {
    flexDirection: 'row',
    width: 259,
    paddingVertical: 16,
    justifyContent: 'space-between',
  },
  button: {
    width: 123,
    height: 40,
    borderRadius: 8,
    backgroundColor: colors.danger,
  },
  cancelButton: {
    backgroundColor: colors.greenSecondary,
  },
  cancelText: {
    color: colors.danger,
  },
});
