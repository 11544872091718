import React, { useLayoutEffect } from 'react';
// import Intercom from 'react-native-intercom';
import * as _ from 'lodash';
import { CartIcon, DrawerWithBackIcons, HeaderButton } from '../../components';
import { images } from '../../common';
import { I18n } from '../../locales';
import UserProfile from '../UserProfile';
import { useSelector } from 'react-redux';
import Login from '../Login';
import { navigateTo } from '../../services';
import { View } from 'react-native';

// eslint-disable-next-line react/display-name
const AccountScreen = ({ navigation }) => {
  const user = useSelector((state) => state.user);
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: '',
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <HeaderButton
          elements={[
            { element: <CartIcon /> },
            {
              icon: images.settings,
              onPress: () => navigateTo('settings'),
              text: I18n.t('profile.settings'),
            },
          ]}
        />
      ),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  // const onIntercom = () => {
  //   trackScreenName('LiveSupport');
  //   Intercom.logEvent('viewed_screen', { extra: 'metadata' });
  //   Intercom.displayConversationsList();
  // };

  const isLoggedIn = _.get(user, 'info.id');
  return (
    <View>
      {isLoggedIn ? <UserProfile /> : <Login navigation={navigation} />}
    </View>
  );
};

export default AccountScreen;
