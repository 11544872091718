import React, { useState, Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import { ChevronAnimation, CustomInput, Text } from '../index';
import { I18n } from '../../locales';
import { colors } from '../../common';
import Accordion from 'react-native-collapsible/Accordion';

const AccordionInput = ({
  comment,
  onCommentChange,
  ref,
  returnKeyType,
  title,
}) => {
  const [activeSections, setActiveSections] = useState([]);

  const renderHeader = (section, index, isActive) => {
    return (
      <View style={styles.deliverySlotsHeader}>
        <Text size="small" style={styles.smallText}>
          {title || I18n.t('payment.yourComments')}
        </Text>
        <ChevronAnimation open={isActive} iconColor={colors.brand} />
      </View>
    );
  };

  const renderContent = section => {
    return (
      <>
        <Text size="smaller" style={styles.optionText}>
          ({I18n.t('payment.optional')})
        </Text>
        <CustomInput
          multiline
          blurOnSubmit
          value={comment}
          onChangeText={onCommentChange}
          containerStyle={styles.commentBox}
          activeContainerStyle={{ borderColor: colors.brandGrey }}
          ref={ref}
          returnKeyType={returnKeyType}
        />
      </>
    );
  };

  const updateSections = activeSections => {
    setActiveSections(activeSections);
  };

  return (
    <Fragment>
      <Accordion
        touchableProps={{
          activeOpacity: 1,
          underlayColor: 'transparent',
        }}
        sections={[1]}
        activeSections={activeSections}
        renderHeader={renderHeader}
        renderContent={renderContent}
        onChange={updateSections}
      />
    </Fragment>
  );
};

export default AccordionInput;

const styles = StyleSheet.create({
  deliverySlotsHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 16,
  },
  commentBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.borderColor,
    marginHorizontal: 15,
    height: 120,
    backgroundColor: colors.button1,
  },
  smallText: {
    letterSpacing: -0.34,
    color: colors.brand,
    fontWeight: '500',
  },
  optionText: {
    textAlign: 'right',
    color: colors.warmGrey,
    marginHorizontal: 16,
    marginBottom: 3,
  },
});
