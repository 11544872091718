import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  header: { marginBottom: 10 },
  btnContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    overflow: 'hidden',
    fontWeight: '600',
  },
  imageView: {
    marginBottom: 10,
    borderRadius: 6,
    borderWidth: 1,
    overflow: 'hidden',
  },
  image: {
    width: 135,
    height: 200,
  },
  btn: {
    width: 100,
    alignSelf: 'center',
    marginVertical: 10,
  },
});
