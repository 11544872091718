import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { convertRGBToRGBA } from '../../common/utils';
export default StyleSheet.create({
  container: {
    width: 816,
    height: 160,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingStart: 20,
    marginBottom: 8,
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 10,
    marginEnd: 24,
  },
  dataContainer: {
    flex: 1,
    height: 160,
    justifyContent: 'space-between',
    paddingVertical: 32,
  },
  viewRow: {
    width: 140,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  brandName: {
    fontSize: 16,
    fontWeight: '500',
    // color: convertRGBToRGBA(colors.darkIndigo, 0.3),
  },
  productName: {
    fontSize: 18,
    fontWeight: '500',
    color: colors.darkIndigo,
  },
  oldPriceStyle: {
    fontSize: 13,
    color: colors.darkIndigo,
    opacity: 0.3,
    marginBottom: 5,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  priceStyle: {
    fontSize: 20,
    fontWeight: '600',
  },
  specialPriceStyle: {
    color: colors.lipstick,
  },
  deliveryMessage: {
    color: colors.grey120,
  },
  textWarning: {
    color: colors.red600,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  qytButton: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: convertRGBToRGBA(colors.darkIndigo, 0.05),
  },
  disabledStyle: {
    backgroundColor: colors.grey130,
  },
  qtyContainer: {
    paddingHorizontal: 4,
    justifyContent: 'center',
  },
  qtyText: {
    color: colors.black,
    maxWidth: 80,
    fontWeight: 'bold',
  },
  outOfStockOpacity: {
    opacity: 0.5,
  },
  deleteButton: {
    position: 'absolute',
    width: 30,
    height: 30,
    top: 15,
    right: 27,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  deleteImage: {
    width: 20,
    height: 22,
  },
  priceContainer: {
    alignSelf: 'flex-end',
    paddingVertical: 34,
    paddingHorizontal: 27,
  },
  border: {
    borderWidth: 1,
    backgroundColor: colors.purpleSecondary,
  },
  spinner: {
    width: 816,
    height: 160,
    backgroundColor: colors.overlayColor,
    zIndex: 2,
    ...StyleSheet.absoluteFillObject,
  },
});
