import { gql } from '@apollo/client';
import { CheckoutFragment } from './CheckoutFragment';

export const SET_BILLING_ADDRESS = gql`
  mutation setBillingAddressOnCart(
    $cartId: String!
    $address: CartAddressInput!
    $same_as_shipping: Boolean!
  ) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cartId
        billing_address: {
          address: $address
          same_as_shipping: $same_as_shipping
        }
      }
    ) {
      cart {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
