import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.button1,
  },
  loginSignupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 8,
    marginTop: 24,
    marginBottom: 30,
  },
  notUserView: {
    flex: 1,
    alignSelf: 'stretch',
  },
  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 8,
    flex: 1,
    alignSelf: 'stretch',
  },
  profileTitle: { marginBottom: 10 },

  langView: {
    margin: -15,
    flexDirection: 'row',
    padding: 15,
    borderTopWidth: 1,
    borderColor: colors.grey300,
  },
  langText: {
    borderRightWidth: 1,
    width: '50%',
    borderColor: colors.grey300,
  },
  supportImage: { width: 32, height: 32 },
  cardBtn: {
    flex: 1,
    minHeight: 137,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginHorizontal: 8,
    shadowColor: colors.grey500,
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    ...Platform.select({
      android: {
        elevation: 3,
        shadowColor: colors.black,
      },
    }),
    borderRadius: 4,
  },
  dialogContainer: {
    minHeight: 220,
    borderRadius: 8,
    backgroundColor: colors.white,
    padding: 15,
  },

  listItem: {
    padding: 15,
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'center',
    borderColor: colors.grey300,
    justifyContent: 'space-between',
  },
  listItemInnerView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  quantityOuter: {
    width: 24,
    height: 24,
    backgroundColor: colors.brand,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -10,
    right: -10,
  },
  quantityNumber: {
    fontWeight: '500',
    color: colors.white,
    // bottom: I18nManager.isRTL ? 9 : 0
  },
  history: {
    margin: 15,
    borderWidth: 1,
    borderColor: colors.grey200,
  },
  orderHestory: {
    margin: 15,
    borderWidth: 1,
    borderColor: colors.grey200,
  },
  imageProfile: {
    width: 56,
    height: 56,
    marginHorizontal: 8,
  },
  titleText: {
    marginTop: 15,
    letterSpacing: 0,
    fontWeight: '600',
    textAlign: 'center',
  },
  descView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  descText: {
    letterSpacing: 0,
    lineHeight: 9,
    marginTop: 10,
    color: colors.textGrey,
    textAlign: 'center',
  },
  notUserText: {
    lineHeight: 32,
    marginTop: 10,
  },
  continueBtn: {
    borderRadius: 6,
    backgroundColor: colors.white,
    shadowColor: colors.shadowBtn,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 14,
    shadowOpacity: 1,
    marginTop: 15,
    marginBottom: 24,
    alignSelf: 'stretch',
  },
  textBtnStyle: {
    color: colors.greyishBrown,
  },
  textWithEmail: {
    textShadowColor: colors.greenShadow,
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 0,
    fontWeight: '500',
  },
  input: {
    alignSelf: 'stretch',
    marginVertical: 10,
  },
  seeAllStyle: {
    backgroundColor: colors.button1,
  },
  versionNumberText: { margin: 10 },
  logoutButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
    paddingHorizontal: 12,
  },
  logoutImage: {
    width: 20,
    height: 20,
    tintColor: colors.lipstick,
    marginHorizontal: 6,
  },
  logoutText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.lipstick,
  },
});
