const IS_DEVELOPMENT = false;
// const IS_DEVELOPMENT = true;
export const SERVER_URL = IS_DEVELOPMENT
  ? 'https://stgprod.teeela.com'
  : // ? 'https://devmage.teeela.com'
    'https://www.teeela.com';
export const MAGENTO_API_URL = `${SERVER_URL}/rest`;
// export const MAGENTO_API_URL = 'https://www.teeela.com/rest';

export const GATEWAY_URL = IS_DEVELOPMENT
  ? 'https://staging-dot-teeela-algolia-graphql.oa.r.appspot.com/graphql'
  : 'https://teeela-algolia-graphql.oa.r.appspot.com/graphql';

export const HOME_BUILDER_URL = IS_DEVELOPMENT
  ? 'https://pb-stage.teeela.com'
  : 'https://pb-main.teeela.com';

export const youtubeUrl =
  'https://www.youtube.com/channel/UCI1GreKnVERq5DvbeDRKpXA';
export const instagramUrl = 'https://www.instagram.com/teeela/';
export const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.teeelamob';
export const appStoreUrl = 'https://apps.apple.com/us/app/teeela/id1382234529';

const getUrl = window.location;
export const baseUrl = getUrl.protocol + '//' + getUrl.host;

export const GOOGLE_API_KEY = 'AIzaSyAZJNuVmH5nQQCVdyyIXnv6aAQbb3jzpqk';

export const IS_DEV_OTP = false;

export const TAP_BASE_URL = `${SERVER_URL}/rest/en/V1/teeela-tap`;

const TAP_SANDBOX_TOKEN = 'Bearer sk_test_5lnTBxghrvMSJNGibWj6u7oU';
const TAP_PRODUCTION_TOKEN = 'Bearer sk_live_9hyIXqjJtl5BD0W6TEpoN2av';
export const TAP_TOKEN = IS_DEVELOPMENT
  ? TAP_SANDBOX_TOKEN
  : TAP_PRODUCTION_TOKEN;
