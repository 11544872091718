import React from 'react';
import {
  View,
  StyleSheet,
  I18nManager,
  ViewPropTypes as RNViewPropTypes,
  TextInput,
} from 'react-native';
import PropTypes from 'prop-types';
import { colors } from '../../common';
// import { TextField } from 'react-native-material-textfield';
import { normalizeSize } from '../../common/utils';

const ViewPropTypes = RNViewPropTypes || View.propTypes;

export default class Input extends React.Component {
  static propTypes = {
    // containerStyle: ViewPropTypes.style,
    // style: ViewPropTypes.style,
    errorMessage: PropTypes.string,
    label: PropTypes.string,
  };
  static defaultProps = {
    placeholder: '',
  };
  state = {
    isFocused: false,
    value: '',
  };

  focus = () => {
    this.input.focus();
  };

  blur = () => {
    this.input.blur();
  };

  isFocused = () => {
    return this.input.isFocused();
  };

  onFocus = () => {
    this.setState({ isFocused: true });
    this.props.onFocus && this.props.onFocus();
  };

  onBlur = () => {
    this.setState({ isFocused: false });
    this.props.onBlur && this.props.onBlur();
  };

  render() {
    const {
      containerStyle,
      errorMessage,
      style,
      label,
      value,
      ...rest
    } = this.props;
    return (
      <TextInput
        {...rest}
        label={label}
        ref={(input) => (this.input = input)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        autoCorrect={false}
        autoCapitalize={'none'}
        underlineColorAndroid="transparent"
        style={[styles.input, style]}
        containerStyle={[styles.containerStyle, containerStyle]}
        tintColor={colors.warmGrey}
        error={errorMessage}
        errorColor={colors.red600}
        defaultValue={value}
        contentInset={{ bottom: 0 }}
        // contentInset={{ left: 5, top: 5, right: 5, input: 5, label: 5 }}
        // labelOffset={{ x0: 0, y0: 5, x1: 0, y1: 0,  }}
      />
    );
  }
}

const styles = StyleSheet.create({
  containerStyle: {
    overflow: 'hidden',
  },
  input: {
    color: colors.greyishBrown,
    fontSize: normalizeSize(12.5),
    letterSpacing: 0.5,
    textAlign: I18nManager.isRTL ? 'right' : 'left',
    fontWeight: '500',
  },
});
