import types from './types';
import MagentoAPI from '../../services/MagentoAPI';
import { createAction } from '../../common/utils';
/**
 * !!!Data Migration should be apply from back-end side!!!
 * Changing the old addresses data description here:
 * https://teeela.atlassian.net/browse/TELA-424
 */
function migrateAddressesData(addresses) {
  //loop through address
  return addresses.map((address) => {
    //loop through address's keys
    Object.keys(address).map((key) => {
      //this array of keys may be updated
      const isKeyMayBeChanged = [
        'house',
        'street_name',
        'avenue',
        'block_number',
      ].includes(key);
      //value not empty and the key may be updated
      if (address[key] && isKeyMayBeChanged) {
        //if nil value empty it
        let value = address[key].replace('nil', '');
        //Switch on keys and remove the added shit strings
        switch (key) {
          case 'house':
            address[key] = value.replace(' House: ', '');
            break;
          case 'street_name':
            address[key] = value.replace('St: ', '');
            break;
          case 'avenue':
            address[key] = value.replace(' Avenue: ', '');
            break;
          case 'block_number':
            address[key] = value.replace(' Block: ', '');
            break;

          default:
            break;
        }
      }
    });
    // console.log('new Address Object', address);
    return address;
  });
}

export const actions = {
  getAddresses: () => (dispatch, getState) => {
    const type = types.GET_ADDRESSES;
    try {
      const user = getState().user;
      const config = getState().config;
      const currentCountry = config.country;
      const { key } = currentCountry;
      if (user.loggedIn) {
        dispatch(createAction(type.REQUEST));

        return MagentoAPI.get(
          `/mobile/addresses?country_code=${key}`,
          type,
        ).then((resp) => {
          if (resp.ok) {
            //!!!Data Migration should be apply from back-end side!!!
            const addresses = migrateAddressesData(resp.json);
            return dispatch(createAction(type.SUCCESS, addresses));
          } else return dispatch(createAction(type.ERROR, resp));
        });
      } else return Promise.resolve();
    } catch (e) {
      dispatch(createAction(type.ERROR, e));
    }
  },

  addAddress: (data) => (dispatch, getState) => {
    const type = types.ADD_ADDRESS;
    try {
      const user = getState().user;
      const config = getState().config;
      const currentCountry = config.country;
      const { key } = currentCountry;

      if (user.loggedIn) {
        dispatch(createAction(type.REQUEST));

        return MagentoAPI.post(
          `/mobile/addresses?country_code=${key}`,
          type,
          JSON.stringify({ ...data }),
        ).then((resp) => {
          if (resp.ok) {
            //!!!Data Migration should be apply from back-end side!!!
            const addresses = migrateAddressesData(resp.json);
            return dispatch(createAction(type.SUCCESS, addresses));
          } else return dispatch(createAction(type.ERROR, resp));
        });
      } else return Promise.resolve();
    } catch (e) {
      dispatch(createAction(type.ERROR, e));
    }
  },

  updateAddress: (data) => (dispatch, getState) => {
    const type = types.UPDATE_ADDRESS;
    try {
      const user = getState().user;
      const config = getState().config;
      const currentCountry = config.country;
      const { key } = currentCountry;

      if (user.loggedIn) {
        dispatch(createAction(type.REQUEST));

        return MagentoAPI.put(
          `/mobile/addresses?country_code=${key}`,
          type,
          JSON.stringify({ ...data }),
        ).then((resp) => {
          if (resp.ok) {
            //!!!Data Migration should be apply from back-end side!!!
            const addresses = migrateAddressesData(resp.json);
            return dispatch(createAction(type.SUCCESS, addresses));
          } else return dispatch(createAction(type.ERROR, resp));
        });
      } else return Promise.resolve();
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },
  deleteAddress: (id) => (dispatch, getState) => {
    const type = types.DELETE_ADDRESS;
    try {
      const user = getState().user;
      const config = getState().config;
      const currentCountry = config.country;
      const { key } = currentCountry;

      if (user.loggedIn) {
        dispatch(createAction(type.REQUEST));

        return MagentoAPI.delete(
          `/mobile/addresses/${id}?country_code=${key}`,
          type,
        ).then((resp) => {
          if (resp.ok) {
            //!!!Data Migration should be apply from back-end side!!!
            const addresses = migrateAddressesData(resp.json);
            return dispatch(createAction(type.SUCCESS, addresses));
          } else return dispatch(createAction(type.ERROR, resp));
        });
      } else return Promise.resolve();
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },
  clearAddresses: () => createAction(types.CLEAR_ADDRESSES),
  setSelectedAddress: (selectedAddress) =>
    createAction(types.SET_SELECTED_ADDRESS, selectedAddress),

  // get areas
  getAreas: () => async (dispatch, getState) => {
    const type = types.GET_AREAS;
    try {
      const config = getState().config;
      const currentCountry = config.country;
      const { key } = currentCountry;
      dispatch(createAction(type.REQUEST));
      const resp = await MagentoAPI.get(`/directory/areas/${key}`, type);
      if (resp.ok) {
        return dispatch(createAction(type.SUCCESS, resp.json));
      } else return dispatch(createAction(type.ERROR, resp));
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },
};
