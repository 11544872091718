import React, { useState, useLayoutEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { actions as configActions } from '../../redux/config';
import { actions as addressActions } from '../../redux/address';
import { actions as wishlistActions } from '../../redux/wishlist';
import { useCart } from '../../redux/useCart';
import {
  Screen,
  Text,
  Logo,
  CountrySelect,
  Button,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import styles from './style';
import * as _ from 'lodash';
import { countries } from '../../common';
import { navigateTo } from '../../services';

const Country = ({ navigation }) => {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const { clearCart_GQL, deleteCart_GQL } = useCart();
  const user = useSelector((state) => state.user);
  const onSelectCountry = (country) => {
    setSelectedCountry(country);
  };
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  const onSetCountry = async () => {
    try {
      await dispatch(configActions.setCountry(selectedCountry));
      if (user.loggedIn) {
        await deleteCart_GQL();
      }
      await clearCart_GQL();
      await dispatch(addressActions.clearAddresses());
      await dispatch(wishlistActions.clearWishList());
      setTimeout(() => {
        location.reload();
        navigateTo('account');
      }, 200);
    } catch (e) {
      console.log('setCountry ', e);
    }
  };

  // const isSame =
  //   !_.isEmpty(country) &&
  //   _.get(this.props, 'config.country.key') == _.get(country, 'key');
  return (
    <Screen>
      <ScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        <View style={styles.topContent}>
          <Logo logoStyle={styles.logo} />
          <View style={styles.selectCountryTextContainer}>
            <Text size="large" style={styles.selectCountryText}>
              {I18n.t('selectCountry.title')}
            </Text>
          </View>

          <CountrySelect
            onSelectCountry={onSelectCountry}
            country={selectedCountry}
            selectedCountry={selectedCountry}
          />
        </View>

        <Button
          text={I18n.t('btns.continue')}
          containerStyle={styles.btn}
          onPress={() => onSetCountry()}
          // disabled={isSame}
        />
      </ScrollView>
    </Screen>
  );
};

export default Country;
