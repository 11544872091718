import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { images } from '../../common';
import i18n from '../../locales/i18n';
import { useCart } from '../../redux/useCart';
import AppLogo from '../AppLogo';
import CartIcon from '../CartIcon';
import { Image, SearchBar, Text } from '../index';
import { actions as configActions } from '../../redux/config';
import styles from './styles';
import { CountriesList } from '../CountriesList';
import { isMobile, navigateToSearch, replace } from '../../services';
import { NavBar } from '../NavBar';
import AccountButton from '../AccountButton';
import { SearchList } from '../SearchList';

export const FixedHeader = ({
  showSearch,
  navigateToCategoryDetails,
  isSearchPage,
}) => {
  const dispatch = useDispatch();
  const { clearCart_GQL, deleteCart_GQL } = useCart();
  const { user, config: storeConfig } = useSelector((state) => {
    return {
      user: state.user,
      config: state.config,
    };
  });
  const [selectedLang, setSelectedLang] = useState(storeConfig.lang);

  const changeLang = async () => {
    const language = selectedLang == 'en' ? 'ar' : 'en';
    await setSelectedLang(language);
    await dispatch(configActions.setLanguage(language));
    if (!user.loggedIn) {
      await clearCart_GQL();
    }
    setTimeout(() => {
      location.reload();
    }, 200);
  };

  const onSetCountry = async (country) => {
    try {
      await dispatch(configActions.setCountry(country));
      if (user.loggedIn) {
        await deleteCart_GQL();
      }
      await clearCart_GQL();
      setTimeout(() => {
        location.reload();
        replace();
      }, 200);
    } catch (e) {
      console.log('setCountry ', e);
    }
  };

  return (
    <View style={styles.container}>
      {isMobile && showSearch && (
        <SearchBar
          onPress={() => navigateToSearch()}
          containerStyle={styles.searchContainer}
          inputContainerStyle={styles.inputContainer}
        />
      )}
      {!isMobile && (
        <View style={styles.contentContainer}>
          <AppLogo />
          <NavBar {...{ navigateToCategoryDetails }} />
          <SearchList {...{ isSearchPage }} />
          <CountriesList onItemPress={onSetCountry} />
          <TouchableOpacity
            style={{ flexDirection: 'row', marginEnd: 16 }}
            onPress={() => changeLang()}
          >
            <Image style={styles.languageIcon} source={images.language} />
            <Text>{i18n.t('settings.lang')}</Text>
          </TouchableOpacity>
          {/* <NotificationButton /> */}
          {/* <FavoriteButton /> */}
          <CartIcon />
          <AccountButton />
        </View>
      )}
    </View>
  );
};
