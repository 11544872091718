import types from './types';

//create initial state
const initialState = {
  count: 0,
};

//create reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case types.UPDATE_INTERCOM_UNREAD_CONVERSATION:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};
