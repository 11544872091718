import React from 'react';
import {
  View,
  StyleSheet,
  Keyboard,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { colors } from '../../common';
import { Text, CustomInput } from '../index';
import { I18n } from '../../locales';

const CouponCode = (props) => {
  const { onApply, loading, isCouponValid, couponCode, setCouponCode } = props;

  const onSubmit = () => {
    if (couponCode.length || isCouponValid) {
      Keyboard.dismiss();
      onApply(couponCode);
    }
  };
  return (
    <View style={styles.container}>
      <CustomInput
        editable={!isCouponValid}
        containerWrapperStyle={styles.inputContainer}
        activeContainerStyle={styles.activeContainerStyle}
        containerStyle={styles.inputStyle}
        placeholder={I18n.t('placeholders.couponCode')}
        value={couponCode}
        onChangeText={(text) => setCouponCode(text)}
        returnKeyType="done"
        style={isCouponValid && styles.activatedCodeColor}
      />
      <TouchableOpacity
        onPress={onSubmit}
        style={[
          styles.applyView,
          isCouponValid ? styles.removeBackground : styles.applyBackground,
        ]}
      >
        {loading ? (
          <ActivityIndicator
            size="small"
            color={isCouponValid ? colors.red500 : colors.greenMain}
          />
        ) : (
          <Text
            size="small"
            style={[
              styles.textStyle,
              isCouponValid ? styles.removeText : styles.applyText,
            ]}
          >
            {isCouponValid ? I18n.t('btns.remove') : I18n.t('btns.apply')}
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 15,
    flexDirection: 'row',
    borderRadius: 6,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.paleGrey,
    padding: 4,
  },
  inputStyle: {
    borderBottomWidth: 0,
  },
  inputContainer: {
    flex: 1,
  },
  activeContainerStyle: {
    borderColor: 'transparent',
  },
  applyView: {
    width: 69,
    height: 37,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18,
  },
  textStyle: {
    fontWeight: '600',
  },
  removeBackground: {
    backgroundColor: colors.redLight,
  },
  applyBackground: {
    backgroundColor: colors.greenSecondary,
  },
  removeText: {
    color: colors.red500,
  },
  applyText: {
    color: colors.greenMain,
  },
  activatedCodeColor: {
    color: colors.grey120,
  },
});

export default CouponCode;
