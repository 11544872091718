export default {
  logo: require('../assets/logo/logo.webp'),
  back: require('../assets/icons/back.png'),
  back_right: require('../assets/icons/back_right.png'),
  placeholder: require('../assets/placeholder.webp'),
  delete: require('../assets/delete.webp'),
  boys: require('../assets/icons/Boys.webp'),
  girls: require('../assets/icons/Girls.webp'),
  gift: require('../assets/gift1.webp'),
  selected: require('../assets/selected.webp'),
  minusGradient: require('../assets/icons/minus-gradient.webp'),
  plusGradient: require('../assets/icons/minus-gradient.webp'),
  spinnerInc: require('../assets/spinner_inc.webp'),
  spinnerDec: require('../assets/spinner_dec.webp'),
  iconPlay: require('../assets/icons/play.webp'),
  iconPause: require('../assets/icons/pause.webp'),
  cartBG: require('../assets/cart.webp'),
  edit: require('../assets/edit.webp'),
  errorGradientIcon: require('../assets/icons/error-gradient.webp'),
  checkGradientIcon: require('../assets/icons/check-gradient.webp'),
  card: require('../assets/card.webp'),
  home: require('../assets/tabsBar/home.png'),
  categories: require('../assets/tabsBar/categories.png'),
  offers: require('../assets/tabsBar/offers.png'),
  profile: require('../assets/tabsBar/account.png'),
  money: require('../assets/money.webp'),
  cashondelivery: require('../assets/money_color.webp'),
  knetGray: require('../assets/KNET_Gray.webp'),
  knet: require('../assets/knet.webp'),
  credit: require('../assets/credit.webp'),
  ccard: require('../assets/ccard.webp'),
  fadeMada: require('../assets/fadeMada.png'),
  mada: require('../assets/mada.png'),
  applePay: require('../assets/applePay.png'),
  fadeApple: require('../assets/fadeApple.png'),
  deliveryIcon: require('../assets/delivery-icon.webp'),
  deliveryIconCopy: require('../assets/delivery-icon/deliveryCopy.webp'),
  iconIntercom: require('../assets/intercom.webp'),
  iconProfile: require('../assets/icons/profile-gradient.webp'),
  successOrder: require('../assets/successOrder.png'),
  notificationAccess: require('../assets/notificationAccess.webp'),
  emptyOrders: require('../assets/orderHistory.webp'),
  update: require('../assets/update/update.webp'),
  contactUs: require('../assets/settings/contactUs.webp'),
  delivery: require('../assets/settings/delivery.webp'),
  language: require('../assets/settings/language.webp'),
  kuwaitFlag: require('../assets/settings/flag.webp'),
  marker: require('../assets/icons/group.webp'),
  pin: require('../assets/icons/pin.png'),
  close: require('../assets/productDetails/exit.webp'),
  share: require('../assets/productDetails/share.webp'),
  favorite: require('../assets/productDetails/loveUnSelected.webp'),
  favorited: require('../assets/productDetails/loveSelected.webp'),
  profileImage: require('../assets/settings/user1.webp'),
  userImage: require('../assets/settings/user2.webp'),
  heart: require('../assets/settings/heart.webp'),
  account: require('../assets/settings/account.png'),
  exit: require('../assets/settings/exit.png'),
  faq: require('../assets/settings/faq.png'),
  emptyCart: require('../assets/emptyCart.png'),
  TELA: require('../assets/logo.png'),
  GF1: require('../assets/GF1.jpeg'),
  GF2: require('../assets/GF2.jpeg'),
  GF3: require('../assets/GF3.jpeg'),
  settings: require('../assets/icons/settings.png'),
  filter: require('../assets/icons/filters.png'),
  checkmark: require('../assets/icons/checkmarkOnBlack.png'),
  bluePlay: require('../assets/icons/blue-play.png'),
  safetyFireShield: require('../assets/icons/safetyFireShield.png'),
  FAILED_PAYMENT: require('../assets/payment/failed-payment.jpg'),
  cartIcon: require('../assets/icons/cartIcon.png'),
  magic: require('../assets/icons/magic.png'),
  processingPayment: require('../assets/payment/processingPayment.png'),
  subcategoryAll: require('../assets/subcategoryAll.png'),
  wrapPlaceholder: require('../assets/wrapPlaceholder.png'),
  boxShadow: require('../assets/boxShadow.png'),
  warning: require('../assets/icons/warning.png'),
  expandBox: require('../assets/icons/expandBox.png'),
  dots: require('../assets/icons/dots.png'),
  boxCard: require('../assets/boxCard.png'),
  customizePhoto: require('../assets/icons/customizePhoto.png'),
  colorBucket: require('../assets/icons/colorBucket.png'),
  sticker: require('../assets/icons/sticker.png'),
  pics: require('../assets/icons/pics.png'),
  checked: require('../assets/icons/checked.png'),
  askWrapping: require('../assets/askWrapping.gif'),
  giphyPowered: require('../assets/giphyPowered.png'),
  gesture: require('../assets/gesture.png'),
  DIYPlaceholder: require('../assets/DIYPlaceholder.png'),
  DIYEmptyStickers: require('../assets/DIY_empty_stickers.png'),
  addons: require('../assets/addons.png'),
  iconAddImage: require('../assets/icons/iconAddImage.png'),
  iconText: require('../assets/iconText.png'),
  simple: require('../assets/icons/simple.png'),
  diy: require('../assets/icons/diy.png'),
  personalized: require('../assets/icons/personalized.png'),
  googleplay: require('../assets/googleplay.png'),
  appstore: require('../assets/appstore.png'),
  newDeliveryIcon: require('../assets/icons/newDeliveryIcon.png'),
  profileIcon: require('../assets/icons/profileIcon.webp'),
  notificationIcon: require('../assets/icons/notificationIcon.webp'),
  favoriteIcon: require('../assets/icons/favoriteIcon.webp'),
  iconSupport: require('../assets/support.png'),
};
