import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { Text } from '../index';
import styles from './styles';
import { Image } from '../index';

export default class Card extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onPress: PropTypes.func,
  };

  render() {
    const { style, item, imageStyle, onPress } = this.props;
    return (
      <TouchableOpacity
        onPress={() => onPress(item)}
        style={[styles.container, style]}
        activeOpacity={0.9}
      >
        <Image
          resizeMode="contain"
          style={[styles.image, imageStyle]}
          source={{ uri: item.image_url }}
        />
        <Text size="small" style={styles.textStyle}>
          {item.title || item.description}
        </Text>
      </TouchableOpacity>
    );
  }
}
