import {
  GET_PRODUCTS,
  PRODUCT_BY_SKU,
  SEARCH_PRODUCTS_GATEWAY,
} from '../queries/products';
import { useDispatch } from 'react-redux';
import { useAwaitQueryGateway } from '../queries/customHooks';
import { createAction, createTypes } from '../common/utils';
// import { GraphQErrorHandling } from '../services/ErrorHandling';
export const types = {
  SEARCH_PRODUCTS_GQL: createTypes('SEARCH_PRODUCTS_GQL'),
  GET_PRODUCTS: createTypes('GET_PRODUCTS'),
  PRODUCT_BY_SKU: createTypes('PRODUCT_BY_SKU'),
};

export const useProducts = () => {
  const dispatch = useDispatch();
  const _searchProducts = useAwaitQueryGateway(SEARCH_PRODUCTS_GATEWAY);
  const _getProducts = useAwaitQueryGateway(GET_PRODUCTS);
  const _getProductBySku = useAwaitQueryGateway(PRODUCT_BY_SKU);

  const searchProducts = async (search, currentPage) => {
    const type = types.SEARCH_PRODUCTS_GQL;
    try {
      dispatch(createAction(type.REQUEST));
      const { data, loading } = await _searchProducts({
        variables: { search, currentPage },
      });
      if (!loading && data.search) {
        dispatch(createAction(type.SUCCESS));
        return data.search;
      } else {
        dispatch(createAction(type.ERROR));
      }
    } catch (e) {
      console.log('error ', e);
      // GraphQErrorHandling(e, type.ERROR);
      dispatch(createAction(type.ERROR));
    }
  };

  const getProducts = async (filters) => {
    const type = types.GET_PRODUCTS;
    try {
      dispatch(createAction(type.REQUEST));
      const { data, loading } = await _getProducts({
        variables: filters,
      });
      if (!loading && data.products) {
        dispatch(createAction(type.SUCCESS));
        const total_pages = data.products?.page_info?.total_pages;
        const json = data?.products?.items?.map((item) => {
          const {
            id,
            sku,
            name,
            price_range,
            small_image,
            brand_name,
            only_x_left_in_stock,
            stock_status,
          } = item;
          const {
            regular_price = {},
            discount = {},
            final_price = {},
          } = price_range?.maximum_price;
          const { value: price = 0 } = regular_price;
          const discount_percentage = discount?.percent_off;
          const { value: product_final_price = 0 } = final_price;
          const special_price =
            discount_percentage !== 0 ? product_final_price : null;
          return {
            id,
            sku,
            name,
            price,
            special_price,
            image_url: small_image?.url,
            brand: { name: brand_name },
            discount_percentage,
            stock_qty: only_x_left_in_stock,
            type: item?.__typename,
            stock_status,
          };
        });
        return { json, total_pages };
      } else {
        dispatch(createAction(type.ERROR));
      }
    } catch (e) {
      console.log('error ', e);
      // GraphQErrorHandling(e, type.ERROR);
      dispatch(createAction(type.ERROR));
      throw e;
    }
  };

  const getProductBySku = async (sku) => {
    const type = types.PRODUCT_BY_SKU;
    try {
      dispatch(createAction(type.REQUEST));
      const { data, loading } = await _getProductBySku({
        variables: { sku },
      });
      if (!loading && data?.products?.items[0]) {
        dispatch(createAction(type.SUCCESS));
        const product = data.products.items[0];
        return product;
      } else {
        dispatch(createAction(type.ERROR));
      }
    } catch (e) {
      console.log('error ', e);
      // GraphQErrorHandling(e, type.ERROR);
      dispatch(createAction(type.ERROR));
    }
  };
  return {
    searchProducts,
    getProductBySku,
    getProducts,
  };
};
