import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from '../../common';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { actions as attributesActions } from '../../redux/attributes';
import {
  CustomInput,
  Text,
  FilterCollapse,
  ToggleButton,
  Button,
} from '../../components';
import styles from './styles';
import { I18n } from '../../locales';
import { parseArabicNum, capitalize } from '../../common/utils';
import { isMobile } from '../../services';

const PriceInput = ({ label, currency, onChangeText, value, isError }) => (
  <View
    style={[
      styles.priceInputContainer,
      isError && { borderColor: colors.red600 },
    ]}
  >
    <View style={styles.inputLableContainer}>
      <Text size="smaller">{I18n.t(`filters.words.${label}`)}</Text>
      <Text size="smaller" style={{ fontWeight: 'bold' }}>
        {currency}
      </Text>
    </View>

    <CustomInput
      style={styles.input}
      hideUnderline
      autoFocus={false}
      value={value}
      onChangeText={onChangeText}
      keyboardType="numeric"
      activeBorderColor="transparent"
      containerStyle={styles.inputContainer}
    />
  </View>
);

const Filters = (props) => {
  const { onFilterPress, filters: filtersParam, onCancelPress } = props;

  //The redux hook (useSelector, useDispatch)
  const attributes = useSelector((state) => state.attributes);
  const currency = useSelector((state) => state.config.country.currencyCode);
  const dispatch = useDispatch();

  //the local state data (useState)
  const [filters, setFilters] = useState(filtersParam);
  const [showError, setShowError] = useState(false);

  //useEffects Section
  useEffect(() => {
    dispatch(attributesActions.getProductAttributes());
  }, []);

  useEffect(() => {
    setFilters(filtersParam);
  }, [filtersParam]);

  //select age function
  const onAgeSelect = (item) => {
    const { ages = [] } = filters;
    const isSelected = ages.includes(item.value);
    const newArr = ages.filter((it) => it !== item.value);
    setFilters({
      ...filters,
      ages: isSelected
        ? newArr.length != 0
          ? newArr
          : undefined
        : ages.concat(item.value),
    });
  };

  // submitting values to products screen
  const onSubmitFilters = () => {
    //validating on the prices inputs
    if (priceError || fromError || toError) {
      setShowError(true);
    } else {
      //calling the function from products screen
      onFilterPress(filters);
    }
  };

  // onChangeText function for the price inputs
  const onChangeText = (text, key) => {
    const convertedText = parseArabicNum(text);
    if (!isNaN(convertedText)) {
      setFilters({
        ...filters,
        price: {
          from: price?.from || '',
          to: price?.to || '',
          [key]: text,
        },
      });
    }
  };

  const { ages, price, gender, price_sort } = filters;

  // rendered sort options
  const sortOptions = [
    {
      field: 'updated_at',
      direction: '',
      label: I18n.t('filters.relevance'),
    },
    {
      field: 'price',
      direction: 'Ascending',
      label: I18n.t('filters.lowToHigh'),
    },
    {
      field: 'price',
      direction: 'Descending',
      label: I18n.t('filters.highToLow'),
    },
  ];

  // prices inputs validation logic
  const fromLength = price?.from?.length || 0;
  const toLength = price?.to?.length || 0;
  const priceError =
    (fromLength != 0 || toLength != 0) &&
    parseArabicNum(price?.to) < parseArabicNum(price?.from);
  const fromError = !fromLength && toLength;
  const toError = !toLength && fromLength;

  const filledInputsArr = price && Object.values(price)?.filter((e) => e != '');
  if (filledInputsArr?.length == 0) {
    setFilters({
      ...filters,
      price: undefined,
    });
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
    >
      {/*---------'Sort List'---------*/}
      <View style={styles.sortContainer}>
        {sortOptions.map((item, index) => {
          const isSelected = price_sort?.direction == item.direction;
          return (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() =>
                setFilters({
                  ...filters,
                  price_sort: item, // {field, direction, label}
                })
              }
              style={styles.sortItemStyle}
              key={index}
            >
              {isSelected ? (
                <Icon
                  name="radio-button-checked"
                  size={22}
                  color={colors.brand}
                />
              ) : (
                <Icon name="radio-button-unchecked" size={22} />
              )}
              <Text> {item.label}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
      {/*---------'filter by prices'---------*/}

      <View style={styles.priceContainer}>
        <Text style={{ fontWeight: '600' }}>
          {I18n.t('filters.priceTitle')}
        </Text>
        <View style={styles.inputsRow}>
          <PriceInput
            label="from"
            currency={currency}
            value={`${price?.from || ''}`}
            onChangeText={(text) => onChangeText(text, 'from')}
            isError={showError && fromError}
          />
          <PriceInput
            label="to"
            currency={currency}
            value={`${price?.to || ''}`}
            onChangeText={(text) => onChangeText(text, 'to')}
            isError={showError && toError}
          />
        </View>
        <View style={{ height: 20, alignItems: 'center' }}>
          {priceError && showError && (
            <Text size="smaller" style={{ color: colors.red600 }}>
              {I18n.t('filters.priceErrorMessage')}
            </Text>
          )}
        </View>
      </View>

      {/* Gender Select */}
      <FilterCollapse
        title={I18n.t('filters.genderTitle')}
        collapsed={false}
        selectedValue={gender?.label}
      >
        <View style={styles.genderContainer}>
          {attributes.gender.map((item, index) => {
            const isSelected = gender?.label == item.label;
            return (
              <ToggleButton
                key={index}
                onPress={() =>
                  setFilters({
                    ...filters,
                    gender: isSelected ? undefined : item,
                  })
                }
                text={item.label && capitalize(item.label)}
                containerStyle={styles.selectionButtonStyle}
                isSelected={isSelected}
              />
            );
          })}
        </View>
      </FilterCollapse>

      {/* Age Select */}
      <FilterCollapse title={I18n.t('filters.ageTitle')} collapsed={false}>
        <View style={styles.attributesContainer}>
          {attributes.age.map((e) => {
            const index = ages ? ages.findIndex((eIn) => eIn === e.value) : -1;
            const isSelected = index >= 0;
            return (
              <ToggleButton
                key={e.value}
                text={e.label}
                onPress={() => onAgeSelect(e)}
                containerStyle={styles.selectionButtonStyle}
                isSelected={isSelected}
              />
            );
          })}
        </View>
      </FilterCollapse>
      <Button
        containerStyle={{ marginHorizontal: 10 }}
        text={I18n.t('btns.apply')}
        disabled={filtersParam == filters}
        onPress={() => onSubmitFilters()}
      />
      {isMobile && (
        <Button
          containerStyle={{ margin: 10, backgroundColor: 'transparent' }}
          textStyle={{ color: colors.red400 }}
          onPress={onCancelPress}
          text={I18n.t('btns.cancel')}
        />
      )}
    </ScrollView>
  );
};

export default Filters;
