import React, { Component } from 'react';
import { View } from 'react-native';
import { Text, Badge, Image, RoundedButton } from '../index';
import styles from './styles';
import { I18n } from '../../locales';
import { getPriceFormat } from '../../common/utils';

export default class OrderCard extends Component {
  render() {
    const { style, order, onPress } = this.props;
    const {
      date,
      increment_id,
      status,
      price,
      // qty,
      totals,
      thumbnail_url,
      items = [],
    } = order;
    const orderImage = thumbnail_url || items[0]?.thumbnail_url;
    return (
      <View style={[styles.container, style]}>
        <View style={styles.topView}>
          <View style={styles.imagePriceView}>
            <View>
              <Image style={styles.image} source={{ uri: orderImage }} />
              {/* <Badge
                value={qty || items?.length}
                containerStyle={styles.badge}
                textStyle={styles.badgeText}
              /> */}
            </View>

            <Text style={styles.price} size="small">
              {getPriceFormat(price || totals?.total)}
              {'\n'}
              <Text style={styles.increment} size="small">
                #{increment_id}
              </Text>
            </Text>
          </View>
          <RoundedButton
            containerStyle={styles.button}
            text={I18n.t('orderHistory.view')}
            textStyle={[styles.orderText, styles.btnText]}
            onPress={onPress}
          />
        </View>
        <View style={styles.bottomView}>
          <Text size="small" style={styles.orderText}>
            {status}
          </Text>
          <Text size="small" style={styles.orderText}>
            {!!date && date.split(' ')[0]}
          </Text>
        </View>
      </View>
    );
  }
}
