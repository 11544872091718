import { createTypes } from '../../common/utils';

//create types
export default {
  GET_ADDRESSES: createTypes('GET_ADDRESSES'),
  ADD_ADDRESS: createTypes('ADD_ADDRESS'),
  UPDATE_ADDRESS: createTypes('UPDATE_ADDRESS'),
  DELETE_ADDRESS: createTypes('DELETE_ADDRESS'),
  GET_AREAS: createTypes('GET_AREAS'),
  CLEAR_ADDRESSES: 'CLEAR_ADDRESSES',
  SET_SELECTED_ADDRESS: 'SET_SELECTED_ADDRESS',
};
