import React from 'react';
import { StyleSheet, Platform, View } from 'react-native';
import {
  CardStyleInterpolators,
  HeaderStyleInterpolators,
} from '@react-navigation/stack';
import { colors } from '../common';
import { isMobile } from '../services';
const styles = StyleSheet.create({
  headerStyle: {
    backgroundColor: colors.white,
  },
  headerTitleStyle: {
    color: colors.black,
  },
});

export const defaultNavigationOptions = {
  headerTintColor: colors.brand,
  headerTitleStyle: styles.headerTitleStyle,
  headerBackTitleVisible: false,
  headerStyle: styles.headerStyle,
  headerStyleInterpolator: HeaderStyleInterpolators.forFade,
  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  headerShown: isMobile,
};

if (location.href.includes('payment')) {
  defaultNavigationOptions.headerLeft = () => <View />;
}
