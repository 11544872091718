import React, { Fragment } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';

import { colors, images } from '../../common';
import { Text, OrderCard, Image } from '../index';
import { I18n } from '../../locales';
import Loading from '../Loading';

export default class OrdersList extends React.PureComponent {
  render() {
    const { orders, onItemPress, loading } = this.props;
    return (
      <Fragment>
        <FlatList
          contentContainerStyle={styles.list}
          keyExtractor={item => item.id}
          data={orders}
          renderItem={({ item }) => (
            <OrderCard
              order={item}
              style={styles.orderCard}
              onPress={() => onItemPress(item)}
            />
          )}
          ListEmptyComponent={() => (
            <View style={styles.emptyOrderHistoryContainer}>
              <Image
                source={images.emptyOrders}
                style={styles.emptyOrderHistoryIcon}
              />
              <Text style={styles.emptyOrderHistoryText}>
                {I18n.t('orderHistory.emptyOrders')}
              </Text>
            </View>
          )}
          ListFooterComponent={loading && <Loading />}
          {...this.props}
        />
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  list: { paddingHorizontal: 11 },
  orderCard: { marginVertical: 5, marginHorizontal: 5 },
  emptyOrderHistoryContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyOrderHistoryIcon: {
    width: 100,
    height: 100,
    alignSelf: 'center',
    marginTop: 24,
  },
  emptyOrderHistoryText: {
    marginTop: 16,
    lineHeight: 24,
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
});
