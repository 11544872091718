import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  scrollStyle: {
    // paddingTop: 16,
  },
  logoStyle: {
    width: 80,
    height: 40,
    alignSelf: 'center',
    marginTop: 32,
  },
  versionNumberText: {
    lineHeight: 32,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.textGrey,
  },
  devModeText: {
    fontSize: 3,
    textAlign: 'center',
    color: colors.textGrey,
  },
});
