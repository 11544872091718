import { createAction } from '../common/utils';

export const types = {
  SET_LANG: 'SET_LANG',
  SET_COUNTRY: 'SET_COUNTRY',
};

export const actions = {
  setLanguage: (lang) => createAction(types.SET_LANG, lang),
  setCountry: (country) => createAction(types.SET_COUNTRY, country),
};

const initialState = {
  country: {},
  lang: 'en',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LANG:
      return {
        ...state,
        lang: action.payload,
        isFinishedSelectLanguage: true,
      };

    case types.SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
        isFinishedSelectCountry: true,
      };

    default:
      return state;
  }
};
