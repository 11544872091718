import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors, config } from '../../common';
import { isMobile, scale, vScale } from '../../services';
import Text from '../Text';

export const DiscountBadge = (props) => {
  const { discountPercentage, style } = props;
  return (
    <View style={[styles.badge, style]}>
      <Text style={styles.text}>
        -{~~discountPercentage}
        {config.percentage}
      </Text>
    </View>
  );
};
const styles = StyleSheet.create({
  badge: {
    width: scale(39),
    height: vScale(18),
    borderRadius: vScale(10),
    top: !isMobile && vScale(12),
    start: scale(17),
    zIndex: 2,
    backgroundColor: colors.amethyst,
    justifyContent: 'center',
    alignItems: 'center',
    position: isMobile ? 'relative' : 'absolute',
  },
  text: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: '600',
    color: colors.white,
  },
});
