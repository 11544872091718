import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';
import styles from './styles';
import { colors } from '../../common';
import { Button } from '../Button';
import { Image, Text } from '../index';

export default class extends PureComponent {
  static propTypes = {
    darkMode: PropTypes.bool,
    data: PropTypes.array.isRequired,
    onPress: PropTypes.func,
  };

  static defaultProps = {
    darkMode: false,
  };

  render() {
    const { data, onPress, darkMode } = this.props;
    // There may be multiple banner items on array, but we only need to only render first banner for cover.
    const banner = (data && data[0]) || {};
    const { image_url, title, description, action_btn } = banner;
    const isActionBtn = action_btn && action_btn.name;

    return (
      <TouchableOpacity
        style={[
          styles.container,
          styles.bannerCoverContainer,
          darkMode && { backgroundColor: colors.darkBannerBG },
        ]}
        onPress={() => onPress(banner)}
        activeOpacity={0.9}
      >
        <View style={[styles.bannerCoverDesc]}>
          <View style={[isActionBtn && styles.bannerCoverDescText]}>
            {!!title && (
              <Text
                style={{
                  fontWeight: 'bold',
                  color: darkMode ? colors.white : colors.greyishBrown,
                }}
              >
                {title}
              </Text>
            )}
            {!!description && (
              <Text
                size="smaller"
                style={{
                  color: darkMode ? colors.white : colors.greyishBrown,
                }}
              >
                {description}
              </Text>
            )}
          </View>

          {!!isActionBtn && (
            <Button
              text={action_btn.name}
              containerStyle={[
                styles.button,
                styles.bannerCoverBtn,
                darkMode && { backgroundColor: colors.darkBannerBtnBG },
              ]}
              textStyle={[
                styles.buttonText,
                darkMode && { color: colors.white },
              ]}
              onPress={() => onPress(banner)}
            />
          )}
        </View>
        <Image
          source={{ uri: image_url }}
          style={styles.bannerCoverImage}
          resizeMode={'cover'}
        />
      </TouchableOpacity>
    );
  }
}
