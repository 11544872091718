import React from 'react';
import { View, StyleSheet, Platform, Text } from 'react-native';

import { colors } from '../../common';

const Badge = (props) => {
  const { size, rounded, value, textStyle, containerStyle, textSize } = props;
  return (
    <View
      style={[
        styles.container,
        size && { width: size, height: size },
        rounded && { borderRadius: 100 },
        containerStyle && containerStyle,
      ]}
    >
      <Text size={textSize || 'small'} style={[styles.badge, textStyle]}>
        {value}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 8,
    paddingVertical: 2,
    borderRadius: 5,
    alignItems: 'center',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    backgroundColor: colors.brand,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
    ...Platform.select({
      android: {
        elevation: 5,
        shadowColor: colors.black,
      },
    }),
    borderWidth: 3,
    borderColor: colors.white,
  },
  badge: {
    color: colors.white,
  },
});
export default Badge;
