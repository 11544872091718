import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';
import { sWidth } from '../../services';

export default StyleSheet.create({
  categoriesContainer: {
    width: sWidth,
    backgroundColor: colors.white,
    shadowColor: colors.shadowDarkColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    ...Platform.select({
      android: {
        elevation: 3,
        shadowColor: colors.black,
      },
    }),
    marginBottom: 10,
  },
});
