import {
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  View,
} from 'react-native';
import React, { useState } from 'react';
import { images, colors } from '../../common';
import { Image, ModalContainer } from '..';
import NavHead from '../NavHead';
import _ from 'lodash';
import { Video } from 'expo-av';
import { isMobile, isRTL, scale, sHeight, sWidth } from '../../services';
import config from '../../redux/config';

export const ZoomImage = ({ data, imageIndex, setModalVisible, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const newItems = isRTL() ? data.slice().reverse() : data;
  const currentItem = newItems[imageIndex];
  const { url, video } = currentItem;
  const isVideo = Boolean(video);
  const onClose = () => setModalVisible(false);
  return (
    <ModalContainer
      onBackButtonPress={onClose}
      setModalVisible={setModalVisible}
      {...rest}
    >
      <NavHead
        containerStyle={styles.headerStyle}
        title={`${imageIndex + 1}/${data.length}`}
        headerLeft={
          <TouchableOpacity
            onPress={onClose}
            hitSlop={{ top: 15, bottom: 15, left: 15, right: 15 }}
          >
            <Image
              source={images.close}
              style={styles.closeImage}
              tintColor={colors.brand}
            />
          </TouchableOpacity>
        }
      />
      <View style={styles.imageContainer}>
        {isLoading && isVideo && (
          <ActivityIndicator
            style={{ height: sHeight }}
            color={colors.brand}
            size={'large'}
          />
        )}
        {isVideo ? (
          <Video
            source={{ uri: video }}
            style={{
              width: sWidth,
              height: isLoading ? 0 : isMobile ? 360 : sHeight,
            }}
            shouldPlay={true}
            repeat
            onLoad={() => setIsLoading(false)}
            resizeMode="contain"
          />
        ) : (
          <Image
            source={{ uri: url }}
            placeholder={config.placeholderImage}
            resizeMode="contain"
            style={{
              width: sWidth,
              height: sHeight - 180,
            }}
          />
        )}
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  headerStyle: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: 0,
    left: 0,
    zIndex: 100,
    paddingVertical: 0,
    marginTop: 0,
    elevation: 0,
    backgroundColor: colors.white,
  },
  closeImage: {
    width: scale(20),
    height: scale(20),
  },
  imageContainer: {
    width: sWidth,
    height: sHeight - 180,
    marginTop: 110,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
});
