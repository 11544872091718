import { StyleSheet, View } from 'react-native';
import React from 'react';
import LottieView from 'react-native-web-lottie';
import productsEmpty from '../../assets/lottie/productsEmpty.json';
import { colors } from '../../common';
import { sWidth } from '../../services/index.js';
import { Text } from '../index.js';

export const EmptyScreen = (props) => (
  <View style={styles.container}>
    <LottieView
      style={styles.lottie}
      source={productsEmpty}
      autoPlay
      width={sWidth * 0.6}
    />
    <Text size="large" style={styles.text}>
      {props.errorMessage?.title}
    </Text>
    <Text size="small" style={styles.subTitle}>
      {props.errorMessage?.subTitle}
    </Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  lottie: {
    position: 'relative',
  },
  text: {
    marginTop: 5,
    fontFamily: 'Cairo-Regular',
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.textGrey,
  },
  subTitle: {
    marginTop: 5,
    fontFamily: 'Cairo-Regular',
    textAlign: 'center',
    color: colors.textGrey,
  },
});
