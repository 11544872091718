import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

const config = {
  rtl: false,
  lang: 'en',
  currency: 'KWD',
  percentage: '%',
  telSupport: '+96522270149',
  whatsapp: '+96522270149',
  ksaTelSupport: '+9660115201120',
  splashScreenDuration: 2000,
  dimension: {
    screenWidth(percent = 1) {
      return Dimensions.get('window').width * percent;
    },
    screenHeight(percent = 1) {
      return Dimensions.get('window').height * percent;
    },
  },
  limitAddToCart: 10,
  window: {
    width: width,
    height: height,
    headerHeight: (65 * height) / 100,
  },
  categoriesId: {
    default: 151, // for `Toys`  top level
    homemobile: 209,
    celebritypicks: 153,
    boys: 386,
    girls: 387,
    boyFilter: 230,
    girlFilter: 231,
  },
  logo: require('./../assets/logo/logo.webp'),
  placeholderImage: require('./../assets/placeholder.webp'),
  stores: {
    KW_en: 'en',
    KW_ar: 'ar',
    SA_en: 'en_sa',
    SA_ar: 'ar_sa',
    AE_en: 'en_ae',
    AE_ar: 'ar_ae',
    QA_en: 'en_qa',
    QA_ar: 'ar_qa',
    OM_en: 'en_om',
    OM_ar: 'ar_om',
    BH_en: 'en_bh',
    BH_ar: 'ar_bh',
  },
};

export default config;
