import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '../../components';
import { getPriceFormat } from '../../common/utils';
import { colors } from '../../common';
import { isMobile } from '../../services';

export const PriceRow = (props) => {
  const { item, style } = props;
  const { price, special_price, discount_percentage } = item;
  const hasDiscount = Boolean(discount_percentage);
  return (
    <View style={[styles.container, style]}>
      <Text
        style={[styles.price, hasDiscount && styles.hasDiscountStyle]}
        numberOfLines={1}
      >
        {getPriceFormat(hasDiscount ? special_price : price)}
      </Text>
      {hasDiscount && (
        <Text style={styles.discountText} numberOfLines={1}>
          {getPriceFormat(price)}
        </Text>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  price: {
    marginEnd: 10,
    fontSize: isMobile ? 10 : 14,
    fontWeight: '500',
  },
  hasDiscountStyle: {
    color: colors.lipstick,
  },
  discountText: {
    textDecorationLine: 'line-through',
    color: colors.darkIndigo,
    opacity: 0.3,
    fontSize: isMobile ? 10 : 14,
  },
});
