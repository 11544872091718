import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { Text, MapStaticImage } from '../index';
import { colors } from '../../common';
import { getSeparatedString, getTextWithLabel } from '../../common/utils';

const AddressMap = (props) => {
  const { data: addressObject } = props;
  const {
    full_name,
    floor,
    street_name,
    block_number,
    area_name,
    phone,
    house,
    avenue,
    extra_guidance,
    geolocation = {},
  } = addressObject;
  let { lang = '', lat = '' } = geolocation;
  console.warn('addressObject', geolocation);
  // console.log('AddressMap => addressObject', addressObject);
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.leftContent}>
          <Text size="small" style={styles.descText}>
            {getSeparatedString([
              getTextWithLabel('area', area_name),
              getTextWithLabel('block', block_number),
              getTextWithLabel('street', street_name),
              getTextWithLabel('avenue', avenue),
              getTextWithLabel('house', house),
              getTextWithLabel('floor', floor),
              getTextWithLabel('guidance', extra_guidance),
            ])}
          </Text>
          <Text size="small" style={[styles.descText, styles.textRow]}>
            {full_name}
          </Text>
          <Text size="small" style={[styles.descText, styles.textRow]}>
            {phone}
          </Text>
        </View>
        <View style={styles.rightContent}>
          <MapStaticImage
            style={styles.imageMap}
            latitude={lat}
            longitude={lang}
            width={111}
            height={111}
          />
        </View>
      </View>
    </View>
  );
};

AddressMap.propTypes = {
  data: PropTypes.object,
  hasMap: PropTypes.bool,
};

AddressMap.defaultProps = {
  data: {},
};

const styles = StyleSheet.create({
  container: {
    margin: 8,
    backgroundColor: colors.greyBackground,
    borderRadius: 4,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContent: {
    margin: 8,
    flex: 0.68,
  },

  descText: {
    letterSpacing: -0.19,
    lineHeight: 24,
  },
  textRow: {
    marginVertical: 1,
  },
  rightContent: {
    alignItems: 'flex-end',
    flex: 0.3,
    justifyContent: 'center',
  },
  imageMap: {
    justifyContent: 'center',
  },
});
export default AddressMap;
