import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const ADD_PRODUCT_TO_CART = gql`
  mutation($cartId: String!, $sku: String!, $quantity: Float!) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [{ data: { quantity: $quantity, sku: $sku } }]
      }
    ) {
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`;
