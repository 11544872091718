import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { actions as configActions } from '../../redux/config';
import {
  actions as widgetsActions,
  types as widgetsTypes,
} from '../../redux/widgets';
import { loadingSelector } from '../../common/utils';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { countries } from '../../common';
import {
  AppLogo,
  CartIcon,
  HeaderButton,
  Screen,
  Widgets,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToProduct, navigateToProducts } from '../../services';

const Home = ({ navigation }) => {
  const didMountRef = useRef(false);
  const dispatch = useDispatch();
  const { widgets, widgetsParentIds, online, loading, config } = useSelector(
    (state) => {
      return {
        widgets: state?.widgets?.list,
        widgetsParentIds: state?.widgets?.parent_ids,
        online: state?.network?.isConnected,
        loading: loadingSelector(state, [
          widgetsTypes.GET_WIDGETS,
          widgetsTypes.GET_WIDGETS_PARENT_IDS,
        ]),
        config: state?.config,
      };
    },
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: <AppLogo />,
      //always keep title in center event in Android
      headerTitleAlign: 'center',
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <HeaderButton
          elements={[{ element: <CartIcon navigation={navigation} /> }]}
        />
      ),
    });
  }, [navigation]);

  useEffect(() => {
    _initialFetch();
    if (didMountRef.current) {
    } else didMountRef.current = true;
  }, [online]);

  const _initialFetch = async () => {
    //if country not exist in store, set default one, this state will happen if user update the app after support multi country version
    if (Object.keys(config.country).length == 0) {
      dispatch(configActions.setCountry(countries[0]));
    }
    const res = await dispatch(widgetsActions.getWidgetsParentIds());
    dispatch(widgetsActions.getWidgets(res?.payload?.data?.[0]?.id || 'home')); // get widgets of homepage.
  };

  const navigateToCategoryDetails = (
    categoryId,
    type,
    title,
    component_type,
  ) => {
    if (!Array.isArray(widgetsParentIds)) return;
    let filters = {};
    if (type == 'age') {
      filters.ages = [categoryId];
    } else {
      filters.category_id = categoryId;
    }
    navigateToProducts(filters, { categoryId, type, title, component_type });
  };

  // handle when press any item of home screen
  const onPressItem = (item, component_type) => {
    // handling item can contain either link to product or category.
    let withTarget = item.target || item.action_btn;
    //get target of action from item itSelf or in action button if not have target will go to product details
    if (withTarget) {
      const { id, type } = withTarget;
      const queryData = {
        id: id,
        title: item.title,
        component_type: component_type,
        type,
      };
      // trackHomeCardViewed(queryData);
      // check the name of target if it's not product
      // TODO: Need to handle attributes target (age, gender etc).
      if (type === 'product') {
        // any other type except product will go to product listing page.
        // console.log('Id: ', id);
        navigateToProduct(id, item?.title);
      } else if (type === 'brand') {
        navigateToProducts({ brand: id }, queryData);
      } else {
        // can be: `age|gender|category`.
        // NOTE: age must be single id here from backend, not comma seperated or array of ids.
        navigateToCategoryDetails(id, type, item.title, component_type);
        // navigation.navigate('Products', { item: { id: id, type: type } });
      }
    } else navigateToProduct(id, item?.title);
  };

  const onPressSeeAll = (item) => {
    const { category_id: id, component_type, title } = item;
    // console.log('Clicked on see all: ', id);
    if (id) {
      navigateToCategoryDetails(id, null, title, component_type);
      // navigation.navigate('Products', { item: { id: id } });
    }
  };

  const openStoryScreen = (storiesData, index) => {
    // trackStoryBtnClicked(storiesData, index);
    navigation.navigate('Story', { storiesData, index });
  };
  const thisWidgets = widgets[widgetsParentIds?.[0]?.id] || [];

  return (
    <Screen showSearch>
      <Widgets
        navigation={navigation}
        loading={loading}
        widgets={thisWidgets}
        onPressItem={onPressItem}
        onPressSeeAll={onPressSeeAll}
        openStoryScreen={openStoryScreen}
      />
    </Screen>
  );
};

Home.propTypes = {
  getWidgets: PropTypes.func,
  getWidgetsParentIds: PropTypes.func,
  setCountry: PropTypes.func,
  online: PropTypes.bool,
  navigation: PropTypes.object,
  // updateIntercomUnreadConversation: PropTypes.func,
  loading: PropTypes.bool,
  widgetsParentIds: PropTypes.array,
  widgets: PropTypes.object,
};

Home.defaultProps = {
  online: true,
  navigation: {},
  // loading: true,
};

export default Home;
