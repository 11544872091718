import { gql } from '@apollo/client';

export const GET_MAIN_CATEGORIES = gql`
  query {
    categoryList {
      children {
        id
        name
        image
        product_count
        children {
          id
          name
          image
          product_count
          children {
            id
            name
            image
            product_count
          }
        }
      }
    }
  }
`;
