import { StyleSheet, Dimensions } from 'react-native';
import { metrics } from '../../common/metrics';
const { width } = Dimensions.get('screen');
export default StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 0,
  },
  homeContainer: {
    marginTop: metrics.HEADER_HEIGHT,
  },
  scrollListContainer: {
    marginVertical: 15,
  },
  shimmerSlider: {
    height: 200,
  },
  headerSeeAllShimmer: {
    height: 24,
    marginVertical: 25,
    paddingHorizontal: 16,
    alignSelf: 'stretch',
  },
  placeHolder: {
    width: 230,
    height: 18,
    marginTop: 7,
    borderRadius: 6,
    marginBottom: 5,
  },
  border: {
    width: 230,
    height: 18,
  },
  card: {
    marginVertical: 5,
    marginLeft: 8,
    width: '47%',
    height: 260,
    borderRadius: 8,
  },
  cardScroll: {
    width: 135,
    height: 126,
    borderRadius: 4,
    marginRight: 8,
  },
  contentContainerScroll: {
    alignItems: 'center',
    marginHorizontal: 16,
  },
  product: {
    marginVertical: 5,
    justifyContent: 'center',
    marginTop: 90,
    height: 400,
    width: '100%',
    borderRadius: 8,
  },
  slider: {
    width: width,
    height: 200,
  },
  productCard: {
    marginTop: 15,
    borderRadius: 6,
    width: 345,
    height: 50,
  },
  border: {
    borderRadius: 6,
  },
});
