import types from './types';
import { I18n } from '../../locales';
import { createAction } from '../../common/utils';
import { HOME_BUILDER_URL } from '../../common/constants';

export default {
  /**
   * Get widgets of a category or homepage.
   */
  getWidgets: (parentId = 'home') => async (dispatch, getState) => {
    const type = types.GET_WIDGETS;
    try {
      const widgets = getState().widgets;
      const { country } = getState().config;
      if (
        widgets.list &&
        widgets.list[parentId] &&
        widgets.list[parentId].length
      )
        return Promise.resolve();

      dispatch(createAction(type.REQUEST)); //fire request action
      const lang = I18n.currentLocale() || 'en';
      const res = await fetch(
        `${HOME_BUILDER_URL}/api/widgets?device=web&lang=${lang}&country=${country.key.toLowerCase()}&page_id=${parentId}&limit=60`,
        {
          method: 'GET',
          // credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
          },
        },
      );
      const json = await res.json();
      console.warn('res', res);
      if (res.ok) {
        console.warn('ecdkfjvnkdfjnvkjdfnv');

        //     // above api response format
        //     // for success => { response: [] }
        //     // for error => { error: {} }
        return dispatch(
          createAction(type.SUCCESS, {
            parentId: parentId,
            data: json.response,
          }),
        );
      } else {
        return dispatch(
          createAction(type.ERROR, json.error && json.error.message),
        );
      }
    } catch (e) {
      console.warn('e', e);
      const msg = e && e.message;
      // console.log('Error [getWidgets]: ', e);
      return dispatch(createAction(type.ERROR, msg));
    }
  },

  /**
   * Get all parent ids of all widgets stored in teeela builder.
   * NOTE: `home` is the default parentId of homepage widgets. So, not included.
   * Parent Ids are basically top-level category id.
   */
  getWidgetsParentIds: () => async (dispatch, getState) => {
    const type = types.GET_WIDGETS_PARENT_IDS;
    try {
      const {
        widgets,
        config: { country },
      } = getState();
      if (widgets.parent_ids && widgets.parent_ids.length)
        return Promise.resolve();

      dispatch(createAction(type.REQUEST));
      const lang = I18n.currentLocale() || 'en';
      const res = await fetch(
        `${HOME_BUILDER_URL}/api/pages?lang=${lang}&country=${country.key.toLowerCase()}`,
        {
          method: 'GET',
          // credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const json = await res.json();
      if (res.ok) {
        return dispatch(
          createAction(type.SUCCESS, {
            data: json.response,
          }),
        );
      } else
        return dispatch(
          createAction(type.ERROR, json.error && json.error.message),
        );
    } catch (e) {
      const msg = e && e.message;
      // console.log('Error [getWidgetsParentIds]: ', e);
      return dispatch(createAction(type.ERROR, msg));
    }
  },
};
