export default {
  // all screens (in flatten state) and their data
  intro0: {
    title: 'Select your preferred app language',
  },
  intro1: {
    title: 'Gift Wrapping',
    description:
      'Enjoy our service for gift wrapping and customize your gifts with warm and lovely add-ons',
  },
  intro2: {
    title: 'Biggest Collections',
    description:
      'We have great collections of toys you can find on the internet',
  },
  intro3: {
    title: 'Fast Delivery',
    description:
      'We have a great experience at same-day delivery within 4 hours',
  },
  selectCountry: {
    title: 'Select Your Country',
  },
  tabBarLabels: {
    home: 'Home',
    categories: 'Categories',
    account: 'Account',
    cart: 'Cart',
    offers: 'Offers',
  },
  gender: {
    boy: 'Boys',
    girl: 'Girls',
    boyToys: "Boy's Toys",
    girlToys: "Girl's Toys",
    switchBoy: '👦🏻 Boys',
    switchGirl: '👧🏼 Girls',
  },

  home: {
    title: 'Home',
    feature: 'Featured',
    appUpdate: 'App Updating',
    outOfDate:
      'We’re always working to improve your experience on Teeela through new exciting  features, improvements.',
    updateApp: 'App Update',
    callToAction: 'Call to Action',
    seeAll: 'See All',
    brands: 'Brands',
    stopOrdersModal: {
      description:
        'We have reach our orders capacity for the day, Please come back again tomorrow.',
    },
  },
  search: {
    errorTitle: 'No results found',
    errorSubTitle: 'Try searching for something else.',
  },
  categories: {
    title: 'Categories',
  },
  profile: {
    title: 'Account',
    settings: 'Settings',
    hi: 'Hi',
    or: 'or',
    logout: 'Logout',
    login: 'Sign in',
    signup: 'Signup',
    contactUs: 'Contact Us',
    appLanguage: 'App Language',
    signChoose: 'You’re not a member, Sign in or sign up',
    haveAccount: 'Have an account?',
    donthaveAccount: "Don't have an account?",
    alreadyHaveAcc: 'Already have an account?',
    createAccount: 'Create and account',
    faq: 'FAQ',
    wishlist: 'My Favourites',
    FavouriteProduct: 'Favourite products',
    orderHistory: 'Order History',
    manageAddresses: 'Manage Addresses',
    logoutModal: {
      description: 'Would you like to logout of your account?',
      title: 'Logout',
    },
    supportModal: {
      title: 'Live Support',
      description:
        'For any question or inquiries please do not hesitate to contact us or email us on support@teeela.com',
      callSupportBtn: 'Live chat with support',
    },
    appLangModal: {
      title: 'App Language',
      description:
        'Which Language would you like teeela application to be changed to?',
    },
    intercom: 'Live Support',
    continueWithEmail: 'Continue with Email',
  },
  cart: {
    title: 'Cart',
    noItems: 'No items in your cart',
    total: 'Total',
    emptyCart: 'Empty Cart',
    outOfStockMsg:
      'Some of the products in your cart have run Out of Stock. Please review the cart before proceeding',
    expectedDelivery: 'Expected Delivery',
    sameDayDelivery: 'Today within 4 hours',
    nextDayDelivery: 'Next Day Delivery',
    twoDayDelivery: 'Delivery within 2 working days',
    internationalDelivery: 'Delivery within 3 to 4 days',
    titleDelivery: 'Delivery',
    warningDelivery:
      ' Some of the products in your cart have Two Day Delivery.',
    deliveryDay: {
      same: 'Same',
      next: 'Next',
      two: 'Two',
    },
    couponCodeSuccess: 'Coupon code applied successfully.',
    deleteCouponCodeSuccess: 'Coupon code removed successfully.',
    giftWrap: {
      title: 'Gift Wrapping',
      startGift: 'Start Wrapping 🎁',
      title: 'Customize your gift',
      description:
        'Bring more joy and happiness with our unique set of accessories.',
      // description: 'Customize this gift into great package and make it cheerful with lovely addons.',
      modal: {
        description: 'Gift Wrap your products and write your message',
      },
      placeholders: {
        sender: 'Sender Name',
        recipient: 'Recipient Name',
        message: 'Your Message here (max 60 chars)',
      },
      addWrapping: 'Add Wrapping',
    },
    startShopping: 'Start Shopping',
    startShoppingItems: 'Start Shopping to add Items to your cart.',
    delete: 'Delete Item ?',
    deleteConfirm: 'Are you sure about deleting the item ?',
    dismiss: 'Dismiss',
    paymentSummary: 'Payment Summary',
    deleteAllProducts:
      'Are you sure about deleting all products and packaging?',
    clearCart: 'Clear Cart',
  },
  celebrityPicks: {
    title: 'Celebrity Picks',
    words: {
      picks: 'picks',
    },
  },
  filters: {
    title: 'Filters',
    priceTitle: 'Price',
    genderTitle: 'Gender',
    ageTitle: 'Age',
    relevance: 'Relevance',
    lowToHigh: 'Price: Low To High',
    highToLow: 'Price: High To Low',
    words: {
      from: 'From',
      to: 'To',
    },
    priceErrorMessage: 'Please Enter Correct Price Range',
  },
  products: {
    title: 'Products',
    outOfStock: 'Sold Out',
    warpProduct: 'Gift wrapping',
    errorTitle: 'No products found',
    errorSubTitle: 'Try reset filters or choose different criteria.',
    allToys: 'All Toys',
  },
  productDetails: {
    title: 'Product Details',
    itemSuccess: 'Added to Cart',
    descriptionTitle: 'Description',
    inStock: 'In Stock',
    outOfStock: 'Sold Out',
    productInfo: {
      title: 'Product Information',
      sku: 'Product SKU',
      model: 'Product Model',
      weight: 'Weight',
      age: 'Recommended Age',
      gender: 'Gender',
      brand: 'Brand',
      dimensions: 'Dimensions',
    },
    relatedItemsTitle: 'Related Items',
    inCart: 'in Cart',
    select: 'Select',
    remove: 'Remove',
    selectYour: 'Please select your',
    Size: 'Size',
    Color: 'Color',
    Numbers: 'Numbers',
    size: 'Size',
    color: 'Color',
    numbers: 'Numbers',
    brandDetails: 'All Product of the brand',
    selectOptions: 'Select Options',
  },

  wishlist: {
    title: 'My Favourites',
    items: 'items',
    noFavorites: 'No Favorites Found',
    addFavorite: 'Try Adding Some Products to Favorites ',
  },
  orderHistory: {
    title: 'Order History',
    noOrder: 'No order found with this OrderId',
    orderNo: 'Order Number',
    orderDate: 'Order Date',
    status: 'Status',
    paymentMethod: 'Payment Method',
    total: 'Total',
    email: 'Email Address',
    view: 'View',
    payment: 'Payment',
    emptyOrders:
      'There are no recent orders to display yet, Start by placing an order.',
    promoCode: 'Promo code discount',
  },
  login: {
    title: 'Login',
    forgetPass: 'Forget Password?',
    donotHaveAnAccount: "Don't have an account",
    haveAnAccount: 'Do you have an account?',
    signup: 'Sign Up?',
    errorMsg: 'No account exists for the requested email address.',
  },
  signup: {
    title: 'Create account',
    description: 'An account with this email already exists',
    resetPassword: 'Reset Password',
  },
  forgetPassword: {
    title: 'Forget your password',
    subtitle:
      'Enter your email used in teeela and we’ll send a link to reset your password.',
    successMsg: 'We have sent successfully password reset link',
  },
  faq: {
    title: 'FAQ',
    questions: [{ id: 1, title: '', description: '' }],
  },
  delivery: {
    title: 'Delivery',
    shippingAddressTitle: 'Shipping Address',
    note: 'Note',
    emailNotice: 'Email used for notification and order invoice.',
    createAccount: 'Create an Account',
  },
  payment: {
    title: 'Payment',
    deliveryAddressTitle: 'Delivery Address',
    orderSummaryTitle: 'Products Summary',
    totalTitle: 'Total',
    paymentMethodTitle: 'Payment Method',
    knetOrVisa: 'KNET',
    card: 'Credit Card',
    mada: 'MADA',
    applePay: 'Apple Pay',
    cod: 'Cash',
    kuwait: 'Kuwait',
    preferredDelivery: 'Preferred Delivery',
    expectedDelivery: 'Expected Delivery',
    yourComments: 'Add Comment',
    optional: 'Optional',
    preferredDate: 'Schedule delivery',
    infoMessage:
      'Your safety is our #1 priority, Cash payment has been disabled temporarily, Online payments available',
  },
  order: {
    title: 'Order',
  },
  newAddress: {
    title: 'New Address',
    missingNumber: 'Account update',
    missingNumberSubTitle:
      'Your account is missing your mobile number, Please update it in order to continue',
    addressTitle: 'Confirm delivery information',
    confirmBtn: 'Confirm the location',
    selectAddressOptionTitle: 'Shipping Information',
    selectAddressOptionSubTitle:
      'Accuracy in the title will help us reach you faster',
    selectAddressOptionMap: 'Select my current location',
    selectAddressOptionManual: 'Add the address manually',
    iWantToDeliver: 'I want to deliver this order to',
  },
  editAddress: {
    title: 'Edit Address',
  },
  manageAddresses: {
    title: 'Manage Shipping Addresses',
    altTitle: 'Delivery Addresses',
    allAddressesTitle: 'All Addresses',
    defaultShippingMsg: 'Please select the default shipping address',
    newAddressMsg: 'Please add new shipping address',
    noAddressMsg: "You don't have any shipping addresses yet.",
    sessionExpiredMsg: 'Session Expired. Sign in again',
    signInAgainMsg: 'You need to signin to manage addresses',
    selectAddress: 'Select Address',
  },

  successOrder: {
    title: 'Order Completed',
    header: 'Congratulations',
    orderNumberTitle: 'Order Number',
    successMsg:
      'Your order is placed successfully, please go to the order page to manage status.',
    goToHomepage: 'Go to homepage',
    paymentId: 'Payment ID',
    transactionId: 'Transaction ID',
  },

  failedOrder: {
    title: 'Payment failed',
    header: 'Payment failed',
    body: 'Card data is wrong, please try again.',
    tableTitle: 'Order information',
    orderNumberTitle: 'Order Number',
    dateTitle: 'Date',
    errorCodeTitle: 'Error Code',
    btnTitle: 'Try again',
    paymentId: 'Payment ID',
    transactionId: 'Transaction ID',
    goToHomepage: 'Go to homepage',
  },

  processingPayment: {
    title: 'Processing Payment',
    header: 'Processing Payment',
    body: 'Payment is taking more than usual,\nPlease check order status in 15 min.',
    btnTitle: 'Check Order Status',
    goToHomepage: 'Go to homepage',
  },
  noNetworkConnection: 'No Network Connection',

  // btns
  btns: {
    back: 'Back',
    confirm: 'Confirm',
    login: 'Login',
    register: 'Register',
    signup: 'Create Account',
    signupContinue: 'Create Account & Continue',
    findOrder: 'Find Order',
    resetPassword: 'Send Reset Email',
    delete: 'Delete',
    deleteTShirt: 'Delete T-Shirt',
    cancel: 'Cancel',
    done: 'Done',
    edit: 'Edit',
    editTShirt: 'Edit T-Shirt',
    next: 'Next',
    yes: 'Yes',
    no: 'No',
    discard: 'Discard',
    skip: 'Skip',
    addNew: 'New Address',
    nextStep: 'Next Step',
    apply: 'Apply',
    create: 'Create',
    update: 'Update',
    addToCart: 'Add To Cart',
    addAllToCart: 'Add All To Cart',
    clearAll: 'Clear All',
    viewList: 'View List',
    gettingStarted: 'Getting Started',
    allowNotifications: 'Allow Notifications',
    DO_NOT_ALLOW: 'Do not allow',
    proceed: 'Proceed',
    proceedToCheckout: 'Proceed To Checkout',
    guestCheckout: 'Guest Checkout',
    loginCheckout: 'Login & Checkout',
    checkoutAsNewUser: 'Checkout as a New User',
    forgetPassword: 'Forget Password?',
    chooseProductOptions: 'Choose Product Options',
    chooseOptions: 'Choose Options',
    newAddress: 'Add address',
    editAddress: 'Edit Address',
    backToCategories: 'Back To Categories',
    checkout: 'Checkout',
    placeOrder: 'Place Order',
    continue: 'Continue',
    backToCart: 'Back to cart',
    closeApp: 'Close App',
    updateApp: 'Update Teeela',
    viewMyOrders: 'View My Orders',
    goToSettings: 'Go to settings',
    LOOKS_GOOD: 'Looks good',
    saveLocation: 'Save Location',
    change: 'Change',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    editProfile: 'Update account',
    editSuccessfully: 'Your data updated successfully',
    tabToEdit: 'Tab to edit',
    confirmGiftCard: 'Confirm gift card',
    editGiftCard: 'Edit gift card',
    confirmImages: 'Confirm Selected Photos',
    activated: 'Activated',
    remove: 'Remove',
    select: 'Select',
    addPhotos: 'Add your photos',
    customize: 'Customize T-Shirt',
  },

  // errors
  errors: {
    email: 'Please provide a valid email',
    fullname: 'Fullname is required',
    firstname: 'Firstname is required',
    lastname: 'Lastname is required',
    orderId: 'Order Id is required',
    passwordLength: 'Password must be atleast 8 characters',
    passwordBlank: "Password can't be blank",
    passwordRequired: 'Password is required',
    passwordAgain: 'Repeat password is required',
    passwordAgainSame: 'Repeat password must be the same',
    noAccountExist: 'No Account Exists for the requested email address',
    tooManyPassReset:
      'Too many password reset requests. Please try after sometime',
    address: 'Please provide a valid address',
    area: 'Please select an area',
    street: 'Street is required',
    block: 'Block is required',
    house: 'House No is required',
    houseNo: 'House No is required',
    tel: 'Please provide a valid Mobile number',
    customerFirstname: 'Customer firstname is required',
    customerLastname: 'Customer lastname is required',
    coupon: 'Coupon code is not valid',
    errorMessage: 'Something went wrong, Please try again in a while',
    notEnoughQty:
      'Some of your products are not available in the requested quantity',
    productNotExist: 'Sorry, this product is not available right now',
    UNAVAILABLE_LOCATION:
      'Sorry, Access the location feature is not available in your device',
    UNAVAILABLE_GALLERY:
      'Sorry, Access the gallery is not available in your device',
    OPEN_SETTINGS_ERROR: 'Sorry, we cannot open settings',
    ERROR_MESSAGE: 'An Error Occurred!',
    from: 'From is required',
    to: 'To is required',
    message: 'Message is required',
    areaRequired: 'Area is required!',
    city: 'Please select a city',
    invalidImage: 'Invalid Image, please choose another one',
    messageLength: 'Max 180 chars',
  },

  // input placeholders.
  placeholders: {
    email: 'Email Address',
    password: 'Password',
    fullname: 'Full Name',
    name: 'Name',
    country: 'Country',
    firstname: 'First Name',
    lastname: 'Last Name',
    couponCode: 'Coupon Code',
    passwordAgain: 'Password Again',
    city: 'City',
    area: 'Area',
    region: 'Region',
    street: 'Street',
    avenue: 'Jadda (Optional)',
    block: 'Block',
    house: 'House / Apt',
    tel: 'Mobile No',
    deliveryTime: 'Select Delivery Time',
    search: 'Search',
    selectArea: 'Select Area',
    searchToys: 'Search for toys',
    searchAreas: 'Search for area',
    floor: 'Floor',
    addressName: 'Address Name',
    address: 'Address',
    extraGuidance: 'Extra guidance for the delivery',
    guidance: 'Extra Guidance',
    giftForSomeone: 'Is it gift for someone?',
    defaultAddress: 'Set address as a default',
    receiverName: 'Receiver Name',
    receiverNo: 'Receiver Mobile Number',
    customizedWriting: 'Customized Name',
    from: 'From',
    to: 'To',
    message: 'Message',
    searchGiphy: 'Search GIPHY',
    noStickersAvailable: 'No Stickers Available',
  },

  labels: {
    area: 'Area',
    block: 'Block',
    street: 'St',
    house: 'House',
    floor: 'Floor',
    avenue: 'Jadda',
    guidance: 'Extra Guidance',
    home: 'Home',
    work: 'Work',
    friend: 'Friend',
  },

  alerts: {
    whoops: 'WHOOPS',
  },
  // components.
  cartIndicator: {
    cart: 'Cart',
    delivery: 'Delivery',
    payment: 'Payment',
    order: 'Order',
    thankYou: 'Thank You',
  },

  cartTotal: {
    subtotal: 'Order Subtotal',
    shippingFee: 'Shipping Fee',
    discount: 'Coupon Discount',
    giftWrapping: 'Gift Wrapping',
    orderTotal: 'Order Total',
    productTotal: 'Product Total',
    tax: 'Value Added Tax',
    fee: 'Cash On Delivery Fee',
  },
  timezone: {
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
  },
  giftWrapping: {
    wrapOptions: 'Wrap Options',
    wrapPreview: 'Wrap Preview',
    dVision: '3D Vision',
    title: 'Gift Wrapping',
    addOnsTitle: 'Add-ons',
    addOnsSubTitle: 'Optional',
    kwd: 'KWD',
    wrappingPaperTitle: 'Wrapping paper',
    messageCardsTitle: 'Message cards',
    addMessageCardsTitle: 'Your message card',
    addMessageCardsSubTitle: 'Add free or premium',
    addCard: 'Add Card',
    editCard: 'Edit Card',
    addMessageCardsButton: 'Add a new message card',
    addNewMessageCardsButton: 'Add additional message card',
    add: 'Add',
    addMessage: 'Add Message',
    addOnProductOptions: 'Product options',
    PaperOptions: 'Select at least one item from your cart',
    kwdPerPiece: 'KWD / Piece',
    from: 'From',
    to: 'To',
    typeMessage: 'Type message here',
    startGiftWrapping: 'Start Gift Wrapping',
    startGiftWrappingSub: 'Make it an awesome gift!',
    paper: 'Add Wrapping Paper',
    edit: 'Edit Gift Wrapping',
    delete: 'Delete Gift Wrapping',
    noItemsTitle: 'No items in the cart to wrap.',
    startingFrom: 'From',
    editAlert: 'Are you sure you want to discard your unsaved changes?',
    selectWrapping: 'Select Products',
    selectAddons: 'Select Add-ons',
    continueGiftwrapping: 'Do you want to continue your gift wrapping?',
    alertGiftwrapping:
      "You haven't finished your wrapping yet. Do you want to get back to the cart and discard changes?",
    product: 'products',
    chooseProducts: 'Choose Gift Products',
    confirmProducts: 'Confirm Gift Products',
    simple: 'Simple',
    diy: 'Design it',
    diyContent: 'Build your own 🔥',
    custom: 'Personalized',
    faces: 'Faces',
    placeholder: 'placeholder',
    pattern: 'pattern',
    stickersOptions: 'Stickers Options',
    color: 'Color',
    stickers: 'Stickers',
    photos: 'Photos',
    chooseProductsToWrap: 'Choose the products to wrap together',
    hint: 'Put images in positions.\nPinch to crop. ',
    notAllowedProducts: 'Not be wrapped for its large size',
    notAllowedProduct: "can't be wrapped",
    wrappingProducts: 'Wrapping products',
    busy: 'busy',
    addSticker: 'Add Sticker',
    text: 'Text',
    done: 'Done',
    writeSomthing: 'Write something…',
    startDesign: 'Start designing',
    addYourFace: 'Add your face 🧒 & choose millions of stickers',
  },
  notificationsAccessModal: {
    title: 'Notification access',
    description:
      'Enjoy our secret deals and the ability to track your orders when you allow notifications access.',
  },
  settings: {
    accountSettings: 'Account Settings',
    appLanguage: 'Language',
    lang: 'عربي',
    country: 'Country',
    manageShippingAddresses: 'Manage Shipping Address',
    Save: 'Save',
    kuwait: 'Kuwait',
    needHelp: 'Need help? Contact Us',
  },
  MAP: {
    TITLE: 'Is this pin in the right place?',
    SUB_TITLE: 'Help your driver find you by adding an accurate location',
    OPEN_SETTINGS_ALERT_MESSAGE:
      'To use your current location, you should enable share location in Settings, go to settings?',
    ALLOW_LOCATION: 'Allow Location',
    ALLOW_LOCATION_MESSAGE: 'We need your permission to access your location.',
  },
  GALLERY: {
    OPEN_SETTINGS_ALERT_MESSAGE:
      'To use your photos, you should enable share gallery in Settings, go to settings?',
  },
  STORY: {
    VIEW_PRODUCT: 'View Product',
  },

  GWProductCard: {
    message: 'Too large to wrap',
  },
  GiftCard: {
    giftCard: 'Gift Card',
    cardAddedSuccessfully: 'Card Added Successfully!',
    cardUpdatedSuccessfully: 'Card Updated Successfully!',
  },
  GiftWrapItems: {
    gifts: 'Gifts',
    unWrappedProducts: 'UnWrapped Products',
    confirmWrapping: 'Confirm Wrapping',
    giftWrapping: 'Gift Wrapping',
    wrapAsGift: 'Wrap as gift',
    addAddOns: 'Add Add-ons',
    wrappedProducts: 'Wrapped Products',
  },
  GWAddons: {
    confirmAddons: 'Confirm Addons',
    addons: 'Addons',
  },
  GWDecision: {
    continueWithoutGW: 'Continue without wrapping 🙈',
    giftWrapping: 'Gift Wrapping 🎁',
    distinctivePW: 'Do you want a distinctive product wrapping?',
  },
  GWCutout: {
    customGiftWrap: 'Custom Gift Wrap',
    diy: 'Do It Yourself',
    emptyStateStickers: '1.000.000+ images available',
    moreThanBillionImages: 'More than a billion images',
  },
  DeleteWrapperModal: {
    deleteWrapper: 'Delete wrapper',
    back: 'Back',
    deleteProductWrapping: 'Delete Product Wrapping',
    delete: 'Delete',
    deletingPackagingPermanently:
      'Deleting product packaging will permanently lose all packaging changes.',
  },
  otp: {
    verificationCode: 'Verification Code',
    pleaseTypeOtp: 'Please type the OTP code sent to your mobile number',
    resendCode: 'Resend Otp',
    invalidOtp: 'Invalid Otp',
    validOtp: 'Valid Otp',
  },
  addons: {
    itemSuccess: 'Added to Cart',
    AddAddonsToOrder: 'Add party props to your order',
    decorateYourOrder: 'Decorate your order by purchasing addons ',
    addonsTypes: '(Balloons, candles, decorations and birthday hats)',
    addAddons: 'Add Addons',
    AddEditAddons: 'Add/Edit Addons',
    addons: 'Addons',
    totalAddons: 'Total addons',
    buyAddons: 'Buy Addons',
  },
  navBar: {
    allCategories: 'All Categories',
    products: 'products',
    from: 'from',
  },
  paymentSummary: {
    subtotal: 'Subtotal',
    discount: 'Discount',
    creditPay: 'Credit Pay',
    delivery: 'Delivery',
    total: 'Total',
    checkout: 'Checkout',
  },
  wrapAsGift: {
    wrapAsGift: 'Wrap as a gift?',
    subTitle:
      'Choose from our wide variety of wrapping.\nAdd your child’s face!',
  },
  navBar: {
    allCategories: 'All Categories',
    products: 'products',
    from: 'from',
  },
  footer: {
    rightsReserved: '© 2021 Teeela. All Rights Reserved',
  },
  countries: {
    KW: 'Kuwait',
    SA: 'Saudi Arabia',
    AE: 'United Arab Emirates',
    QA: 'Qatar',
    OM: 'Oman',
    BH: 'Bahrain',
  },
  DeleteModal: {
    deleteWrapper: 'Delete wrapper',
    back: 'Back',
    delete: 'Delete',
  },
  pagination: {
    previous: '←   Previous',
    next: 'Next    →',
  },
};
