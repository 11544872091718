import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    width: 347,
    height: 102,
    alignSelf: 'center',
    borderRadius: 16,
    flexDirection: 'row',
    marginBottom: 8,
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingStart: 14,
    paddingEnd: 10,
    //fix shadow colors
    borderColor: colors.overlayColor,
    shadowColor: colors.brandShadow,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 24,
    shadowOpacity: 1,
    ...Platform.select({
      android: {
        elevation: 2,
        shadowColor: colors.black,
      },
    }),
  },
  shadowContainer: {
    shadowColor: colors.grey300,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    ...Platform.select({
      android: {
        elevation: 3,
        shadowColor: colors.black,
      },
    }),
  },
  image: {
    width: 79,
    height: 75,
    borderRadius: 16,
    marginEnd: 16,
  },
  dataContainer: {
    flex: 1,
    height: 102,
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 10,
  },
  viewRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  productName: {
    color: colors.black,
    flex: 1,
  },
  price: {
    color: colors.grey900,
    marginBottom: 2,
  },
  deliveryMessage: {
    color: colors.grey120,
  },
  textWarning: {
    color: colors.red600,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  decrementContainer: {
    padding: 5,
  },
  incrementContainer: {
    padding: 5,
    paddingEnd: 0,
  },
  qytButton: {
    width: 25,
    height: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12.5,
    backgroundColor: colors.greenSecondary,
  },
  disabledStyle: {
    backgroundColor: colors.grey130,
  },
  qtyContainer: {
    paddingHorizontal: 4,
    justifyContent: 'center',
  },
  qtyText: {
    color: colors.black,
    maxWidth: 60,
  },
  outOfStockOpacity: {
    opacity: 0.5,
  },
  deleteButton: {
    alignSelf: 'flex-start',
    width: 30,
    alignItems: 'flex-end',
  },
  deleteImage: {
    width: 20,
    height: 22,
  },
  priceAndMessageContainer: {
    flex: 1,
  },
  checkMark: {
    width: 21,
    height: 21,
    borderRadius: 10.5,
    borderWidth: 0,
    alignSelf: 'flex-end',
    top: 6,
  },
  border: {
    borderWidth: 1,
    backgroundColor: colors.purpleSecondary,
  },
});
