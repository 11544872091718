import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const REMOVE_COUPON_FROM_CART = gql`
  mutation($cartId: String!) {
    removeCouponFromCart(input: { cart_id: $cartId }) {
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`;
