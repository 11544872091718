import React, { useRef } from 'react';
import {
  View,
  // FlatList,
  StyleSheet,
  Platform,
  ScrollView,
  // ViewPropTypes as RNViewPropTypes,
} from 'react-native';
import { ShimmerList, ProductCard, EmptyScreen } from '../index';
import PropTypes from 'prop-types';
import Loading from '../Loading';
// import Animated from 'react-native-reanimated';
import { useScrollToTop } from '@react-navigation/native';
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from 'recyclerlistview';
import { isMobile } from '../../services';

// const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

// const ViewPropTypes = RNViewPropTypes || View.propTypes;
const ScrollViewWithHeader = React.forwardRef(
  ({ children, renderHeader, ...props }, ref) => {
    return (
      <ScrollView ref={ref} {...props}>
        {renderHeader?.()}
        {children}
      </ScrollView>
    );
  },
);

const ProductsList = (props) => {
  const {
    style,
    data,
    moreLoading,
    loading,
    contentContainerStyle,
    onPressItem,
    errorMessage,
    navigation,
  } = props;
  const noData = data?.length == 0;
  const ref = useRef(null);
  useScrollToTop(ref);
  let dataProvider = new DataProvider((r1, r2) => {
    return r1 !== r2;
  }).cloneWithRows(data);
  const _layoutProvider = new LayoutProvider(
    (index) => {
      return index;
    },
    (type, dimension) => {
      dimension.width = isMobile ? innerWidth / 2 : 202;
      dimension.height = 349;
    },
  );
  return (
    <View style={[styles.container, style]}>
      {loading ? (
        <ShimmerList
          loading={loading}
          num={isMobile ? 6 : 15}
          style={{ width: 176 }}
        />
      ) : (
        <>
          {noData ? (
            <EmptyScreen errorMessage={errorMessage} />
          ) : (
            <RecyclerListView
              ref={ref}
              layoutProvider={_layoutProvider}
              dataProvider={dataProvider}
              keyExtractor={(item, index) => index.toString()}
              renderFooter={() => (
                <View style={styles.loadingStyle}>
                  {moreLoading && <Loading />}
                </View>
              )}
              rowRenderer={(type, item, index) => (
                <ProductCard
                  index={index}
                  item={item}
                  onPressItem={() => onPressItem(item)}
                  navigation={navigation}
                />
              )}
              keyboardShouldPersistTaps={'always'}
              removeClippedSubviews={Platform.OS == 'android'}
              {...props}
              externalScrollView={ScrollViewWithHeader}
              contentContainerStyle={[
                styles.contentContainerStyle,
                contentContainerStyle,
              ]}
            />
          )}
        </>
      )}
    </View>
  );
};

ProductsList.propTypes = {
  // style: ViewPropTypes.style,
  // contentContainerStyle: ViewPropTypes.style,
  data: PropTypes.array.isRequired,
  numColumns: PropTypes.number,
  loading: PropTypes.bool,
  moreLoading: PropTypes.bool,
  online: PropTypes.bool,
  onPressItem: PropTypes.func,
};

ProductsList.defaultProps = {
  numColumns: 2,
  loading: true,
};

export default ProductsList;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    justifyContent: 'space-between',
    marginTop: 8,
    paddingBottom: 20,
    alignSelf: 'center',
  },
  loadingStyle: {
    height: 40,
  },
});
