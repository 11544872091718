import types from './types';

//create initial state
const initialState = {
  token: null,
  loggedIn: false,
  navigateTo: '',
  info: {}, // full user info including all addresses.
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN.SUCCESS:
      return {
        ...state,
        token: action.payload,
        loggedIn: true,
      };

    case types.USER_LOGOUT:
      return initialState;

    case types.SET_AUTH_NAVIGATE:
      return {
        ...state,
        navigateTo: action.payload,
      };
    case types.USER_SIGNUP.SUCCESS: // this also returns customer data.
      return {
        ...state,
        token: action.payload.token,
      };
    case types.GET_USER_INFO.SUCCESS:
      return {
        ...state,
        info: action.payload, // including all addresses.
      };
    case types.EDIT_USER_PROFILE.SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
