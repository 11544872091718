import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const UPDATE_CART_ITEMS = gql`
  mutation($cartId: String!, $cart_item_id: Int!, $quantity: Float!) {
    updateCartItems(
      input: {
        cart_id: $cartId
        cart_items: { cart_item_id: $cart_item_id, quantity: $quantity }
      }
    ) {
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`;
