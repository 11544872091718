import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { scale, vScale } from '../../services';

export default StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  headerContent: {
    alignItems: 'center',
  },
  headerTextContent: {
    marginTop: vScale(60),
    alignItems: 'center',
  },
  button: {
    marginHorizontal: scale(15),
    marginVertical: vScale(26),
  },
  image: {
    width: scale(242),
    height: vScale(170),
    marginTop: vScale(16),
  },
  headerText: {
    fontWeight: 'bold',
    lineHeight: 26,
    letterSpacing: -1.14,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
  bodyTextContainer: {
    marginTop: vScale(24),
    width: scale(287),
  },
  bodyText: {
    fontWeight: '500',
    lineHeight: 22,
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
  bodyTextBold: {
    fontWeight: 'bold',
    lineHeight: 22,
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
  orderCardContainer: {
    marginTop: vScale(28),
    marginHorizontal: scale(5),
  },
  orderInfoTableContainer: {
    marginBottom: vScale(10),
    marginHorizontal: scale(22),
  },
  orderInfoTable: {
    marginTop: vScale(8),
  },
  successMsg: {
    marginTop: vScale(8),
  },
});
