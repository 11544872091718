import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/order';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import {
  Text,
  Image,
  Screen,
  OrderCard,
  Button,
  TableCard,
} from '../../components';
import { images } from '../../common';
import { I18n } from '../../locales';
// import {
//   isNotificationAccessGranted,
//   vibrateSuccess,
// } from '../../services/nativeFeatures';
import styles from './styles';
import { replace } from '../../services';
import { loadingSelector } from '../../common/utils';

const SuccessOrder = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => {
    return {
      loading: loadingSelector(state, [types.GET_ORDER_DETAILS]),
    };
  });
  const [orderDetails, setOrderDetails] = useState({});
  const transactionData = JSON.parse(route?.params?.params) || {};
  const { paymentid, tranid } = transactionData;

  // useLayoutEffect(() => {
  //   navigation.setOptions({
  //     headerTitle: I18n.t('successOrder.title'),
  //     gesturesEnabled: false,
  //     headerLeft: () => null,
  //   });
  // }),
  //   [navigation];

  useEffect(() => {
    fetchOrderDetails();
    // vibrateSuccess();
  }, []);

  const fetchOrderDetails = async () => {
    try {
      const orderData = await dispatch(actions.getOrderDetails());
      setOrderDetails(orderData.payload);
    } catch (error) {
      console.warn('error', error);
    }
  };

  const navigateToHome = () => {
    // checkNotificationNotAllowed();
    replace();
  };

  // const checkNotificationNotAllowed = async () => {
  //   const isNotificationNotAllowed = await isNotificationAccessGranted();
  //   !isNotificationNotAllowed
  //     ? navigation.navigate('NotificationAccess')
  //     : navigateTo();
  // };

  const navigateToOrderDetails = () => {
    const { id, increment_id } = orderDetails;
    // navigation.navigate('OrderDetails', { id, increment_id });
  };

  return (
    <Screen isLoading={loading}>
      <View style={styles.content}>
        <ScrollView>
          <View style={styles.headerContent}>
            <Image source={images.successOrder} style={styles.image} />

            <View style={styles.headerTextContent}>
              <Text size="larger" style={styles.headerText}>
                {I18n.t('successOrder.header')}
              </Text>

              <View style={styles.bodyTextContainer}>
                <Text style={styles.bodyText}>
                  {I18n.t('successOrder.orderNumberTitle')}:{' '}
                  <Text style={styles.bodyTextBold}>
                    {`#${orderDetails.increment_id || ''}`}
                  </Text>
                </Text>
                <View style={styles.successMsg}>
                  <Text style={styles.bodyText}>
                    {I18n.t('successOrder.successMsg')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <OrderCard
            order={orderDetails}
            style={[styles.orderCardContainer, loading && { opacity: 0 }]}
            onPress={() => navigateToOrderDetails()}
          />
          <View style={styles.orderInfoTableContainer}>
            <TableCard
              title={I18n.t('successOrder.paymentId')}
              text={paymentid}
            />
            <TableCard
              title={I18n.t('successOrder.transactionId')}
              text={tranid}
            />
          </View>
          <Button
            text={I18n.t('successOrder.goToHomepage')}
            containerStyle={styles.button}
            onPress={() => navigateToHome()}
          />
        </ScrollView>
      </View>
    </Screen>
  );
};

export default SuccessOrder;
