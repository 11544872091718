import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  View,
  ScrollView,
  SafeAreaView,
  RefreshControl,
  TouchableOpacity,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useCart, types as CartTypes } from '../../redux/useCart';
import { actions as errorsActions } from '../../redux/errors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import * as _ from 'lodash';
import {
  Button,
  Text,
  Image,
  Spinner,
  SecondaryButton,
  CartTotal,
  CouponCode,
  CartItemsLabel,
  CartItemMobile,
  DeleteModal,
  DrawerWithBackIcons,
} from '../../components';

import styles from './mobileStyle';
import { I18n } from '../../locales';
import { colors, images } from '../../common';
import {
  isAvailable,
  navigateToProduct,
  sHeight,
  useHeaderHeight,
  navigateToManageAddresses,
  navigateToAddress,
  navigateToLogin,
} from '../../services';
import {
  errorMsgSelector,
  getPriceFormat,
  loadingSelector,
  showErrorMsg,
  showSuccessMsg,
} from '../../common/utils';
import { actions as addressAction } from '../../redux/address';
// import { Header } from '@react-navigation/elements';

const Cart = ({ navigation, route, options }) => {
  const scrollRef = useRef();
  const dispatch = useDispatch();
  // const dd = Header(options, route.name);
  // console.warn('Header()', ...dd);
  const { HEADER_HIGHT_WITH_BOTTOM_AREA } = useHeaderHeight();
  const {
    user,
    cart,
    addCartError,
    loading,
    config: { country, lang },
    address,
  } = useSelector((state) => {
    return {
      user: state.user,
      cart: state.cartState,
      addCartError: errorMsgSelector(state, [CartTypes.UPDATE_CART_ITEMS]),
      loading: loadingSelector(state, [
        CartTypes.GET_CART_INFO,
        CartTypes.UPDATE_CART_ITEMS,
        CartTypes.REMOVE_CART_ITEM,
        CartTypes.DELETE_CART_GQL,
      ]),
      config: state.config,
      address: state.address,
    };
  });
  const {
    removeItemFromCart_GQL,
    updateCartItems_gql,
    addCouponCode_gql,
    removeCoupon,
    getCartInfo_GQL,
    clearCart_GQL,
    deleteCart_GQL,
  } = useCart();
  const [clearCartVisible, setClearCartVisible] = useState(false);
  const [showStockWarning, setShowStockWarning] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [refreshing, setRefresh] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const hasDiscount = cart?.applied_coupons?.length;
  const subTotal = cart?.prices?.grand_total?.value;
  const addonsItems = cart?.addonsItems;
  const items = cart.items;
  const { isFromLogin = false } = JSON.parse(route?.params?.params);
  useEffect(() => {
    I18n.locale = lang;
    _initialFetch();
    checkProductsStatus();
  }, []);
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  useEffect(() => {
    checkProductsStatus();
  }, [cart]);

  const _initialFetch = async () => {
    getCartInfo_GQL();
    if (hasDiscount) {
      setCouponCode(cart.applied_coupons[0].code);
    }
  };

  // call onProceedToCheckout after login
  useEffect(() => {
    if (isFromLogin) {
      onProceedToCheckout();
    }
  }, [isFromLogin]);

  const checkProductsStatus = () => {
    const isAllInStock = cart.items?.every((it) => isAvailable(it));
    //if any item is out of stock show warning box
    if (!isAllInStock) {
      setShowStockWarning(true);
      return false;
    } else {
      setShowStockWarning(false);
      return true;
    }
  };

  // show toast on add cart error.
  useEffect(() => {
    if (addCartError && navigation.isFocused()) {
      showErrorMsg(addCartError);
      setTimeout(() => dispatch(errorsActions.resetError()), 3000);
    }
  }, [addCartError]);

  const onProceedToCheckout = async () => {
    const allProductsInStock = checkProductsStatus(true);
    if (allProductsInStock) {
      // if (hasWrapRequest || disabledWrap) {
      if (user.loggedIn) {
        const address = await dispatch(addressAction.getAddresses());
        const userHasNoAddress = address?.payload?.length == 0;
        if (userHasNoAddress) {
          navigateToAddress({ isCheckout: true });
        } else {
          navigateToManageAddresses({ isCheckout: true });
        }
      } else {
        // guest user.
        navigateToLogin({ fromCart: true });
      }
    }
  };

  const onAddToCart = (item) => {
    const { quantity, originalQuantity } = item;
    updateCartItems_gql({
      cart_item_id: item.id,
      quantity: (originalQuantity || quantity) + 1,
    });
  };

  const onSubtractFromCart = (item) => {
    const { quantity, originalQuantity, product } = item;
    const updateCount = isAvailable(item)
      ? (originalQuantity || quantity) - 1
      : product?.only_x_left_in_stock;
    updateCartItems_gql({
      cart_item_id: item.id,
      quantity: updateCount,
    });
  };

  const onDeleteItem = async (item) => {
    try {
      const resp = await removeItemFromCart_GQL(item.id);
      if (!resp?.payload?.id) {
        showErrorMsg(resp?.payload);
      } else {
      }
    } catch (error) {
      showErrorMsg();
      console.log('error', error);
    }
  };

  const onStockMsgDismiss = () => {
    setShowStockWarning(false);
  };

  const handleCouponAction = (code) => {
    hasDiscount ? onRemoveCoupon() : onAddCouponCode(code);
  };

  const onAddCouponCode = (code) => {
    setCouponLoading(true);
    addCouponCode_gql(code).then((res) => {
      if (res && !res.error) {
        // show success message
        showSuccessMsg(I18n.t('cart.couponCodeSuccess'));
        setCouponLoading(false);
      } else {
        setCouponLoading(false);
      }
    });
  };

  const onRemoveCoupon = () => {
    setCouponLoading(true);
    removeCoupon().then((res) => {
      if (res && !res.error) {
        showSuccessMsg(I18n.t('cart.deleteCouponCodeSuccess'));
        setCouponLoading(false);
        setCouponCode('');
      } else {
        showErrorMsg(I18n.t('errors.errorMessage'));
        setCouponLoading(false);
      }
    });
  };
  const onRefresh = async () => {
    setRefresh(true);
    await getCartInfo_GQL();
    setRefresh(false);
  };

  const cartItemPress = (item) => {
    const sku = item?.product?.sku;
    const name = item?.product?.name;
    navigateToProduct(sku, name);
  };

  const onClearCart = async () => {
    onCloseClearCart();
    clearCart_GQL();
    if (user.loggedIn) {
      await deleteCart_GQL();
    }
  };

  const onCloseClearCart = () => {
    setClearCartVisible(false);
  };

  const containerStyle = {
    minHeight: sHeight - (HEADER_HIGHT_WITH_BOTTOM_AREA + 80),
  };
  if (loading) return <Spinner />;
  return (
    <View style={styles.mainContainer}>
      {cart?.items?.length || addonsItems?.length ? (
        <View style={styles.mainContainer}>
          <ScrollView
            ref={scrollRef}
            contentContainerStyle={containerStyle}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                tintColor={colors.brand}
                onRefresh={onRefresh}
              />
            }
          >
            <View>
              {/* Warning Toast */}
              {showStockWarning && (
                <View style={styles.cartDangerMsg}>
                  <View style={styles.warnContainer}>
                    <Icon
                      name="info"
                      color={colors.brand}
                      style={styles.infoIcon}
                      size={24}
                    />
                    <Text size="small" style={styles.outOfStockText}>
                      {I18n.t('cart.outOfStockMsg')}
                    </Text>
                  </View>
                  <Button
                    onPress={onStockMsgDismiss}
                    text={I18n.t('cart.dismiss')}
                  />
                </View>
              )}
              {/* Cart Items */}
              <CartItemsLabel
                label={I18n.t('products.title')}
                counter={items.length}
              />
            </View>

            {items?.length > 0 &&
              items.map((item, index) => {
                return (
                  <CartItemMobile
                    {...{ item }}
                    key={index}
                    onPress={() => cartItemPress(item)}
                    onIncrement={() => onAddToCart(item)}
                    onDecrement={() => onSubtractFromCart(item)}
                    onDelete={() => onDeleteItem(item)}
                  />
                );
              })}
            <TouchableOpacity
              onPress={() => setClearCartVisible(true)}
              style={styles.clearCart}
            >
              <Text style={styles.clearCartText}>
                {I18n.t('cart.clearCart')}
              </Text>
            </TouchableOpacity>
            {clearCartVisible && (
              <DeleteModal
                isVisible={clearCartVisible}
                onClose={onCloseClearCart}
                onPress={onClearCart}
                title={I18n.t('cart.clearCart')}
                subTitle={I18n.t('cart.deleteAllProducts')}
              />
            )}
            <View style={styles.footerContainer}>
              {/* Coupon Code Input */}
              <CouponCode
                onApply={handleCouponAction}
                loading={couponLoading}
                isCouponValid={hasDiscount}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
              />
              {/* Cart Totals */}
              <CartTotal
                totals={cart?.prices}
                currency={country.currencyCode}
                isCartPage
              />
            </View>
          </ScrollView>
          <SafeAreaView style={styles.saveArea}>
            <SecondaryButton
              text={`${I18n.t('btns.checkout')}  ${getPriceFormat(subTotal)}`}
              onPress={onProceedToCheckout}
              containerStyle={styles.ctaBtnGrd}
            />
          </SafeAreaView>
        </View>
      ) : (
        <>
          {/* Empty Cart Screen */}
          <View style={styles.middleView}>
            <Image source={images.emptyCart} style={styles.emptyCart} />
            <View>
              <Text
                size="large"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {I18n.t('cart.emptyCart')}
              </Text>
              <Text style={styles.descText}>
                {I18n.t('cart.startShoppingItems')}
              </Text>
            </View>
            {/* <Button
              text={I18n.t('cart.startShopping')}
              onPress={() => navigateTo('categories')}
            /> */}
          </View>
        </>
      )}
    </View>
  );
};

export default Cart;
