import React from 'react';
import {
  View,
  TextInput,
  StyleSheet,
  Platform,
  ViewPropTypes as RNViewPropTypes,
} from 'react-native';
import PropTypes from 'prop-types';
import { colors } from '../../common';
import { normalizeSize } from '../../common/utils';
import Text from '../Text';
import { isRTL } from '../../services';

const ViewPropTypes = RNViewPropTypes || View.propTypes;

class CustomInput extends React.Component {
  static propTypes = {
    // activeContainerStyle: ViewPropTypes.style,
    // containerStyle: ViewPropTypes.style,
    // style: ViewPropTypes.style,
    // containerWrapperStyle: ViewPropTypes.style,
    leftIcon: PropTypes.node,
    // leftIconStyle: ViewPropTypes.style,
    rightIcon: PropTypes.node,
    // rightIconStyle: ViewPropTypes.style,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
  };
  static defaultProps = {
    activeContainerStyle: { borderColor: colors.brand },
    placeholder: '',
  };
  state = {
    isFocused: false,
    value: '',
  };

  focus = () => {
    this.input.focus();
  };

  blur = () => {
    this.input.blur();
  };

  isFocused = () => {
    return this.input.isFocused();
  };

  clear = () => {
    // If one doesn't work, other will.
    this.input.clear();
    this.input.setNativeProps({ text: '' });
  };

  onFocus = () => {
    this.setState({ isFocused: true });
    this.props.onFocus && this.props.onFocus();
  };

  onBlur = () => {
    this.setState({ isFocused: false });
    this.props.onBlur && this.props.onBlur();
  };

  render() {
    const {
      containerWrapperStyle,
      disabled,
      containerStyle,
      leftIcon,
      leftIconStyle,
      rightIcon,
      rightIconStyle,
      activeContainerStyle,
      errorMessage,
      style,
      label,
      hideUnderline,
      ...rest
    } = this.props;
    return (
      <View style={[containerWrapperStyle]}>
        {label ? (
          <Text style={styles.label} size="smaller">
            {label}
          </Text>
        ) : null}
        <View
          style={[
            styles.container,
            containerStyle,
            this.state.isFocused && !hideUnderline && activeContainerStyle,
          ]}
        >
          {leftIcon && (
            <View style={[{ marginRight: 15 }, leftIconStyle]}>{leftIcon}</View>
          )}
          <TextInput
            {...rest}
            placeholderTextColor={colors.warmGrey}
            ref={(input) => (this.input = input)}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            autoCorrect={false}
            autoCapitalize={'none'}
            underlineColorAndroid="transparent"
            style={[styles.input, style && style]}
          />

          {rightIcon && (
            <View style={[{ marginLeft: 15 }, rightIconStyle]}>
              {rightIcon}
            </View>
          )}
        </View>
        {errorMessage && (
          <Text size="smaller" style={{ color: colors.red600 }}>
            {errorMessage}
          </Text>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: colors.grey300,
    borderBottomWidth: 1,
    height: 42,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 1,
  },

  input: {
    flex: 1,
    height: '100%',
    alignSelf: 'center',
    color: '#212121',
    fontFamily: isRTL() ? 'Cairo-Regular' : 'Gotham Rounded',
    fontSize: normalizeSize(12),
    letterSpacing: 0.5,
    textAlign: isRTL() ? 'right' : 'left',
    ...Platform.select({
      android: {
        transform: [{ translateY: 1.2 }],
      },
      ios: {
        transform: [{ translateY: 0.2 }],
      },
    }),
    outlineStyle: 'none',
  },
  label: {
    color: colors.warmGrey,
  },
});

export default CustomInput;
