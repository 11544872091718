import { gql } from '@apollo/client';
import { CheckoutFragment } from './CheckoutFragment';

export const SET_SHIPPING_METHOD = gql`
  mutation setShippingMethodsOnCart(
    $cartId: String!
    $shipping_methods: ShippingMethodInput!
  ) {
    setShippingMethodsOnCart(
      input: { cart_id: $cartId, shipping_methods: [$shipping_methods] }
    ) {
      cart {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
