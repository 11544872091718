import React from 'react';
import { View, Image } from 'react-native';
import { config, colors } from '../../common';
import { Text } from '../index';
import styles from './styles';
import * as _ from 'lodash';
import { getPriceFormat } from '../../common/utils';

const ProductVariationSummary = ({ selectedOption, option_products }) => {
  if (!selectedOption) return null;
  const { price_range, sku, small_image } = selectedOption;
  const price = price_range?.maximum_price?.regular_price?.value;
  const product = _.find(option_products, (e) => e?.product?.sku == sku);
  const value = product?.attributes[0]?.label || null;
  return (
    <View style={styles.container}>
      <Image
        source={{ uri: small_image?.url }}
        placeholder={config.placeholderImage}
        style={styles.image}
        resizeMode="contain"
      />
      <View>
        <Text style={{ fontWeight: '600' }}>{getPriceFormat(price)}</Text>
        <View style={styles.textView}>
          <Text size="small" style={{ color: colors.grey500 }}>
            Selected:&nbsp;
          </Text>
          <Text size="small">{value}</Text>
        </View>
      </View>
    </View>
  );
};
export default ProductVariationSummary;
