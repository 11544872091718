import React from 'react';
import { Dimensions, PixelRatio, I18nManager, BackHandler } from 'react-native';
import is from 'is_js';
import { I18n } from './../locales';

const { width, height } = Dimensions.get('window');
import _ from 'lodash';
import toast from 'react-hot-toast';
import { store } from '.';
const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

let currencyCode = 'KWD';
export const setCurrencyCode = (currentCurrencyCode) => {
  currencyCode = currentCurrencyCode;
};

export const stripHTML = (htmlText) => {
  if (htmlText) {
    const regex = /(<([^>]+)>)/gi;
    const pureText = htmlText.replace(regex, '');
    return pureText;
  }
};

export const validateEmail = (email) => {
  return isEmail(email);
};

export const validateNumber = (text) => {
  if (!text) return false;
  return is.number(Number(text));
};

export const parseArabicNum = (str) => {
  // Ref: https://stackoverflow.com/a/17025392/2092405
  return Number(
    str
      .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
        return d.charCodeAt(0) - 1632; // Convert Arabic numbers
      })
      .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
        return d.charCodeAt(0) - 1776; // Convert Persian numbers
      }),
  );
};

// parseQuery(queryString) {
//   var query = {};
//   var pairs = (queryString[0] === '?'
//     ? queryString.substr(1)
//     : queryString
//   ).split('&');
//   for (var i = 0; i < pairs.length; i++) {
//     var pair = pairs[i].split('=');
//     query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
//   }
//   return query;
// },

// mergeArrayObj(arrObj) {
//   const resultObj = arrObj.reduce(function (result, currentObject) {
//     for (var key in currentObject) {
//       if (currentObject.hasOwnProperty(key)) {
//         result[key] = currentObject[key];
//       }
//     }
//     return result;
//   }, {});

//   return resultObj;
// },
export const createTypes = (base) => {
  const requestType = {};
  ['REQUEST', 'SUCCESS', 'ERROR'].forEach((type) => {
    requestType[type] = `${base}_${type}`;
  });
  return requestType;
};
// loading selector loaders reducer
export const loadingSelector = (state, types) => {
  // you can combine multiple actions too: call like this: (state, [types.GET_TODOS, types.GET_USERS]);
  // return when atleast one action is true.
  return types.some((type) => {
    const base = type.REQUEST.replace('_REQUEST', '');
    return state.loaders[base];
  });
};
export const showErrorMsg = (message = I18n.t('errors.errorMessage')) => {
  console.log('Error message from showErrorMsg():  ', message);
  toast.error(message, { duration: 4000 });
};

export const errorMsgSelector = (state, types) => {
  const errors = types.map((type) => {
    const base = type.ERROR.replace('_ERROR', '');
    return state.errors[base];
  });
  //  returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return errors && errors[0] ? errors[0] : '';
};

export const showSuccessMsg = (message = I18n.t('btns.done')) => {
  toast.success(message, { duration: 4000 });
};

// // FSA complaint redux actions.
// // See: https://redux-actions.js.org/api-reference/createaction-s
// // Generate action : {type, payload (optional), error (optional), meta (optional)}
export const createAction = (type, payload, meta) => {
  if (/(.*)_(FAILURE|ERROR)/.test(type)) {
    let errorMessage = null;

    if (payload)
      errorMessage = payload.message || I18n.t('errors.errorMessage');

    return { type, payload: errorMessage, error: true };
  } else {
    let obj = { type };
    if (typeof meta === 'object' && Object.keys(meta).length)
      obj = { ...obj, meta };
    if (payload) obj = { ...obj, payload };
    return obj;
  }
};

export const capitalize = (str) => {
  if (typeof str === 'string')
    return str.charAt(0).toUpperCase() + str.slice(1);
  else return str;
};
// // https://github.com/react-native-training/react-native-elements/blob/master/src/helpers/normalizeText.js
export const normalizeSize = (size) => {
  const pixelRatio = PixelRatio.get();
  const deviceHeight = height;
  const deviceWidth = width;

  if (pixelRatio >= 2 && pixelRatio < 3) {
    // iphone 5s and older Androids
    if (deviceWidth < 360) {
      return size * 0.95;
    }
    // iphone 5
    if (deviceHeight < 667) {
      return size;
      // iphone 6-6s
    } else if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.15;
    }
    // older phablets
    return size * 1.25;
  } else if (pixelRatio >= 3 && pixelRatio < 3.5) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
    }
    // Catch other weird android width sizings
    if (deviceHeight < 667) {
      return size * 1.15;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.2;
    }
    // catch larger devices
    // ie iphone 6s plus / 7 plus / mi note 等等
    return size * 1.27;
  } else if (pixelRatio >= 3.5) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
      // Catch other smaller android height sizings
    }
    if (deviceHeight < 667) {
      return size * 1.2;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.25;
    }
    // catch larger phablet devices
    return size * 1.4;
  } else {
    // if older device ie pixelRatio !== 2 || 3 || 3.5
    return size;
  }
};

// // magento searchCriteria array map to query string util.
// // convert searchCriteria array to query string for magento api.
export const searchArrayToQuery = (fields, searchCriteria) => {
  const query = [];
  const filterGroups = searchCriteria.filter_groups;
  const sortOrders = searchCriteria.sort_orders;
  const pageSize = searchCriteria.page_size;
  const currentPage = searchCriteria.current_page;

  // query for filter groups.
  if (Array.isArray(filterGroups) && filterGroups.length) {
    filterGroups.map((it, i) => {
      let filters = it.filters;
      filters.forEach((filter, j) => {
        let field = `searchCriteria[filterGroups][${i}][filters][${j}][field]=${filter.field}`;
        let value = `searchCriteria[filterGroups][${i}][filters][${j}][value]=${filter.value}`;
        let condition_type = `searchCriteria[filterGroups][${i}][filters][${j}][condition_type]=${filter.condition_type}`;
        query.push(field, value, condition_type);
      });
    });
  }
  // query for sort orders. (optional)
  if (Array.isArray(sortOrders) && sortOrders.length) {
    sortOrders.forEach((it, i) => {
      query.push(`searchCriteria[sortOrders][${i}][field]=${it.field}`);
      query.push(`searchCriteria[sortOrders][${i}][direction]=${it.direction}`);
    });
  }

  // query for pageSize
  if (typeof pageSize === 'number') {
    query.push(`searchCriteria[pageSize]=${pageSize}`);
  }

  if (typeof currentPage === 'number') {
    query.push(`searchCriteria[currentPage]=${currentPage}`);
  }

  if (typeof fields === 'string' && fields.length > 0) {
    query.push(`fields=${fields}`);
  }

  return query.join('&');
};

// getRouteNameFromUrl: (url) => {
//   const obj = { routeName: '', name: '' };
//   const route = url.replace(/.*?:\/\//g, '');
//   const name = route.match(/\/([^\/]+)\/?$/);

//   if (name) {
//     const routeName = route.split('/')[0];
//     obj.name = name[1];
//     obj.routeName = routeName;
//   }
//   return obj;
// },

// arabicNumber(number) {
//   if (!I18nManager.isRTL) {
//     return number;
//   }

//   const numbers = {
//     0: '٠',
//     1: '١',
//     2: '٢',
//     3: '٣',
//     4: '٤',
//     5: '٥',
//     6: '٦',
//     7: '٧',
//     8: '٨',
//     9: '٩',
//   };

//   let numberString = `${number}`.split(''),
//     arabic = '';

//   numberString.forEach((num) => {
//     if (numbers[num]) {
//       arabic += numbers[num];
//     } else {
//       arabic += num;
//     }
//   });
//   return arabic;
// },

// changeImageExtention(str) {
//   if (str) {
//     let extention = '.png';
//     if (str.endsWith('.jpg')) {
//       extention = '.jpg';
//     }
//     return str.replace(extention, '.webp');
//   }
// },
// handleAndroidBackButton(cb) {
//   BackHandler.addEventListener('hardwareBackPress', cb);
// },

// removeAndroidBackButtonHandler(cb) {
//   BackHandler.removeEventListener('hardwareBackPress', cb);
// },

export const validateUserInputs = (inputs) => {
  const errors = {};
  const { firstname, lastname, email, phone } = inputs;
  if (!firstname) errors.firstname = I18n.t('errors.firstname');
  if (!lastname) errors.lastname = I18n.t('errors.lastname');
  if (!is.email(email)) errors.email = I18n.t('errors.email');
  if (!phone || !is.number(Number(phone))) errors.phone = I18n.t('errors.tel');
  return errors;
};

export const validateUserPassword = (inputs) => {
  const errors = {};
  const { currentPassword, newPassword } = inputs;
  if (!currentPassword) errors.currentPassword = I18n.t('errors.passwordBlank');
  if (!newPassword) errors.newPassword = I18n.t('errors.passwordBlank');
  return errors;
};

export const convertQueryParams = (params) => {
  let paramsStr = '';
  Object.keys(params).map((key) => (paramsStr += `${[key]}=${params[key]}&`));
  paramsStr = paramsStr.substring(0, paramsStr.length - 1);
  return paramsStr;
};

export const getPriceFormat = (price, isShowCurrency = true) => {
  price = Number(price);
  const currencyCode = store.getState().config.country.currencyCode || 'KWD';
  const isKW = currencyCode == 'KWD';
  price = isKW ? price.toFixed(3) : price.toFixed(2);

  return isShowCurrency ? `${price} ${currencyCode}` : price;
};

//return the Separated String from array with a certain separator and ignore the empty array items
export const getSeparatedString = (array, separator = ', ') => {
  return array.filter(Boolean).join(`${separator}`);
};

//return the actual text with the translated label with a certain separator like: "Email: {email}"
export const getTextWithLabel = (label, text, separator = ':') => {
  return text ? `${I18n.t(['labels.' + label])}${separator} ${text}` : '';
};

// generateRandomColor() {
//   return '#' + Math.random().toString(16).slice(-6);
// },

export const pickStories = (
  item,
  keys = ['image_url', 'id', 'video', 'sku', 'title', 'price', 'special_price'],
) => {
  const { id, title, items } = item;
  const stories = _.filter(items, (item) => item.video);
  const pickedStories = _.map(stories, (item) => _.pick(item, ...keys));
  if (pickedStories) return { id, title, stories: pickedStories };
};

// getTheBaseURL(url) {
//   const urlArray = url.split('/');
//   if (urlArray && urlArray.length) return urlArray[2];
// },

export const getURLParams = (url) => {
  let regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
    console.log(match[1], match[2]);
  }
  return params;
};

export const convertRGBToRGBA = (color, opacity) => {
  return `${color.slice(0, 3)}a${color.slice(
    3,
    color.length - 1,
  )}, ${opacity}${color.slice(color.length - 1, color.length)}`;
};
