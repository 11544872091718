import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import * as _ from 'lodash';
// import PropTypes from 'prop-types';
import { RadioButtons } from 'react-native-radio-buttons';
import { ToggleButton } from '..';

const RadioOptionSelection = ({ options, onSelection, optionSelected }) => {
  return (
    <Fragment>
      <RadioButtons
        options={options}
        onSelection={onSelection}
        selectedOption={optionSelected}
        renderOption={(option, selected, onSelect, index) => {
          const isSelected = optionSelected === option.id;
          return (
            <ToggleButton
              key={index}
              onPress={onSelect}
              text={option.name}
              containerStyle={styles.btn}
              isSelected={isSelected}
            />
          );
        }}
        renderContainer={nodes => (
          <View style={styles.optionsContainer}>{nodes}</View>
        )}
      />
    </Fragment>
  );
};

export default RadioOptionSelection;

// RadioOptionSelection.propTypes = {
//   style: PropTypes.object,
//   optionSelected: PropTypes.string,
//   options: PropTypes.array.isRequired,
//   onSelection: PropTypes.func,
// };

const styles = StyleSheet.create({
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  btn: {
    marginBottom: 8,
    marginRight: 10,
  },
});
