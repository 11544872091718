import React from 'react';
import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { RoundedButton } from '..';

export const ToggleButton = props => (
  <RoundedButton
    {...props}
    containerStyle={[
      props.containerStyle,
      !props.isSelected && styles.selectedContainer,
    ]}
    textStyle={!props.isSelected && styles.selectedText}
  />
);

const styles = StyleSheet.create({
  selectedContainer: {
    backgroundColor: colors.brandGrey,
  },
  selectedText: {
    color: colors.grey700,
  },
});
