import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 15,
  },
  thumbnail: {
    width: 30,
    height: 30,
  },
  productBrand: {
    color: colors.brand,
    // lineHeight: 20,
    letterSpacing: -0.47,
  },
  title: {
    color: colors.greyishBrown,
    // lineHeight: 24,
    letterSpacing: -1.05,
    fontWeight: 'bold',
  },

  headerRightView: {
    flexDirection: 'row',
    justifyContent:'space-between'
  },
  bannerImage: {
    maxWidth: '100%',
    minHeight: 360,
  },

  shareIconView: {
    marginRight: 40,
    padding: 10,
    position: 'absolute',
    zIndex: 101,
    right: 0,
  },

  closeIcon: {
    width: 60,
    marginRight: 20,
    paddingVertical: 10,
    paddingLeft: 10,
    height: 60,
  },
  imageVariation: {
    maxWidth: '100%',
    height: 360,
  },
  currency: {
    color: colors.brand,
    // lineHeight: 24,
    letterSpacing: -1.05,
  },
  banner: {
    height: 360,
    backgroundColor: colors.white,
    margin: 0,
    padding: 0,
  },
  discount: {
    textDecorationLine: 'line-through',
    color: colors.warmGrey,
    fontWeight: '500',
    // lineHeight: 20,
    letterSpacing: -0.47,
  },
  productInfoRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  productInfoRowGrey: {
    backgroundColor: colors.brandGreyLight,
  },
  bannerVideo: {
    // don't set backgroundColor on Video on android > 24. It's not allowed on TextureView.
    ...Platform.select({
      ios: {
        backgroundColor: colors.brand,
      },
    }),
  },
  deliveryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    shadowColor: colors.paleGrey,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowRadius: 0,
    shadowOpacity: 1,
    // marginVertical: 10
  },

  borderRow: {
    borderBottomWidth: 1,
    borderColor: colors.white,
  },
  innerDelivery: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  deliveryIcon: {
    width: 24,
    height: 24,
  },
  ctaContainer: {
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderTopWidth: 2,
    borderColor: colors.brandGrey,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toast: {
    backgroundColor: colors.grey600,
  },
  doneBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  toastView: {
    backgroundColor: colors.brandGreen,
    padding: 20,
    alignItems: 'center',
  },
  containerView: {
    padding: 16,
    justifyContent: 'center',
    // alignItems: 'flex-start'
  },

  customizedWritingInput: {
    flex: 1,
    backgroundColor: colors.button1,
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 0,
    shadowOpacity: 1,
  },
  addBtn: {
    marginHorizontal: 10,
    flex: 1.5,
    backgroundColor: colors.greenMain,
  },
  addBtnTitle: {
    paddingHorizontal: 25,
    fontWeight: '600',
    color: colors.white,
    textAlign: 'center',
  },

  deliveryData: {
    flex: 1,
  },
  deliveryTitle: {
    marginLeft: 15,
    flex: 1,
  },
});

export default styles;
