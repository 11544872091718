import types from './types';
import { types as CartTypes } from './../useCart';
import MagentoAPI from '../../services/MagentoAPI';
import { createAction } from '../../common/utils';

//create actions
export const actions = {
  userSignup: (o) => (dispatch, getState) => {
    // NOTE: after signup, you need to perform login to get userToken.
    const type = types.USER_SIGNUP;
    dispatch(createAction(type.REQUEST));

    if (typeof o !== 'object')
      throw Error('USER_SIGNUP object must be an object');

    const data = {
      firstName: o.firstName,
      lastName: o.lastName,
      email: o.email,
      password: o.password,
      phone: o.phone,
    };
    // useful in case of checkout.
    if (o.addresses) {
      data.addresses = o.addresses;
    }
    if (o.custom_attributes) {
      data.custom_attributes = o.custom_attributes;
    }

    return MagentoAPI.post('/mobile/register', type, JSON.stringify(data)).then(
      (resp) => {
        if (resp.ok) {
          // sign in user also.
          return dispatch(
            actions.userLogin({
              email: o.email,
              password: o.password,
            }),
          ).then(() => {
            return dispatch(createAction(type.SUCCESS, resp.json[0]));
          });
        } else return dispatch(createAction(type.ERROR, resp, data));
      },
    );
  },

  userLogin: (data) => (dispatch, getState) => {
    const type = types.USER_LOGIN;
    try {
      dispatch(createAction(type.REQUEST));

      return MagentoAPI.post('/mobile/login', type, JSON.stringify(data)).then(
        (resp) => {
          if (resp.ok) {
            return dispatch(createAction(type.SUCCESS, resp.json[0].token));
          } else return dispatch(createAction(type.ERROR, resp, { data }));
        },
      );
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },

  getUserInfo: () => (dispatch, getState) => {
    const type = types.GET_USER_INFO;
    try {
      // get loggedIn user Info.
      const user = getState().user;
      if (user.loggedIn) {
        dispatch(createAction(type.REQUEST));

        return MagentoAPI.get('/customers/me', type).then((resp) => {
          if (resp.ok) {
            const userInfo = resp.json;
            const {
              firstname: first_name,
              lastname: last_name,
              email,
              custom_attributes,
            } = userInfo;
            let phoneValue = null;
            if (custom_attributes && custom_attributes.length != 0) {
              const phone = custom_attributes.reduce(
                (item) => item.attribute_code == 'phone',
              );
              phoneValue = phone ? phone.value : null;
            }

            return dispatch(createAction(type.SUCCESS, resp.json));
          } else
            return dispatch(
              createAction(type.ERROR, resp, {
                token: user.token,
              }),
            );
        });
      } else return Promise.resolve();
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },

  updateUserInfo: (data) => (dispatch, getState) => {
    const type = types.UPDATE_USER_INFO;
    try {
      // where data params format should be customer data : {..user.info..}
      // To add new address, simply get the user info first and then merge new address into it.
      const user = getState().user;
      if (user.loggedIn) {
        dispatch(createAction(type.REQUEST));

        return MagentoAPI.put(
          '/customers/me',
          type,
          JSON.stringify({ customer: data }),
        ).then((resp) => {
          if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
          else return dispatch(createAction(type.ERROR, resp, data));
        });
      } else return Promise.resolve();
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },

  editUserProfile: (data) => (dispatch, getState) => {
    const type = types.EDIT_USER_PROFILE;
    try {
      const user = getState().user;
      dispatch(createAction(type.REQUEST));

      return MagentoAPI.put(
        '/mobile/profile?=',
        type,
        JSON.stringify(data),
      ).then((resp) => {
        if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
        else {
          return dispatch(createAction(type.ERROR, resp));
        }
      });
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },

  resetPassword: (email) => (dispatch, getState) => {
    const type = types.RESET_PASSWORD;
    try {
      const user = getState().user;
      // user must not loggedIn.
      dispatch(createAction(type.REQUEST));
      return MagentoAPI.put(
        '/customers/password',
        type,
        JSON.stringify({
          email: email,
          websiteId: 1,
          template: 'email_reset', // I get this value, by wrong query data.
        }),
      ).then((resp) => {
        if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
        else return dispatch(createAction(type.ERROR, resp, email));
      });
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  },

  userLogout: () => (dispatch) => {
    dispatch(createAction(CartTypes.CLEAR_CART));
    dispatch(createAction(types.USER_LOGOUT));
  },

  setAuthNavigate: (navigateTo) =>
    createAction(types.SET_AUTH_NAVIGATE, navigateTo),
};
