import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    // marginRight: 10,
  },
  imageStyle: {
    width: 30,
    height: 30,
  },
});

export default styles;
