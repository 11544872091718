import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 10,
    alignItems: 'center',
    borderBottomColor: colors.grey200,
    borderBottomWidth: 1,
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: 6,
    marginRight: 10,
    borderWidth: 1,
    borderColor: colors.grey300,
  },
  textView: {
    flexDirection: 'row',
  },
});
