import { createTypes } from '../../common/utils';

//create types
export default {
  USER_SIGNUP: createTypes('USER_SIGNUP'),
  USER_LOGIN: createTypes('USER_LOGIN'),
  GET_USER_INFO: createTypes('GET_USER_INFO'),
  UPDATE_USER_INFO: createTypes('UPDATE_USER_INFO'),
  RESET_PASSWORD: createTypes('RESET_PASSWORD'),
  USER_LOGOUT: 'USER_LOGOUT',
  SET_AUTH_NAVIGATE: 'SET_AUTH_NAVIGATE',
  EDIT_USER_PROFILE: createTypes('EDIT_USER_PROFILE'),
};
