import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Swiper from 'react-native-web-swiper';
import { colors } from '../../common';
import { Text, Image } from '../index';
import { isMobile, isRTL, sWidth } from '../../services';

const BannerSlider = (props) => {
  const _renderItem = ({ item, index }) => {
    return (
      <View style={[styles.container, props.containerStyle]}>
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={() => props.onPressSliderItem(item)}
        >
          <Image
            source={{ uri: item.image_url }}
            resizeMode="cover"
            showExtraControls={false}
            style={styles.imageView}
          />
        </TouchableOpacity>
      </View>
    );
  };

  const _renderItemWithDetails = ({ item }) => (
    <TouchableOpacity
      activeOpacity={0.9}
      onPress={() => props.onPressSliderItem(item)}
      style={[
        styles.container,
        styles.withDetailsContainer,
        props.containerStyle,
      ]}
    >
      <Image
        source={{ uri: item.image_url }}
        resizeMode="cover"
        showExtraControls={false}
        style={styles.imageView}
      />
      <View style={styles.textView}>
        {/* <Text size="smaller" style={styles.productsText}>
          {item.countProducts} Product
        </Text> */}
        <Text size="large" style={styles.titleText}>
          {item.title}
        </Text>
        <Text size="smaller" style={styles.descriptionText}>
          {item.description}
        </Text>
      </View>
    </TouchableOpacity>
  );
  const { withDetails, items, renderItem } = props;
  const isSingleItem = items.length == 1;
  return (
    <View style={{ minHeight: isMobile ? 194 : 516 }}>
      {isSingleItem ? (
        items.map(
          (item) =>
            renderItem?.({ item }) ||
            (withDetails
              ? _renderItemWithDetails({ item })
              : _renderItem({ item })),
        )
      ) : (
        <Swiper
          from={0}
          minDistanceForAction={0.1}
          swipeAreaStyle={{
            left:
              isRTL() &&
              -(isMobile ? sWidth : sWidth - 200) * (items.length - 1),
          }}
          loop
          timeout={5}
          controlsProps={{
            dotsTouchable: true,
            dotsPos: isMobile ? false : 'bottom',
            dotActiveStyle: [styles.dotStyle, props.paginationDotStyle],
            dotInactiveStyle: styles.dotStyle,
            dotProps: {
              containerStyle: { marginHorizontal: 8 },
            },
            dotsWrapperStyle: { bottom: 17 },
            prevPos: false,
            nextPos: false,
          }}
        >
          {items.map(
            (item) =>
              renderItem?.({ item }) ||
              (withDetails
                ? _renderItemWithDetails({ item })
                : _renderItem({ item })),
          )}
        </Swiper>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    minHeight: isMobile ? 194 : 440,
    marginTop: 0,
    marginBottom: 16,
  },
  withDetailsContainer: {
    marginHorizontal: 8,
  },
  dotStyle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    marginHorizontal: 0,
    backgroundColor: colors.brand,
  },
  imageView: {
    height: isMobile ? 194 : 440,
    borderRadius: isMobile ? 4 : 16,
  },
  textView: {
    marginTop: 10,
  },
  titleText: {
    letterSpacing: -1.05,
    fontWeight: 'bold',
    color: colors.greyishBrown,
  },
  descriptionText: {
    letterSpacing: 0,
    marginTop: 8,
    color: colors.greyishBrownTwo,
  },
});

export default BannerSlider;
