import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { config } from '../../common';
import { Image } from '../index';
import Text from '../Text';

import { I18n } from '../../locales';
import { RoundedButton } from '../Button';
import { getPriceFormat } from '../../common/utils';

class WishlistItem extends React.Component {
  render() {
    const { item, itemId, onDelete } = this.props;

    const { name, price, thumbnail_url } = item;

    return (
      <TouchableOpacity {...this.props} style={styles.itemContainer}>
        <Image
          source={{ uri: thumbnail_url }}
          placeholder={config.placeholderImage}
          resizeMode="contain"
          style={styles.cardImage}
        />
        <View style={styles.cardBody}>
          <View style={{ flexDirection: 'row' }}>
            <Text size="small" style={styles.price}>
              {getPriceFormat(price)}
            </Text>
          </View>
          <Text size="small" style={styles.cardTitle}>
            {name}
          </Text>
          <View style={{ flexDirection: 'row', paddingVertical: 5 }}>
            <RoundedButton
              text={I18n.t('btns.delete')}
              onPress={() => onDelete(itemId)}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    padding: 15,
  },
  cardImage: {
    width: 100,
    maxWidth: '100%',
    height: 100,
    marginRight: 20,
  },
  cardBody: {
    // width: Dimensions.get('window').width - 170,
    marginRight: 8,
    flex: 1,
    marginTop: 2,
  },
  cardTitle: {
    marginBottom: 4,
    textAlign: 'left',
  },
  price: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
});

export default WishlistItem;
