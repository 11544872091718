import { gql } from '@apollo/client';
import { CheckoutFragment } from './CheckoutFragment';

export const SET_SHIPPING_ADDRESS = gql`
  mutation setShippingAddressesOnCart(
    $cartId: String!
    $address: CartAddressInput!
  ) {
    setShippingAddressesOnCart(
      input: { cart_id: $cartId, shipping_addresses: [{ address: $address }] }
    ) {
      cart {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
