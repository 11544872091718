import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { colors } from '../../common';
import { I18n } from '../../locales';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialIcons';
import Text from '../Text';
import { isRTL } from '../../services';

class HeaderSeeAll extends React.PureComponent {
  render() {
    const { style, title, onPress, hideSeeAll } = this.props;
    return (
      <View style={[styles.container, style]}>
        <Text style={styles.title} size="large">
          {title}
        </Text>
        {!hideSeeAll && (
          <TouchableOpacity
            style={styles.seeAllView}
            onPress={onPress}
            activeOpacity={0.9}
          >
            <Text style={styles.seeAll} size="small">
              {I18n.t('home.seeAll')}
            </Text>
            <Icon
              name={isRTL() ? 'keyboard-arrow-left' : 'keyboard-arrow-right'}
              size={20}
              style={styles.iconStyle}
              color={colors.brand}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

HeaderSeeAll.propTypes = {
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  onPress: PropTypes.func,
  hideSeeAll: PropTypes.bool,
};

HeaderSeeAll.defaultProps = {
  style: {},
  title: 'Categories',
  onPress: () => {},
  hideSeeAll: false,
};

export default HeaderSeeAll;

const styles = StyleSheet.create({
  seeAllView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    // borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    flexDirection: 'row',
  },
  iconStyle: {
    // marginTop: -4,
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: -1.05,
    color: colors.greyishBrown,
  },
  seeAll: {
    textAlign: 'right',
    color: colors.brand,
  },
});
