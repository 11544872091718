import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { I18n } from '../../locales';
import { Button, CustomInput, Text } from '..';
import { colors } from '../../common';
import { convertRGBToRGBA, getPriceFormat } from '../../common/utils';
import Icon from 'react-native-vector-icons/Ionicons';

export const PaymentSummary = ({
  onApply,
  couponLoading,
  isCouponValid,
  couponCode,
  setCouponCode,
  onProceedToCheckout,
  paymentInfo,
  total,
}) => {
  const onSubmit = () => {
    if (couponCode.length || isCouponValid) {
      onApply(couponCode);
    }
  };

  return (
    <View style={styles.container}>
      {paymentInfo.map((e, i) => {
        if (!e.value) return;
        return (
          <View key={i} style={styles.row}>
            <Text style={styles.infoTitleStyle}>{e.title}</Text>
            <Text styles={styles.valueStyle}>{getPriceFormat(e.value)}</Text>
          </View>
        );
      })}
      <View style={styles.separator} />
      <View style={[styles.row, styles.totalContainer]}>
        <Text style={styles.totalText}>{I18n.t('paymentSummary.total')}</Text>
        <Text style={styles.totalPrice}>{getPriceFormat(total)}</Text>
      </View>
      <View style={[styles.row, styles.couponContainer]}>
        <CustomInput
          editable={!isCouponValid}
          containerWrapperStyle={styles.inputContainer}
          activeContainerStyle={styles.activeContainerStyle}
          containerStyle={styles.containerStyle}
          placeholder={I18n.t('placeholders.couponCode')}
          value={couponCode}
          onChangeText={(text) => setCouponCode(text)}
          returnKeyType="done"
          style={[styles.input, isCouponValid && styles.activatedCodeColor]}
          onSubmitEditing={onSubmit}
        />
        <TouchableOpacity style={styles.applyBtn} onPress={onSubmit}>
          {couponLoading ? (
            <ActivityIndicator
              size="small"
              color={isCouponValid ? colors.red500 : colors.black100}
            />
          ) : (
            <>
              <Text
                size="normal"
                style={isCouponValid ? styles.removeText : styles.applyText}
              >
                {isCouponValid ? I18n.t('btns.remove') : I18n.t('btns.apply')}
              </Text>
            </>
          )}
        </TouchableOpacity>
      </View>
      <Button containerStyle={styles.wrapping}>
        <Icon
          name="md-gift"
          color={colors.amethyst}
          size={24}
          style={styles.iconGift}
        />
        <Text size="normal" style={styles.wrapText}>
          {I18n.t('cart.giftWrap.addWrapping')}
        </Text>
      </Button>

      <Button
        onPress={onProceedToCheckout}
        containerStyle={styles.checkoutStyle}
        text={I18n.t('paymentSummary.checkout')}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 400,
    borderRadius: 10,
    backgroundColor: colors.white,
    padding: 32,
  },
  row: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: colors.white,
    marginBottom: 18,
  },
  infoTitleStyle: {
    color: convertRGBToRGBA(colors.darkIndigo, 0.4),
    fontSize: 16,
    fontWeight: '500',
  },
  valueStyle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.darkIndigo,
  },
  separator: {
    width: 336,
    height: 1,
    borderRadius: 1,
    backgroundColor: convertRGBToRGBA(colors.darkIndigo, 0.05),
    marginBottom: 24,
    marginTop: 6,
  },
  totalContainer: {
    marginBottom: 38,
  },
  couponContainer: {
    marginBottom: 0,
  },
  input: {
    fontSize: 16,
    fontWeight: '500',
  },
  containerStyle: {
    borderBottomWidth: 0,
    width: 210,
    height: 52,
    borderRadius: 10,
    backgroundColor: '#f3f2f5',
  },
  inputContainer: {
    flex: 1,
  },
  activeContainerStyle: {
    borderColor: 'transparent',
  },
  applyBtn: {
    width: 110,
    height: 52,
    borderRadius: 10,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: convertRGBToRGBA(colors.darkIndigo, 0.05),
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeText: {
    color: colors.red500,
    fontWeight: 'bold',
  },
  applyText: {
    color: colors.black100,
    fontWeight: 'bold',
  },
  activatedCodeColor: {
    color: colors.grey120,
  },
  wrapping: {
    width: 336,
    height: 52,
    borderRadius: 10,
    backgroundColor: convertRGBToRGBA(colors.amethyst, 0.1),
    marginVertical: 16,
  },
  checkoutStyle: {
    width: 336,
    height: 52,
    borderRadius: 10,
    backgroundColor: colors.greenMain,
    shadowColor: convertRGBToRGBA(colors.selection, 0.5),
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 20,
    shadowOpacity: 1,
    marginVertical: 0,
  },
  wrapText: {
    color: colors.amethyst,
    fontWeight: 'bold',
  },
  iconGift: {
    marginEnd: 16,
  },
  totalText: {
    fontSize: 20,
    color: '#170a3c',
    fontWeight: '600',
  },
  totalPrice: {
    fontWeight: 'bold',
  },
});
