import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';
import { isRTL } from '../../services';

export default StyleSheet.create({
  containerStyle: {
    flex: 1,
    // width: 500,
    // ...Platform.select({
    //   android: {
    //     marginHorizontal: 16,
    //     height: 44,
    //     justifyContent: 'center',
    //   },
    //   ios: {
    //     backgroundColor: colors.white,
    //     height: 36,
    //   },
    // }),
  },
  inputContainerStyle: {
    // ...Platform.select({
    //   ios: {
    //     height: 10,
    //     borderRadius: 10,
    //     backgroundColor: colors.grey200,
    //   },
    // }),
  },
  inputStyle: {
    outlineStyle: 'none',
    textAlign: isRTL() ? 'right' : 'left',
  },
});
