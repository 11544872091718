import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../redux';
import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-community/async-storage';
// import Reactotron from './ReactotronConfig';
import { saveCurrencyMiddleware } from '../services/middlewares';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: [
    'cartState',
    'config',
    'attributes',
    'codePush',
    'categories',
    'user',
    'order',
    'address',
    'checkout',
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const isDev = process.env.NODE_ENV === 'development';
// const reactotrionEnhancer = Reactotron.createEnhancer();

const composeEnhancers =
  (isDev &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middlewares = [saveCurrencyMiddleware, thunk];

export const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);
