import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text } from '..';
import ImageLoader from '../ImageLoader';
export const ImageButton = (props) => {
  const {
    onPress,
    container,
    imageContainer,
    imageStyle,
    textStyle,
    image,
    title,
  } = props;
  return (
    <TouchableOpacity activeOpacity={0.6} onPress={onPress} style={container}>
      <View style={imageContainer}>
        <ImageLoader
          style={imageStyle}
          source={{ uri: image }}
          resizeMode="cover"
          {...props}
        />
      </View>
      <Text size="small" numberOfLines={1} style={textStyle}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};
