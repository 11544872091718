import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ModalContainer } from '..';
import { colors } from '../../common';
// The Main Modal Container of the App
export const SelectionModal = props => (
  <ModalContainer
    onBackButtonPress={props.onBack}
    onBackdropPress={props.onBack}
    {...props}
    style={[styles.container, props.style]}
  >
    <View style={styles.innerContainer}>{props.children}</View>
  </ModalContainer>
);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
  },
  innerContainer: {
    minHeight: 120,
    padding: 15,
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
});
