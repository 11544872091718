import { combineReducers } from 'redux';
import categories from './useCategories';
import cartState from './useCart';
import attributes from './attributes';
import config from './config';
import errors from './errors';
import loaders from './loaders';
import { reducer as home } from './home';
import { reducer as widgets } from './widgets';
import search from './useSearch';
import { reducer as user } from './user';
import { reducer as address } from './address';
import checkout from './useCheckout';
import order from './order';
import wishlist from './wishlist';
// All reducers.
export default combineReducers({
  categories,
  cartState,
  attributes,
  config,
  errors,
  loaders,
  home,
  widgets,
  search,
  user,
  address,
  checkout,
  order,
  wishlist,
});
