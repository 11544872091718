export const COMPONENT_TYPES = {
  BANNER_SLIDER: 'banner-slider',
  BANNER_CARD_SLIDER: 'banner-card-slider',
  MINI_BOX_SLIDER: 'mini-box-slider',
  BANNER_HALF: 'banner-half',
  BANNER_HALF_DARK: 'banner-half-dark',
  BANNER_COVER: 'banner-cover',
  BANNER_TEXT: 'banner-text',
  BANNER_CARD: 'banner-card',
  BRAND_SLIDER: 'brand-slider',
  CATEGORY_SLIDER: 'category-slider',
  CATEGORY_GRID_COL_TWO: 'category-grid-col-two',
  CATEGORY_GRID_COL_THREE: 'category-grid-col-three',
  CATEGORY_CARD_COL_TWO: 'category-card-col-two',
  PRODUCT_GRID: 'product-grid',
  PRODUCT_GRID_COL_TWO: 'product-grid-col-two',
  PRODUCT_SLIDER: 'product-slider',
  VIDEO_PRODUCTS_SLIDER: 'video-products-slider',
};
