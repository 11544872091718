import React, { Component } from 'react';
import { FlatList } from 'react-native';
import PropTypes from 'prop-types';
import Card from '../Card';
import styles from './styles';


export default class CategoryGrid extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    numColumns: PropTypes.number,
  };

  static defaultProps = {
    numColumns: 2,
  };

  render() {
    const { items, onPress, style, numColumns, cardStyle } = this.props;
    return (
      <FlatList
        keyExtractor={(item, index) => index.toString()}
        initialNumToRender={4}
        windowSize={5}
        removeClippedSubviews={false}
        numColumns={numColumns}
        data={items}
        style={[styles.container, style]}
        renderItem={({ item }) => (
          <Card item={item} onPress={onPress} style={cardStyle} />
        )}
      />
    );
  }
}
