import {
  ADD_PRODUCT_TO_CART,
  UPDATE_CART_ITEMS,
  GET_CART_DETAILS,
  REMOVE_ITEM_FROM_CART,
  CREATE_EMPTY_CART,
  APPLY_COUPON_TO_CART,
  REMOVE_COUPON_FROM_CART,
  GET_CUSTOMER_CART,
  MERGE_CARTS,
} from '../queries/cart';
import { I18n } from '../locales';
import { useDispatch, useSelector } from 'react-redux';
import { useAwaitQuery, useMutation } from '../queries/customHooks';
import * as _ from 'lodash';
import { createAction, createTypes, showErrorMsg } from '../common/utils';
import MagentoAPI from '../services/MagentoAPI';

export const types = {
  CREATE_EMPTY_CART: createTypes('CREATE_GQL_EMPTY_CART'),
  GET_CART_INFO: createTypes('GET_GQL_CART_INFO'),
  ADD_CART_ITEM: createTypes('ADD_GQL_CART_ITEM'),
  UPDATE_CART_ITEMS: createTypes('UPDATE_GQL_CART_ITEMS'),
  REMOVE_CART_ITEM: createTypes('REMOVE_GQL_CART_ITEM'),
  ADD_COUPON_CODE: createTypes('ADD_COUPON_CODE'),
  REMOVE_COUPON: createTypes('REMOVE_GQL_COUPON'),
  CLEAR_CART: 'CLEAR_GQL_CART',
  GET_CUSTOMER_CART: createTypes('GET_GQL_CUSTOMER_CART'),
  MERGE_CARTS: createTypes('MERGE_GQL_CARTS'),
  DELETE_CART_GQL: createTypes('DELETE_CART_GQL'),
};

export const useCart = () => {
  const { cartState, user } = useSelector((state) => {
    return {
      cartState: state.cartState,
      user: state.user,
    };
  });
  const dispatch = useDispatch();
  const cartId = cartState.id;
  const [addProductToCart] = useMutation(ADD_PRODUCT_TO_CART);
  const [createEmptyCart] = useMutation(CREATE_EMPTY_CART);
  const [updateCartItem] = useMutation(UPDATE_CART_ITEMS);
  const [removeItemFromCart] = useMutation(REMOVE_ITEM_FROM_CART);
  const [merge_Carts] = useMutation(MERGE_CARTS);
  const [apply_Coupon] = useMutation(APPLY_COUPON_TO_CART);
  const [remove_Coupon] = useMutation(REMOVE_COUPON_FROM_CART);
  const fetchCartDetails = useAwaitQuery(GET_CART_DETAILS);
  const getCustomerCart = useAwaitQuery(GET_CUSTOMER_CART);

  const createEmptyCart_GQL = async () => {
    const type = types.CREATE_EMPTY_CART;
    try {
      dispatch(createAction(type.REQUEST));
      const resp = await createEmptyCart();
      const guestCartId = resp.data.createEmptyCart;
      if (!resp.errors) {
        return dispatch(createAction(type.SUCCESS, { guestCartId, user }));
      }
    } catch (error) {
      return dispatch(createAction(type.ERROR, error));
    }
  };

  const getCartInfo_GQL = async () => {
    const type = types.GET_CART_INFO;
    try {
      if (!cartId) await createEmptyCart_GQL();
      else {
        dispatch(createAction(type.REQUEST));
        const { data, loading } = await fetchCartDetails({
          variables: { cartId },
        });
        const { cart } = data;
        if (!loading && cart) {
          return dispatch(createAction(type.SUCCESS, cart));
        } else {
          dispatch(createAction(type.ERROR));
        }
      }
    } catch (e) {
      console.log('error ', e);
      dispatch(createAction(type.ERROR));
    }
  };

  const getCustomerCart_GQL = async () => {
    const type = types.GET_CUSTOMER_CART;
    try {
      if (!cartId) await createEmptyCart_GQL();
      dispatch(createAction(type.REQUEST));
      const { data } = await getCustomerCart({});
      const { customerCart } = data;
      if (cartState.total_quantity && cartState.guestCartId) {
        await dispatch(
          createAction(type.SUCCESS, {
            ...cartState,
            ...customerCart,
          }),
        );
        mergeCarts(customerCart.id);
      } else {
        return dispatch(
          createAction(type.SUCCESS, {
            ...customerCart,
            guestCartId: null,
          }),
        );
      }
    } catch (e) {
      console.log('error ', e);
      return dispatch(createAction(type.ERROR));
    }
  };

  const newAddToCart_gql = async ({ sku }) => {
    const type = types.ADD_CART_ITEM;
    try {
      /**
       * Need to check if there is no existing cart id, create an empty cart
       * this case happened after place order
       */
      let newCartId;
      if (!cartId) {
        const resp = await createEmptyCart_GQL();
        newCartId = resp?.payload.guestCartId;
      }
      dispatch(createAction(type.REQUEST));
      const resp = await addProductToCart({
        variables: { cartId: newCartId || cartId, sku, quantity: 1 },
      });
      if (!resp.errors) {
        return dispatch(
          createAction(type.SUCCESS, resp.data.addSimpleProductsToCart.cart),
        );
      }
    } catch (e) {
      const errorMessage = e.graphQLErrors[0]?.message;
      dispatch(createAction(type.ERROR));
      return dispatch(showErrorMsg(errorMessage));
    }
  };

  const updateCartItems_gql = async ({ cart_item_id, quantity }) => {
    const type = types.UPDATE_CART_ITEMS;
    try {
      dispatch(createAction(type.REQUEST));
      const resp = await updateCartItem({
        variables: { cartId, cart_item_id, quantity },
      });
      if (!resp.errors) {
        return dispatch(
          createAction(type.SUCCESS, resp.data.updateCartItems.cart),
        );
      }
    } catch (e) {
      return dispatch(
        createAction(type.ERROR, {
          message: I18n.t('errors.notEnoughQty'),
        }),
      );
    }
  };

  const removeItemFromCart_GQL = async (cartItemId) => {
    const type = types.REMOVE_CART_ITEM;
    try {
      dispatch(createAction(type.REQUEST));
      const result = await removeItemFromCart({
        variables: { cartId, cart_item_id: cartItemId },
      });
      const { errors, data } = result;
      if (!errors) {
        return dispatch(
          createAction(type.SUCCESS, data?.removeItemFromCart?.cart),
        );
      }
    } catch (e) {
      return dispatch(createAction(type.ERROR, e));
    }
  };
  const mergeCarts = async (destination_cart_id) => {
    const type = types.MERGE_CARTS;
    try {
      dispatch(createAction(type.REQUEST));
      const { guestCartId: source_cart_id } = cartState;
      const result = await merge_Carts({
        variables: { source_cart_id, destination_cart_id },
      });
      const { errors, data } = result;
      if (!errors) {
        return dispatch(createAction(type.SUCCESS, data?.mergeCarts));
      }
    } catch (e) {
      dispatch(createAction(type.ERROR, e));
    }
  };

  const clearCart_GQL = () => {
    dispatch({
      type: types.CLEAR_CART,
    });
  };

  const addCouponCode_gql = async (coupon_code) => {
    const type = types.ADD_COUPON_CODE;
    try {
      dispatch(createAction(type.REQUEST));
      const resp = await apply_Coupon({
        variables: { cartId, coupon_code },
      });
      if (!resp.errors) {
        return dispatch(
          createAction(type.SUCCESS, resp.data.applyCouponToCart.cart),
        );
      }
    } catch (e) {
      const errorMessage = e.graphQLErrors[0]?.message;
      showErrorMsg(errorMessage);
      dispatch(createAction(type.ERROR));
    }
  };

  const removeCoupon = async () => {
    const type = types.REMOVE_COUPON;
    try {
      dispatch(createAction(type.REQUEST));
      const result = await remove_Coupon({
        variables: { cartId },
      });
      const { errors, data } = result;
      if (!errors) {
        return dispatch(
          createAction(type.SUCCESS, data?.removeCouponFromCart?.cart),
        );
      }
    } catch (e) {
      dispatch(createAction(type.ERROR, e));
    }
  };

  /**
   * there is no default magento query for clear the cart
   * so we use the old rest request for this till the back-end custom build it for us
   */
  const deleteCart_GQL = async () => {
    const type = types.DELETE_CART_GQL;
    try {
      dispatch(createAction(type.REQUEST));
      const resp = await MagentoAPI.post('cart/mine/clear', type);
      if (resp.ok) return dispatch(createAction(type.SUCCESS, resp.json));
      return dispatch(createAction(type.ERROR));
    } catch (e) {
      dispatch(createAction(type.ERROR));
    }
  };

  return {
    createEmptyCart_GQL,
    getCartInfo_GQL,
    newAddToCart_gql,
    updateCartItems_gql,
    clearCart_GQL,
    removeItemFromCart_GQL,
    addCouponCode_gql,
    removeCoupon,
    getCustomerCart_GQL,
    deleteCart_GQL,
  };
};

const initialState = {
  id: null,
  guestCartId: null,
  total_quantity: 0,
  prices: {},
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_EMPTY_CART.SUCCESS:
      return {
        ...initialState,
        id: action.payload.guestCartId,
        guestCartId: action.payload.user.token
          ? null
          : action.payload.guestCartId,
      };

    case types.GET_CART_INFO.SUCCESS:
      return { ...state, ...action.payload };

    case types.GET_CUSTOMER_CART.SUCCESS:
      return { ...state, ...action.payload };

    case types.ADD_CART_ITEM.SUCCESS:
      return { ...state, ...action.payload };

    case types.UPDATE_CART_ITEMS.SUCCESS:
      return { ...state, ...action.payload };

    case types.REMOVE_CART_ITEM.SUCCESS:
      return { ...state, ...action.payload };

    case types.MERGE_CARTS.SUCCESS:
      return { ...state, ...action.payload, guestCartId: null };

    case types.REMOVE_COUPON.SUCCESS:
      return { ...state, ...action.payload };

    case types.CLEAR_CART:
      return initialState;

    case types.ADD_COUPON_CODE.SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
