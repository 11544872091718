import React, { useState } from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { colors, config, images } from '../../common';
import {
  Text,
  DiscountBadge,
  BrandName,
  PriceRow,
  // FavoriteButton,
} from '..';
import ImageLoader from '../ImageLoader';
import { Button } from '../Button';
import { I18n } from '../../locales';
import { useSelector } from 'react-redux';
import { loadingSelector, showSuccessMsg } from '../../common/utils';
import { useCart, types as cartTypes } from '../../redux/useCart';
import { isMobile, navigateToProduct } from '../../services';

export const ProductCard = ({
  item,
  onPressItem,
  hideAddToCart = false,
  style,
}) => {
  const {
    id,
    name,
    discount_percentage,
    thumbnail_url: thumbnail,
    title,
    image_url,
    stock_qty,
    sku,
    stock_status,
  } = item;
  const isConfigurable = item?.type == 'ConfigurableProduct';
  const { cart, addToCartLoading } = useSelector((state) => {
    return {
      cart: state.cartState,
      addToCartLoading: loadingSelector(state, [
        cartTypes.ADD_CART_ITEM,
        cartTypes.CREATE_EMPTY_CART,
      ]),
    };
  });
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { newAddToCart_gql: newAddToCart } = useCart();
  const loading = selectedItemId == id && addToCartLoading;
  const thumbnail_url = { uri: thumbnail || image_url };
  const isInStock = stock_qty > 0 || stock_status == 'IN_STOCK';
  const onAddToCart = async () => {
    try {
      const resp = await newAddToCart({ entityId: id, sku });
      if (resp.type === cartTypes.ADD_CART_ITEM.SUCCESS) {
        showSuccessMsg(I18n.t('productDetails.itemSuccess'));
      }
    } catch (error) {
      console.warn('error', error);
    }
    setSelectedItemId(null);
  };
  const getAddCartBtnText = () => {
    const cartItem = cart?.items?.find((it) => it.product.sku === sku);
    let returnedText;
    if (!isInStock) returnedText = I18n.t('products.outOfStock');
    else if (cartItem)
      returnedText = cartItem.quantity + ' ' + I18n.t('productDetails.inCart');
    else returnedText = I18n.t('btns.addToCart');
    return returnedText;
  };

  const onPressAddToCart = () => {
    if (isConfigurable) {
      navigateToProduct(sku, name);
    } else {
      setSelectedItemId(id);
      onAddToCart();
    }
  };
  const disableButton = addToCartLoading || (!isInStock && !isConfigurable);
  return (
    <TouchableOpacity
      activeOpacity={0.85}
      onPress={onPressItem}
      style={[
        styles.container,
        hideAddToCart && styles.hideAddToCartContainer,
        style,
      ]}
      disabled={addToCartLoading}
    >
      {/* <FavoriteButton style={styles.favorite} item={item} /> */}
      {discount_percentage ? (
        <DiscountBadge
          discountPercentage={discount_percentage}
          style={{ position: 'absolute' }}
        />
      ) : null}
      <ImageLoader
        source={thumbnail_url || images.placeholder}
        placeholder={config.placeholderImage}
        style={styles.image}
        resizeMode="contain"
      />
      <View style={styles.brandContainer}>
        <BrandName {...item} />
      </View>
      <View style={styles.descView}>
        <View style={styles.nameContainer}>
          <Text size="small" style={styles.titleStyle} numberOfLines={2}>
            {name || title}
          </Text>
        </View>
        <PriceRow item={item} style={styles.priceContainer} />
      </View>
      {!hideAddToCart && (
        <Button
          disabled={disableButton}
          onPress={() => onPressAddToCart()}
          containerStyle={styles.addToCartBtn}
        >
          {loading ? (
            <ActivityIndicator size="small" color={colors.white} />
          ) : (
            <Text style={styles.addToCartStyle}>
              {isConfigurable
                ? I18n.t('productDetails.selectOptions')
                : getAddCartBtnText()}
            </Text>
          )}
        </Button>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: isMobile ? innerWidth / 2 - 5 : 180,
    height: isMobile ? 300 : 339,
    paddingHorizontal: 20,
    paddingTop: isMobile ? 10 : 20,
    paddingBottom: 10,
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  hideAddToCartContainer: {
    height: 283,
  },
  image: {
    width: 152,
    height: 152,
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  favorite: {
    position: 'absolute',
    zIndex: 2,
    end: 5,
    top: 5,
  },
  brandContainer: {
    marginTop: 8,
    marginBottom: 10,
  },
  nameContainer: {
    // flex: 1,
    marginBottom: 10,
  },
  titleStyle: {
    fontSize: isMobile ? 12 : 14,
    fontWeight: '500',
    color: '#170a3c',
  },
  descView: {
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 12,
  },
  addToCartBtn: {
    // width: 152,
    height: 44,
    borderRadius: 10,
    backgroundColor: colors.selection,
    shadowColor: colors.darkAquamarine50,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
  },
  addToCartStyle: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.white,
  },
});
