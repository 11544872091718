import React, { useRef } from 'react';
import { StyleSheet, FlatList, ScrollView, View } from 'react-native';
import {
  ProductCard,
  Screen,
  Pagination,
  EmptyScreen,
  ShimmerList,
} from '../../components';
import { useSelector } from 'react-redux';
import { colors } from '../../common';
import Highlighter from 'react-native-highlight-words';
import { I18n } from '../../locales';
import { loadingSelector } from '../../common/utils';
import { useSearch, types } from '../../redux/useSearch';
import { navigateToProduct } from '../../services';

const WebSearch = () => {
  const scrollRef = useRef(null);
  const { changeCurrentPage } = useSearch();
  const searchState = useSelector((state) => state.search);
  const { data, text, total_pages } = searchState;
  const loading = useSelector((state) =>
    loadingSelector(state, [types.SEARCH_PRODUCTS]),
  );

  const renderItem = ({ item }) => {
    const {
      regular_price = {},
      discount = {},
      final_price = {},
    } = item?.price_range?.maximum_price;
    const { value: price = 0 } = regular_price;
    const discount_percentage = discount?.percent_off;
    const { value: product_final_price = 0 } = final_price;
    const special_price =
      discount_percentage !== 0 ? product_final_price : null;
    const newItem = {
      id: item.id,
      image_url: item?.small_image?.url,
      name: item?.name,
      price,
      special_price,
      brand: { name: item?.brand_object?.value, id: item?.brand_object?.id },
      discount_percentage,
      stock_status: item?.stock_status,
      sku: item.sku,
      type: item?.__typename,
    };
    return (
      <ProductCard
        item={newItem}
        onPressItem={() => onPressItem(item)}
        nameElement={
          <Highlighter
            style={styles.nameStyle}
            highlightStyle={styles.highlightStyle}
            searchWords={[text]}
            textToHighlight={newItem.name}
            autoEscape
            numberOfLines={2}
          />
        }
        style={{ margin: 5 }}
      />
    );
  };

  const onPagePress = (item) => {
    changeCurrentPage(text, item);
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  const onPressItem = (item) => {
    navigateToProduct(item?.sku, item?.name);
  };
  const showPagination = typeof total_pages == 'number' && total_pages > 1;
  return (
    <ScrollView
      style={{ flex: 1 }}
      ref={scrollRef}
      showsVerticalScrollIndicator={false}
    >
      <Screen isSearchPage>
        <View>
          {loading ? (
            <ShimmerList loading={loading} num={15} style={{ width: 176 }} />
          ) : (
            <FlatList
              scrollEnabled={false}
              numColumns={5}
              data={data}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              contentContainerStyle={{
                paddingVertical: 20,
                alignItems: 'center',
              }}
              ListEmptyComponent={() => {
                return (
                  <EmptyScreen
                    errorMessage={{
                      title: I18n.t('search.errorTitle'),
                      subTitle: I18n.t('search.errorSubTitle'),
                    }}
                  />
                );
              }}
            />
          )}
        </View>
        {showPagination && (
          <Pagination {...{ total_pages }} onPress={onPagePress} />
        )}
      </Screen>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  nameStyle: {
    textAlign: 'left',
  },
  highlightStyle: {
    color: colors.brand,
  },
});
export default WebSearch;
