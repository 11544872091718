import { createAction, createTypes, searchArrayToQuery } from '../common/utils';
import MagentoAPI from '../services/MagentoAPI';

export const types = {
  GET_PRODUCT_ATTRIBUTES: createTypes('GET_PRODUCT_ATTRIBUTES'),
};

export const actions = {
  getProductAttributes: (
    attrs = [
      'gender',
      'age',
      'size',
      'color',
      'visibility',
      'numbers',
      'brand',
    ],
  ) => (dispatch, getState) => {
    const attributes = getState().attributes;
    // cache, check any one.
    // if (attributes.cached) return Promise.resolve();
    const type = types.GET_PRODUCT_ATTRIBUTES;

    dispatch(createAction(type.REQUEST));
    if (Array.isArray(attrs) && attrs.length) {
      const searchCriteria = {
        filter_groups: [
          {
            filters: [
              {
                field: 'attribute_code',
                value: attrs.join(','),
                condition_type: 'in',
              },
            ],
          },
        ],
      };
      const query = searchArrayToQuery(attributes.fields, searchCriteria);
      return MagentoAPI.get(`products/attributes?${query}`, type).then(
        (resp) => {
          if (resp.ok) {
            console.warn('resp.json.items', resp.json.items);
            return dispatch(createAction(type.SUCCESS, resp.json.items));
          } else return dispatch(createAction(type.ERROR, resp));
        },
      );
    } else throw new Error('Attrs must be an array');
  },
};

const initialState = {
  fields: 'items[options,attribute_code,attribute_id]',
  gender: [],
  age: [],
  size: [],
  color: [],
  visibility: [],
  brand: [],
  codes: [], // format: [{id: 172, value: 'size'}, ...]
  numbers: [],
  cached: false,
};

export default (state = initialState, action) => {
  let finalState = {};
  switch (action.type) {
    case types.GET_PRODUCT_ATTRIBUTES.SUCCESS:
      const items = action.payload;

      items.map((item) => {
        // where item.options: {label, value}
        // filter out empty object values.
        const options = item.options.filter((it) => it.label && it.value);
        switch (item.attribute_code) {
          case 'gender':
            finalState.gender = options;
            break;
          case 'age':
            finalState.age = options;
            break;
          case 'brand':
            finalState.brand = options;
            break;
          case 'color':
            finalState.color = options;
            break;
          case 'size':
            finalState.size = options;
            break;
          case 'visibility':
            finalState.visibility = options;
            break;
          case 'numbers':
            finalState.numbers = options;
            break;
          default:
            break;
        }
      });
      return {
        ...state,
        cached: true,
        codes: items.map((it) => ({
          id: it.attribute_id,
          value: it.attribute_code,
        })),
        ...finalState,
      };
    default:
      return state;
  }
};
