import { gql } from '@apollo/client';

export const SEARCH_PRODUCTS = gql`
  query ($text: String!, $currentPage: Int!) {
    products(search: $text, currentPage: $currentPage, pageSize: 10) {
      page_info {
        total_pages
      }
      items {
        id
        name
        sku
        brand
        brand_name
        only_x_left_in_stock
        stock_status
        price_range {
          maximum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        special_price
        small_image {
          url
        }
      }
    }
  }
`;
