import React, { Component } from 'react';
import { View, FlatList } from 'react-native';

import { ShimmerPlaceHolder } from './index';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styles from './styles';
import { colors } from '../../common';

export default class ShimmerProducts extends Component {
  static propTypes = {
    style: PropTypes.object,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
    loading: false,
  };

  renderItem = () => {
    return (
      <View style={{ marginTop: 20 }}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.border}
          colorShimmer={colors.brandShimm3}
        />

        <ShimmerPlaceHolder
          autoRun
          style={styles.placeHolder}
          colorShimmer={colors.brandShimm3}
        />

        <ShimmerPlaceHolder
          autoRun
          style={styles.productCard}
          colorShimmer={colors.brandShimm3}
        />
      </View>
    );
  };

  render() {
    const { style, loading } = this.props;
    return (
      <View style={styles.container}>
        <ShimmerPlaceHolder
          autoRun
          style={[styles.product, style]}
          visible={!loading}
          colorShimmer={colors.brandShimm3}
        />

        <FlatList
          data={_.range(1)}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          contentContainerStyle={{
            alignItems: 'flex-start',
          }}
        />
      </View>
    );
  }
  keyExtractor = index => {
    return `${index}`;
  };
}
