import React from 'react';
import { TouchableOpacity } from 'react-native';
import { images } from '../../common';
import { navigateTo } from '../../services';
import { Image } from '../index';
import styles from './styles';

const AppLogo = (props) => {
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      onPress={() => navigateTo()}
      {...props}
    >
      <Image
        resizeMode="contain"
        style={styles.iconStyle}
        source={images.logo}
      />
    </TouchableOpacity>
  );
};

export default AppLogo;
