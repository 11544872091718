import { colors } from '../../common';
import { StyleSheet } from 'react-native';
import { metrics } from '../../common/metrics';
import { isMobile } from '../../services';
const NAV_BAR_HEIGHT = 48;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // maxWidth: 1232,
    // margin: isMobile ? 0 : 'auto',
  },
  seeAllTransparent: {
    backgroundColor: 'transparent',
    paddingTop: 8,
  },

  bannerSliderContainer: {
    marginBottom: 8,
  },

  bannerSliderPagination: {
    marginTop: isMobile ? -32 : 0,
    paddingVertical: 8,
  },

  productsListContainer: {
    marginTop: 0,
  },

  categoryCardContentContainer: {
    backgroundColor: 'transparent',
    margin: 4,
  },

  categoryCardChild: {
    marginHorizontal: 4,
    marginVertical: 4,
    backgroundColor: colors.white,
  },

  navBar: {
    position: 'absolute',
    top: metrics.HEADER_HEIGHT,
    left: 0,
    right: 0,
    justifyContent: 'center',
    height: NAV_BAR_HEIGHT,
    zIndex: 2,
  },
  scrollViewContent: {
    paddingTop: !isMobile && NAV_BAR_HEIGHT,
    // paddingHorizontal: isMobile ? 0 : 104,
  },
  SearchContainer: {
    width: 452,
    height: 48,
  },
});

export default styles;
