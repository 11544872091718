import { colors } from '../../common';
import { StyleSheet } from 'react-native';
import { scale, vScale } from '../../services';

export default StyleSheet.create({
  sectionStyle: {
    fontWeight: '500',
    lineHeight: 26,
    transform: [{ translateY: 0 }],
    paddingTop: vScale(5),
    paddingHorizontal: scale(15),
    color: colors.black100,
    backgroundColor: colors.grey140,
  },
  list: {
    backgroundColor: colors.white,
  },
  descView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: vScale(10),
    paddingHorizontal: scale(16),
    flex: 1,
  },
  tinyView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: vScale(8),
    paddingHorizontal: scale(24),
    flex: 1,
  },
  descText: {
    letterSpacing: -0.36,
    color: colors.black,
  },
  tinyText: {
    letterSpacing: 0,
    color: colors.textGrey,
  },
  listFooter: {
    marginTop: vScale(35),
  },
});
