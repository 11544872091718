// https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
import { createAction } from '../common/utils';

export const types = {
  RESET_ERROR: 'RESET_ERROR',
};

export const actions = {
  resetError: () => createAction(types.RESET_ERROR),
};

export default (state = {}, action) => {
  if (action.type === 'RESET_ERROR') {
    return {};
  } else {
    const { type, payload } = action; // payload is an error string.
    const matches = /(.*)_(REQUEST|ERROR)/.exec(type);

    // not a *_REQUEST / *_FAILURE actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;
    return {
      ...state,
      // Store errorMessage
      // e.g. stores errorMessage when receiving GET_TODOS_ERROR
      //      else clear errorMessage when receiving GET_TODOS_REQUEST
      [requestName]: requestState === 'ERROR' ? payload : '',
    };
  }
};

// const types = {
//     RESET_ERROR: 'RESET_ERROR',
// }
//
// export const actions = {
//     resetError:  () => ({
//         type: types.RESET_ERROR
//     })
// }
//
// // Global Error handling for all reducers. Also called global reducer pattern.
// // See : https://github.com/reactjs/redux/blob/master/examples/real-world/src/reducers/index.js
// export default (state = null, action) => {
//     const {type, error} = action;
//
//     if(type === types.RESET_ERROR) {
//         return null
//     }
//     else if(error) {
//         // this will run if any action dispatch which has field error.
//         return action.error;
//     }
//
//     return state;
// }
