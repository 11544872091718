import { gql } from '@apollo/client';

export const CartFragment = gql`
  fragment CartFragment on Cart {
    id
    total_quantity
    applied_coupons {
      code
    }
    prices {
      grand_total {
        value
      }
      subtotal_excluding_tax {
        value
      }
      discounts {
        amount {
          value
        }
      }
    }
    items {
      ... on ConfigurableCartItem {
        configurable_options {
          id
          option_label
          value_id
          value_label
        }
      }
      id
      quantity
      prices {
        discounts {
          amount {
            value
          }
        }
        price {
          value
          currency
        }
      }
      ... on SimpleCartItem {
        customizable_options {
          label
          values {
            value
          }
        }
      }
      product {
        stock_status
        id
        sku
        name
        nmcit_giftwrap_allowed
        brand_name
        only_x_left_in_stock
        small_image {
          url
        }
        attribute_set_name
        delivery_message
        shipping_message
        special_price
        price_range {
          maximum_price {
            discount {
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        ts_dimensions_height
        ts_dimensions_length
        ts_dimensions_width
        extension_attributes {
          gift_wrap
          gift_wrap_sub_cat_name
          gift_wrap_sub_cat_id
        }
      }
    }
  }
`;
