import React from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  Platform,
  TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import { Text } from '..';
import { colors } from '../../common';
import { RoundedButton } from '../Button';

export const ChildrenList = props => {
  const {
    data,
    productsLoading,
    onSelectSubCategory,
    selectedSubcategory,
  } = props;

  return (
    <View style={styles.container}>
      <FlatList
        data={data}
        horizontal
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => {
          const { name, id } = item;
          const isSelected = id == selectedSubcategory.id;
          if (isSelected)
            return (
              <RoundedButton
                text={name}
                onPress={() => onSelectSubCategory({})}
                containerStyle={styles.subCatRoundBtnContainer}
                textStyle={styles.subCatTextSelected}
              />
            );
          return (
            <TouchableOpacity
              disabled={productsLoading}
              activeOpacity={0.85}
              onPress={() => onSelectSubCategory(item)}
              style={styles.subCategoryItemContainer}
            >
              <Text size="small" style={styles.subCatTextStyle}>
                {name}
              </Text>
            </TouchableOpacity>
          );
        }}
        removeClippedSubviews={Platform.OS == 'android'}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </View>
  );
};

ChildrenList.propTypes = {
  data: PropTypes.array.isRequired,
  productsLoading: PropTypes.bool,
  onSelectSubCategory: PropTypes.func,
  selectedSubcategory: PropTypes.object,
};

const styles = StyleSheet.create({
  contentContainerStyle: {
    marginTop: 8,
    paddingHorizontal: 12,
  },
  subCategoryItemContainer: {
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 6,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  subCatTextStyle: {
    fontWeight: '600',
    color: colors.textGrey,
  },
  subCatTextSelected: {
    color: colors.brand,
  },
  subCatRoundBtnContainer: {
    marginVertical: 0,
    borderColor: colors.brand,
    backgroundColor: colors.white,
    borderWidth: 1,
    marginHorizontal: 6,
  },
});
