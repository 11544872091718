import { colors } from '../../common';

export default {
  mainContainer: {
    flex: 1,
    // width: 1232,
    margin: 'auto',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cartDangerMsg: {
    flex: 1,
    borderWidth: 1,
    marginHorizontal: 15,
    marginVertical: 15,
    backgroundColor: colors.brandDanger,
    borderColor: colors.brand,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 4,
  },
  titleStyle: {
    alignSelf: 'flex-start',
    paddingTop: 77,
    paddingBottom: 13,
    fontSize: 24,
    fontWeight: 'bold',
    color: '#170a3c',
  },
  middleView: {
    backgroundColor: colors.cartBackground,
    justifyContent: 'space-around',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 70,
  },
  descText: {
    textAlign: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: colors.warmGrey,
  },
  emptyCart: {
    width: 181,
    height: 200,
  },

  infoIcon: {
    marginRight: 10,
  },

  warnContainer: {
    flexDirection: 'row',
  },
  outOfStockText: {
    flex: 1,
  },
  wrapAsGift: {
    color: colors.greenMain,
    marginHorizontal: 4,
  },
  clearCart: {
    alignSelf: 'center',
    alignItems: 'center',
    padding: 10,
    width: 140,
    marginVertical: 16,
  },
  clearCartText: {
    color: colors.greenMain,
  },
};
