// A function that returns false if the item is a gift wrap item
export const isGiftWrapItem = item => {
  const { product } = item;
  const { extension_attributes } = product;
  const isGiftWrapItem = extension_attributes?.gift_wrap == 1;
  return isGiftWrapItem;
};

// A function that returns false if the item is out of stock
export const isInStock = item => {
  const { product } = item;
  const { stock_status } = product;
  const isInStock = stock_status == 'IN_STOCK';
  return isInStock;
};

// A function that returns false if the current quantity exceeded the available quantity
const isAvailableQuantity = item => {
  const { originalQuantity, quantity, product } = item;
  const { only_x_left_in_stock } = product;
  const isAvailableQuantity =
    (originalQuantity || quantity) <= (only_x_left_in_stock || 0);
  return isAvailableQuantity;
};

/**
 * A function that returns false if the current quantity exceeded the available quantity
 * or the item is out of stock
 */ export const isAvailable = item =>
  isAvailableQuantity(item) && isInStock(item);

// A function that returns false if the current quantity reached the available quantity
export const isIncreasable = item => {
  const { originalQuantity, quantity, product } = item;
  const { only_x_left_in_stock } = product;
  const isIncreasable =
    (originalQuantity || quantity) < (only_x_left_in_stock || 0);
  return isIncreasable && isInStock(item) && !isGiftWrapItem(item);
};

/**
 * A function that returns false if the current quantity exceeded the available quantity
 * or the item is out of stock
 * or a gift wrap item
 */
export const isAvailableProduct = item =>
  isAvailableQuantity(item) && isInStock(item) && !isGiftWrapItem(item);

// A function that returns true if the item is a tShirt product
export const isTShirtProduct = item => {
  const { product } = item;
  const { attribute_set_name } = product;
  return attribute_set_name == 't-shirt' && Boolean(findTShirtId(item));
};

// A function that returns tShirtId of the product
export const findTShirtId = item => {
  const { customizable_options } = item;
  return Array.isArray(customizable_options)
    ? customizable_options[0]?.values[0]?.value
    : null;
};
