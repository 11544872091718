import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '..';
import { colors } from '../../common';

export const CartItemsLabel = ({
  label,
  counter,
  style,
  textStyle,
  hideCounter,
}) => {
  return (counter && counter > 0) || hideCounter ? (
    <View style={[styles.headerContainer, style]}>
      <Text numberOfLines={1} style={[styles.giftsText, textStyle]}>
        {label}
      </Text>

      {!hideCounter && (
        <View style={styles.giftsCounterContainer}>
          <Text numberOfLines={1} size="small" style={styles.counterText}>
            {counter}
          </Text>
        </View>
      )}
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 15,
    marginBottom: 9,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  giftsText: {
    fontWeight: '600',
    color: colors.greyishBrown,
  },
  giftsCounterContainer: {
    minHeight: 20,
    marginHorizontal: 8,
    borderRadius: 6.3,
    justifyContent: 'center',
    paddingHorizontal: 6,
    maxWidth: 200,
    alignItems: 'center',
    backgroundColor: colors.greyishBrown,
  },
  counterText: {
    fontWeight: 'bold',
    color: colors.white,
  },
});
