import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Text } from '../index';
import { I18n } from '../../locales';
import styles from './style';
import { getSeparatedString, getTextWithLabel } from '../../common/utils';

const AddressCard = (props) => {
  const {
    data: addressObject,
    navigateToEditAddress,
    onDeleteAddress,
    onSelect,
    hideSelectButton,
    isEditable,
  } = props;
  const {
    full_name,
    floor,
    street_name,
    block_number,
    area_name,
    phone,
    house,
    address_name,
    avenue,
    extra_guidance,
    default_shipping,
  } = addressObject;
  return (
    <View style={styles.container}>
      <View style={styles.containerBTN}>
        {/* header (address name / default label/edit button) */}
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={[styles.title, styles.active]}>
              {address_name || area_name}
            </Text>
            {default_shipping ? (
              <View style={styles.labelBox}>
                <Text size="small" style={styles.defaultLabel}>
                  Default
                </Text>
              </View>
            ) : null}
          </View>
          <TouchableOpacity
            onPress={() => navigateToEditAddress(addressObject)}
          >
            <Text style={styles.editBtnTxt}>{I18n.t('btns.edit')}</Text>
          </TouchableOpacity>
        </View>

        {/* name */}
        <View style={styles.rowContainer}>
          <View style={styles.rowLeft}>
            <Text size="small" style={[styles.descText, styles.titleText]}>
              {I18n.t('placeholders.name')}
            </Text>
          </View>
          <View style={styles.rowRight}>
            <Text size="small" style={styles.descText}>
              {full_name}
            </Text>
          </View>
        </View>

        {/* address */}
        <View style={styles.rowContainer}>
          <View style={styles.rowLeft}>
            <Text size="small" style={[styles.descText, styles.titleText]}>
              {I18n.t('placeholders.address')}
            </Text>
          </View>
          <View style={styles.rowRight}>
            <Text size="small" style={styles.descText}>
              {getSeparatedString(
                [
                  getTextWithLabel('area', area_name),
                  getTextWithLabel('block', block_number),
                  getTextWithLabel('street', street_name),
                  getTextWithLabel('avenue', avenue),
                  getTextWithLabel('house', house),
                  getTextWithLabel('floor', floor),
                ],
                ' - ',
              )}
            </Text>
          </View>
        </View>

        {/* Mobile */}
        <View style={styles.rowContainer}>
          <View style={styles.rowLeft}>
            <Text size="small" style={[styles.descText, styles.titleText]}>
              {I18n.t('placeholders.tel')}
            </Text>
          </View>
          <View style={styles.rowRight}>
            <Text size="small" style={styles.descText}>
              {phone}
            </Text>
          </View>
        </View>

        {/* Guidance */}
        {extra_guidance ? (
          <View style={styles.rowContainer}>
            <View style={styles.rowLeft}>
              <Text size="small" style={[styles.descText, styles.titleText]}>
                {I18n.t('placeholders.guidance')}
              </Text>
            </View>
            <View style={styles.rowRight}>
              <Text size="small" style={styles.descText}>
                {extra_guidance}
              </Text>
            </View>
          </View>
        ) : null}
      </View>

      {!hideSelectButton ? (
        <TouchableOpacity
          onPress={() => onSelect(addressObject)}
          style={styles.selectAddressBtn}
        >
          <Text style={styles.selectAddressBtnTxt}>
            {I18n.t('manageAddresses.selectAddress')}
          </Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default AddressCard;
