import React from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import { Text } from '../../components';
import styles from './style';
import { colors, countries } from '../../common';
import * as _ from 'lodash';
export default class CountrySelect extends React.Component {
  constructor(props) {
    super(props);
  }

  onItemChanged = (index) => {
    const { onSelectCountry } = this.props;
    const selectedCountry = countries.find((ele) => ele.id === index);
    this.setState({ index });
    onSelectCountry(selectedCountry);
  };

  renderItem = ({ item, index }) => {
    const isSelected = this.props?.selectedCountry?.key == item.key;
    return (
      <TouchableOpacity
        style={[styles.countryContainer]}
        onPress={() => this.onItemChanged(item.id)}
        activeOpacity={1}
      >
        <View
          style={[
            styles.flagContainer,
            isSelected && { backgroundColor: colors.grey400 },
          ]}
        >
          <Text style={styles.flag}>{item.flag}</Text>
          {/* <View style={styles.countryNameContainer}>
            <Text size="larger" style={styles.countryName}>
              {item[`${lang}Name`]}
            </Text>
          </View> */}
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <FlatList
        numColumns={3}
        data={countries}
        renderItem={this.renderItem}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  }
}
