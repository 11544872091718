import { StyleSheet, I18nManager } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    height: 121,
    borderRadius: 8,
    backgroundColor: colors.white,
    shadowColor: colors.shadowBtn,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    alignSelf: 'stretch',
    marginVertical: 5,
    marginHorizontal: 20,
    padding: 16,
    justifyContent: 'space-between',
  },
  topView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderRadius: 1,
    borderBottomColor: colors.borderColor,
    paddingBottom: 10,
    alignItems: 'center',
  },
  imagePriceView: {
    flexDirection: 'row',
  },
  badge: {
    backgroundColor: colors.brightBlue,
    position: 'absolute',
    top: -8,
    right: -8,
    zIndex: 30,
    borderWidth: 2,
    borderColor: colors.white,
    width: 24,
    height: 24,
    borderRadius: 12,
    padding: 0,
    paddingVertical: 0,
  },
  badgeText: {
    fontWeight: 'bold',
    lineHeight: 20,
    letterSpacing: -0.47,
    color: colors.white,
    padding: 0,
    textAlign: 'center',
  },
  image: {
    width: 48,
    height: 48,
    borderRadius: 8,
    backgroundColor: colors.grey200,
  },
  price: {
    fontWeight: '500',
    letterSpacing: 0,
    color: colors.black,
    marginHorizontal: 10,
  },
  increment: {
    color: colors.textGrey,
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: 20,
  },
  bottomView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: I18nManager.isRTL ? 5 : 10,
  },
  button: {
    backgroundColor: colors.button1,
    marginBottom: 0,
    alignSelf: 'center',
  },
  orderText: {
    padding: 0,
    color: colors.textGrey,
    fontWeight: '500',
  },
  btnText: {
    color: colors.brand,
  },
});
