import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const GET_CUSTOMER_CART = gql`
  query {
    customerCart {
      ...CartFragment
    }
  }
  ${CartFragment}
`;
