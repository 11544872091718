import React, { useEffect, useState } from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  Platform,
  TouchableOpacity,
  Text,
} from 'react-native';
import PropTypes from 'prop-types';
// import { animationVScale } from '../../services';
import { colors, images } from '../../common';
// import Animated from 'react-native-reanimated';
import { ChildrenList } from './ChildrenList';
import { ShimmerSubCategories } from '../Shimmer';
import { I18n } from '../../locales';
import { Image } from '..';

// const { interpolateNode, Extrapolate, createAnimatedComponent } = Animated;
// const AnimatedTouchableOpacity = createAnimatedComponent(TouchableOpacity);

// const mainItemHeight = 110;
// const innerItemHeight = mainItemHeight - 12;

export const SubcategoriesList = ({ scrollY, style, ...props }) => {
  // const containerHeight = interpolateNode(scrollY, {
  //   inputRange: [0, 100],
  //   outputRange: [mainItemHeight, mainItemHeight / 2 + 2],
  //   extrapolate: Extrapolate.CLAMP,
  // });
  // const height = interpolateNode(scrollY, {
  //   inputRange: [0, 100],
  //   outputRange: [innerItemHeight, innerItemHeight / 2],
  //   extrapolate: Extrapolate.CLAMP,
  // });
  // const selectedHeight = interpolateNode(scrollY, {
  //   inputRange: [0, 100],
  //   outputRange: [innerItemHeight + 4, innerItemHeight / 2],
  //   extrapolate: Extrapolate.CLAMP,
  // });

  // const imageHeight = interpolateNode(scrollY, {
  //   inputRange: [0, 20],
  //   outputRange: [55, 0],
  //   extrapolate: Extrapolate.CLAMP,
  // });

  // const imageOpacity = interpolateNode(scrollY, {
  //   inputRange: [0, 5],
  //   outputRange: [1, 0],
  //   extrapolate: Extrapolate.CLAMP,
  // });

  const { data, loading, productsLoading, onSelectCategory, selectedCategory } =
    props;
  const newData = [
    {
      id: selectedCategory.parentId,
      name: I18n.t('products.allToys'),
      source: images.subcategoryAll,
    },
    ...data,
  ];
  const hasChildren = selectedCategory?.children?.length != undefined;

  return (
    <View style={[style, styles.container]}>
      {loading ? (
        <ShimmerSubCategories loading={loading} num={1} />
      ) : (
        <>
          <FlatList
            data={newData}
            horizontal
            keyExtractor={(item, index) => index.toString()}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item }) => {
              const { name, image, id, source } = item;
              const isSelected =
                id == (selectedCategory.id || selectedCategory.categoryId);
              return (
                // <AnimatedTouchableOpacity
                <TouchableOpacity
                  disabled={productsLoading || isSelected}
                  activeOpacity={0.85}
                  onPress={() => onSelectCategory(item)}
                  style={[
                    styles.categoryItemContainer,
                    // { height: animationVScale(containerHeight) },
                    isSelected && styles.categorySelectedContainer,
                  ]}
                >
                  <View
                    // <Animated.View
                    style={[
                      styles.categoryInner,
                      // {
                      //   height: isSelected
                      //     ? animationVScale(selectedHeight)
                      //     : animationVScale(height),
                      // },
                      isSelected && styles.categorySelectedInner,
                    ]}
                  >
                    <Image
                      // <Animated.Image
                      style={[
                        styles.categoryImage,
                        isSelected && styles.categorySelectedImage,
                        // {
                        //   opacity: imageOpacity,
                        //   height: animationVScale(imageHeight),
                        // },
                      ]}
                      source={source || (image && { uri: image })}
                    />
                    {/* <Animated.Text */}
                    <Text style={styles.categoryName} numberOfLines={2}>
                      {name}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            }}
            removeClippedSubviews={Platform.OS == 'android'}
            contentContainerStyle={styles.contentContainerStyle}
          />
          {hasChildren && (
            <ChildrenList {...props} data={selectedCategory.children} />
          )}
        </>
      )}
    </View>
  );
};

SubcategoriesList.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  productsLoading: PropTypes.bool,
  onSelectCategory: PropTypes.func,
  onSelectSubCategory: PropTypes.func,
  selectedCategory: PropTypes.object,
  selectedSubcategory: PropTypes.object,
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 5,
  },
  contentContainerStyle: {
    marginTop: 8,
    paddingHorizontal: 12,
  },
  categoryItemContainer: {
    width: 82,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 11,
    borderColor: 'transparent',
  },
  categorySelectedContainer: {
    borderColor: colors.blueA700,
  },
  categoryInner: {
    width: 70,
    justifyContent: 'space-around',
    backgroundColor: colors.subCategoriesBackground,
    borderRadius: 7,
  },
  categorySelectedInner: {
    width: 74,
    borderRadius: 8.1,
    opacity: 1,
  },
  categoryImage: {
    width: 53,
    height: 55,
    resizeMode: 'cover',
    marginTop: 2,
    alignSelf: 'center',
  },
  categorySelectedImage: {
    width: 57,
    height: 59,
  },
  categoryName: {
    marginTop: 2,
    fontSize: 12,
    color: colors.black,
    lineHeight: 14,
    paddingHorizontal: 4,
    letterSpacing: -0.26,
    textAlign: 'left',
  },

  subCategoryItemContainer: {
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 6,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  subCatTextStyle: {
    fontSize: 12,
    fontWeight: '600',
    color: colors.textGrey,
  },
  subCatTextSelected: {
    color: colors.brand,
  },
  subCatRoundBtnContainer: {
    marginVertical: 0,
    borderColor: colors.brand,
    backgroundColor: colors.white,
    borderWidth: 1,
    marginHorizontal: 6,
  },
});
