import { Dimensions } from 'react-native';
import { IS_ANDROID } from './platform';

const { height, width } = Dimensions.get('window');

export const metrics = {
  screenHeight: height,
  screenWidth: width,
  HEADER_HEIGHT: IS_ANDROID ? 60 : 88,
};
