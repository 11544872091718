import React from 'react';
import { View, ScrollView } from 'react-native';
import { Text, Image, Screen, Button } from '../../components';
import { images } from '../../common';
import { I18n } from '../../locales';
import styles from './styles';
import { navigateTo } from '../../services';

const ProcessingPayment = () => {
  return (
    <Screen>
      <ScrollView>
        <View style={styles.headerContent}>
          <Image
            source={images.processingPayment}
            style={styles.image}
            resizeMode="cover"
          />
        </View>
        <View style={styles.bodyContent}>
          <Text size="larger" style={styles.bodyHeaderText}>
            {I18n.t('processingPayment.title')}
          </Text>
          <View style={styles.bodyTextContainer}>
            <Text style={styles.bodyText}>
              {I18n.t('processingPayment.body')}
            </Text>
          </View>
        </View>
        <Button
          text={I18n.t('processingPayment.btnTitle')}
          containerStyle={styles.button}
          onPress={() => navigateTo('/orderHistory')}
        />
        <Button
          text={I18n.t('processingPayment.goToHomepage')}
          containerStyle={styles.button}
          onPress={() => navigateTo()}
        />
      </ScrollView>
    </Screen>
  );
};

export default ProcessingPayment;
