import { Platform, StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  mainView: {
    flex: 1,
    marginVertical: 20,
  },
  nameAndMobileContainer: {
    paddingBottom: 10,
  },
  shippingAddress2: {
    width: 234,
    fontWeight: 'bold',
    letterSpacing: -1.05,
    color: colors.greyishBrown,
    marginBottom: 5,
    paddingHorizontal: 10,
  },
  // input: {
  //   flex: 1,
  //   backgroundColor: colors.white,
  //   paddingHorizontal: 16,
  //   minHeight: 0,
  //   // marginBottom: 15,
  // },
  select: {
    height: 60,
    paddingVertical: 10,
  },
  defaultAddress: {
    margin: 16,
  },
  twoCols: {
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    paddingHorizontal: 6,
  },
  twoColInput: {
    paddingHorizontal: 10,
  },
  twoCol: {
    flexDirection: 'row',
  },

  ctaBtn: {
    ...Platform.select({
      ios: {
        // height: isIphoneX ? 77 : 56,
      },
      android: {
        height: 56,
      },
    }),
    borderRadius: 0,
    marginBottom: 0,
    justifyContent: 'center',
  },
  endView: {
    ...Platform.select({
      ios: {
        // height: isIphoneX ? 77 : 56,
        // paddingBottom: isIphoneX ? 22 : 0,
      },
      android: {
        height: 56,
      },
    }),
    justifyContent: 'center',
    alignSelf: 'stretch',
  },

  row2: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  forSomeOne: {
    fontWeight: '500',
    lineHeight: 24,
    textAlign: 'center',
    color: colors.greyishBrown,
  },
  addBtn: {
    marginBottom: 41,
    marginHorizontal: 16,
  },
  accordionView: {
    marginVertical: 20,
  },
  // label: {
  //   color: colors.warmGrey,
  // },
  addressNameView: {
    marginHorizontal: 16,
    marginVertical: 8,
    justifyContent: 'center',
  },
  mapEditTextView: {
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: colors.white,
    borderWidth: 1,
    alignSelf: 'center',
    borderColor: colors.borderColor10,
    position: 'absolute',
    top: 30,
  },
  label: {
    color: colors.grey600,
    marginVertical: 8,
    fontWeight: '600',
  },
  input: {
    paddingHorizontal: 10,
    outline: 'none',
    backgroundColor: colors.white,
    height: 40,
    borderRadius: 8,
  },
  inputWeb: {
    width: 400,
  },
  errorMsg: {
    marginVertical: 5,
    color: colors.buttons,
  },
});
