import React, { useEffect, useState } from 'react';
import styles from './styles';
import { ProductCard } from '../ProductCard';
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from 'recyclerlistview';
import { isRTL } from '../../services';

const ProductsSlider = (props) => {
  const { items, containerStyle, onPressItem, hideAddToCart } = props;
  const [newItems, setNewItems] = useState([]);

  useEffect(() => {
    let mutatedArray = new Array(...items);
    mutatedArray.splice(20);
    if (isRTL()) {
      mutatedArray.reverse();
    }
    setNewItems(mutatedArray);
  }, []);

  let dataProvider = new DataProvider((r1, r2) => {
    return r1 !== r2;
  }).cloneWithRows(newItems);
  const _layoutProvider = new LayoutProvider(
    (index) => {
      return index;
    },
    (type, dimension) => {
      dimension.width = 202;
      dimension.height = hideAddToCart ? 293 : 349;
    },
  );

  const rowRender = (type, item, index) => {
    return (
      <ProductCard
        onPressItem={() => onPressItem(item)}
        {...{ item, hideAddToCart }}
      />
    );
  };

  return (
    <RecyclerListView
      style={[
        styles.container,
        containerStyle,
        hideAddToCart && styles.hideAddToCartContainer,
      ]}
      layoutProvider={_layoutProvider}
      dataProvider={dataProvider}
      isHorizontal
      scrollViewProps={{
        showsHorizontalScrollIndicator: false,
      }}
      renderAheadOffset={isRTL() ? 4000 : 0}
      rowRenderer={rowRender}
    />
  );
};

export default ProductsSlider;
