import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FlatList, ScrollView, View } from 'react-native';
import {
  CartIcon,
  HeaderButton,
  Screen,
  SubcategoriesList,
  Pagination,
  ProductCard,
  EmptyScreen,
  ShimmerList,
} from '../../components';
import styles from './styles';
import { useSelector } from 'react-redux';
import { customFilters, navigateToProduct } from '../../services';
import Animated from 'react-native-reanimated';
import { useMemoOne } from 'use-memo-one';
import { useCategories } from '../../redux/useCategories';
import { defaultNavigationOptions } from '../../navigation/navigationOptions';
import { useProducts } from '../../redux/useProducts';
import * as _ from 'lodash';
import Filters from '../Filters';
import Loading from '../../components/Loading';
import { I18n } from '../../locales';

const WebProductsScreen = (props) => {
  const scrollRef = useRef(null);
  const { navigation, route } = props;
  const filtersParam = route.params?.filters;
  const stringfiedCategory = route.params?.category;
  const mainCategory = JSON.parse(stringfiedCategory);
  const [filters, setFilters] = useState(JSON.parse(filtersParam));
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(mainCategory);
  const [selectedSubcategory, setSelectedSubcategory] = useState({});
  const { getSubcategories } = useCategories();
  const { getProducts: _getProducts } = useProducts();
  const [total_pages, setTotalPages] = useState(null);
  const hasCategories = Boolean(categories?.length);

  const attributes = useSelector((state) => state.attributes);

  const { Value } = Animated;
  const { scrollY } = useMemoOne(() => ({ scrollY: new Value(0) }), []);

  useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerTitle: mainCategory?.name || mainCategory?.title,
      //always keep title in center event in Android
      headerTitleAlign: 'center',
      // eslint-disable-next-line react/display-name
      headerRight: () => (
        <HeaderButton
          elements={[{ element: <CartIcon navigation={navigation} /> }]}
        />
      ),
      headerStyle: hasCategories
        ? {
            shadowOpacity: 0,
          }
        : defaultNavigationOptions.headerStyle,
    });
  }, [navigation, filters, loading]);

  useEffect(() => {
    onSelectFilters();
  }, [route]);

  useEffect(() => {
    getCategories();
  }, [filters.category_id]);

  const getProducts = async (pageIndex = page, newFilters = filters) => {
    let resp = {};
    try {
      const queryParamsString = customFilters({
        ...newFilters,
        currentPage: pageIndex,
        pageSize: 50,
      });
      resp = await _getProducts(queryParamsString);
      if (resp?.json) {
        setProducts(resp.json);
        setTotalPages(resp.total_pages);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.warn('error', error);
    }
    setProductsLoading(false);
  };

  const getCategories = async () => {
    const categoryId =
      mainCategory?.parentId || mainCategory?.id || mainCategory?.categoryId;
    try {
      if (categoryId) {
        // const data = await categoriesActions.getSubcategories(categoryId);
        const data = await getSubcategories(categoryId);
        setCategories(data);
      }
    } catch (error) {
      console.log('error ', error);
    }
    setLoading(false);
  };

  // //open item details
  const onPressItem = (item) => {
    const { parent_sku, sku: isSku, name } = item;
    const sku = parent_sku ? parent_sku : isSku;
    const childSku = parent_sku ? isSku : null;
    navigateToProduct(sku, name);
  };

  const handleFiltersChange = (newFilters) => {
    const currentFilters =
      typeof newFilters == 'string' ? JSON.parse(newFilters) : newFilters;
    setTotalPages(null);
    setFilters(currentFilters);
    getProducts(1, currentFilters);
    setPage(1);
    setProductsLoading(true);
  };

  const onSelectFilters = () => {
    if (filters != filtersParam) {
      handleFiltersChange(filtersParam);
      const hasAges = filtersParam?.ages?.length > 0;
      let ageValue = [];
      if (hasAges) {
        attributes.age.forEach((e) => {
          if (filtersParam.ages.includes(e.value)) {
            ageValue.push(e.label);
          }
        });
      }
    }
  };

  const onSelectCategory = (item) => {
    setTotalPages(null);
    const mainCategoryId = mainCategory.id || mainCategory.categoryId;
    const newFilters = { ...filters, category_id: item?.id || mainCategoryId };
    handleFiltersChange(newFilters);
    setSelectedCategory({ ...item, parentId: mainCategory.parentId });
    setSelectedSubcategory({});
  };

  const onSelectSubcategory = (item) => {
    setTotalPages(null);
    const newFilters = {
      ...filters,
      category_id: item?.id || selectedCategory?.id,
    };
    handleFiltersChange(newFilters);
    setSelectedSubcategory(item);
  };

  const renderItem = ({ item }) => {
    return (
      <ProductCard
        item={item}
        onPressItem={() => onPressItem(item)}
        style={{ margin: 5 }}
      />
    );
  };
  const onPagePress = (item) => {
    setProductsLoading(true);
    setPage(item);
    getProducts(item);
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };
  const noData = products?.length == 0;
  const showPagination = typeof total_pages == 'number' && total_pages > 1;
  return (
    <ScrollView
      style={{ flex: 1 }}
      ref={scrollRef}
      showsVerticalScrollIndicator={false}
    >
      <Screen>
        <View style={{ width: 1237, margin: 'auto' }}>
          <View style={styles.categoriesContainer}>
            {(hasCategories || loading) && (
              <SubcategoriesList
                data={categories}
                loading={loading}
                productsLoading={productsLoading}
                onSelectCategory={(item) => onSelectCategory(item)}
                onSelectSubCategory={(item) => onSelectSubcategory(item)}
                selectedCategory={selectedCategory}
                selectedSubcategory={selectedSubcategory}
                {...{ scrollY }}
              />
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: 192 }}>
              <Filters
                {...props}
                filters={filters}
                onFilterPress={(filters) => handleFiltersChange(filters)}
              />
            </View>

            {/*---------'Render Products List Components'---------*/}
            {productsLoading ? (
              <ShimmerList
                loading={productsLoading}
                num={15}
                style={{ width: 176 }}
              />
            ) : noData ? (
              <EmptyScreen
                errorMessage={{
                  title: I18n.t('search.errorTitle'),
                  subTitle: I18n.t('search.errorSubTitle'),
                }}
              />
            ) : (
              <FlatList
                scrollEnabled={false}
                numColumns={5}
                data={products}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                contentContainerStyle={{
                  paddingBottom: 20,
                  alignItems: 'center',
                }}
              />
            )}
          </View>
          {showPagination && (
            <Pagination {...{ total_pages }} onPress={onPagePress} />
          )}
        </View>
      </Screen>
    </ScrollView>
  );
};

export default WebProductsScreen;
