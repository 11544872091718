import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { isMobile } from '../../services';

export default StyleSheet.create({
  container: {
    padding: 8,
    marginVertical: !isMobile ? 16 : 8,
    backgroundColor: colors.white,
    shadowColor: colors.shadowDarkColor,
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowRadius: 25,
    shadowOpacity: 1,
    marginHorizontal: !isMobile ? 40 : 0,
  },
  containerBTN: {
    justifyContent: 'space-between',
    marginHorizontal: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    // borderBottomWidth: 1,
    // borderBottomColor: colors.borderColor,
  },
  headerLeft: {
    flexDirection: 'row',
  },
  rowContainer: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  rowLeft: { flex: 0.27 },
  rowRight: { flex: 0.7 },
  twoButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    marginRight: 5,
  },
  titleText: {
    color: colors.grey500,
  },
  descText: {
    lineHeight: 20,
    letterSpacing: -0.47,
    textAlign: 'left',
  },

  imageMap: {
    justifyContent: 'center',
  },
  labelBox: {
    marginTop: 3,
    width: 51,
    height: 20,
    borderRadius: 3,
    backgroundColor: '#f0f0f0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultLabel: {
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.blueGrey100,
  },
  iconStyle: {
    marginRight: 16,
    alignSelf: 'center',
  },
  selectAddressBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    borderTopWidth: 1,
    borderTopColor: colors.borderColor,
    // backgroundColor: 'red',
    // marginHorizontal: 16,
  },

  selectAddressBtnTxt: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 22,
    letterSpacing: -0.38,
    textAlign: 'center',
    color: colors.brand,
  },
  editBtnTxt: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: -0.47,
    color: colors.brand,
  },
});
