import { StyleSheet } from 'react-native';
import { colors } from '../../common';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 8,
  },
  badge: {
    color: colors.white,
    padding: 0,
    textAlign: 'center',
  },
});
