import React, { useState, useLayoutEffect } from 'react';
import {
  View,
  ScrollView,
  TextInput,
  ActivityIndicator,
  Linking,
  TouchableOpacity,
} from 'react-native';
import {
  Button,
  Screen,
  MainAlert,
  Text,
  OrLine,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/user';
import { useCart } from '../../redux/useCart';
import {
  isMobile,
  replace,
  replaceToAddress,
  navigateToForgetPassword,
  replaceToLogin,
} from '../../services';
import {
  parseArabicNum,
  validateEmail,
  validateNumber,
} from '../../common/utils';
import { colors, config } from '../../common';

const Signup = ({ navigation, route }) => {
  const dispatch = useDispatch();
  let inputs = {};
  const user = useSelector((state) => state.user);
  const [email, setEmail] = useState(route.params && route.params.initEmail);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [password_again, setPassword_again] = useState('');
  const [telephone, setTelephone] = useState('');
  const [errors, setErrors] = useState({});
  const [chooseBox, setChooseBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getCustomerCart_GQL } = useCart();

  const { fromCart } = JSON.parse(route?.params?.params);
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  const validate = (o) => {
    const errors = {};
    if (!validateEmail(o.email)) errors.email = I18n.t('errors.email');

    if (!o.firstname.trim().length)
      errors.firstname = I18n.t('errors.firstname');
    if (!o.lastname.trim().length) errors.lastname = I18n.t('errors.lastname');
    if (o.password.length < 8)
      errors.password = I18n.t('errors.passwordLength');
    if (!o.password.length) errors.password = I18n.t('errors.passwordRequired');
    if (!o.password_again.length)
      errors.password_again = I18n.t('errors.passwordAgain');
    if (o.password_again !== o.password)
      errors.password_again = I18n.t('errors.passwordAgainSame');
    // we should always convert arabic numeral to numbers.
    const tel = parseArabicNum(o.telephone);
    if (!validateNumber(tel)) errors.telephone = I18n.t('errors.tel');

    return errors;
  };

  const onSignupPress = async () => {
    try {
      const errors = validate({
        email,
        password,
        firstname,
        lastname,
        password_again,
        telephone,
      });
      if (Object.keys(errors).length) {
        setErrors(errors);
        // display errors only for 5 sec
        setTimeout(() => setErrors({}), 5000);
      } else {
        setLoading(true);
        const resp = await dispatch(
          actions.userSignup({
            email: email,
            firstName: firstname,
            lastName: lastname,
            password: password,
            // always send telephone in english numeral.
            phone: parseArabicNum(telephone),
          }),
        );
        if (resp.type === types.USER_SIGNUP.SUCCESS) {
          await getCustomerCart_GQL();
          await dispatch(actions.getUserInfo());

          const userHasNoAddress =
            !user.info ||
            !user.info.addresses ||
            user.info.addresses.length == 0;

          setLoading(false);
          !fromCart && replace();
          setTimeout(() => {
            fromCart &&
              userHasNoAddress &&
              replaceToAddress({ isCheckout: true });
          }, 1000);
        } else {
          setChooseBox(!chooseBox);
          setLoading(false);
        }
      }
    } catch (e) {
      console.log('error in sign up function', e);
    }
  };

  const navToLogin = () => {
    setChooseBox(false);
    replaceToLogin({ fromCart });
  };
  const navigateToForgetPass = () => {
    setChooseBox(false);
    navigateToForgetPassword();
  };
  const onCloseModal = () => {
    setChooseBox(false);
  };

  const focusNext = (id) => {
    if (inputs[id]) inputs[id].focus();
  };
  const sendWhatsApp = (msg = '') => {
    const mobile = config.whatsapp;
    if (mobile) {
      const url = `https://wa.me/${mobile}`;
      // const url = 'whatsapp://send?text=' + msg + '&phone=' + mobile;
      Linking.openURL(url)
        .then((data) => {
          console.log('WhatsApp Opened');
          console.log('data', data);
        })
        .catch(() => {
          alert('Make sure WhatsApp installed on your device');
        });
    } else {
      alert('Please insert mobile no');
    }
  };

  return (
    <Screen>
      <ScrollView>
        {/* <Spinner visible={loading} /> */}
        <View>
          {/** choose modal box **/}
          <MainAlert
            visible={chooseBox}
            title={I18n.t('alerts.whoops')}
            description={I18n.t('signup.description')}
            onClose={onCloseModal}
            buttons={[
              {
                title: I18n.t('signup.resetPassword'),
                onPress: () => navigateToForgetPass(),
              },

              {
                title: I18n.t('login.title'),
                onPress: () => navToLogin(),
              },
            ]}
          />
          <OrLine
            title={I18n.t('profile.createAccount')}
            style={[styles.orStyle, !isMobile && styles.orStyleWeb]}
          />
          <Text style={styles.alreadyhaveAccText}>
            {I18n.t('profile.alreadyHaveAcc')}{' '}
            <Text
              onPress={() => replaceToLogin({ fromCart })}
              style={{ color: colors.buttons }}
            >
              {I18n.t('profile.login')}
            </Text>
          </Text>
          <View style={[styles.inputsView, !isMobile && styles.inputsViewWeb]}>
            <Text style={styles.label}>{I18n.t('placeholders.firstname')}</Text>
            <TextInput
              ref={(i) => (inputs.a = i)}
              value={firstname}
              textContentType="name"
              autoCompleteType="name"
              onChangeText={(text) => setFirstName(text)}
              onSubmitEditing={() => focusNext('b')}
              returnKeyType="next"
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.firstname && (
              <Text size="small" style={styles.errorMsg}>
                {errors.firstname}
              </Text>
            )}
            <Text style={styles.label}>{I18n.t('placeholders.lastname')}</Text>
            <TextInput
              ref={(i) => (inputs.b = i)}
              value={lastname}
              textContentType="oneTimeCode"
              onChangeText={(text) => setLastName(text)}
              onSubmitEditing={() => focusNext('c')}
              returnKeyType="next"
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.lastname && (
              <Text size="small" style={styles.errorMsg}>
                {errors.lastname}
              </Text>
            )}

            <Text style={styles.label}>{I18n.t('placeholders.tel')}</Text>
            <TextInput
              ref={(i) => (inputs.c = i)}
              value={telephone}
              textContentType="telephoneNumber"
              autoCompleteType="tel"
              onChangeText={(text) => setTelephone(text)}
              onSubmitEditing={() => focusNext('d')}
              keyboardType="phone-pad"
              returnKeyType="next"
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.telephone && (
              <Text size="small" style={styles.errorMsg}>
                {errors.telephone}
              </Text>
            )}
            <Text style={styles.label}>{I18n.t('placeholders.email')}</Text>
            <TextInput
              ref={(i) => (inputs.d = i)}
              value={email}
              textContentType="email"
              autoCompleteType="email"
              onChangeText={(text) => setEmail(text.trim())}
              onSubmitEditing={() => focusNext('e')}
              keyboardType="email-address"
              returnKeyType="next"
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.email && (
              <Text size="small" style={styles.errorMsg}>
                {errors.email}
              </Text>
            )}
            <Text style={styles.label}>{I18n.t('placeholders.password')}</Text>
            <TextInput
              ref={(i) => (inputs.e = i)}
              value={password}
              textContentType="newPassword"
              autoCompleteType="password"
              returnKeyType="done"
              secureTextEntry
              onChangeText={(text) => {
                setPassword(text);
                setPassword_again(text);
              }}
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.password && (
              <Text size="small" style={styles.errorMsg}>
                {errors.password}
              </Text>
            )}
          </View>
        </View>

        <View style={[styles.bottomView, !isMobile && styles.bottomViewWeb]}>
          <Button
            disabled={loading}
            containerStyle={!isMobile && styles.webButton}
            onPress={onSignupPress}
          >
            {loading ? (
              <ActivityIndicator
                size={isMobile ? 'small' : 'large'}
                color={colors.white}
              />
            ) : (
              <Text size="small" style={styles.buttonText}>
                {fromCart
                  ? I18n.t('btns.signupContinue')
                  : I18n.t('btns.signup')}
              </Text>
            )}
          </Button>
        </View>
      </ScrollView>
      <TouchableOpacity style={styles.helpView} onPress={sendWhatsApp}>
        <Text style={styles.helpText}>{I18n.t('settings.needHelp')}</Text>
      </TouchableOpacity>
    </Screen>
  );
};

export default Signup;
