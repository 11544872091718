import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';
import styles from './styles';
import { Button } from '../Button';
import Image from '../Image';
import { Text } from '..';

export default class BannerCard extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onPress: PropTypes.func,
  };

  render() {
    const { data, onPress } = this.props;
    // There may be multiple banner items on array, but we only need to only render first banner for cover.
    const banner = (data && data[0]) || {};
    const { image_url, title, description, action_btn } = banner;
    const isActionBtn = action_btn && action_btn.name;

    return (
      <TouchableOpacity
        style={[styles.container, styles.bannerCardContainer]}
        onPress={() => onPress(banner)}
        activeOpacity={0.9}
      >
        {image_url && (
          <Image
            source={{ uri: image_url }}
            style={styles.bannerCardImage}
            resizeMode={'cover'}
          />
        )}
        <View style={styles.bannerCardDesc}>
          {!!title && (
            <Text size="large" style={styles.titleText}>
              {title}
            </Text>
          )}
          {!!description && (
            <Text size="smaller" style={styles.decText}>
              {description}
            </Text>
          )}
          {!!isActionBtn && (
            <Button
              text={action_btn.name}
              containerStyle={[styles.button, styles.bannerCardBtn]}
              textStyle={styles.buttonText}
              onPress={() => onPress(banner)}
            />
          )}
        </View>
      </TouchableOpacity>
    );
  }
}
