import '../../bootstrap.css';
import './styles.css';
import { Hoverable } from 'react-native-web-hover';
import React, { useLayoutEffect, useState } from 'react';
import {
  View,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import { Image, Text } from '../../components';
import { useSelector } from 'react-redux';
import {
  isMobile,
  isRTL,
  navigateToProduct,
  navigateToSearch,
} from '../../services';
import styles from './styles.js';
import { I18n } from '../../locales';
import { types, useSearch } from '../../redux/useSearch';
import { colors, images } from '../../common';
import { getPriceFormat, loadingSelector } from '../../common/utils';
import Highlighter from 'react-native-highlight-words';

export const SearchList = ({ isSearchPage }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const target = urlParams?.get('eq');
  console.warn('target', target);
  const { changeText, clearSearch } = useSearch();
  const [focused, setFocused] = useState(false);
  const [searchState, setSearchState] = useState(target || '');
  const { searchText, searchData, loading } = useSelector((state) => {
    return {
      searchText: state.search?.text,
      searchData: state.search?.data,
      loading: loadingSelector(state, [types.SEARCH_PRODUCTS]),
    };
  });
  const list = searchText.length !== 0 ? searchData : [];
  const isDropdown = list?.length > 0 && focused && !isSearchPage;
  useLayoutEffect(() => {
    if (Boolean(target?.length)) changeText(target);
  }, []);
  return (
    <View
      style={{
        flex: 1,
        height: 120,
        marginTop: 20,
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: colors.grey140,
          // height: 48,
          borderRadius: 24,
          paddingHorizontal: 10,
          flexDirection: 'row',
          marginTop: 20,
        }}
      >
        <TextInput
          nativeID="searchBar"
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setFocused(false);
            }, 100);
          }}
          style={{
            flex: 1,
            outlineStyle: 'none',
            textAlign: isRTL ? 'left' : 'right',
            paddingEnd: 5,
          }}
          onChangeText={(text) =>
            isSearchPage ? setSearchState(text) : changeText(text)
          }
          value={isSearchPage ? searchState : searchText}
          onSubmitEditing={() =>
            isSearchPage
              ? changeText(isSearchPage ? searchState : searchText)
              : Boolean(searchText.length) && navigateToSearch(searchText)
          }
          placeholder={I18n.t('placeholders.searchToys')}
          placeholderTextColor={colors.grey600}
        />
        {loading && <ActivityIndicator size="small" color={colors.grey600} />}
        {!loading && searchText?.length !== 0 && (
          <TouchableOpacity
            style={{
              width: 12,
              height: 12,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
            onPress={() => {
              clearSearch();
              setSearchState('');
            }}
          >
            <Image
              style={{
                width: 12,
                height: 12,
                tintColor: colors.grey600,
              }}
              resizeMode="contain"
              source={images.close}
            />
          </TouchableOpacity>
        )}
      </View>

      <View style={{ flex: 1 }} nativeID="search-results">
        {!isMobile && (
          <nav
            id="container"
            class="navbar navbar-expand-lg navbar-dark bg-primary"
          >
            <div class="container-fluid">
              <div class="collapse navbar-collapse" id="main_nav">
                <ul class="navbar-nav">
                  <li class="nav-item-search">
                    {isDropdown && (
                      <ul class="dropdown-menu" id="dropdown-menu-custom">
                        <View style={styles.dropDownContainer}>
                          <View style={styles.dropDownRow}>
                            <View style={{ marginEnd: 27, paddingBottom: 20 }}>
                              {list?.slice(0, 5)?.map((item) => {
                                const price =
                                  item?.price_range?.maximum_price
                                    ?.regular_price?.value;
                                const final_price =
                                  item?.price_range?.maximum_price?.final_price
                                    ?.value;
                                const discount_percentage =
                                  item?.price_range?.maximum_price?.discount
                                    ?.percent_off;
                                const special_price =
                                  discount_percentage !== 0
                                    ? final_price
                                    : null;

                                return (
                                  <li>
                                    <li
                                      class="dropdown-item"
                                      id="sub-category-item sub-category-item-search"
                                    >
                                      <Hoverable>
                                        {({ hovered }) => {
                                          if (hovered) {
                                            // setSelectedCat(item);
                                          }
                                          return (
                                            <TouchableOpacity
                                              onPress={() =>
                                                navigateToProduct(
                                                  item?.sku,
                                                  item?.name,
                                                )
                                              }
                                              style={styles.subCategoryItem}
                                            >
                                              <View
                                                style={
                                                  styles.subCategoryImageContainer
                                                }
                                              >
                                                <Image
                                                  style={
                                                    styles.subCategoryImage
                                                  }
                                                  source={{
                                                    uri: item.small_image?.url,
                                                  }}
                                                />
                                              </View>
                                              <View
                                                style={
                                                  styles.subCategoryTextContainer
                                                }
                                              >
                                                <View>
                                                  <Highlighter
                                                    style={
                                                      styles.subCategoryName
                                                    }
                                                    highlightStyle={{
                                                      color: colors.brand,
                                                    }}
                                                    searchWords={[searchText]}
                                                    textToHighlight={item.name}
                                                    autoEscape
                                                  />
                                                </View>
                                                <View
                                                  style={{
                                                    flexDirection: 'row',
                                                  }}
                                                >
                                                  {special_price && (
                                                    <Text
                                                      size="small"
                                                      style={{
                                                        color:
                                                          colors.darkIndigo,
                                                        opacity: 0.3,
                                                        textDecorationLine:
                                                          'line-through',
                                                        textDecorationStyle:
                                                          'solid',
                                                        marginHorizontal: 4,
                                                      }}
                                                      // style={styles.oldPriceStyle}
                                                      numberOfLines={1}
                                                    >
                                                      {getPriceFormat(price)}
                                                    </Text>
                                                  )}
                                                  <Text
                                                    size="small"
                                                    style={[
                                                      {
                                                        fontSize: 13,
                                                        fontWeight: '600',
                                                      },
                                                      special_price && {
                                                        color: colors.lipstick,
                                                      },
                                                    ]}
                                                    numberOfLines={1}
                                                  >
                                                    {getPriceFormat(
                                                      special_price
                                                        ? special_price
                                                        : price,
                                                    )}
                                                  </Text>
                                                </View>
                                              </View>
                                            </TouchableOpacity>
                                          );
                                        }}
                                      </Hoverable>
                                    </li>
                                  </li>
                                );
                              })}
                            </View>
                          </View>
                        </View>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        )}
      </View>
    </View>
  );
};
