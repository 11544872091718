import React, { useState } from 'react';
import { Image } from 'react-native';

const ImageWithPlaceholder = ({
  placeholder,
  source,
  fallback,
  tintColor,
  children,
  style,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    setLoading(false);
    setIsError(true);
  };

  return (
    <Image
      source={source}
      // onLoad={onLoad}
      // onError={onError}
      style={style}
      // fallback={fallback}
      tintColor={tintColor}
      {...rest}
    >
      {/* {(loading || isError) && placeholder && (
        <FastImage source={placeholder} />
      )} */}
    </Image>
  );
};

export default ImageWithPlaceholder;
