import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { colors } from '../../common';
import { metrics } from '../../common/metrics';

const Header = ({ children }) => (
  <View style={styles.headerContainer}>
    <View style={styles.headerContent}>{children}</View>
  </View>
);

const styles = StyleSheet.create({
  headerContainer: {
    height: metrics.HEADER_HEIGHT,
    backgroundColor: colors.white,
    zIndex: 100,
  },
  headerContent: {
    justifyContent: 'center',
    // alignItems: 'center',
    flex: 1,
    ...Platform.select({
      // android: { marginTop: 0 },
      ios: {
        marginTop: 44,
      },
    }),
  },
});

export default Header;
