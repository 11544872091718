import React, { Component } from 'react';
import { ScrollView, TouchableOpacity, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import ImageLoader from '../ImageLoader';

export default class MiniBoxSlider extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onPress: PropTypes.func,
  };

  render() {
    const { items, onPress, navigation } = this.props;
    return (
      <FlatList
        keyExtractor={(item, index) => index.toString()}
        initialNumToRender={4}
        windowSize={5}
        removeClippedSubviews={false}
        data={items}
        horizontal
        style={styles.container}
        nestedScrollEnabled
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            key={item.id}
            onPress={() => onPress(item)}
            style={styles.imageContainer}
            activeOpacity={0.9}
          >
            <ImageLoader
              style={styles.image}
              source={{ uri: item.image_url }}
              resizeMode="cover"
              navigation={navigation}
              index={index}
            />
          </TouchableOpacity>
        )}
      />
    );
  }
}
