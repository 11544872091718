import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { isMobile, sWidth } from '../../services';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    width: 622,
    height: 473,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageView: {
    width: 622,
    height: 473,
    borderRadius: 8,
    // maxWidth: sWidth,
    // minHeight: isMobile ? 360 : 440,
  },
  videoStyle: {
    height: 473,
    width: 622,
    // height: isMobile ? 360 : 440,
    // width: isMobile ? sWidth : 622,
  },
  listContentContainer: {
    // height: 100,
    width: 88,
    alignItems: 'center',
    // marginLeft: 8,
  },
  smallItemContainer: {
    width: 88,
    height: 88,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.36,
    borderWidth: 1,
    borderColor: colors.paleGrey,
    marginBottom: 16,
  },
  activeItemContainer: {
    opacity: 1,
    borderColor: colors.greenHover,
    shadowColor: colors.blueGrey100,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  smallItem: {
    width: 88,
    height: 88,
    borderRadius: 8,
  },
  iconContainer: {
    position: 'absolute',
    width: 30,
    height: 30,
    borderRadius: 22,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.shadowColor700,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  bigIconContainer: {
    position: 'absolute',
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.shadowColor700,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  iconStyle: {
    width: 10,
    height: 10,
    tintColor: colors.lipstick,
  },
  bigIconStyle: {
    width: 25,
    height: 25,
    tintColor: colors.lipstick,
  },
  carouselContainer: {
    width: 622,
    borderBottomWidth: 1,
    borderColor: colors.paleGrey,
    minHeight: isMobile ? 150 : 440,
    marginHorizontal: 16,
  },
});
