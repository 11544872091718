import { baseUrl } from '../common/constants';

export const navigateTo = (routes = '') => {
  location.assign(`${baseUrl}/${routes}`);
};

export const replace = (routes = '') => {
  location.replace(`${baseUrl}/${routes}`);
};

export const handelParam = (param) => encodeURIComponent(JSON.stringify(param));

export const navigateToProduct = (target, title) => {
  navigateTo(`product/${target}?title=${title}`);
};

export const navigateToProducts = (filters, category) => {
  navigateTo(`products/${handelParam(filters)}/${handelParam(category)}`);
};

export const navigateToCart = (params = {}) => {
  navigateTo(`cart/${handelParam(params)}`);
};

export const replaceToCart = (params = {}) => {
  replace(`cart/${handelParam(params)}`);
};

export const navigateToSearch = (target) => {
  navigateTo(`search?eq=${target}`);
};

export const navigateToManageAddresses = (params = {}) => {
  navigateTo(`manageAddresses/${handelParam(params)}`);
};

export const replaceToManageAddresses = (params = {}) => {
  replace(`manageAddresses/${handelParam(params)}`);
};

export const navigateToAddress = (params = {}) => {
  navigateTo(`address/${handelParam(params)}`);
};

export const replaceToAddress = (params = {}) => {
  replace(`address/${handelParam(params)}`);
};

export const navigateToLogin = (params = {}) => {
  navigateTo(`login/${handelParam(params)}`);
};

export const replaceToLogin = (params = {}) => {
  replace(`login/${handelParam(params)}`);
};

export const navigateToSignUp = (params = {}) => {
  navigateTo(`signup/${handelParam(params)}`);
};

export const replaceToSignUp = (params = {}) => {
  replace(`signup/${handelParam(params)}`);
};

export const navigateToForgetPassword = () => {
  navigateTo('forgetPassword');
};

export const navigateToPayment = () => {
  replace(`payment`);
};

export const navigateToSuccessOrder = (params = {}) => {
  replace(`successOrder/${handelParam(params)}`);
};

export const navigateToFailedOrder = (params = {}) => {
  replace(`failedOrder/${handelParam(params)}`);
};

export const navigateToProcessing = () => {
  replace(`processingPayment`);
};
export const navigateToOrderDetails = (params = {}) => {
  navigateTo(`orderDetails/${handelParam(params)}`);
};
