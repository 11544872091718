import React, { useEffect, useState } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import WebProduct from '../screens/ProductDetails/web';
import MobileProduct from '../screens/ProductDetails/mobile';
import WebProducts from '../screens/Products/web';
import MobileProducts from '../screens/Products/mobile';
import Home from '../screens/Home';
import Categories from '../screens/Categories';

import { defaultNavigationOptions } from './navigationOptions';
import { isMobile, sWidth } from '../services';
import MobileCart from '../screens/Cart/mobile';
import WebCart from '../screens/Cart/web';
import { CustomDrawer } from '../components/CustomDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../redux/useCart';
import { I18nManager } from 'react-native';
import { useCategories } from '../redux/useCategories';
import Login from '../screens/Login';
import Signup from '../screens/Signup';
import ForgetPassword from '../screens/ForgetPassword';
import ManageAddresses from '../screens/ManageAddresses';
// import { actions as addressAction } from '../redux/address';
import { actions as orderActions, types as orderTypes } from '../redux/order';
import Address from '../screens/Address';
import UserProfile from '../screens/UserProfile';
import OrderHistory from '../screens/OrderHistory';
import OrderDetails from '../screens/OrderDetails';
import Wishlist from '../screens/Wishlist';
import AccountScreen from '../screens/Account';
import AccountSettings from '../screens/AccountSettings';
import ChangePassword from '../screens/ChangePassword';
import Settings from '../screens/Settings';
import Country from '../screens/Country';
import Payment from '../screens/Payment';
import PaymentHandler from '../screens/PaymentHandler';
import SuccessOrder from '../screens/SuccessOrder';
import FailedOrder from '../screens/FailedOrder';
import ProcessingPayment from '../screens/ProcessingPayment';
import MobileSearch from '../screens/Search/mobile';
import WebSearch from '../screens/Search/web';
import I18n from '../locales/i18n';

const DrawerStack = createDrawerNavigator();
const MainStack = () => {
  const dispatch = useDispatch();
  const { user, categories, config } = useSelector((state) => ({
    categories: state.categories?.main,
    config: state.config,
    user: state.user,
  }));
  const {
    clearCart_GQL,
    getCustomerCart_GQL,
    getCartInfo_GQL,
    deleteCart_GQL,
  } = useCart();
  const { getMainCategories } = useCategories();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [subCategories, setSubcategories] = useState([]);
  const isArabic = config.lang === 'ar';
  useEffect(() => {
    I18nManager.forceRTL(isArabic);
    _initialFetch();
    getUserData();
  }, []);

  const getUserData = async () => {
    if (!location.href.includes('paymentHandler')) {
      dispatch(orderActions.checkOrderStatus());
    }
    //we use setTimeOut to insure that the order is restored before the we get the cart
    setTimeout(async () => {
      if (user.token) {
        await getCustomerCart_GQL();
        // dispatch(addressAction.getAddresses());
      } else {
        await getCartInfo_GQL();
      }
    }, 2000);
  };
  const _initialFetch = async () => {
    try {
      const data = await getMainCategories();
      if (data?.payload[0]?.children) {
        setSubcategories(data?.payload[0]?.children);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTitle = (text) => {
    const title = I18n.t(text, { locale: isArabic ? "ar" : "en" })
    return title
  }

  return (
    <DrawerStack.Navigator
      initialRouteName="Home"
      screenOptions={defaultNavigationOptions}
      drawerContent={(props) =>
        CustomDrawer({
          ...props,
          categories,
          config,
          clearCart_GQL,
          dispatch,
          selectedCategory,
          setSelectedCategory,
          subCategories,
          setSubcategories,
          isLoggedIn: user?.loggedIn,
          deleteCart_GQL,
        })
      }
      drawerPosition={isArabic ? 'right' : 'left'}
      drawerStyle={{ right: sWidth }}
    >
      <DrawerStack.Screen
        name="Home"
        component={Home}
        options={{
          title: getTitle('home.title')
        }}
      />
      <DrawerStack.Screen
        name="Search"
        component={isMobile ? MobileSearch : WebSearch}
        options={{
          title: getTitle('placeholders.search'),
        }}
      />
      <DrawerStack.Screen name="Categories" component={Categories} />
      <DrawerStack.Screen
        name="Products"
        component={isMobile ? MobileProducts : WebProducts}
        options={({ route }) => {
          const category = route?.params?.category;
          return { title: category ? JSON.parse(category)?.title : '' };
        }}
      />
      <DrawerStack.Screen
        name="Product"
        component={isMobile ? MobileProduct : WebProduct}
        options={({ route }) => ({ title: route?.params?.title })}
      />
      <DrawerStack.Screen
        name="Cart"
        component={isMobile ? MobileCart : WebCart}
        options={{
          title: getTitle('cart.title'),
        }}
      />
      <DrawerStack.Screen
        name="Login"
        component={Login}
        options={{
          title: getTitle('profile.login'),
        }}
      />
      <DrawerStack.Screen
        name="Signup"
        component={Signup}
        options={{
          title: getTitle('profile.signup'),
        }}
      />
      <DrawerStack.Screen
        name="ForgetPassword"
        component={ForgetPassword}
        options={{
          title: getTitle('forgetPassword.title'),
        }}
      />
      <DrawerStack.Screen
        name="ManageAddresses"
        component={ManageAddresses}
        options={{
          title: getTitle('manageAddresses.altTitle'),
        }}
      />
      <DrawerStack.Screen name="Address" component={Address} />
      <DrawerStack.Screen name="UserProfile" component={UserProfile} />
      <DrawerStack.Screen
        name="OrderHistory"
        component={OrderHistory}
        options={{
          title: getTitle('orderHistory.title'),
        }}
      />
      <DrawerStack.Screen
        name="OrderDetails"
        component={OrderDetails}
        options={{
          title: getTitle('order.title'),
        }}
      />
      <DrawerStack.Screen
        name="Wishlist"
        component={Wishlist}
        options={{
          title: getTitle('profile.wishlist'),
        }}
      />
      <DrawerStack.Screen
        name="AccountScreen"
        component={AccountScreen}
        options={{
          title: getTitle('profile.settings'),
        }}
      />
      <DrawerStack.Screen
        name="AccountSettings"
        component={AccountSettings}
        options={{
          title: getTitle('settings.accountSettings'),
        }}
      />
      <DrawerStack.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{
          title: getTitle('btns.changePassword'),
        }}
      />
      <DrawerStack.Screen
        name="Settings"
        component={Settings}
        options={{
          title: getTitle('settings.accountSettings'),
        }}
      />
      <DrawerStack.Screen
        name="Country"
        component={Country}
        options={{
          title: getTitle('selectCountry.title'),
        }}
      />
      <DrawerStack.Screen
        name="Payment"
        component={Payment}
        options={{
          title: getTitle('btns.checkout'),
        }}
      />
      <DrawerStack.Screen
        name="PaymentHandler"
        component={PaymentHandler}
        options={{
          title: getTitle('payment.title'),
        }}
      />
      <DrawerStack.Screen
        name="SuccessOrder"
        component={SuccessOrder}
        options={{
          title: getTitle('successOrder.title'),
        }}
      />
      <DrawerStack.Screen
        name="FailedOrder"
        component={FailedOrder}
        options={{
          title: getTitle('failedOrder.title'),
        }}
      />
      <DrawerStack.Screen
        name="ProcessingPayment"
        component={ProcessingPayment}
        options={{
          title: getTitle('processingPayment.title'),
        }}
      />
    </DrawerStack.Navigator>
  );
};

export default MainStack;
