import React from 'react';
import { StyleSheet, ActivityIndicator } from 'react-native';
import { colors } from '../../common';

const Loading = () => {
  return <ActivityIndicator style={styles.loading} color={colors.brand} />;
};

const styles = StyleSheet.create({
  loading: {
    marginVertical: 20,
  },
});

export default Loading;
