import { colors } from '../../common';
import { sWidth } from '../../services';

export default {
  mainContainer: {
    flex: 1,
    backgroundColor: colors.button1,
  },
  cartDangerMsg: {
    flex: 1,
    borderWidth: 1,
    marginHorizontal: 15,
    marginVertical: 15,
    backgroundColor: colors.brandDanger,
    borderColor: colors.brand,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 4,
  },

  saveArea: {
    flexDirection: 'row',
    backgroundColor: colors.greenSecondary,
  },
  ctaBtnGrd: {
    marginHorizontal: 16,
    marginVertical: 16,
    flex: 1,
    backgroundColor: colors.greenMain,
  },
  middleView: {
    backgroundColor: colors.cartBackground,
    justifyContent: 'space-around',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 70,
  },
  descText: {
    textAlign: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: colors.warmGrey,
  },
  emptyCart: {
    width: 181,
    height: 200,
  },

  infoIcon: {
    marginRight: 10,
  },

  warnContainer: {
    flexDirection: 'row',
  },
  outOfStockText: {
    flex: 1,
  },

  GWItemsContainer: {
    width: sWidth,
    flex: 1,
    backgroundColor: colors.greyBackground,
  },
  headerContainer: {
    width: sWidth,
    marginVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  wrapAsGift: {
    color: colors.greenMain,
    marginHorizontal: 4,
  },
  addButtonStyle: {
    backgroundColor: colors.greenSecondary,
    height: 54,
    marginHorizontal: 11,
    borderRadius: 5,
  },
  unWrappedHeader: {
    width: 343,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
    alignSelf: 'center',
  },
  divider: {
    width: 343,
    height: 2,
    backgroundColor: colors.brandGreyLight,
  },
  unWrappedListContainer: {
    // alignSelf: 'center',
    // alignItems: 'center',
    marginBottom: 25,
    backgroundColor: colors.white,
    borderRadius: 14,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 24,
    shadowOpacity: 1,
  },
  giftsListContainer: {
    alignItems: 'center',
  },
  buttonStyle: {
    height: 56,
    width: 359,
    borderRadius: 12,
    marginBottom: 20,
    alignSelf: 'center',
  },
  addOnButton: {
    width: 359,
    alignSelf: 'center',
    marginVertical: 24,
    backgroundColor: colors.greenSecondary,
  },
  addonTextStyle: {
    color: colors.buttons,
  },
  cartItemsLabel: {
    paddingHorizontal: 0,
  },
  footerContainer: {
    backgroundColor: colors.greenSecondary,
    marginTop: 60,
    paddingTop: 25,
    paddingBottom: 15,
  },
  headerStyle: {
    fontWeight: '600',
    color: colors.black,
  },
  bottomDimmedStyle: {
    flex: 1,
    justifyContent: 'space-between',
  },
  clearCart: {
    alignSelf: 'center',
    alignItems: 'center',
    padding: 10,
    width: 140,
  },
  clearCartText: {
    color: colors.greenMain,
  },
};
