import React from 'react';
import { View } from 'react-native';
import { config } from '../../common';
import { Image } from '../index';

export default class Logo extends React.Component {
  render() {
    const { style, logoStyle } = this.props;
    return (
      <View style={style}>
        <Image source={config.logo} style={logoStyle} resizeMode="contain" />
      </View>
    );
  }
}
