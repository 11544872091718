import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View, ScrollView, I18nManager, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
Icon.loadFont();
import * as _ from 'lodash';
import { I18n } from '../../locales';
import styles from './styles';
import {
  Text,
  Input,
  HeaderButton,
  Screen,
  DrawerWithBackIcons,
  Button,
} from '../../components';
import { colors } from '../../common';
import { actions, types } from '../../redux/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadingSelector,
  showErrorMsg,
  showSuccessMsg,
  validateUserInputs,
} from '../../common/utils';
import { isMobile, navigateTo } from '../../services';
// eslint-disable-next-line react/display-name
const AccountSettings = ({ navigation }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => {
    return {
      loading: loadingSelector(state, [
        types.GET_USER_INFO,
        types.EDIT_USER_PROFILE,
      ]),
    };
  });
  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});
  const { firstname, lastname, email, phone } = state;
  let inputs = {};
  useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/display-name
      headerTitleAlign: 'center',
      headerRight: () => (
        <HeaderButton
          elements={[
            {
              text: I18n.t('settings.Save'),
              onPress: () => save(),
            },
          ]}
        />
      ),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation, state]);

  useEffect(() => {
    dispatch(actions.getUserInfo()).then((user) => {
      const { payload } = user;
      setState({
        ...state,
        ...payload,
        phone: payload.custom_attributes
          ? payload.custom_attributes.find(
              (val) => val.attribute_code == 'phone',
            ).value
          : '',
      });
    });
  }, []);

  // called when inputs changed
  const onChangeInput = (input, text) => {
    setState({ ...state, [input]: text });
  };
  //called when presss on submit keyboard input to focus next one
  const focusNext = (id) => {
    if (inputs[id]) inputs[id].focus();
  };

  //save changes
  const save = async () => {
    const errors = validateUserInputs(state);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const editResponse = await dispatch(
      actions.editUserProfile({
        first_name: firstname,
        last_name: lastname,
        email,
        phone,
      }),
    );
    if (editResponse.error) {
      showErrorMsg(editResponse.payload);
      return;
    }
    await dispatch(actions.getUserInfo());
    showSuccessMsg(I18n.t('btns.editSuccessfully'));
    navigateTo('account');
  };

  return (
    <Screen isLoading={loading}>
      <ScrollView contentContainerStyle={styles.scrollStyle}>
        <View style={[styles.inputsView, !isMobile && styles.inputsViewWeb]}>
          <Text style={styles.label}>{I18n.t('placeholders.firstname')}</Text>
          <Input
            blurOnSubmit={false}
            ref={(i) => (inputs.a = i)}
            returnKeyType="next"
            onSubmitEditing={() => focusNext('b')}
            textContentType="name"
            autoCompleteType="name"
            onChangeText={(text) => onChangeInput('firstname', text)}
            containerStyle={styles.input}
            value={firstname}
            style={[styles.input, !isMobile && styles.inputWeb]}
          />
          {errors.firstname && (
            <Text size="small" style={styles.errorMsg}>
              {errors.firstname}
            </Text>
          )}
          <Text style={styles.label}>{I18n.t('placeholders.lastname')}</Text>

          <Input
            blurOnSubmit={false}
            ref={(i) => (inputs.b = i)}
            returnKeyType="next"
            onSubmitEditing={() => focusNext('c')}
            textContentType="familyName"
            onChangeText={(text) => onChangeInput('lastname', text)}
            containerStyle={styles.input}
            value={lastname}
            style={[styles.input, !isMobile && styles.inputWeb]}
          />
          {errors.lastname && (
            <Text size="small" style={styles.errorMsg}>
              {errors.lastname}
            </Text>
          )}
          <Text style={styles.label}>{I18n.t('placeholders.email')}</Text>

          <Input
            blurOnSubmit={false}
            ref={(i) => (inputs.c = i)}
            onSubmitEditing={() => focusNext('d')}
            textContentType="email"
            autoCompleteType="email"
            onChangeText={(text) => onChangeInput('email', text)}
            containerStyle={styles.input}
            value={email}
            returnKeyType="next"
            keyboardType="email-address"
            style={[styles.input, !isMobile && styles.inputWeb]}
          />
          {errors.email && (
            <Text size="small" style={styles.errorMsg}>
              {errors.email}
            </Text>
          )}
          <Text style={styles.label}>{I18n.t('placeholders.tel')}</Text>

          <Input
            ref={(i) => (inputs.d = i)}
            onChangeText={(text) => onChangeInput('phone', text)}
            containerStyle={styles.input}
            value={phone}
            returnKeyType="done"
            keyboardType="phone-pad"
            style={[styles.input, !isMobile && styles.inputWeb]}
          />
          {errors.phone && (
            <Text size="small" style={styles.errorMsg}>
              {errors.phone}
            </Text>
          )}

          <TouchableOpacity
            style={styles.changePassword}
            onPress={() => navigateTo('changePassword')}
          >
            <Text style={styles.label}>{I18n.t('placeholders.password')}</Text>

            <View style={styles.rowView}>
              <Text color={colors.brand} size={12} weight={'500'}>
                {I18n.t('btns.changePassword')}
              </Text>
              <Icon
                name={
                  I18nManager.isRTL ? 'ios-arrow-back' : 'ios-arrow-forward'
                }
                size={15}
                color={colors.brand}
              />
            </View>
          </TouchableOpacity>
          {!isMobile && (
            <Button text={I18n.t('settings.Save')} onPress={() => save()} />
          )}
        </View>
      </ScrollView>
    </Screen>
  );
};

export default AccountSettings;
