import React, { useEffect, useLayoutEffect } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import {
  actions as addressAction,
  types as addressTypes,
} from '../../redux/address';
import {
  Text,
  Button,
  AddressCard,
  Screen,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import { colors } from '../../common';
import {
  useCheckout,
  types as useCheckoutTypes,
} from '../../redux/useCheckout';
import {
  showErrorMsg,
  loadingSelector,
  getSeparatedString,
} from '../../common/utils';
import { navigateToAddress, navigateToPayment } from '../../services';
// NOTE: Listen for navigation state param `checkout` if navigation was from cart page.
const ManageAddresses = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const {
    loading,
    address,
    config: { country },
  } = useSelector((state) => {
    return {
      address: state.address,
      config: state.config,
      loading: loadingSelector(state, [
        addressTypes.GET_ADDRESSES,
        useCheckoutTypes.SET_BILLING_ADDRESS,
        useCheckoutTypes.SET_SHIPPING_METHOD,
      ]),
    };
  });
  const { addresses } = address;
  const { setShippingMethod, setBillingAddress } = useCheckout();
  const { getAddresses, setSelectedAddress } = addressAction;
  const { isCheckout, fromPayment, fromSettings } = JSON.parse(
    route?.params?.params,
  );

  useEffect(() => {
    dispatch(getAddresses());
  }, []);
  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  const navigateToNewAddress = () => {
    navigateToAddress({
      isCheckout,
      fromPayment,
      isNew: !(isCheckout || fromPayment),
      fromSettings,
    });
  };

  const navigateToEditAddress = (address) => {
    navigateToAddress({ address, isEdit: true, fromSettings });
  };

  const onPress = async (address) => {
    if (isCheckout || fromPayment) {
      dispatch(setSelectedAddress(address));
      estimateShipping(address);
    } else navigateToEditAddress(address);
  };

  const estimateShipping = async (address) => {
    const {
      country_id,
      city,
      area_code,
      full_name,
      street_name,
      phone,
      avenue,
      block_number,
      house,
      floor,
      extra_guidance,
      postcode,
    } = address;
    const addressData = [
      country ? country && country.enName : '',
      `City: ${city}`,
      `Avenue/Area: ${avenue ? avenue : ''}`,
      `Block: ${block_number ? block_number : ''}`,
      `St: ${street_name}`,
      `House: ${house ? house : ''}`,
      `Floor: ${floor ? floor : ''}`,
      `Extra-guidance: ${extra_guidance ? extra_guidance : ''}`,
    ];
    const shippingAddress = {
      firstname: full_name,
      lastname: full_name,
      street: [getSeparatedString(addressData)],
      city,
      region: area_code,
      postcode,
      country_code: country_id,
      telephone: phone,
    };
    _setBillingAddress(shippingAddress);
  };

  const _setBillingAddress = async (shippingAddress) => {
    try {
      const res = await setBillingAddress(shippingAddress);
      if (res?.payload?.id) {
        const availableShippingMethod =
          res?.payload?.shipping_addresses[0]?.available_shipping_methods[0];
        const shipping_methods = {
          carrier_code: availableShippingMethod?.carrier_code,
          method_code: availableShippingMethod?.method_code,
        };
        _setShippingMethod(shipping_methods);
      } else {
        showErrorMsg();
      }
    } catch (error) {
      showErrorMsg();
    }
  };

  const _setShippingMethod = async (shipping_methods) => {
    try {
      const res = await setShippingMethod(shipping_methods);
      if (res?.payload?.id) {
        navigateToPayment();
      } else {
        showErrorMsg();
      }
    } catch (error) {
      showErrorMsg();
    }
  };

  return (
    <Screen isLoading={loading} style={styles.container}>
      <View style={styles.content}>
        <ScrollView contentContainerStyle={[styles.scrollContainer]}>
          <View>
            {address && addresses.length === 0 && (
              <View style={styles.emptyView}>
                <Text
                  size="small"
                  style={{
                    textAlign: 'center',
                    color: colors.grey600,
                  }}
                >
                  {I18n.t('manageAddresses.noAddressMsg')}
                </Text>
              </View>
            )}
          </View>

          {addresses.length
            ? addresses.map((address, key) => (
                <AddressCard
                  key={key}
                  data={address}
                  navigateToEditAddress={navigateToEditAddress}
                  onSelect={() => onPress(address)}
                  hideSelectButton={!!fromSettings}
                />
              ))
            : null}
        </ScrollView>

        <Button
          text={I18n.t('btns.newAddress')}
          onPress={navigateToNewAddress}
          containerStyle={styles.addBtn}
        />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colors.grey100,
  },
  content: {
    flex: 1,
    marginTop: 8,
  },

  scrollContainer: {
    marginTop: 8,
    marginBottom: 40,
    paddingBottom: 20,
  },
  emptyView: {
    marginVertical: 16,
  },
  addBtn: {
    alignSelf: 'center',
    width: 343,
    marginBottom: 20,
  },
});

export default ManageAddresses;
