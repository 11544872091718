import React from 'react';
import { StyleSheet } from 'react-native';
import { Text } from '..';
import { isMobile } from '../../services';

export const BrandName = ({ brand }) => {
  const hasBrand = typeof brand?.name == 'string';
  return (
    <>
      {hasBrand && (
        <Text style={styles.text} numberOfLines={1}>
          {brand.name}
        </Text>
      )}
    </>
  );
};
const styles = StyleSheet.create({
  text: {
    opacity: 0.3,
    fontWeight: 'bold',
    fontSize: isMobile ? 10 : 14,
  },
});
