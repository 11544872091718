import { HeaderBackButton } from '@react-navigation/stack';
import React from 'react';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { colors } from '../../common';
import { isRTL } from '../../services';

export const DrawerWithBackIcons = ({ navigation }) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <HeaderBackButton
        onPress={() => history.go(-1)}
        style={isRTL() && { transform: [{ rotate: '180deg' }] }}
      />
      <Icon
        name="md-menu"
        color={colors.brand}
        size={25}
        onPress={() => navigation.toggleDrawer()}
      />
    </View>
  );
};
