import React from 'react';
import { View, StyleSheet } from 'react-native';
import LottieView from 'react-native-web-lottie';
import loadingAnimation from '../../assets/lottie/loading.json';
import { colors } from '../../common';
// import { ModalContainer } from './../';

const Spinner = ({ visible = true, style }) => {
  return (
    // <ModalContainer visible={visible}>
    <View style={[styles.container, style]}>
      <LottieView
        style={styles.lottie}
        source={loadingAnimation}
        autoPlay
        loop
      />
    </View>
    // </ModalContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.overlayColor,
  },
  lottie: {
    width: 200,
  },
});

export default Spinner;
