import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const APPLY_COUPON_TO_CART = gql`
  mutation($cartId: String!, $coupon_code: String!) {
    applyCouponToCart(input: { cart_id: $cartId, coupon_code: $coupon_code }) {
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`;
