import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
  View,
  ActivityIndicator,
  ScrollView,
  TextInput,
  Linking,
  TouchableOpacity,
} from 'react-native';
import {
  Button,
  Text,
  OrLine,
  Screen,
  DrawerWithBackIcons,
} from '../../components';
import { I18n } from '../../locales';
import styles from './styles';
import { actions, types } from '../../redux/user';
import { actions as addressActions } from '../../redux/address';
import { useCart } from '../../redux/useCart';
import { showErrorMsg, validateEmail } from '../../common/utils';
import { colors, config } from '../../common';
import { useDispatch } from 'react-redux';
import {
  isMobile,
  navigateToForgetPassword,
  replaceToCart,
  replaceToSignUp,
} from '../../services';

const Login = ({ navigation, route, ...props }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: null, password: null });
  const [loading, setLoading] = useState(false);
  const { getCustomerCart_GQL } = useCart();
  const [fromCart, setFromCart] = useState(false);
  let inputs = {};
  const validate = (obj) => {
    const errors = {};
    if (!validateEmail(obj.email)) errors.email = I18n.t('errors.email');
    if (!obj.password.length) errors.password = I18n.t('errors.passwordBlank');
    return errors;
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  useEffect(() => {
    if (route && route?.params) {
      setEmail(route?.params?.initEmail);
      setFromCart(JSON.parse(route?.params?.params)?.fromCart);
    }
  }, []);
  const onLoginPress = async () => {
    try {
      const errors = validate({ email, password });
      if (Object.keys(errors).length) {
        setErrors(errors);
        // display errors only for 5 sec
        setTimeout(() => setErrors({}), 5000);
      } else {
        setLoading(true);
        const resp = await dispatch(
          actions.userLogin({
            email,
            password,
          }),
        );
        if (resp.type === types.USER_LOGIN.SUCCESS) {
          // run in parallel is for performance.
          await getCustomerCart_GQL();
          await dispatch(actions.getUserInfo());
          setLoading(false);
          fromCart && handleCheckout();
        } else {
          showErrorMsg(I18n.t('errors.noAccountExist'));
          setLoading(false);
        }
      }
    } catch (e) {
      console.log('error login function', e);
    }
  };

  const handleCheckout = async () => {
    await dispatch(addressActions.getAddresses());
    setTimeout(() => {
      replaceToCart({ isFromLogin: true });
    }, 1000);
  };

  const focusNext = (id) => {
    if (inputs[id]) inputs[id].focus();
  };

  // const navigationToRegister = () => {
  //   if (fromCart) {
  //     navigateToSignUp({ fromCart: true });
  //   } else {
  //     navigateToSignUp();
  //   }
  // };

  const sendWhatsApp = (msg = '') => {
    const mobile = config.whatsapp;
    if (mobile) {
      const url = `https://wa.me/${mobile}`;
      // const url = 'whatsapp://send?text=' + msg + '&phone=' + mobile;
      Linking.openURL(url)
        .then((data) => {
          console.log('WhatsApp Opened');
          console.log('data', data);
        })
        .catch(() => {
          alert('Make sure WhatsApp installed on your device');
        });
    } else {
      alert('Please insert mobile no');
    }
  };

  return (
    <Screen>
      <ScrollView>
        {/* {fromCart && (
          <TouchableOpacity
            style={styles.topView}
            onPress={() => navigation.navigate('Signup', { fromCart: true })}
          >
            <Text style={styles.cancelStyle}>
              {I18n.t('btns.checkoutAsNewUser')}
            </Text>
          </TouchableOpacity>
        )} */}

        <OrLine
          title={I18n.t('profile.haveAccount')}
          style={[styles.orStyle, !isMobile && styles.orStyleWeb]}
        />
        <Text style={styles.donthaveAccText}>
          {I18n.t('profile.donthaveAccount')}{' '}
          <Text
            onPress={() => replaceToSignUp({ fromCart })}
            style={{ color: colors.buttons }}
          >
            {I18n.t('profile.signup')}
          </Text>
        </Text>
        <View style={[styles.inputsView, !isMobile && styles.inputsViewWeb]}>
          <Text style={styles.label}>{I18n.t('placeholders.email')}</Text>
          <TextInput
            ref={(i) => (inputs.a = i)}
            value={email}
            textContentType="username"
            autoCompleteType="email"
            onChangeText={(text) => setEmail(text.trim())}
            onSubmitEditing={() => focusNext('b')}
            keyboardType="email-address"
            returnKeyType="next"
            style={[styles.input, !isMobile && styles.inputWeb]}
            placeholder="example@domain.com"
          />
          {errors.email && (
            <Text size="small" style={styles.errorMsg}>
              {errors.email}
            </Text>
          )}

          <Text style={styles.label}>{I18n.t('placeholders.password')}</Text>
          <TextInput
            ref={(i) => (inputs.b = i)}
            value={password}
            textContentType="password"
            autoCompleteType="password"
            onChangeText={(text) => setPassword(text)}
            returnKeyType="done"
            secureTextEntry
            style={[styles.input, !isMobile && styles.inputWeb]}
          />
          {errors.password && (
            <Text size="small" style={styles.errorMsg}>
              {errors.password}
            </Text>
          )}
        </View>
        <View style={[styles.bottomView, !isMobile && styles.bottomViewWeb]}>
          <Button
            disabled={loading}
            containerStyle={!isMobile && styles.webButton}
            onPress={onLoginPress}
          >
            {loading ? (
              <ActivityIndicator
                size={isMobile ? 'small' : 'large'}
                color={colors.white}
              />
            ) : (
              <Text size="small" style={styles.buttonText}>
                {I18n.t('btns.login')}
              </Text>
            )}
          </Button>
          <Text
            size={isMobile ? 'small' : 'normal'}
            style={styles.forgetStyle}
            onPress={() => navigateToForgetPassword()}
          >
            {I18n.t('forgetPassword.title')}
          </Text>
        </View>
      </ScrollView>
      <TouchableOpacity style={styles.helpView} onPress={sendWhatsApp}>
        <Text style={styles.helpText}>{I18n.t('settings.needHelp')}</Text>
      </TouchableOpacity>
    </Screen>
  );
};

export default Login;
