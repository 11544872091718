import { gql } from '@apollo/client';
import { CartFragment } from './cartFragment';

export const GET_CART_DETAILS = gql`
  query getCartDetails($cartId: String!) {
    cart(cart_id: $cartId) {
      ...CartFragment
    }
  }
  ${CartFragment}
`;
