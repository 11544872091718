import React from 'react';
import { View, StyleSheet } from 'react-native';
import { images, colors } from '../../common';
import { Image, Text } from '..';
import { scale, sWidth, vScale } from '../../services';
import { I18n } from '../../locales';
import PropTypes from 'prop-types';
import { convertRGBToRGBA } from '../../common/utils';

export const DeliveryInfo = ({ delivery_message, isInStock }) => {
  const stockStatus = isInStock
    ? I18n.t('productDetails.inStock')
    : I18n.t('productDetails.outOfStock');

  return (
    <View style={styles.container}>
      <Image
        resizeMode="contain"
        source={images.newDeliveryIcon}
        style={styles.imageStyle}
      />
      <View style={styles.info}>
        <Text style={styles.title}>{`${I18n.t(
          'delivery.title',
        )} - ${stockStatus}`}</Text>
        <Text style={styles.subTitle}>{delivery_message}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 290,
    height: 60,
    alignItems: 'center',
    flexDirection: 'row',
  },
  imageStyle: {
    width: 44,
    height: 24,
  },
  info: {
    flex: 1,
    paddingStart: 28,
  },
  title: {
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: '500',
    color: convertRGBToRGBA(colors.darkIndigo, 0.3),
    marginTop: 4,
  },
});

DeliveryInfo.propTypes = {
  delivery_message: PropTypes.string.isRequired,
  isInStock: PropTypes.bool.isRequired,
};
