import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Image } from '../index';
import PropTypes from 'prop-types';
import { GOOGLE_API_KEY } from '../../common/constants';

// const LATITUDE = '29.083461';
// const LONGITUDE = '48.07347619999999';
const WIDTH = 111;
const HEIGHT = 111;
const ZOOM = 15;
// const MARKER_ICON = 'https://goo.gl/5y3S82';
const MARKER_LABEL = '';
const MARKER_COLOR = '';
const MARKER_SIZE = 'small';
// const MARKER_COLOR = colors.brand;

export default class MapStaticImage extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    zoom: PropTypes.number,
    markerColor: PropTypes.string,
    markerLabel: PropTypes.string,
    markerSize: PropTypes.string,
  };

  static defaultProps = {
    latitude: 0,
    longitude: 0,
    width: WIDTH,
    height: HEIGHT,
    zoom: ZOOM,
    markerLabel: MARKER_LABEL,
    markerColor: MARKER_COLOR,
    markerSize: MARKER_SIZE,
    style: {},
  };

  render() {
    const {
      style,
      latitude,
      longitude,
      width,
      height,
      zoom,
      markerColor,
      markerLabel,
      markerSize,
      children,
      onPress,
    } = this.props;
    // console.log('MapStaticImage props ', this.props);
    if (latitude && longitude) {
      const uri = `https://maps.googleapis.com/maps/api/staticmap?
      center=${latitude},${longitude}
      &zoom=${zoom}
      &size=${width}x${height}
      &markers=size:${markerSize}|color:${markerColor}|label:${markerLabel}|${latitude},${longitude}
      &key=${GOOGLE_API_KEY}`;
      // &language=ar

      const mapImageUrl = encodeURI(uri);
      console.warn('mapImageUrl', mapImageUrl);
      return (
        <TouchableOpacity
          disabled
          onPress={onPress}
          style={style}
          activeOpacity={1}
        >
          <Image
            source={{ uri: mapImageUrl }}
            style={{ height: height, width: width }}
          />
          {children}
        </TouchableOpacity>
      );
    } else return null;
  }
}
