import { gql } from '@apollo/client';

export const CheckoutFragment = gql`
  fragment CheckoutFragment on Cart {
    id
    selected_payment_method {
      code
    }

    shipping_addresses {
      firstname
      lastname
      street
      city
      region {
        code
      }
      country {
        code
      }
      telephone
      available_shipping_methods {
        carrier_code
        carrier_title
        method_code
      }
      selected_shipping_method {
        carrier_code
        carrier_title
        method_code
        method_title
        amount {
          value
          currency
        }
      }
    }

    available_payment_methods {
      code
      title
      image
      additioncode
    }
  }
`;
