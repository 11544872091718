import API from './MagentoAPI';
import { types as configTypes } from '../redux/config';
import { types as widgetTypes } from '../redux/widgets';
import { setCurrencyCode } from '../common/utils';

export const saveCurrencyMiddleware = (store) => (next) => (action) => {
  let currencyCode;
  const storeCountry = store.getState()?.config?.country;
  if (action?.type == configTypes?.SET_COUNTRY) {
    currencyCode = action?.payload?.currencyCode;
  } else if (storeCountry && action?.type == widgetTypes?.GET_WIDGETS.REQUEST) {
    currencyCode = storeCountry.currencyCode;
  }

  if (currencyCode) {
    API.setCurrencyCode(currencyCode);
    setCurrencyCode(currencyCode);
  }

  return next(action);
};
