/* eslint-disable react/display-name */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  View,
  I18nManager,
  ScrollView,
  TextInput,
  Modal,
  // Dimensions,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { actions, types } from '../../redux/address';
import { actions as addressActions } from '../../redux/address';
import { colors } from '../../common';
// import KeyboardManager from 'react-native-keyboard-manager';
import * as _ from 'lodash';
import {
  Text,
  // TextInput,
  SelectArea,
  // Spinner,
  Button,
  SwitchButton,
  AccordionInput,
  RadioOptionSelection,
  HeaderButton,
  Screen,
  DrawerWithBackIcons,
  // MapStaticImage,
  // MainAlert,
} from '../../components';
import { I18n } from '../../locales';
// import { geocoderLocation } from '../../services/locations';
import styles from './styles';
import {
  useCheckout,
  types as useCheckoutTypes,
} from '../../redux/useCheckout';
import {
  getSeparatedString,
  loadingSelector,
  showErrorMsg,
  showSuccessMsg,
} from '../../common/utils';
import {
  isMobile,
  navigateToPayment,
  replaceToManageAddresses,
} from '../../services';
import Areas from '../Areas';

// const screenWidth = Dimensions.get('screen').width;
const Address = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { config, user, areas, loading, address } = useSelector((state) => {
    return {
      areas: state.address.areas,
      config: state.config,
      user: state.user,
      loading: loadingSelector(state, [
        types.DELETE_ADDRESS,
        types.GET_AREAS,
        types.UPDATE_ADDRESS,
        useCheckoutTypes.SET_BILLING_ADDRESS,
        useCheckoutTypes.SET_SHIPPING_METHOD,
      ]),
      address: state.address,
    };
  });
  const [errors, setErrors] = useState({});
  const [areasVisible, setAreasVisible] = useState(false);
  const [state, setState] = useState({
    country: config.country || {},
    country_id: '',
    fullName: '',
    mobile: '',
    address: { id: 1, name: I18n.t('labels.home') },
    area_id: '',
    area_name: '',
    city: '',
    street: '',
    avenue: '',
    block: '',
    house: '',
    floor: '',
    extraGuidance: '',
    isDefault: isFirstAddress ? true : false,
    postcode: '',
    geolocation: {},
    showUpdateProfileModal: false,
    isFirstAddress,
  });
  const {
    fullName,
    mobile,
    city,
    street,
    avenue,
    block,
    house,
    country,
    area_id,
    area_name,
    floor,
    isDefault,
    extraGuidance,
    geolocation,
    showUpdateProfileModal,
  } = state;
  const { setShippingMethod, setBillingAddress } = useCheckout();
  const { addresses } = address;
  const isFirstAddress = addresses && addresses.length === 0 ? true : false;
  const _inputs = {};
  const {
    isCheckout,
    fromPayment,
    isEdit,
    isNew,
    address: _address,
    fromSettings,
  } = JSON.parse(route?.params?.params);

  const { key, enName, arName } = country;
  const isKW = key == 'KW';
  const address_Names = [
    { id: 1, name: I18n.t('labels.home') },
    { id: 2, name: I18n.t('labels.work') },
    { id: 3, name: I18n.t('labels.friend') },
  ];

  const selectedCity = _.find(areas, { id: state.area_id?.toString() });
  const selectedCityName = selectedCity ? selectedCity?.area_name : '';

  // const getInitialState = () => {
  //   const initialState = {
  //     country_id: '',
  //     fullName: '',
  //     mobile: '',
  //     address: { id: 1, name: I18n.t('labels.home') },
  //     area_id: '',
  //     area_name: '',
  //     city: '',
  //     street: '',
  //     avenue: '',
  //     block: '',
  //     house: '',
  //     floor: '',
  //     extraGuidance: '',
  //     isDefault: isFirstAddress ? true : false,
  //     postcode: '',
  //     geolocation: {},
  //     errors: {},
  //     showUpdateProfileModal: false,
  //     isFirstAddress,
  //   };
  //   setState({
  //     ...state,
  //     ...initialState,
  //   });
  // };

  // const resetState = () => {
  //   getInitialState();
  // };

  // const changeGeoLocation = async (area_name, country) => {
  //   const locationData = await geocoderLocation(`${area_name}, ${country}`);
  //   setState({
  //     ...state,
  //     geolocation: {
  //       lat: locationData.location.lat,
  //       lang: locationData.location.lng,
  //     },
  //   });
  // };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: 'center',
      headerTitle: getTitleText(),
      headerRight: () =>
        isEdit && (
          <HeaderButton
            elements={[
              {
                text: I18n.t('btns.delete'),
                onPress: () => _deleteAddress(),
              },
            ]}
          />
        ),
      headerLeft: () => <DrawerWithBackIcons navigation={navigation} />,
    });
  }, [navigation]);

  useEffect(() => {
    dispatch(addressActions.getAreas());
    getData();
    // if (Platform.OS === 'ios') {
    //   KeyboardManager.setEnable(true);
    //   KeyboardManager.setKeyboardDistanceFromTextField(50);
    //   KeyboardManager.setToolbarPreviousNextButtonEnable(true);
    // }
  }, []);
  const getData = () => {
    //if Edit Address
    if (isEdit && _address) {
      const {
        full_name,
        country_id,
        area_id,
        area_name,
        city,
        street_name,
        block_number,
        phone,
        house,
        floor,
        address_name,
        avenue,
        extra_guidance,
        default_shipping,
        postcode,
        geolocation,
      } = _address;

      setState({
        ...state,
        country_id,
        fullName: full_name,
        mobile: phone,
        address: address_name,
        area_id,
        area_name,
        city,
        street: street_name,
        avenue,
        block: block_number ? block_number : '',
        house,
        floor: floor ? floor : '',
        extraGuidance: extra_guidance ? extra_guidance : '',
        isDefault: default_shipping,
        postcode,
        geolocation,
      });
    }
    //adding new address set the user fullName and mobile by default
    else if (user && user.info) {
      const userInfo = user.info;
      const { firstname, lastname, custom_attributes } = userInfo;
      let mobile = null;
      const fullName = firstname + ' ' + lastname;
      if (custom_attributes && custom_attributes.length != 0) {
        const phone = custom_attributes.find(
          (val) => val.attribute_code == 'phone',
        ).value;
        mobile = phone;
      }
      setState({
        ...state,
        fullName,
        mobile,
      });
    }
  };

  const onChangeInput = (name, text) => {
    setState({ ...state, [name]: text });
  };

  const handleAreaChange = ({ id, area_name: area }) => {
    setState({ ...state, area_id: id, area_name: area, city: area });
    // isEdit && changeGeoLocation(state.area, state.country.enName);
  };

  const focusNext = (id) => {
    if (_inputs[id]) _inputs[id].focus();
  };

  const validate = () => {
    const errors = {};
    const isKW = country.key == 'KW';
    if (!fullName || !fullName.trim().length)
      errors.fullName = I18n.t('errors.fullname');
    if (!mobile || !mobile.trim().length) errors.mobile = I18n.t('errors.tel');
    if (isEdit && !state.address.trim().length)
      errors.address = I18n.t('errors.address');
    if (!city)
      errors.area = isKW ? I18n.t('errors.area') : I18n.t('errors.city');
    if (!street || !street.trim().length)
      errors.street = I18n.t('errors.street');
    if (!block || !block.trim().length) errors.block = I18n.t('errors.block');
    if (!house || !house.trim().length) errors.house = I18n.t('errors.house');
    if (!isKW) {
      if (!avenue || !avenue.trim().length)
        errors.avenue = I18n.t('errors.areaRequired');
    }
    return errors;
  };

  const navigateToAccountSettings = () => {
    navigation.navigate('AccountSettings', { fromCart: true });
    setState({ ...state, showUpdateProfileModal: false });
  };

  const handleNewAddress = () => {
    const address = {
      full_name: fullName.trim(), //trim() added Because add address the api crashes of the full_name starts with space
      country_id: country ? country.key : null,
      address_name: isEdit ? state.address : state.address.name,
      city,
      area_id,
      area_name,
      street_name: street,
      phone: mobile,
      avenue: avenue,
      block_number: block,
      house,
      floor,
      extra_guidance: extraGuidance,
      default_shipping: isDefault,
      postcode: '1',
      geolocation,
    };
    const newAddress = { ..._address, ...address };
    return newAddress;
  };

  const submitAddress = async () => {
    const { mobile: userMobile } = state;
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      setTimeout(() => setErrors({}), 8000);
      if (!userMobile && errors.mobile) {
        setState({ ...state, showUpdateProfileModal: true });
      }
    } else {
      const newAddress = handleNewAddress();
      await dispatch(addressActions.setSelectedAddress(newAddress));
      if (isEdit) {
        try {
          const resp = await dispatch(actions.updateAddress(newAddress));
          if (resp.type === types.UPDATE_ADDRESS.SUCCESS) {
            navigateToAddressesList();
          } else showErrorMsg(`error in update Address`);
        } catch (e) {
          showErrorMsg(`error in adding Address `);
        }
        return;
      }
      _addAddress(newAddress);
    }
  };

  const getBtnText = () => {
    if (isCheckout) return I18n.t('btns.nextStep');
    else if (isEdit) return I18n.t('btns.editAddress');
    else return I18n.t('btns.newAddress');
  };

  const getTitleText = () => {
    if (isCheckout) return I18n.t('delivery.title');
    else if (isEdit) return I18n.t('editAddress.title');
    else return I18n.t('newAddress.title');
  };

  const _deleteAddress = async () => {
    try {
      if (_address.id) {
        const resp = await dispatch(actions.deleteAddress(_address.id));
        if (resp.type === types.DELETE_ADDRESS.SUCCESS) {
          navigateToAddressesList();
        } else console.log('error in address deletion');
      }
    } catch (e) {
      console.log('error in address deletion', e);
    }
  };

  const onAddressNameSelect = (selected) => {
    onChangeInput('address', selected);
  };

  // const setCoordinates = ({ lat, lang }) => {
  //   setState({ ...state, geolocation: { lat, lang } });
  // };

  const navigateToAddressesList = () => {
    showSuccessMsg(I18n.t('btns.editSuccessfully'));
    setTimeout(() => {
      if (fromSettings) {
        replaceToManageAddresses({ fromSettings: true });
      } else {
        replaceToManageAddresses({ isCheckout: true });
      }
    }, 800);
  };

  const _addAddress = async (address) => {
    try {
      const resp = await dispatch(actions.addAddress(address));
      if (resp.type === types.ADD_ADDRESS.SUCCESS) {
        const lastAddress = resp.payload[resp.payload.length - 1];
        navigateAfterSuccess(lastAddress);
      } else showErrorMsg(`error in adding Address`);
    } catch (e) {
      showErrorMsg(`error in adding Address ${e}`);
    }
  };

  const navigateAfterSuccess = async (address) => {
    await dispatch(addressActions.setSelectedAddress(address));
    if (fromPayment || isCheckout) {
      estimateShipping(address);
    } else replaceToManageAddresses({ fromSettings });
  };

  const estimateShipping = async (address) => {
    const {
      country_id,
      city,
      area_code,
      full_name,
      street_name,
      phone,
      avenue,
      block_number,
      house,
      floor,
      extra_guidance,
      postcode,
    } = address;
    const addressData = [
      country ? country && country.enName : '',
      `City: ${city}`,
      `Avenue/Area: ${avenue ? avenue : ''}`,
      `Block: ${block_number ? block_number : ''}`,
      `St: ${street_name}`,
      `House: ${house ? house : ''}`,
      `Floor: ${floor ? floor : ''}`,
      `Extra-guidance: ${extra_guidance ? extra_guidance : ''}`,
    ];
    const shippingAddress = {
      firstname: full_name,
      lastname: full_name,
      street: [getSeparatedString(addressData)],
      city,
      region: area_code,
      postcode,
      country_code: country_id,
      telephone: phone,
    };
    _setBillingAddress(shippingAddress);
  };

  const _setBillingAddress = async (shippingAddress) => {
    try {
      const res = await setBillingAddress(shippingAddress);
      if (res?.payload?.id) {
        const availableShippingMethod =
          res?.payload?.shipping_addresses[0]?.available_shipping_methods[0];
        const shipping_methods = {
          carrier_code: availableShippingMethod?.carrier_code,
          method_code: availableShippingMethod?.method_code,
        };
        _setShippingMethod(shipping_methods);
      } else {
        showErrorMsg();
      }
    } catch (error) {
      showErrorMsg();
    }
  };

  const _setShippingMethod = async (shipping_methods) => {
    try {
      const res = await setShippingMethod(shipping_methods);
      if (res?.payload?.id) {
        navigateToPayment();
      } else {
        showErrorMsg();
      }
    } catch (error) {
      showErrorMsg();
    }
  };
  const onClose = () => {
    setAreasVisible(false);
  };

  return (
    <Screen isLoading={loading}>
      <Modal
        visible={areasVisible}
        animationType={'slide'}
        transparent
        onRequestClose={onClose}
      >
        <Areas {...{ handleAreaChange, onClose }} />
      </Modal>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 20 }}
      >
        <View style={styles.mainView}>
          <Text style={styles.shippingAddress2} size="larger">
            {`${I18n.t('delivery.shippingAddressTitle')}`}
          </Text>

          <View style={styles.nameAndMobileContainer}>
            <Text style={styles.label}>{I18n.t('placeholders.fullname')}</Text>
            <TextInput
              ref={(i) => (_inputs.name = i)}
              value={state.fullName}
              textContentType="name" //for iOS
              autoCompleteType="name" //for Android
              returnKeyType="next"
              blurOnSubmit={false}
              onSubmitEditing={() => focusNext('mobile')}
              onChangeText={(text) => onChangeInput('fullName', text)}
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.fullName && (
              <Text size="small" style={styles.errorMsg}>
                {errors.fullName}
              </Text>
            )}
            <Text style={styles.label}>{I18n.t('placeholders.tel')}</Text>
            <TextInput
              ref={(i) => (_inputs.mobile = i)}
              value={state.mobile}
              textContentType="telephoneNumber"
              autoCompleteType="tel"
              returnKeyType="next"
              blurOnSubmit={false}
              onSubmitEditing={() => focusNext('address')}
              onChangeText={(text) => onChangeInput('mobile', text)}
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.mobile && (
              <Text size="small" style={styles.errorMsg}>
                {errors.mobile}
              </Text>
            )}
          </View>

          {/* =============== new address name field ================== */}
          {!isEdit ? (
            <View style={styles.addressNameView}>
              <Text style={styles.label} size="small">
                {`${I18n.t('placeholders.addressName')}*`}
              </Text>
              <RadioOptionSelection
                options={address_Names}
                onSelection={onAddressNameSelect}
                optionSelected={state.address.id}
              />
              {errors?.address && (
                <Text
                  style={[styles.label, { color: colors.red600 }]}
                  size="small"
                >
                  {errors.address}
                </Text>
              )}
            </View>
          ) : (
            <>
              <Text style={styles.label}>
                {I18n.t('placeholders.addressName')}
              </Text>
              <TextInput
                ref={(i) => (_inputs.address = i)}
                value={state.address}
                returnKeyType="next"
                blurOnSubmit={false}
                onSubmitEditing={() => focusNext(isKW ? 'block' : 'GULFAvenue')}
                onChangeText={(text) => onChangeInput('address', text)}
                style={[styles.input, !isMobile && styles.inputWeb]}
              />
              {errors.address && (
                <Text size="small" style={styles.errorMsg}>
                  {errors.address}
                </Text>
              )}
            </>
          )}
          <Text style={styles.label}>{I18n.t('placeholders.country')}</Text>
          <TextInput
            containerStyle={styles.input}
            editable={false}
            value={I18nManager.isRTL ? arName : enName}
          />
          <SelectArea
            onPress={() => setAreasVisible(true)}
            placeholder={
              isKW
                ? `${I18n.t('placeholders.area')}*`
                : `${I18n.t('placeholders.city')}*`
            }
            selectedValue={selectedCityName}
            onValueChange={handleAreaChange}
            errorMessage={errors.area}
          />

          {!isKW ? (
            <>
              <Text style={styles.label}>
                {`${I18n.t('placeholders.area')}*`}
              </Text>
              <TextInput
                ref={(i) => (_inputs.GULFAvenue = i)}
                value={state.avenue}
                textContentType="addressState"
                returnKeyType="next"
                blurOnSubmit={false}
                onSubmitEditing={() => focusNext('block')}
                onChangeText={(text) => onChangeInput('avenue', text)}
                style={[styles.input, !isMobile && styles.inputWeb]}
              />
              {errors.avenue && (
                <Text size="small" style={styles.errorMsg}>
                  {errors.avenue}
                </Text>
              )}
            </>
          ) : null}
          <View style={styles.twoCols}>
            <Text style={styles.label}>
              {`${I18n.t('placeholders.block')}*`}
            </Text>
            <TextInput
              ref={(i) => (_inputs.block = i)}
              value={state.block}
              keyboardType="number-pad"
              returnKeyType="next"
              blurOnSubmit={false}
              onSubmitEditing={() => focusNext('street')}
              containerStyle={[styles.input, styles.twoColInput]}
              onChangeText={(text) => onChangeInput('block', text)}
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.block && (
              <Text size="small" style={styles.errorMsg}>
                {errors.block}
              </Text>
            )}
            <Text style={styles.label}>
              {`${I18n.t('placeholders.street')}*`}
            </Text>
            <TextInput
              ref={(i) => (_inputs.street = i)}
              value={state.street}
              textContentType="streetAddressLine1"
              autoCompleteType="street-address"
              returnKeyType="next"
              blurOnSubmit={false}
              onSubmitEditing={() => focusNext('house')}
              containerStyle={[styles.input, styles.twoColInput]}
              onChangeText={(text) => onChangeInput('street', text)}
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.street && (
              <Text size="small" style={styles.errorMsg}>
                {errors.street}
              </Text>
            )}
          </View>
          <View style={styles.twoCols}>
            <Text style={styles.label}>
              {`${I18n.t('placeholders.house')}*`}
            </Text>
            <TextInput
              ref={(i) => (_inputs.house = i)}
              value={state.house}
              label={`${I18n.t('placeholders.house')}*`}
              returnKeyType="next"
              blurOnSubmit={false}
              onSubmitEditing={() => focusNext('floor')}
              onChangeText={(text) => onChangeInput('house', text)}
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
            {errors.house && (
              <Text size="small" style={styles.errorMsg}>
                {errors.house}
              </Text>
            )}
            <Text style={styles.label}>
              {`${I18n.t('placeholders.floor')}`}
            </Text>
            <TextInput
              ref={(i) => (_inputs.floor = i)}
              value={state.floor}
              label={I18n.t('placeholders.floor')}
              keyboardType="number-pad"
              returnKeyType="next"
              blurOnSubmit={false}
              onSubmitEditing={() =>
                focusNext(isKW ? 'avenue' : 'extraGuidance')
              }
              onChangeText={(text) => onChangeInput('floor', text)}
              style={[styles.input, !isMobile && styles.inputWeb]}
            />
          </View>

          {isKW ? (
            <>
              <Text style={styles.label}>
                {`${I18n.t('placeholders.avenue')}`}
              </Text>
              <TextInput
                ref={(i) => (_inputs.avenue = i)}
                value={state.avenue}
                label={I18n.t('placeholders.avenue')}
                // returnKeyType="next"
                returnKeyType="done"
                blurOnSubmit={false}
                // onSubmitEditing={() => focusNext('extraGuidance')}
                onChangeText={(text) => onChangeInput('avenue', text)}
                style={[styles.input, !isMobile && styles.inputWeb]}
              />
            </>
          ) : null}
          {/*---------'extra guidance input'---------*/}
          <View style={styles.accordionView}>
            <AccordionInput
              title={I18n.t('placeholders.extraGuidance')}
              ref={(i) => (_inputs.extraGuidance = i)}
              returnKeyType="done"
              onCommentChange={(text) => onChangeInput('extraGuidance', text)}
              comment={state.extraGuidance}
            />
          </View>
          {/* <TextInput
              ref={i => (_inputs.extraGuidance = i)}
              value={state.extraGuidance}
              label={I18n.t('placeholders.extraGuidance')}
              returnKeyType="done"
              containerStyle={styles.input}
              onChangeText={text => onChangeInput('extraGuidance', text)}
            /> */}
          {/* {isEdit && (
            <MapStaticImage
              latitude={geolocation.lat ? geolocation.lat : LATITUDE}
              longitude={geolocation.lang ? geolocation.lang : LONGITUDE}
              width={screenWidth}
              height={160}
              onPress={() =>
                navigation.push('Map', {
                  isEdit,
                  setCoordinates,
                  address: handleNewAddress(),
                  isNew,
                  isCheckout,
                  fromPayment,
                  resetForm: resetState,
                })
              }
            >
              <View style={styles.mapEditTextView}>
                <Text
                  style={[styles.label, { color: colors.black }]}
                  size="small"
                >
                  {I18n.t('btns.tabToEdit')}
                </Text>
              </View>
            </MapStaticImage>
          )} */}
          {!isFirstAddress ? (
            <View style={[styles.row2, styles.defaultAddress]}>
              <Text style={styles.forSomeOne}>
                {I18n.t('placeholders.defaultAddress')}
              </Text>
              <SwitchButton
                value={isDefault}
                toggleValue={(checked) => {
                  setState({ ...state, isDefault: checked });
                }}
              />
            </View>
          ) : null}
        </View>

        <View
          style={[
            styles.endView,
            isEdit && !isMobile && { flexDirection: 'row' },
          ]}
        >
          <Button
            text={getBtnText()}
            onPress={submitAddress}
            containerStyle={styles.addBtn}
          />
          {isEdit && !isMobile && (
            <Button
              text={I18n.t('btns.delete')}
              onPress={() => _deleteAddress()}
              textStyle={{ color: colors.buttons }}
              containerStyle={{
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: colors.buttons,
              }}
            />
          )}
        </View>
      </ScrollView>
      {/* <MainAlert
        isVisible={showUpdateProfileModal}
        title={I18n.t('newAddress.missingNumber')}
        description={I18n.t('newAddress.missingNumberSubTitle')}
        buttons={[
          {
            title: I18n.t('btns.editProfile'),
            onPress: navigateToAccountSettings,
          },
        ]}
      /> */}
    </Screen>
  );
};

export default Address;
