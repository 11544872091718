import { StyleSheet } from 'react-native';
import { colors } from '../../common';
import { isRTL } from '../../services';

const styles = StyleSheet.create({
  container: {
    // marginRight: 10,
  },
  imageStyle: {
    width: 22,
    height: 22,
  },
  badgeContainer: {
    width: 20,
    height: 20,
    bottom: 10,
    right: isRTL() ? -10 : 10,
    padding: 0,
    paddingVertical: 0,
    position: 'absolute',
    backgroundColor: colors.cartIconColor,
  },
  badgeTextStyle: {
    fontWeight: 'bold',
  },
});

export default styles;
