import { TAP_BASE_URL, TAP_TOKEN } from '../common/constants';
import axios from 'axios';

export const retrieveCharge = (tap_id) => {
  return tapRequest({
    method: 'get',
    api: `charges?chargeID=${tap_id}`,
  });
};

const tapRequest = ({ method, api, params, data }) => {
  return axios({
    method,
    url: `${TAP_BASE_URL}/${api}`,
    timeout: 4000,
    params,
    data,
    headers: { Authorization: TAP_TOKEN },
  });
};
